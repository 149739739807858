// Home Screen for logged in tutor
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import { Picker } from "@react-native-picker/picker";
import NumericTextInput from "@wwdrew/react-native-numeric-textinput";
import * as ImagePicker from "expo-image-picker";
import {
	collection,
	doc,
	getDocs,
	onSnapshot,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadBytesResumable,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
	Image,
	Platform,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import {
	Menu,
	MenuOption,
	MenuOptions,
	MenuTrigger,
} from "react-native-popup-menu";
import { useMediaQuery } from "react-responsive";

// File imports
import { Header as AuthHeader } from "../../../components/AuthHeader";
import { CustomTimeInput } from "../../../components/CustomTimeInput";
import Footer from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { LoadingPage } from "../../../components/LoadingPage";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

// Data for pickers
import { languages as languagesData } from "../../../config/languages";
import {
	provinces as provincesData,
	statesUSA,
} from "../../../config/locations";
import { schools } from "../../../config/schools";
import { subjects as subjectsData } from "../../../config/subjects";

// Edit Tutor Profile Screen
export const EditProfile = ({ navigation, setEditProfile, route, params }) => {
	// Contexts
	const { isTutor, isVerified, chats, user, name, profile, userId } =
		useUserContext();
	const { db } = useFirebaseContext();

	// alert
	const customAlert = useAlert();
	const [image, setImage] = useState(
		profile
			? profile
			: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e"
	);

	const [original, setOriginalInfo] = useState(null);
	const [view, setView] = useState("personal");
	const [updateName, setUpdateName] = useState(name);
	const [updateEmail, setUpdateEmail] = useState("");
	const [city, setCity] = useState("");
	const [selectedProvince, setSelectedProvince] = useState("");
	const [selectedCountry, setSelectedCountry] = useState("");
	const [selectedUniversity, setSelectedUniversity] = useState("");
	const [selectedDegree, setSelectedDegree] = useState("");
	const [selectedCompletionYear, setSelectedCompletionYear] = useState("");
	const [selectedDegreeType, setSelectedDegreeType] = useState("");
	const [selectedSubject, setSelectedSubject] = useState([]);
	const [selectedSkill, setSelectedSkill] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState([]);
	const [selectedRate, setSelectedRate] = useState("");
	const [description, setDescription] = useState("");
	const [degreeArray, setDegreeArray] = useState([]);
	const [customUniversity, setCustomUniversity] = useState("");

	// TIME CODE ---------------------------------------------------------------
	const timeToMinutes = (timeString) => {
		const [time, period] = timeString.split(" ");
		const [hours, minutes] = time.split(":");
		let convertedHours = parseInt(hours);
		const convertedMinutes = parseInt(minutes);

		if (period === "PM" && convertedHours !== 12) {
			convertedHours += 12;
		}

		return convertedHours * 60 + convertedMinutes;
	};

	const [alwaysAvailable, setAlwaysAvailable] = useState(false);
	const [userAvailability, setUserAvailability] = useState({
		monday: {
			start: null,
			end: null,
		},
		tuesday: {
			start: null,
			end: null,
		},
		wednesday: {
			start: null,
			end: null,
		},
		thursday: {
			start: null,
			end: null,
		},
		friday: {
			start: null,
			end: null,
		},
		saturday: {
			start: null,
			end: null,
		},
		sunday: {
			start: null,
			end: null,
		},
	});
	const [showMonday, setShowMonday] = useState(false);
	const [showTuesday, setShowTuesday] = useState(false);
	const [showWednesday, setShowWednesday] = useState(false);
	const [showThursday, setShowThursday] = useState(false);
	const [showFriday, setShowFriday] = useState(false);
	const [showSaturday, setShowSaturday] = useState(false);
	const [showSunday, setShowSunday] = useState(false);

	const [monday, setMonday] = useState({
		start: null,
		end: null,
	});

	const [tuesday, setTuesday] = useState({
		start: null,
		end: null,
	});
	const [wednesday, setWednesday] = useState({
		start: null,
		end: null,
	});
	const [thursday, setThursday] = useState({
		start: null,
		end: null,
	});
	const [friday, setFriday] = useState({
		start: null,
		end: null,
	});

	const [saturday, setSaturday] = useState({
		start: null,
		end: null,
	});
	const [sunday, setSunday] = useState({
		start: null,
		end: null,
	});

	// Availability formatting

	// TIME CODE ---------------------------------------------------------------

	// Make ID string for image name
	const makeid = (length) => {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	};

	const getUserInfo = async function () {
		if (!user && isTutor) {
			return <LoadingPage> </LoadingPage>;
		} else {
			const q = query(
				collection(db, "users"),
				where("uid", "==", userId)
			);
			onSnapshot(q, (querySnapshot) => {
				querySnapshot.forEach((doc) => {
					if (doc.data().picture) {
						setImage(doc.data().picture);
					}
					setUpdateName(doc.data().name);
					setUpdateEmail(doc.data().email);
					setCity(doc.data().location.city);
					setSelectedProvince(doc.data().location.province);
					setSelectedCountry(doc.data().location.country);
					Object.keys(doc.data().subjects).map((key) =>
						selectedSubject.push(key)
					);
					setSelectedSkill(doc.data().skills);
					Object.keys(doc.data().languages).map((key) =>
						selectedLanguage.push(key)
					);
					setSelectedRate(doc.data().rate);
					setDescription(doc.data().description);
					setDegreeArray(doc.data().degrees);

					if (doc.data().alwaysAvailable) {
						setAlwaysAvailable(true);
					}

					if (doc.data().availability) {
						setUserAvailability(doc.data().availability);

						// TODO: Load availability in new format
						setShowMonday(
							doc.data().availability.monday.start ? true : false
						);

						setMonday({
							start: doc.data().availability.monday.start
								? doc.data().availability.monday.start
								: null,
							end: doc.data().availability.monday.end
								? doc.data().availability.monday.end
								: null,
						});

						setShowTuesday(
							doc.data().availability.tuesday.start ? true : false
						);
						setTuesday({
							start: doc.data().availability.tuesday.start
								? doc.data().availability.tuesday.start
								: null,
							end: doc.data().availability.tuesday.end
								? doc.data().availability.tuesday.start
								: null,
						});

						setShowWednesday(
							doc.data().availability.wednesday.start
								? true
								: false
						);
						setWednesday({
							start: doc.data().availability.wednesday.start
								? doc.data().availability.wednesday.start
								: null,
							end: doc.data().availability.wednesday.end
								? doc.data().availability.wednesday.end
								: null,
						});

						setShowThursday(
							doc.data().availability.thursday.start
								? true
								: false
						);
						setThursday({
							start: doc.data().availability.thursday.start
								? doc.data().availability.thursday.start
								: null,
							end: doc.data().availability.thursday.end
								? doc.data().availability.thursday.end
								: null,
						});

						setShowFriday(
							doc.data().availability.friday.start ? true : false
						);
						setFriday({
							start: doc.data().availability.friday.start
								? doc.data().availability.friday.start
								: null,
							end: doc.data().availability.friday.end
								? doc.data().availability.friday.end
								: null,
						});

						setShowSaturday(
							doc.data().availability.saturday.start
								? true
								: false
						);
						setSaturday({
							start: doc.data().availability.saturday.start
								? doc.data().availability.saturday.start
								: null,
							end: doc.data().availability.saturday.end
								? doc.data().availability.saturday.end
								: null,
						});

						setShowSunday(
							doc.data().availability.sunday.start ? true : false
						);
						setSunday({
							start: doc.data().availability.sunday.start
								? doc.data().availability.sunday.start
								: null,
							end: doc.data().availability.sunday.end
								? doc.data().availability.sunday.end
								: null,
						});
					}
				});
			});
		}
	};

	useEffect(() => {
		getUserInfo();
	}, [user, isTutor]);

	// Reset degree values
	const resetDegreeValues = () => {
		setSelectedDegree("");
		setSelectedUniversity("");
		setSelectedCompletionYear("");
		setSelectedDegreeType("");
		setCustomUniversity("");
	};

	//  update user info
	const updateUserInfo = async function () {
		// validate input
		if (selectedLanguage.length === 0) {
			customAlert.error(
				"[Edit Profile Error] Please select at least one language!"
			);
			return;
		}
		if (selectedSubject.length === 0) {
			customAlert.error(
				"[Edit Profile Error] Please select at least one subject!"
			);
			return;
		}
		if (selectedRate > 1000) {
			customAlert.error(
				"[Edit Profile Error] Please enter a rate less than $1000!"
			);
			return;
		}
		if (selectedRate < 0) {
			customAlert.error(
				"[Edit Profile Error] Please enter a rate greater than $0!"
			);
			return;
		}
		if (degreeArray.length > 3) {
			customAlert.error(
				"[Edit Profile Error] Please enter at most three degrees!"
			);
			return;
		}

		// add boolleen to language and subject
		let languages = {};
		let subjects = {};
		selectedLanguage.forEach((language) => {
			languages[language] = true;
		});
		selectedSubject.forEach((subject) => {
			subjects[subject] = true;
		});

		var availability = {
			monday: {
				start: null,
				end: null,
			},
			tuesday: {
				start: null,
				end: null,
			},
			wednesday: {
				start: null,
				end: null,
			},
			thursday: {
				start: null,
				end: null,
			},
			friday: {
				start: null,
				end: null,
			},
			saturday: {
				start: null,
				end: null,
			},
			sunday: {
				start: null,
				end: null,
			},
		};

		// Validate availability dates
		if (!alwaysAvailable) {
			if (showMonday) {
				if (!monday.start || !monday.end) {
					customAlert.error(
						"[Edit Profile Error] Please enter a start and end time for Monday!"
					);
					return;
				}

				// Check if start time is before end time
				if (timeToMinutes(monday.start) >= timeToMinutes(monday.end)) {
					customAlert.error(
						"[Edit Profile Error] Monday start time must be before end time!"
					);
					return;
				}

				availability.monday.start = monday.start;
				availability.monday.end = monday.end;
			}

			if (showTuesday) {
				if (!tuesday.start || !tuesday.end) {
					customAlert.error(
						"[Edit Profile Error] Please enter a start and end time for Tuesday!"
					);
					return;
				}

				// Check if start time is before end time
				if (
					timeToMinutes(tuesday.start) >= timeToMinutes(tuesday.end)
				) {
					customAlert.error(
						"[Edit Profile Error] Tuesday start time must be before end time!"
					);
					return;
				}

				availability.tuesday.start = tuesday.start;
				availability.tuesday.end = tuesday.end;
			}

			if (showWednesday) {
				if (!wednesday.start || !wednesday.end) {
					customAlert.error(
						"[Edit Profile Error] Please enter a start and end time for Wednesday!"
					);
					return;
				}

				// Check if start time is before end time
				if (
					timeToMinutes(wednesday.start) >=
					timeToMinutes(wednesday.end)
				) {
					customAlert.error(
						"[Edit Profile Error] Wednesday start time must be before end time!"
					);
					return;
				}

				availability.wednesday.start = wednesday.start;
				availability.wednesday.end = wednesdaytuesday.end;
			}

			if (showThursday) {
				if (!thursday.start || !thursday.end) {
					customAlert.error(
						"[Edit Profile Error] Please enter a start and end time for Thursday!"
					);
					return;
				}

				// Check if start time is before end time
				if (
					timeToMinutes(thursday.start) >= timeToMinutes(thursday.end)
				) {
					customAlert.error(
						"[Edit Profile Error] Thursday start time must be before end time!"
					);
					return;
				}

				availability.thursday.start = thursday.start;
				availability.thursday.end = thursday.end;
			}

			if (showFriday) {
				if (!friday.start || !friday.end) {
					customAlert.error(
						"[Edit Profile Error] Please enter a start and end time for Friday!"
					);
					return;
				}

				// Check if start time is before end time
				if (timeToMinutes(friday.start) >= timeToMinutes(friday.end)) {
					customAlert.error(
						"[Edit Profile Error] Friday start time must be before end time!"
					);
					return;
				}

				availability.friday.start = friday.start;
				availability.friday.end = friday.end;
			}

			if (showSaturday) {
				if (!saturday.start || !saturday.end) {
					customAlert.error(
						"[Edit Profile Error] Please enter a start and end time for Saturday!"
					);
					return;
				}

				// Check if start time is before end time
				if (
					timeToMinutes(saturday.start) >= timeToMinutes(saturday.end)
				) {
					customAlert.error(
						"[Edit Profile Error] Saturday start time must be before end time!"
					);
					return;
				}

				availability.saturday.start = saturday.start;
				availability.saturday.end = saturday.end;
			}

			if (showSunday) {
				if (!sunday.start || !sunday.end) {
					customAlert.error(
						"[Edit Profile Error] Please enter a start and end time for Sunday!"
					);
					return;
				}

				// Check if start time is before end time
				if (timeToMinutes(sunday.start) >= timeToMinutes(sunday.end)) {
					customAlert.error(
						"[Edit Profile Error] Sunday start time must be before end time!"
					);
					return;
				}

				availability.sunday.start = sunday.start;
				availability.sunday.end = sunday.end;
			}
		}

		const userRef = collection(db, "users");
		const q = query(userRef, where("uid", "==", user.uid));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setOriginalInfo(doc.data());
			setOriginalInfo(doc.data());
			updateDoc(doc.ref, {
				name: updateName,
				rate: selectedRate,
				email: updateEmail,
				skills: selectedSkill,
				languages: languages,
				subjects: subjects,
				location: {
					city: city,
					province: selectedProvince,
					country: selectedCountry,
				},
				description: description,
				degrees: degreeArray,
				availability: availability,
				alwaysAvailable: alwaysAvailable,
			});
		});
		customAlert.success(
			"[Profile Updated] The changes to your profile have been saved!"
		);
	};

	// State

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	if (darkMode) {
		// Import darkmode CSS DarkSearchInput.css
		// require('../DarkSearchInput.css');
	} else {
		// Import lightmode CSS
		// require('../LightSearchInput.css');
	}

	// DATA
	const [cities, setCities] = useState([
		{ city: "Select city", enabled: false },
	]);

	const [provinces, setProvinces] = useState([
		{ province: "Select province", enabled: false },
	]);

	const [subjects, setSubjects] = useState([
		{ subject: "Select subject", enabled: false, value: "Select subject" },
	]);

	const [skills, setSkills] = useState([
		{ skill: "Select skills", enabled: false },
	]);

	const [universities, setUniversities] = useState([
		{ school: "Select university", enabled: false },
	]);
	const degrees = [
		{ name: "Select degree", enabled: false },
		{ name: "Bachelor of Arts", enabled: true },
		{ name: "Bachelor of Business Administration", enabled: true },
		{ name: "Bachelor of Commerce", enabled: true },
		{ name: "Bachelor of Computer Science", enabled: true },
		{ name: "Bachelor of Education", enabled: true },
		{ name: "Bachelor of Engineering", enabled: true },
		{ name: "Bachelor of Fine Arts", enabled: true },
		{ name: "Bachelor of Health Sciences", enabled: true },
		{ name: "Bachelor of Journalism", enabled: true },
		{ name: "Bachelor of Laws (LLB)", enabled: true },
		{ name: "Bachelor of Music", enabled: true },
		{ name: "Bachelor of Nursing", enabled: true },
		{ name: "Bachelor of Science", enabled: true },
		{ name: "Bachelor of Social Work", enabled: true },
		{ name: "Bachelor of Technology", enabled: true },
		{
			name: "Bachelor of Tourism and Hospitality Management",
			enabled: true,
		},
	];

	const [languages, setLanguages] = useState([
		{ lang: "Select language", enabled: false },
	]);

	const years = [
		{ year: "Select year", enabled: false },
		{ year: "In progress", enabled: true },
		{ year: 2023, enabled: true },
		{ year: 2022, enabled: true },
		{ year: 2021, enabled: true },
		{ year: 2020, enabled: true },
		{ year: 2019, enabled: true },
		{ year: 2018, enabled: true },
		{ year: 2017, enabled: true },
		{ year: 2016, enabled: true },
		{ year: 2015, enabled: true },
		{ year: 2014, enabled: true },
		{ year: 2013, enabled: true },
		{ year: 2012, enabled: true },
		{ year: 2011, enabled: true },
		{ year: 2010, enabled: true },
		{ year: 2009, enabled: true },
		{ year: 2008, enabled: true },
		{ year: 2007, enabled: true },
		{ year: 2006, enabled: true },
		{ year: 2005, enabled: true },
		{ year: 2004, enabled: true },
		{ year: 2003, enabled: true },
		{ year: 2002, enabled: true },
		{ year: 2001, enabled: true },
		{ year: 2000, enabled: true },
		{ year: 1999, enabled: true },
		{ year: 1998, enabled: true },
		{ year: 1997, enabled: true },
		{ year: 1996, enabled: true },
		{ year: 1995, enabled: true },
		{ year: 1994, enabled: true },
		{ year: 1993, enabled: true },
		{ year: 1992, enabled: true },
		{ year: 1991, enabled: true },
		{ year: 1990, enabled: true },
		{ year: 1989, enabled: true },
	];

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Degree info
	const degreeInfo = {
		school:
			selectedUniversity == "Other"
				? customUniversity
				: selectedUniversity,
		name: selectedDegree,
		type: selectedDegreeType,
		year: selectedCompletionYear,
	};

	// Pick image from gallery
	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});

		if (!result.cancelled) {
			// Set local image
			setImage(result.uri);
			// Upload image to firebase storage
			const storage = getStorage();
			let imageFile = await fetch(result.uri);
			let name =
				imageFile._bodyBlob && imageFile._bodyBlob._data
					? imageFile._bodyBlob._data.name
					: makeid(12);
			let imageBlob = await imageFile.blob();
			const storageRef = ref(storage, `${user.uid}/profile`);
			let task = uploadBytesResumable(storageRef, imageBlob);
			task.on(
				"state_changed",
				(snapshot) => {},
				(error) => {
					Platform.OS === "web"
						? customAlert.error(error)
						: Alert.alert("Error uploading image!", error, [
								{
									text: "OK",
									onPress: () => console.log("OK Pressed"),
								},
						  ]);
				},
				() => {
					getDownloadURL(task.snapshot.ref).then((downloadURL) => {
						let userRef = doc(db, "users", user.uid);
						updateDoc(userRef, {
							picture: downloadURL,
						})
							.then((response) => {
								setImage(downloadURL);
							})
							.catch((error) => {
								Platform.OS === "web"
									? customAlert.error(error.message)
									: Alert.alert(
											"Error uploading image!",
											error.message,
											[
												{
													text: "OK",
													onPress: () =>
														console.log(
															"OK Pressed"
														),
												},
											]
									  );
							});
					});
				}
			);
		}
	};

	// Create stylesheet
	const styles = StyleSheet.create({
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		mainSection: {
			flex: 1,
			padding: 45,
			paddingTop: 0,
		},
		title: {
			fontSize: 22,
			color: text,
			fontFamily: defaultStyles.titleFont,
		},
		container: {
			backgroundColor: background,
			flex: 1,
			height: "100vh",
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			justifyContent: "center",
			alignItems: isSm ? "flex-start" : "center",
			borderBottomWidth: 1,
			borderBottomColor: border,
			paddingBottom: 15,
		},
		imageButton: {
			backgroundColor: backgroundVariant,
			borderRadius: 10,
			width: 90,
			height: 90,
			alignSelf: isSm ? "flex-start" : "center",
			// justifyContent:"center",
			// alignItems:"center",
			borderWidth: 1,
			borderColor: border,
		},
		name: {
			marginTop: 5,
			fontSize: 36,
			color: text,
			fontFamily: defaultStyles.titleFont,
		},
		email: {
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
		},
		title: {
			// marginTop: 30,
			fontSize: 22,
			color: text,
			fontFamily: defaultStyles.titleFont,
		},
		listItem: {
			marginTop: 15,
		},
		icon: {
			width: 50,
			height: 50,
			borderRadius: defaultStyles.radius,
			backgroundColor: backgroundVariant,
			marginRight: 15,
			justifyContent: "center",
			alignItems: "center",
		},
		listText: {
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
		},
		details: {
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
		},
		iconContainer: {
			height: 50,
			width: 50,
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			justifyContent: "center",
			alignItems: "center",
			marginVertical: "auto",
			marginBottom: 10,
		},
		input: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 5,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
		},
		buttonText: {
			fontFamily: defaultStyles.boldFont,
			fontSize: 16,
			color: text,
			justifyContent: "center",
		},
		button: {
			backgroundColor: backgroundVariant,
			paddingHorizontal: 15,
			paddingVertical: 30,
			borderRadius: defaultStyles.buttonRadius,
			width: "100%",
		},
		subTitle: {
			color: textVariant,
			fontSize: 14,
			fontFamily: defaultStyles.boldFont,
		},
		wordContainer: {
			display: "flex",
			flexDirection: "row",
			width: "90%",
			paddingVertical: 10,
			flexWrap: "wrap",
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			paddingVertical: 15,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			fontSize: 16,
		},

		menuText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
		},
		sectionTitle: {
			fontSize: 30,
			color: text,
			fontFamily: defaultStyles.titleFont,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 150,
		},
		addDegreeButton: {
			color: primary,
			fontSize: 14,
			fontFamily: defaultStyles.boldFont,
			marginTop: 10,
		},
		updateButton: {
			marginLeft: 10,
			backgroundColor: backgroundVariant,
			height: 45,
			borderRadius: defaultStyles.buttonRadius,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			border: "1px solid " + border,
		},
		saveButton: {
			marginLeft: 10,
			padding: 15,
			backgroundColor: primary,
			height: 45,
			borderRadius: defaultStyles.buttonRadius,
			justifyContent: "center",
		},
	});

	if (!isTutor !== null && !user) {
		// Redirect to settings
		navigation.navigate("Profile", {
			screen: "Profile",
		});
	}

	if (isTutor === false) {
		// Redirect to settings
		navigation.navigate("Profile", {
			screen: "Profile",
		});
	}

	useEffect(() => {
		if (subjectsData) {
			let array = [];
			let subjectKeys = Object.keys(subjectsData);
			subjectKeys.map((subject) => {
				array.push({
					label: subject,
					subject: subject,
					enabled: true,
				});
			});
			// Sort alphabetically
			array.sort((a, b) => (a.label > b.label ? 1 : -1));
			array.unshift({
				label: "Select a subject",
				subject: "Select a subject",
				enabled: false,
			});
			setSubjects(array);

			// Set skills
			let skillsArray = [];
			subjectKeys.map((subject) => {
				let subjectData = subjectsData[subject];
				subjectData.map((skill) => {
					skillsArray.push({
						label: skill,
						skill: skill,
						enabled: true,
					});
				});
			});

			// Sort alphabetically
			skillsArray.sort((a, b) => (a.label > b.label ? 1 : -1));
			skillsArray.unshift({
				label: "Select a skill",
				skill: "Select a skill",
				enabled: false,
			});
			setSkills(skillsArray);
		}

		if (languagesData) {
			let array = [];
			languagesData.map((language) => {
				array.push({
					label: language,
					lang: language,
					enabled: true,
				});
			});
			// Sort alphabetically
			array.sort((a, b) => (a.label > b.label ? 1 : -1));
			array.unshift({
				label: "Select a language",
				lang: "Select a language",
				enabled: false,
			});
			setLanguages(array);
		}

		if (schools) {
			let array = [];
			schools.map((school) => {
				array.push({
					label: school,
					school: school,
					enabled: true,
				});
			});
			// Sort alphabetically
			array.sort((a, b) => (a.label > b.label ? 1 : -1));
			array.unshift({
				label: "Select a school",
				school: "Select a school",
				enabled: false,
			});
			array.push({
				label: "Other",
				school: "Other",
				enabled: true,
			});
			setUniversities(array);
		}

		if (selectedCountry == "US") {
			if (statesUSA) {
				let array = [];
				statesUSA.map((province) => {
					array.push({
						label: province,
						province: province,
						enabled: true,
					});
				});
				// Sort alphabetically
				array.sort((a, b) => (a.label > b.label ? 1 : -1));
				array.unshift({
					label: "Select a province",
					province: "Select a province",
					enabled: false,
				});
				setProvinces(array);
			}
		} else {
			if (provincesData) {
				let array = [];
				provincesData.map((province) => {
					array.push({
						label: province,
						province: province,
						enabled: true,
					});
				});
				// Sort alphabetically
				array.sort((a, b) => (a.label > b.label ? 1 : -1));
				array.unshift({
					label: "Select a province",
					province: "Select a province",
					enabled: false,
				});
				setProvinces(array);
			}
		}
	}, [selectedCountry, selectedProvince]);

	useEffect(() => {
		if (selectedSubject.length === 0) {
		} else {
			// Set the skills to the matching subjects
			let skillsArray = [];
			selectedSubject.map((subject) => {
				let subjectData = subjectsData[subject];
				subjectData.map((skill) => {
					skillsArray.push({
						label: skill,
						skill: skill,
						enabled: true,
					});
				});
			});
			// Sort alphabetically
			skillsArray.sort((a, b) => (a.label > b.label ? 1 : -1));
			skillsArray.unshift({
				label: "Select a skill",
				skill: "Select a skill",
				enabled: false,
			});
			setSkills(skillsArray);
		}
	}, [selectedSubject]);

	// Skills / subject / language component
	const WordCard = ({ word, array }) => {
		return (
			<View
				style={{
					marginRight: 5,
					backgroundColor: backgroundVariant,
					borderRadius: defaultStyles.buttonRadius,
					padding: 5,
					border: `.5px solid ${border}`,
					display: "flex",
					flexDirection: "row",
					alignContent: "center",
					alignItems: "center",
					justifyContent: "space-evenly",
					height: 50,
				}}
			>
				<Text
					style={{
						fontSize: 14,
						color: textVariant,
						fontFamily: defaultStyles.regularFont,
						padding: 5,
					}}
				>
					{word}
				</Text>
				<TouchableOpacity
					style={{ paddingHorizontal: 5 }}
					onPress={() => {
						// Remove from subjects array
						if (array == "subject") {
							let newArray = [...selectedSubject];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedSubject(newArray);
						}
						// Remove from skills array
						else if (array == "skill") {
							let newArray = [...selectedSkill];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedSkill(newArray);
						}
						// Remove from languages array
						else if (array == "languages") {
							let newArray = [...selectedLanguage];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedLanguage(newArray);
						}
					}}
				>
					<CloseOutlined
						style={{ fontSize: 14, color: text }}
					></CloseOutlined>
				</TouchableOpacity>
			</View>
		);
	};

	// Degree component
	const DegreeCard = ({ year, degree, school, type }) => {
		return (
			<View
				style={{
					//   height: 75,
					padding: 10,
					minWidth: 150,
					borderRadius: defaultStyles.radius,
					backgroundColor: backgroundVariant,
					border: `.5px solid ${border}`,
					marginTop: 10,
					marginRight: 10,
				}}
			>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<View>
						<Text
							style={{
								color: primary,
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
								marginBottom: 2.5,
							}}
						>
							{degree}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginBottom: 5,
							}}
						>
							{type}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
							}}
						>
							{school}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{year}
						</Text>
					</View>
					<View>
						<TouchableOpacity
							onPress={() => {
								let newArray = [...degreeArray];
								newArray = newArray.filter(function (item) {
									return item.year !== year;
								});
								// Set state to mutated array
								setDegreeArray(newArray);
							}}
						>
							<CloseOutlined
								style={{
									fontSize: 14,
									color: text,
									padding: 10,
								}}
							></CloseOutlined>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};
	// Navigation component
	const Navigation = () => {
		return (
			// <View
			//   style={{
			//     display: 'flex',
			//     flexDirection: 'row',
			//     backgroundColor: background,
			//     width: 'fit-content',
			//     height: 50,
			//     borderRadius: defaultStyles.radius,
			//     justifyContent: 'space-between',
			//     alignContent: 'center',
			//     alignItems: 'center',
			//     marginBottom: 15,
			//   }}
			// >
			//   {/* personal btn  */}
			//   <TouchableOpacity
			//     style={{
			//       backgroundColor: background,
			//       padding: 15,
			//       paddingLeft: 0,
			//       borderRadius: defaultStyles.buttonRadius,
			//       display: 'flex',
			//       flexDirection: 'row',
			//       alignContent: 'center',
			//       justifyContent: 'center',
			//       alignItems: 'center',
			//     }}
			//     onPress={() => {
			//       setView('personal');
			//     }}
			//   >
			//     <Text
			//       style={{
			//         color: view === 'personal' ? primary : text,
			//         fontSize: 14,
			//         fontFamily:
			//           view == 'personal'
			//             ? defaultStyles.boldFont
			//             : defaultStyles.regularFont,
			//       }}
			//     >
			//       Personal information
			//     </Text>
			//     {view === 'personal' ? (
			//       <FontAwesome
			//         style={{ marginLeft: 5, paddingTop: 2 }}
			//         name='chevron-right'
			//         size={12}
			//         color={view == 'personal' ? primary : text}
			//       />
			//     ) : null}
			//   </TouchableOpacity>
			//   {/* Tutor btn  */}
			//   <TouchableOpacity
			//     style={{
			//       backgroundColor: background,
			//       padding: 15,
			//       borderRadius: defaultStyles.buttonRadius,
			//       display: 'flex',
			//       flexDirection: 'row',
			//       alignContent: 'center',
			//       justifyContent: 'center',
			//       alignItems: 'center',
			//     }}
			//     onPress={() => {
			//       setView('tutor');
			//     }}
			//   >
			//     <Text
			//       style={{
			//         color: view === 'tutor' ? primary : text,
			//         fontSize: 14,
			//         fontFamily:
			//           view == 'tutor'
			//             ? defaultStyles.boldFont
			//             : defaultStyles.regularFont,
			//       }}
			//     >
			//       Tutor details
			//     </Text>
			//     {view === 'tutor' && isTutor ? (
			//       <FontAwesome
			//         style={{ marginLeft: 5, paddingTop: 2 }}
			//         name='chevron-right'
			//         size={12}
			//         color={view == 'tutor' ? primary : text}
			//       />
			//     ) : null}
			//   </TouchableOpacity>
			//   {/* Degree btn  */}
			//   <TouchableOpacity
			//     style={{
			//       backgroundColor: background,
			//       padding: 15,
			//       borderRadius: defaultStyles.radius,
			//       display: 'flex',
			//       flexDirection: 'row',
			//       alignContent: 'center',
			//       justifyContent: 'center',
			//       alignItems: 'center',
			//     }}
			//     onPress={() => {
			//       setView('degree');
			//     }}
			//   >
			//     <Text
			//       style={{
			//         color: view === 'degree' ? primary : text,
			//         fontSize: 14,
			//         fontFamily:
			//           view == 'degree'
			//             ? defaultStyles.boldFont
			//             : defaultStyles.regularFont,
			//       }}
			//     >
			//       Degree information
			//     </Text>
			//     {view === 'degree' && isTutor ? (
			//       <FontAwesome
			//         style={{ marginLeft: 5, paddingTop: 2 }}
			//         name='chevron-right'
			//         size={12}
			//         color={view == 'degree' ? primary : text}
			//       />
			//     ) : null}
			//   </TouchableOpacity>

			//   {/* Availability btn  */}
			//   <TouchableOpacity
			//     style={{
			//       backgroundColor: background,
			//       padding: 15,
			//       borderRadius: defaultStyles.buttonRadius,
			//       display: 'flex',
			//       flexDirection: 'row',
			//       alignContent: 'center',
			//       justifyContent: 'center',
			//       alignItems: 'center',
			//     }}
			//     onPress={() => {
			//       setView('availability');
			//     }}
			//   >
			//     <Text
			//       style={{
			//         color: view === 'availability' ? primary : text,
			//         fontSize: 14,
			//         fontFamily:
			//           view == 'availability'
			//             ? defaultStyles.boldFont
			//             : defaultStyles.regularFont,
			//       }}
			//     >
			//       Availability
			//     </Text>
			//     {view === 'availability' && isTutor ? (
			//       <FontAwesome
			//         style={{ marginLeft: 5, paddingTop: 2 }}
			//         name='chevron-right'
			//         size={12}
			//         color={view == 'availability' ? primary : text}
			//       />
			//     ) : null}
			//   </TouchableOpacity>
			// </View>

			<Menu>
				<MenuTrigger
					style={{
						backgroundColor: background,
						width: "fit-content",
						display: "flex",
						paddingVertical: 30,
						flexDirection: "row",
						alignContent: "center",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Text
						style={{
							color: text,
							fontSize: 16,
							fontFamily: defaultStyles.regularFont,
						}}
					>
						{view == "personal"
							? "Personal information"
							: view == "tutor"
							? "Tutor details"
							: view == "degree"
							? "Education information"
							: view == "availability"
							? "Availability"
							: null}
					</Text>
					<FontAwesome
						style={{ marginLeft: 5, paddingTop: 2 }}
						name="chevron-down"
						size={12}
						color={text}
					/>
				</MenuTrigger>
				<MenuOptions
					optionsContainerStyle={{
						backgroundColor: backgroundVariant,
						borderRadius: defaultStyles.radius,
						padding: 15,
						marginLeft: 0,
						marginTop: 60,
						width: 300,
						shadowColor: "rgba(0, 0, 0, 0.1)",
						shadowOpacity: 0.8,
						shadowOffset: { width: 2, height: 2 },
						shadowRadius: 4,
					}}
					customStyles={{
						optionText: {
							fontSize: 16,
							fontFamily: defaultStyles.regularFont,
						},
					}}
				>
					<MenuOption
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 10,
							marginBottom: 10,
						}}
						onSelect={() => {
							setView("personal");
						}}
					>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								width: 25,
								height: 25,
								display: "flex",
							}}
						>
							<AntDesign
								size={19}
								name="user"
								color={textVariant}
							></AntDesign>
						</View>
						<Text style={styles.menuText}>
							Personal Information
						</Text>
					</MenuOption>
					<MenuOption
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 10,
							marginBottom: 10,
						}}
						onSelect={() => {
							setView("tutor");
						}}
					>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								width: 25,
								height: 25,
								display: "flex",
							}}
						>
							<AntDesign
								size={19}
								name="laptop"
								color={textVariant}
							></AntDesign>
						</View>
						<Text style={styles.menuText}>Tutor Details</Text>
					</MenuOption>
					<MenuOption
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 10,
							marginBottom: 10,
						}}
						onSelect={() => {
							setView("degree");
						}}
					>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								width: 25,
								height: 25,
								display: "flex",
								padding: 5,
							}}
						>
							<AntDesign
								size={19}
								name="book"
								color={textVariant}
							></AntDesign>
						</View>
						<Text style={styles.menuText}>
							Education Information
						</Text>
					</MenuOption>
					<MenuOption
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 10,
						}}
						onSelect={() => {
							setView("availability");
						}}
					>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								width: 25,
								height: 25,
								display: "flex",
							}}
						>
							<AntDesign
								size={19}
								name="clockcircleo"
								color={textVariant}
							></AntDesign>
						</View>
						<Text style={styles.menuText}>Availability</Text>
					</MenuOption>
				</MenuOptions>
			</Menu>
		);
	};

	return (
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}

			<ScrollView>
				<View style={styles.maxWidth}>
					<View style={styles.mainSection}>
						<View
							style={{
								flex: 1,
							}}
						>
							{/* Left  */}
							<View
								style={{
									padding: 10,
									flex: 1,
								}}
							>
								{/* Navigation */}
								<Navigation />
								<View
									style={{
										alignItems: "center",
										display: "flex",
										flexDirection: "row",
									}}
								>
									{/* Profile img  */}

									{/* Image  */}
									<Image
										style={{
											borderRadius:
												defaultStyles.buttonRadius,
											border: "1px solid" + border,
											width: 120,
											height: 120,
										}}
										source={{
											uri: image,
										}}
									/>
									<View>
										<TouchableOpacity
											style={[styles.updateButton]}
											onPress={() => {
												pickImage();
											}}
										>
											<Text
												style={{
													color: primary,
													fontSize: 14,
													fontFamily:
														defaultStyles.boldFont,
												}}
											>
												Update Profile Picture
											</Text>
										</TouchableOpacity>

										<View
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
											}}
										>
											<TouchableOpacity
												style={[
													styles.saveButton,
													{ marginTop: 15 },
												]}
												onPress={() => {
													updateUserInfo();
												}}
											>
												<Text
													style={{
														color: "#fff",
														fontSize: 14,
														fontFamily:
															defaultStyles.boldFont,
														textAlign: "center",
													}}
												>
													Save Changes
												</Text>
											</TouchableOpacity>

											<TouchableOpacity
												style={[
													styles.saveButton,
													{
														marginTop: 15,
														marginLeft: 10,
														backgroundColor:
															backgroundVariant,
													},
												]}
												onPress={() => {
													setEditProfile(false);
												}}
											>
												<Text
													style={{
														color: text,
														fontSize: 14,
														fontFamily:
															defaultStyles.boldFont,
														textAlign: "center",
													}}
												>
													Cancel
												</Text>
											</TouchableOpacity>
										</View>
									</View>
								</View>

								{view == "personal" ? (
									<>
										<Text
											style={[
												styles.sectionTitle,
												{ marginTop: 15 },
											]}
										>
											Personal Information
										</Text>
										{/* Email  */}
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Email
											</Text>
											<TextInput
												disabled={true}
												style={styles.input}
												placeholder={updateEmail}
												onChange={(e) => {
													setUpdateEmail(
														e.target.value
													);
												}}
												onPres={() => {
													customAlert.error(
														"[Edit Profile Error] You can't change your email!"
													);
												}}
											></TextInput>
										</View>
										{/* Name  */}
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Name
											</Text>
											<TextInput
												style={styles.input}
												placeholder={name}
												value={updateName}
												onChange={(e) => {
													setUpdateName(
														e.target.value
													);
												}}
											></TextInput>
										</View>
										{/* Location - City province country */}
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Location
											</Text>

											{/* Country  */}
											<Picker
												style={styles.picker}
												selectedValue={selectedCountry}
												onValueChange={(
													itemValue,
													itemIndex
												) =>
													setSelectedCountry(
														itemValue
													)
												}
											>
												<Picker.Item
													label="Select Country"
													value="Select Country"
												/>
												<Picker.Item
													label="Canada"
													value="CA"
												></Picker.Item>

												<Picker.Item
													label="United States"
													value="US"
												></Picker.Item>
											</Picker>

											{/* Province  */}
											<Picker
												style={styles.picker}
												selectedValue={
													selectedProvince == ""
														? provinces[0].province
														: selectedProvince
												}
												onValueChange={(
													itemValue,
													itemIndex
												) =>
													setSelectedProvince(
														itemValue
													)
												}
											>
												{provinces.map((item, i) => (
													<Picker.Item
														key={`provinces-picker-${i}`}
														label={
															provinces[i]
																.province
														}
														value={
															provinces[i]
																.province
														}
														enabled={
															provinces[i].enabled
														}
													/>
												))}
											</Picker>

											{/* City */}
											<View style={{ marginTop: 15 }}>
												<TextInput
													style={styles.input}
													placeholder="City"
													value={city}
													onChange={(e) => {
														setCity(e.target.value);
													}}
												></TextInput>
											</View>
										</View>
									</>
								) : null}
								{view == "tutor" ? (
									<>
										<Text
											style={[
												styles.sectionTitle,
												{ marginTop: 15 },
											]}
										>
											Tutor Profile
										</Text>
										{/* Rate */}
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Rate
											</Text>

											<NumericTextInput
												style={[styles.input, styles]}
												type="currency"
												locale="en-Us"
												currency="CAD"
												decimalPlaces={2}
												value={selectedRate}
												onUpdate={(value) =>
													setSelectedRate(value)
												}
												placeholder="$0.00"
											/>
										</View>
										{/*Language*/}
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Language
											</Text>
											<View style={styles.wordContainer}>
												{selectedLanguage.map(
													(doc, index) => {
														return (
															<WordCard
																key={`lang-word-card-${index}`}
																word={
																	selectedLanguage[
																		index
																	]
																}
																array="languages"
															></WordCard>
														);
													}
												)}
											</View>
											<Picker
												style={styles.picker}
												selectedValue={
													selectedLanguage.length == 0
														? languages[0].lang
														: selectedLanguage[
																selectedLanguage.length -
																	1
														  ]
												}
												onValueChange={(
													itemValue,
													itemIndex
												) => {
													if (
														selectedLanguage.includes(
															itemValue
														)
													) {
														return null;
													} else {
														setSelectedLanguage([
															...selectedLanguage,
															itemValue,
														]);
													}
												}}
											>
												{languages.map((item, i) => (
													<Picker.Item
														key={`lang-picker-${i}`}
														label={
															languages[i].lang
														}
														value={
															languages[i].lang
														}
														enabled={
															languages[i].enabled
														}
													/>
												))}
											</Picker>
										</View>
										{/*Subject*/}
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Subject
											</Text>
											<View style={styles.wordContainer}>
												{selectedSubject.map(
													(doc, index) => {
														return (
															<WordCard
																key={`subject-word-card-${index}`}
																word={
																	selectedSubject[
																		index
																	]
																}
																array={
																	"subject"
																}
															></WordCard>
														);
													}
												)}
											</View>
											<Picker
												style={styles.picker}
												selectedValue={
													selectedSubject.length == 0
														? subjects[0].subject
														: selectedSubject[
																selectedSubject.length -
																	1
														  ]
												}
												onValueChange={(
													itemValue,
													itemIndex
												) => {
													if (
														selectedSubject.includes(
															itemValue
														)
													) {
														return null;
													} else {
														setSelectedSubject([
															...selectedSubject,
															itemValue,
														]);
													}
												}}
											>
												{subjects.map((item, i) => (
													<Picker.Item
														label={
															subjects[i].subject
														}
														value={
															subjects[i].subject
														}
														enabled={
															subjects[i].enabled
														}
													/>
												))}
											</Picker>
										</View>
										{/*Skills*/}
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Skills
											</Text>
											<View style={styles.wordContainer}>
												{selectedSkill.map(
													(doc, index) => {
														return (
															<WordCard
																key={`skill-word-card-${index}`}
																word={
																	selectedSkill[
																		index
																	]
																}
																array="skill"
															></WordCard>
														);
													}
												)}
											</View>
											<Picker
												style={styles.picker}
												selectedValue={
													selectedSkill.length == 0
														? skills[0].skill
														: selectedSkill[
																selectedSkill.length -
																	1
														  ]
												}
												onValueChange={(
													itemValue,
													itemIndex
												) => {
													if (
														selectedSkill.includes(
															itemValue
														)
													) {
														return null;
													} else {
														setSelectedSkill([
															...selectedSkill,
															itemValue,
														]);
													}
												}}
											>
												{skills.map((item, i) => (
													<Picker.Item
														key={`skills-picker-${i}`}
														label={skills[i].skill}
														value={skills[i].skill}
														enabled={
															skills[i].enabled
														}
													/>
												))}
											</Picker>
										</View>
										<View style={{ marginTop: 15 }}>
											<Text style={styles.subTitle}>
												Bio
											</Text>
											<TextInput
												placeholderTextColor="#999999"
												style={styles.descriptionInput}
												placeholder={description}
												multiline={true}
												value={description}
												maxLength={1000}
												onChange={(e) => {
													setDescription(
														e.target.value
													);
												}}
												// maxLength={250}
											></TextInput>
											<Text
												style={[
													styles.details,
													{ padding: 5 },
												]}
											>
												{description.length}/1000
											</Text>
										</View>
									</>
								) : null}
								{view == "degree" ? (
									<>
										<Text
											style={[
												styles.sectionTitle,
												{ marginTop: 15 },
											]}
										>
											Education Information
										</Text>
										{/* Show added degrees  */}
										{
											<View
												style={{
													display: "flex",
													flexDirection: "row",
													flexWrap: "wrap",
												}}
											>
												{degreeArray.map(
													(doc, index) => {
														return (
															<DegreeCard
																key={`degree-card-${index}`}
																school={
																	degreeArray[
																		index
																	].school
																}
																year={
																	degreeArray[
																		index
																	].year
																}
																type={
																	degreeArray[
																		index
																	].type
																}
																degree={
																	degreeArray[
																		index
																	].name
																}
															></DegreeCard>
														);
													}
												)}
											</View>
										}
										<View
											style={{
												width: "100%",
												paddingVertical: 10,
											}}
										>
											<Text style={styles.subTitle}>
												Degree info
											</Text>
											{/* University  */}
											{selectedUniversity != "Other" ? (
												<Picker
													style={styles.picker}
													selectedValue={
														selectedUniversity == ""
															? universities[0]
																	.school
															: selectedUniversity
													}
													onValueChange={(
														itemValue,
														itemIndex
													) =>
														setSelectedUniversity(
															itemValue
														)
													}
												>
													{universities.map(
														(item, i) => (
															<Picker.Item
																key={`uni-picker-${i}`}
																label={
																	universities[
																		i
																	].school
																}
																value={
																	universities[
																		i
																	].school
																}
																enabled={
																	universities[
																		i
																	].enabled
																}
															/>
														)
													)}
												</Picker>
											) : (
												<>
													<TextInput
														placeholderTextColor={
															darkMode
																? "#fff"
																: "#000"
														}
														style={[
															styles.descriptionInput,
															{ height: 50 },
														]}
														placeholder={
															"Enter University Name"
														}
														multiline={false}
														value={customUniversity}
														onChange={(e) => {
															setCustomUniversity(
																e.target.value
															);
														}}
													></TextInput>
												</>
											)}
											{/* Field of study  */}

											<Picker
												style={styles.picker}
												selectedValue={
													selectedDegree == ""
														? degrees[0].name
														: selectedDegree
												}
												onValueChange={(
													itemValue,
													itemIndex
												) =>
													setSelectedDegree(itemValue)
												}
											>
												{degrees.map((item, i) => (
													<Picker.Item
														key={`degree-picker-${i}`}
														label={degrees[i].name}
														value={degrees[i].name}
														enabled={
															degrees[i].enabled
														}
													/>
												))}
											</Picker>

											{/* Type */}
											<Picker
												style={styles.picker}
												selectedValue={
													selectedDegreeType
												}
												onValueChange={(
													itemValue,
													itemIndex
												) =>
													setSelectedDegreeType(
														itemValue
													)
												}
											>
												<Picker.Item
													key={`degree-type-picker-0`}
													label="Select a degree type"
													value=""
												/>

												<Picker.Item
													key={`degree-type-picker-1`}
													label="Associates Degree"
													value="Associates Degree"
												/>

												<Picker.Item
													key={`degree-type-picker-3`}
													label="Bachelors Degree"
													value="Bachelors Degree"
												/>

												<Picker.Item
													key={`degree-type-picker-4`}
													label="Masters Degree"
													value="Masters Degree"
												/>

												<Picker.Item
													key={`degree-type-picker-5`}
													label="Doctorate Degree"
													value="Doctorate Degree"
												/>

												<Picker.Item
													key={`degree-type-picker-6`}
													label="Professional Degree"
													value="Professional Degree"
												/>
											</Picker>

											{/* Year */}
											<Picker
												style={styles.picker}
												selectedValue={
													selectedCompletionYear == ""
														? years[0].year
														: selectedCompletionYear
												}
												onValueChange={(
													itemValue,
													itemIndex
												) =>
													setSelectedCompletionYear(
														itemValue
													)
												}
											>
												{years.map((item, i) => (
													<Picker.Item
														key={`year-picker-${i}`}
														label={years[i].year}
														value={years[i].year}
														enabled={
															years[i].enabled
														}
													/>
												))}
											</Picker>

											{selectedUniversity === "Other" ? (
												/* Add degree */
												<TouchableOpacity
													onPress={() => {
														if (
															degreeInfo.customUniversity ===
																"" ||
															degreeInfo.year ===
																"" ||
															degreeInfo.school ===
																"" ||
															degreeInfo.type ===
																""
														) {
															customAlert.error(
																"[Edit Profile Error] You must fill in all fields!"
															);
															return;
														}

														setDegreeArray([
															...degreeArray,
															degreeInfo,
														]);
														resetDegreeValues();
													}}
												>
													<Text
														style={
															styles.addDegreeButton
														}
													>
														{" "}
														Add Degree{" "}
													</Text>
												</TouchableOpacity>
											) : (
												/* Add degree */
												<TouchableOpacity
													onPress={() => {
														if (
															degreeInfo.name ===
																"" ||
															degreeInfo.year ===
																"" ||
															degreeInfo.school ===
																"" ||
															degreeInfo.type ===
																""
														) {
															customAlert.error(
																"[Edit Profile Error] You must fill in all fields!"
															);
															return;
														}

														setDegreeArray([
															...degreeArray,
															degreeInfo,
														]);
														resetDegreeValues();
													}}
												>
													<Text
														style={
															styles.addDegreeButton
														}
													>
														{" "}
														Add Degree{" "}
													</Text>
												</TouchableOpacity>
											)}
										</View>
									</>
								) : null}

								{view == "availability" ? (
									<>
										<Text
											style={[
												styles.sectionTitle,
												{ marginTop: 15 },
											]}
										>
											Set Availability
										</Text>
										<Text
											style={{
												fontSize: 15,
												color: textVariant,
												marginBottom: 15,
											}}
										>
											Set your daily availability
										</Text>

										<View style={{ marginBottom: 15 }}>
											{/* Toggle to turn off always available */}
											<View
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													marginBottom: 15,
												}}
											>
												<TouchableOpacity
													style={{
														width: 20,
														height: 20,
														backgroundColor:
															alwaysAvailable
																? primary
																: backgroundVariant,
														border:
															"1px solid" +
															border,
														borderRadius:
															defaultStyles.radius,
														marginRight: 10,
													}}
													onPress={() => {
														if (alwaysAvailable) {
															setAlwaysAvailable(
																false
															);
														} else {
															setAlwaysAvailable(
																true
															);
														}
													}}
												></TouchableOpacity>

												<Text
													style={{
														color: text,
														fontFamily:
															defaultStyles.boldFont,
														fontSize: 16,
													}}
												>
													Always available
												</Text>
											</View>
										</View>

										{alwaysAvailable ? (
											<></>
										) : (
											<>
												<View
													style={{ marginBottom: 15 }}
												>
													<Text
														style={styles.subTitle}
													>
														Current Availability
													</Text>

													<View
														style={{
															display: "flex",
															flexDirection:
																"column",
														}}
													>
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={
																	styles.details
																}
															>
																Monday:{" "}
															</Text>
															<Text
																style={
																	styles.details
																}
															>
																{/* TODO: Load time */}
																{monday &&
																monday.start &&
																monday.end
																	? `${monday.start} - ${monday.end}`
																	: "Not set"}
															</Text>
														</View>

														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={
																	styles.details
																}
															>
																Tuesday:{" "}
															</Text>
															<Text
																style={
																	styles.details
																}
															>
																{/* TODO: Load time */}
																{tuesday &&
																tuesday.start &&
																tuesday.end
																	? `${tuesday.start} - ${tuesday.end}`
																	: "Not set"}
															</Text>
														</View>

														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={
																	styles.details
																}
															>
																Wednesday:{" "}
															</Text>
															<Text
																style={
																	styles.details
																}
															>
																{/* TODO: Load time */}
																{wednesday &&
																wednesday.start &&
																wednesday.end
																	? `${wednesday.start} - ${wednesday.end}`
																	: "Not set"}
															</Text>
														</View>

														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={
																	styles.details
																}
															>
																Thursday:{" "}
															</Text>
															<Text
																style={
																	styles.details
																}
															>
																{/* TODO: Load time */}
																{thursday &&
																thursday.start &&
																thursday.end
																	? `${thursday.start} - ${thursday.end}`
																	: "Not set"}
															</Text>
														</View>

														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={
																	styles.details
																}
															>
																Friday:{" "}
															</Text>
															<Text
																style={
																	styles.details
																}
															>
																{/* TODO: Load time */}
																{friday &&
																friday.start &&
																friday.end
																	? `${friday.start} - ${friday.end}`
																	: "Not set"}
															</Text>
														</View>

														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={
																	styles.details
																}
															>
																Saturday:{" "}
															</Text>
															<Text
																style={
																	styles.details
																}
															>
																{/* TODO: Load time */}
																{saturday &&
																saturday.start &&
																saturday.end
																	? `${saturday.start} - ${saturday.end}`
																	: "Not set"}
															</Text>
														</View>

														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={
																	styles.details
																}
															>
																Sunday:{" "}
															</Text>
															<Text
																style={
																	styles.details
																}
															>
																{/* TODO: Load time */}
																{sunday &&
																sunday.start &&
																sunday.end
																	? `${sunday.start} - ${sunday.end}`
																	: "Not set"}
															</Text>
														</View>
													</View>
												</View>

												<View
													style={{
														width: "100%",
														paddingVertical: 10,
													}}
												>
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showMonday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() => {
																if (
																	showMonday
																) {
																	setMonday({
																		start: "",
																		end: "",
																	});
																	setShowMonday(
																		false
																	);
																} else {
																	setShowMonday(
																		true
																	);
																}
															}}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Monday
														</Text>
													</View>

													{showMonday && (
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
																width: "100%",
																alignContent:
																	"flex-start",
																alignItems:
																	"center",
																gap: 15,
															}}
														>
															{Platform.OS ==
															"web" ? (
																<>
																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setMonday(
																				{
																					...monday,
																					start: time,
																				}
																			);
																		}}
																		time={
																			userAvailability
																				.monday
																				.start
																		}
																	></CustomTimeInput>

																	<Text
																		style={{
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			fontSize: 12,
																		}}
																	>
																		to
																	</Text>

																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setMonday(
																				{
																					...monday,
																					end: time,
																				}
																			);
																		}}
																		time={
																			userAvailability
																				.monday
																				.end
																		}
																	></CustomTimeInput>
																</>
															) : (
																<Text
																	style={{
																		color: text,
																	}}
																>
																	Coming
																	soon...
																</Text>
															)}
														</View>
													)}

													{/* Tuesday  */}
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
															marginTop: 15,
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showTuesday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() => {
																if (
																	showTuesday
																) {
																	setShowTuesday(
																		{
																			start: "",
																			end: "",
																		}
																	);
																	setShowTuesday(
																		false
																	);
																} else {
																	setShowTuesday(
																		true
																	);
																}
															}}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Tuesday
														</Text>
													</View>

													{showTuesday && (
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
																width: "100%",
																alignContent:
																	"flex-start",
																alignItems:
																	"center",
																gap: 15,
															}}
														>
															{Platform.OS ==
															"web" ? (
																<>
																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setTuesday(
																				{
																					...tuesday,
																					start: time,
																				}
																			);
																		}}
																		time={
																			tuesday.start
																		}
																	></CustomTimeInput>

																	<Text
																		style={{
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			fontSize: 12,
																		}}
																	>
																		to
																	</Text>

																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setTuesday(
																				{
																					...tuesday,
																					end: time,
																				}
																			);
																		}}
																		time={
																			tuesday.end
																		}
																	></CustomTimeInput>
																</>
															) : (
																<Text
																	style={{
																		color: text,
																	}}
																>
																	Coming
																	soon...
																</Text>
															)}
														</View>
													)}

													{/* Wednesday */}
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
															marginTop: 15,
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showWednesday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() => {
																if (
																	showWednesday
																) {
																	setWednesday(
																		{
																			start: "",
																			end: "",
																		}
																	);
																	setShowWednesday(
																		false
																	);
																} else {
																	setShowWednesday(
																		true
																	);
																}
															}}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Wednesday
														</Text>
													</View>

													{showWednesday && (
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
																width: "100%",
																alignContent:
																	"flex-start",
																alignItems:
																	"center",
																gap: 15,
															}}
														>
															{Platform.OS ==
															"web" ? (
																<>
																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setWednesday(
																				{
																					...wednesday,
																					start: time,
																				}
																			);
																		}}
																		time={
																			wednesday.start
																		}
																	></CustomTimeInput>

																	<Text
																		style={{
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			fontSize: 12,
																		}}
																	>
																		to
																	</Text>

																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setWednesday(
																				{
																					...wednesday,
																					end: time,
																				}
																			);
																		}}
																		time={
																			wednesday.end
																		}
																	></CustomTimeInput>
																</>
															) : (
																<Text
																	style={{
																		color: text,
																	}}
																>
																	Coming
																	soon...
																</Text>
															)}
														</View>
													)}

													{/* Thursday */}
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
															marginTop: 15,
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showThursday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() => {
																if (
																	showThursday
																) {
																	setThursday(
																		{
																			start: "",
																			end: "",
																		}
																	);
																	setShowThursday(
																		false
																	);
																} else {
																	setShowThursday(
																		true
																	);
																}
															}}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Thursday
														</Text>
													</View>

													{showThursday && (
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
																width: "100%",
																alignContent:
																	"flex-start",
																alignItems:
																	"center",
																gap: 15,
															}}
														>
															{Platform.OS ==
															"web" ? (
																<>
																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setThursday(
																				{
																					...thursday,
																					start: time,
																				}
																			);
																		}}
																		time={
																			thursday.start
																		}
																	></CustomTimeInput>

																	<Text
																		style={{
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			fontSize: 12,
																		}}
																	>
																		to
																	</Text>

																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setThursday(
																				{
																					...thursday,
																					end: time,
																				}
																			);
																		}}
																		time={
																			thursday.end
																		}
																	></CustomTimeInput>
																</>
															) : (
																<Text
																	style={{
																		color: text,
																	}}
																>
																	Coming
																	soon...
																</Text>
															)}
														</View>
													)}

													{/* Friday  */}
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
															marginTop: 15,
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showFriday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() => {
																if (
																	showFriday
																) {
																	setFriday({
																		start: "",
																		end: "",
																	});
																	setShowFriday(
																		false
																	);
																} else {
																	setShowFriday(
																		true
																	);
																}
															}}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Friday
														</Text>
													</View>

													{showFriday && (
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
																width: "100%",
																alignContent:
																	"flex-start",
																alignItems:
																	"center",
																gap: 15,
															}}
														>
															{Platform.OS ==
															"web" ? (
																<>
																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setFriday(
																				{
																					...friday,
																					start: time,
																				}
																			);
																		}}
																		time={
																			friday.start
																		}
																	></CustomTimeInput>

																	<Text
																		style={{
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			fontSize: 12,
																		}}
																	>
																		to
																	</Text>

																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setFriday(
																				{
																					...friday,
																					end: time,
																				}
																			);
																		}}
																		time={
																			friday.end
																		}
																	></CustomTimeInput>
																</>
															) : (
																<Text
																	style={{
																		color: text,
																	}}
																>
																	Coming
																	soon...
																</Text>
															)}
														</View>
													)}

													{/* Saturday  */}
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
															marginTop: 15,
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showSaturday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() => {
																if (
																	showSaturday
																) {
																	setSaturday(
																		{
																			start: "",
																			end: "",
																		}
																	);
																	setShowSaturday(
																		false
																	);
																} else {
																	setShowSaturday(
																		true
																	);
																}
															}}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Saturday
														</Text>
													</View>

													{showSaturday && (
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
																width: "100%",
																alignContent:
																	"flex-start",
																alignItems:
																	"center",
																gap: 15,
															}}
														>
															{Platform.OS ==
															"web" ? (
																<>
																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setSaturday(
																				{
																					...saturday,
																					start: time,
																				}
																			);
																		}}
																		time={
																			saturday.start
																		}
																	></CustomTimeInput>

																	<Text
																		style={{
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			fontSize: 12,
																		}}
																	>
																		to
																	</Text>

																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setSaturday(
																				{
																					...saturday,
																					end: time,
																				}
																			);
																		}}
																		time={
																			saturday.end
																		}
																	></CustomTimeInput>
																</>
															) : (
																<Text
																	style={{
																		color: text,
																	}}
																>
																	Coming
																	soon...
																</Text>
															)}
														</View>
													)}

													{/* Sunday  */}
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
															marginTop: 15,
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showSunday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() => {
																if (
																	showSunday
																) {
																	setSunday({
																		start: "",
																		end: "",
																	});
																	setShowSunday(
																		false
																	);
																} else {
																	setShowSunday(
																		true
																	);
																}
															}}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Sunday
														</Text>
													</View>

													{showSunday && (
														<View
															style={{
																display: "flex",
																flexDirection:
																	"row",
																width: "100%",
																alignContent:
																	"flex-start",
																alignItems:
																	"center",
																gap: 15,
															}}
														>
															{Platform.OS ==
															"web" ? (
																<>
																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setSunday(
																				{
																					...sunday,
																					start: time,
																				}
																			);
																		}}
																		time={
																			sunday.start
																		}
																	></CustomTimeInput>

																	<Text
																		style={{
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			fontSize: 12,
																		}}
																	>
																		to
																	</Text>

																	<CustomTimeInput
																		setTime={(
																			time
																		) => {
																			setSunday(
																				{
																					...sunday,
																					end: time,
																				}
																			);
																		}}
																		time={
																			sunday.end
																		}
																	></CustomTimeInput>
																</>
															) : (
																<Text
																	style={{
																		color: text,
																	}}
																>
																	Coming
																	soon...
																</Text>
															)}
														</View>
													)}
												</View>
											</>
										)}
									</>
								) : null}
							</View>
						</View>
					</View>
				</View>
				{/* Footer  */}
				{(isSm && !alwaysAvailable) ||
				(isSm && view != "availability") ? (
					<Footer></Footer>
				) : null}
			</ScrollView>
			{/* Footer  */}
			{isSm && alwaysAvailable && view == "availability" ? (
				<Footer></Footer>
			) : null}
		</SafeAreaView>
	);
};
