import { getAuth } from "firebase/auth";
import React, { useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import tutredLogo from "../assets/tutred_logo.png";
import tutredLogoSmall from "../assets/tutred_logo_small.png";
import { useColorsContext, useStyles } from "../utils/styles";

import { Link } from "@react-navigation/native";
import { useUserContext } from "../contexts/userContext";
import { LoginModal } from "./LoginModal";
import { SignUpModal } from "./SignUpModal";
import { TutorSignUpModal } from "./TutorSignUpModal";

export const Header = ({ navigation, page }) => {
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	const auth = getAuth();
	const { profile } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page

	const [loginModal, setLoginModal] = useState(false);
	const [signUpModal, setSignUpModal] = useState(false);
	const [tutorModal, setTutorModal] = useState(false);

	// Stylesheet

	const styles = StyleSheet.create({
		header: {
			backgroundColor: background,
			width: "100%",
			flexDirection: "row",
			paddingHorizontal: !isSm ? 15 : 45,
			paddingVertical: 20,
			alignItems: "center",
			justifyContent: "space-between",
			borderBottomWidth: 1,
			borderBottomColor: border,
			zIndex: 100,
		},
		menuText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 15,
		},
	});

	return (
		<>
			{signUpModal && (
				<SignUpModal
					loginModal={signUpModal}
					setLoginModal={setSignUpModal}
				/>
			)}

			{loginModal && (
				<LoginModal
					loginModal={loginModal}
					setLoginModal={setLoginModal}
				/>
			)}

			{tutorModal && (
				<TutorSignUpModal
					loginModal={tutorModal}
					setLoginModal={setTutorModal}
				/>
			)}

			<View style={styles.header}>
				{/* Logo left */}
				<Link
					to={{
						screen: "Tutred",
					}}
				>
					{isSm ? (
						<Image
							style={{ maxHeight: 30, height: 25, width: 200 }}
							source={{ uri: tutredLogo }}
						/>
					) : (
						<Image
							style={{ width: 160, minHeight: 20 }}
							source={{ uri: tutredLogoSmall }}
						/>
					)}
				</Link>

				{/* Buttons */}

				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{/* Tutors */}
					<TouchableOpacity
						onPress={() => {
							setTutorModal(true);
						}}
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: defaultStyles.buttonRadius,
							paddingVertical: 10,
							paddingHorizontal: 15,
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
							}}
						>
							Become a tutor
						</Text>
					</TouchableOpacity>

					{/* Login */}
					<TouchableOpacity
						onPress={() => {
							setLoginModal(true);
						}}
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: defaultStyles.buttonRadius,
							paddingVertical: 10,
							paddingHorizontal: 15,
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
							}}
						>
							Login
						</Text>
					</TouchableOpacity>
					{/* <Link style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: defaultStyles.buttonRadius,
                        paddingVertical: 10,
                        paddingHorizontal: 15,
                }}
                    to={{
                        screen: 'Auth',
                        params: { screen: 'Login' },
                    }}
                >
                    <Text style={{color: text, fontSize: 16, fontFamily: defaultStyles.boldFont}}>Login</Text>
                </Link> */}

					{/* Sign up */}
					<TouchableOpacity
						onPress={() => {
							setSignUpModal(true);
						}}
						style={{
							marginLeft: 15,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: primary,
							borderRadius: defaultStyles.buttonRadius,
							paddingVertical: 10,
							paddingHorizontal: 30,
						}}
						to={{
							screen: "Auth",
							params: { screen: "Signup" },
						}}
					>
						<Text
							style={{
								color: "#fff",
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
							}}
						>
							Sign up
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		</>
	);
};
