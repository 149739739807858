import { CloseOutlined } from "@mui/icons-material";
import { useAlert } from "react-alert";
import {
	Modal,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";
import { BookingCard } from "./BookingCard";

export const CalendarModal = ({
	isTutor,
	modalVisible,
	setModalVisible,
	date,
	sessions,
	setSessionModalVisible,
	setSelectedSession,
	setPastSessionModalVisible,
	setSelectedPastSession,
}) => {
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	const customAlert = useAlert();

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "50%"
				: isLg
				? "60%"
				: isMd
				? "70%"
				: isSm
				? "80%"
				: "90%",
			height: "80%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			// justifyContent: 'space-between',
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			// position: 'absolute',
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
	});
	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(!modalVisible);
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={styles.modalHeader}>
						{/* Close filters button */}
						<TouchableOpacity
							style={styles.closeButton}
							onPress={() => {
								setModalVisible(!modalVisible);
							}}
						>
							<CloseOutlined
								style={{ fontSize: 22, color: text }}
							></CloseOutlined>
						</TouchableOpacity>
						{/* Title */}
						<Text style={styles.modalTitle}>
							Sessions for {date}
						</Text>
					</View>

					<ScrollView
						style={{ paddingTop: 15, flex: 1 }}
						showsVerticalScrollIndicator={false}
						contentContainerStyle={{
							flexGrow: 1,
						}}
					>
						{sessions.map((doc, index) => {
							var sessionName = sessions[index].user.name;
							var sessionImage = sessions[index].user.image;

							if (!isTutor) {
								sessionName = sessions[index].tutorProfile.name;
								sessionImage =
									sessions[index].tutorProfile.image;
							}

							return (
								<TouchableOpacity
									key={`session-${sessions[index].id}`}
									onPress={() => {
										// If completed or cancelled diff click
										if (
											sessions[index].status ===
												"completed" ||
											sessions[index].status ===
												"cancelled"
										) {
											// bring up past session modal
											setModalVisible(!modalVisible);
											setSelectedPastSession(
												sessions[index]
											);
											setPastSessionModalVisible(true);
										} else {
											setModalVisible(!modalVisible);
											setSelectedSession(sessions[index]);
											setSessionModalVisible(true);
										}
									}}
								>
									<BookingCard
										studentName={sessionName}
										studentImg={sessionImage}
										time={sessions[index].time}
										date={sessions[index].date}
										status={sessions[index].status}
										subject={sessions[index].subject}
										booked={sessions[index].booked}
									></BookingCard>
								</TouchableOpacity>
							);
						})}
					</ScrollView>
				</View>
			</View>
		</Modal>
	);
};
