// Imports
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

const Stack = createNativeStackNavigator();

// Import screens
import { Account } from "../../screens/loggedIn/Profile/Account";
import { EditProfile } from "../../screens/loggedIn/Profile/EditProfile";
import { InstaBook } from "../../screens/loggedIn/Profile/InstaBook";
import { Notifications } from "../../screens/loggedIn/Profile/Notifications";
import { PaymentsNew } from "../../screens/loggedIn/Profile/PaymentsNew";
import { Performance } from "../../screens/loggedIn/Profile/Performance";
import { ProfileNew } from "../../screens/loggedIn/Profile/ProfileNew";
import { SessionSettings } from "../../screens/loggedIn/Profile/SessionSettings";

// Components
export const ProfileStack = ({ navigation, route, params }) => {
	return (
		<Stack.Navigator initialRouteName="Profile">
			<Stack.Screen
				options={{ headerShown: false }}
				name="Profile"
				component={ProfileNew}
			/>
			<Stack.Screen
				options={{ headerShown: false }}
				name="EditProfile"
				component={EditProfile}
			/>

			<Stack.Screen
				options={{ headerShown: false }}
				name="Performance"
				component={Performance}
			/>

			<Stack.Screen
				options={{ headerShown: false }}
				name="PaymentProfile"
				component={PaymentsNew}
			/>

			<Stack.Screen
				options={{ headerShown: false }}
				name="Account"
				component={Account}
			/>

			<Stack.Screen
				options={{ headerShown: false }}
				name="Notifications"
				component={Notifications}
			/>

			<Stack.Screen
				options={{ headerShown: false }}
				name="InstaBook"
				component={InstaBook}
			/>

			<Stack.Screen
				options={{ headerShown: false }}
				name="SessionSettings"
				component={SessionSettings}
			/>
		</Stack.Navigator>
	);
};
