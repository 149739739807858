import { CloseOutlined } from "@mui/icons-material";
import { Picker } from "@react-native-picker/picker";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import {
	Modal,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";
const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";
// const hostname = "http://localhost:8000";

export const PaymentMethodModal = ({ modalVisible, setModalVisible }) => {
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	const customAlert = useAlert();

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "50%"
				: isLg
				? "60%"
				: isMd
				? "70%"
				: isSm
				? "80%"
				: "90%",
			height: "80%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			// justifyContent: 'space-between',
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			// position: 'absolute',
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		marginTop: {
			marginTop: 15,
		},
		title: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		applyButton: {
			marginTop: 15,
			borderRadius: defaultStyles.buttonRadius,
			height: 40,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
		},
		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.boldFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.regularFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	const { user, customerId, getPaymentMethods } = useUserContext();

	// States
	const [submit, setSubmit] = useState(false);
	const [error, setError] = useState("");
	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [month, setMonth] = useState("01");
	const [year, setYear] = useState("2023");
	const [expiry, setExpiry] = useState("01/2023");
	const [cvc, setCvc] = useState("");
	const [focus, setFocus] = useState(false);

	useEffect(() => {
		setExpiry(month + "/" + year);
	}, [month, year]);

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(!modalVisible);
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={styles.modalHeader}>
						{/* Close filters button */}
						<TouchableOpacity
							style={styles.closeButton}
							onPress={() => {
								setModalVisible(!modalVisible);
							}}
						>
							<CloseOutlined
								style={{ fontSize: 22, color: text }}
							></CloseOutlined>
						</TouchableOpacity>

						{/* Title */}
						<Text style={styles.modalTitle}>
							Add a payment method
						</Text>
					</View>

					<ScrollView showsVerticalScrollIndicator={false}>
						<View style={styles.marginTop}>
							<Cards
								cvc={cvc}
								expiry={expiry}
								focused={focus}
								name={name}
								number={number}
							/>
						</View>

						{/* Form */}
						<View style={styles.marginTop}>
							<Text style={styles.pickerInfoText}>Name</Text>
							<TextInput
								style={styles.picker}
								onChangeText={setName}
								value={name}
								onFocus={() => setFocus("name")}
							/>
						</View>

						<View style={styles.marginTop}>
							<Text style={styles.pickerInfoText}>
								Card Number
							</Text>
							<TextInput
								style={styles.picker}
								onChangeText={setNumber}
								value={number}
								onFocus={() => setFocus("number")}
								maxLength={16}
							/>
						</View>

						<View style={styles.marginTop}>
							<Text style={styles.pickerInfoText}>Expiry</Text>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<Picker
									style={[
										styles.picker,
										{ flex: 1, marginRight: 5 },
									]}
									selectedValue={month}
									onValueChange={(itemValue, itemIndex) => {
										// Set expiry to month/year
										setMonth(itemValue);
									}}
									onFocus={() => setFocus("expiry")}
								>
									<Picker.Item label="January" value="01" />
									<Picker.Item label="February" value="02" />
									<Picker.Item label="March" value="03" />
									<Picker.Item label="April" value="04" />
									<Picker.Item label="May" value="05" />
									<Picker.Item label="June" value="06" />
									<Picker.Item label="July" value="07" />
									<Picker.Item label="August" value="08" />
									<Picker.Item label="September" value="09" />
									<Picker.Item label="October" value="10" />
									<Picker.Item label="November" value="11" />
									<Picker.Item label="December" value="12" />
								</Picker>

								<Picker
									style={[
										styles.picker,
										{ flex: 1, marginLeft: 5 },
									]}
									selectedValue={year}
									onValueChange={(itemValue, itemIndex) => {
										// Set expiry to month/year
										setYear(itemValue);
									}}
									onFocus={() => setFocus("expiry")}
								>
									<Picker.Item label="2023" value="23" />
									<Picker.Item label="2024" value="24" />
									<Picker.Item label="2025" value="25" />
									<Picker.Item label="2026" value="26" />
									<Picker.Item label="2027" value="27" />
									<Picker.Item label="2028" value="28" />
									<Picker.Item label="2029" value="29" />
									<Picker.Item label="2030" value="30" />
								</Picker>
							</View>
						</View>

						<View style={styles.marginTop}>
							<Text style={styles.pickerInfoText}>CVC</Text>
							<TextInput
								style={styles.picker}
								onChangeText={setCvc}
								value={cvc}
								onFocus={() => setFocus("cvc")}
								maxLength={3}
							/>
						</View>

						{error && (
							<Text
								style={{
									color: redVariant,
									fontSize: 16,
									fontFamily: defaultStyles.boldFont,
									marginBottom: 30,
								}}
							>
								{error}
							</Text>
						)}
						<TouchableOpacity
							style={[
								styles.applyButton,
								{
									opacity: submit ? 0.5 : 1,
								},
							]}
							disabled={submit}
							onPress={() => {
								if (
									!name ||
									!number ||
									!month ||
									!year ||
									!cvc
								) {
									setError("Please fill out all fields");
									// Clear error
									setTimeout(() => {
										setError("");
									}, 5000);

									return;
								}
								if (submit) return;
								setSubmit(true);
								// Add payment method
								fetch(
									`${hostname}/api/${customerId}/payment-method`,
									{
										method: "POST",
										headers: {
											"Content-Type": "application/json",
											Authorization:
												"Bearer " + user.accessToken,
										},
										body: JSON.stringify({
											name: name,
											cardNumber: number,
											expMonth: month,
											expYear: year,
											cvc: cvc,
										}),
									}
								)
									.then((res) => res.json())
									.then((data) => {
										if (data.success) {
											// Clear values
											setName("");
											setNumber("");
											setMonth("01");
											setYear("2023");
											setCvc("");
											setModalVisible(!modalVisible);
											getPaymentMethods();
											setSubmit(false);
											customAlert.success(
												"[Payment Added] You have succesfully added a new payment method."
											);
										} else {
											// Display error in modal
											setError(data.message);
											setSubmit(false);
											// Clear error
											setTimeout(() => {
												setError("");
											}, 5000);
										}
									})
									.catch((err) => {
										// Display error in modal
										setSubmit(false);
										setError(
											"Unknown error occured. Please try again later."
										);
										// Clear error
										setTimeout(() => {
											setError("");
										}, 5000);
									});
							}}
						>
							<Text style={styles.buttonText}>
								{submit ? "..." : "Submit"}
							</Text>
						</TouchableOpacity>
					</ScrollView>
				</View>
			</View>
		</Modal>
	);
};
