import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaRegClock, FaBolt, FaUserTie } from "react-icons/fa";
import onlineEducation from "../../assets/OnlineEducation3.jpg";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
function Main2() {
	return (
		<Container fluid className="about-section">
			<Container className="inner-about-section">
				<Card sx={{ display: "flex", bgcolor: "#f9f9f9", borderColor: "#f9f9f9", borderRadius: 5}} variant="outlined">
					<CardMedia
						component="img"
						image={onlineEducation}
						alt="student studying"
						style={{ maxHeight: "30vw" }}
						sx={{ width: '26.04vw' }}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column"
						}}
					>
						<CardContent sx={{ flex: "1 0 auto", marginLeft : '2rem' }}>
							<h1 className="about-content">
								{" "}
								Discover what{" "}
								<strong className="main-name">
									Tutred
								</strong>{" "}
								has to offer
							</h1>
							<blockquote className="about-description">
								<p style={{ textAlign: "justify" }}>
									We designed an innovative solution for
									students
									<br />
									to instantly match with the right tutor
								</p>
								<ul>
									<li className="about-activity">
										<FaRegClock /> 24/7 access to tutors
									</li>
									<li className="about-activity">
										<FaBolt /> Use instabook to start a
										session instantly
									</li>
									<li className="about-activity">
										<FaUserTie /> Browse and choose tutors
										based on filters, credentials and
										reviews
									</li>
								</ul>
							</blockquote>
                            
						</CardContent>
					</Box>
				</Card>
			</Container>
		</Container>
	);
}

export default Main2;
