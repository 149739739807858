// Home Screen for logged in tutor
import React, { useEffect, useState } from "react";
import {
	Picker,
	Dimensions,
	ScrollView,
	TouchableOpacity,
	Platform,
	SafeAreaView,
	ActivityIndicator,
	Image,
	View,
	Text,
	StyleSheet,
	Switch,
	TextInput,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../../../utils/styles";
import { LoadingPage } from "../../../components/LoadingPage";
import { useUserContext } from "../../../contexts/userContext";
import Footer from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { Header as AuthHeader } from "../../../components/AuthHeader";
import { Svg, Circle } from "react-native-svg";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { A } from "@expo/html-elements";
import {
	FontAwesome5,
	AntDesign,
	Feather,
	FontAwesome,
} from "@expo/vector-icons";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from "@react-navigation/native";
import { useFirebaseContext } from "../../../contexts/firebaseContext";

// Firebase
import {
	getFirestore,
	collection,
	getDocs,
	query,
	where,
	doc,
	getDoc,
	setDoc,
	deleteDoc,
	updateDoc,
	arrayUnion,
	arrayRemove,
	increment,
} from "firebase/firestore";

const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

// Account Screen
export const InstaBook = ({ navigation }) => {
	const {
		user,
		instaBook,
		instaBookLength,
		instaBookMinimum,
		name,
		URL,
		isTutor,
		notificationPreferences,
		toggleNotificationPreferences,
	} = useUserContext();
	const [instaBookMinimumState, setInstaBookMinimumState] =
		React.useState(instaBookMinimum);
	const customAlert = useAlert();
	const { db } = useFirebaseContext();

	useEffect(() => {
		setInstaBookMinimumState(instaBookMinimum);
	}, [instaBookMinimum]);

	// User Context
	const auth = getAuth();
	const [loading, setLoading] = useState(true);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [confirmDeleteText, setConfirmDeleteText] = useState("");
	const [lastUpdated, setLastUpdated] = useState(
		// Date formatted with year month day hour minute second in local time
		new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
	);

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		toggleDarkMode,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},
		mainSection: {
			flex: 1,
			padding: isSm ? 45 : 15,
			paddingTop: 30,
		},
		title: {
			fontSize: 36,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		subtitle: {
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		description: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
		},
		infoTitle: {
			textAlign: "left",
			fontSize: 22,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		infoSubtitle: {
			fontSize: 19,
			fontFamily: defaultStyles.boldFont,
			color: textVariant,
		},
		infoDescription: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
			marginTop: 5,
			display: "flex",
			flex: 1,
		},
		infoLink: {
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
			color: primary,
			marginTop: 10,
		},
		picker: {
			height: 50,
			// width: 100,
			color: text,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	useEffect(() => {
		if (user) {
			// Log email notifications
			// console.log('notificationPreferences.newMessage: ', notificationPreferences.emailNotifications);
			setLoading(false);
		}
	}, [user]);

	const tutorial = () => {
		confirmAlert({
			title: "InstaBook",
			customUI: ({ onClose }) => {
				return (
					<View
						style={{
							width: "75%",
							maxWidth: 1050,
							margin: "auto",
							backgroundColor: background,
							borderRadius: 10,
							padding: 30,
							border: "1px solid" + border,
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 19,
								fontFamily: defaultStyles.titleFont,
								marginTop: 10,
							}}
						>
							How to use InstaBook
						</Text>

						{/* GIF GUIDE */}
						<Image
							source={require("../../../assets/guide.gif")}
							style={{
								height: 350,
								width: 350,
								margin: "auto",
								marginTop: 15,
								border: "1px solid" + primaryVariant,
								borderRadius: 10,
							}}
						/>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 30,
							}}
						>
							InstaBook gives you the ability to be instantly
							booked by students looking for on-demand help.
						</Text>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 8,
							}}
						>
							1. To use InstaBook, simply set the default length
							of your sessions, and the minimum fee you would like
							to charge. The standard rate will be your hourly
							rate multiplied by the length of the session however
							if the amount is less than the minimum fee you will
							be receive the minimum fee.
						</Text>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 8,
							}}
						>
							2. After configuring your settings you can flip on
							InstaBook and you will be able to be booked by
							students looking for on-demand help!
						</Text>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 8,
							}}
						>
							3. When a student books a session with you you will
							be redirected to a video session, you will start the
							session after you both discuss the details in the 5
							minute window provided before the session starts.
						</Text>

						<View
							style={{
								flexDirection: "row",
								// justifyContent: "space-around",
								marginTop: 20,
							}}
						>
							<TouchableOpacity
								onPress={() => {
									onClose();
								}}
								style={{
									backgroundColor: primary,
									width: 100,
									height: 40,
									borderRadius: defaultStyles.buttonRadius,
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Text
									style={{
										color: "white",
										fontSize: 16,
										fontFamily: defaultStyles.regularFont,
										textAlign: "center",
									}}
								>
									Got it
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				);
			},
		});
	};

	return (
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}

			{/* Main Content */}
			{loading ? (
				<LoadingPage />
			) : (
				<ScrollView
					showsVerticalScrollIndicator={false}
					style={{ backgroundColor: background }}
				>
					<View style={styles.maxWidth}>
						<View style={[styles.mainSection]}>
							{/* Title */}
							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									marginBottom: 5,
								}}
							>
								<Link
									// onMouseEnter={handlePressIn}
									// onMouseLeave={handlePressOut}
									to={"/settings"}
								>
									<Text>Settings</Text>
								</Link>{" "}
								<Text
									style={{
										fontFamily: defaultStyles.boldFont,
										color: primary,
									}}
								>
									<Feather
										name="chevron-right"
										size={15}
										color={text}
									/>{" "}
									InstaBook
								</Text>
							</Text>

							<Text style={styles.title}>InstaBook settings</Text>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<Text
									style={{
										color: text,
										fontSize: 16,
										fontFamily: defaultStyles.regularFont,
									}}
								>
									InstaBook status: {instaBook ? "On" : "Off"}
								</Text>
							</View>

							<View
								style={{
									display: "flex",
									flexDirection: isLg ? "row" : "column",
									gap: 30,
								}}
							>
								<View
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										paddingRight: 30,
										marginTop: 30,
									}}
								>
									{/* Put main Content here  */}
									{/* IB length input */}
									<View
										style={{
											borderBottomColor: border,
											borderBottomWidth: 1,
											paddingBottom: 15,
											marginBottom: 15,
											display: "flex",
											flexDirection: isMd
												? "row"
												: "column",
											justifyContent: "space-between",
											alignItems: isMd
												? "center"
												: "flex-start",
										}}
									>
										<View
											style={{
												maxWidth: "70%",
											}}
										>
											<Text style={styles.subtitle}>
												Session length
											</Text>
											<Text style={styles.description}>
												Set the default length of your
												InstaBook sessions.
											</Text>
										</View>
										<Picker
											selectedValue={instaBookLength}
											style={styles.picker}
											onValueChange={(
												itemValue,
												itemIndex
											) => {
												// // Update user in database with new instabook value
												updateDoc(
													doc(db, "users", user.uid),
													{
														instaBookLength:
															parseInt(itemValue),
													}
												)
													.then(() => {})
													.catch((error) => {
														console.log(error);
													});
											}}
										>
											<Picker.Item
												label="15 mins"
												value="15"
											/>
											<Picker.Item
												label="30 mins"
												value="30"
											/>
											<Picker.Item
												label="45 mins"
												value="45"
											/>
											<Picker.Item
												label="1 hour"
												value="60"
											/>
											<Picker.Item
												label="1.5 hours"
												value="90"
											/>
											<Picker.Item
												label="2 hours"
												value="120"
											/>
										</Picker>
									</View>

									{/* Minimum fee input */}
									<View
										style={{
											borderBottomColor: border,
											borderBottomWidth: 1,
											paddingBottom: 15,
											marginBottom: 15,
											display: "flex",
											flexDirection: isMd
												? "row"
												: "column",
											justifyContent: "space-between",
											alignItems: isMd
												? "center"
												: "flex-start",
										}}
									>
										<View
											style={{
												maxWidth: "70%",
											}}
										>
											<Text style={styles.subtitle}>
												Minimum fee
											</Text>
											<Text style={styles.description}>
												If the time of your session is
												less than the minimum fee, you
												will earn the minimum fee.
											</Text>
										</View>
										<TextInput
											style={[styles.picker]}
											placeholder="Minimum fee"
											placeholderTextColor={textVariant}
											keyboardType="numeric"
											value={instaBookMinimumState}
											// value={minimumFee}
											onChangeText={(text) => {
												setInstaBookMinimumState(text);
												// setMinimumFee(text);
												// If minimum fee is less than 1 set it to 1
												if (parseInt(text) < 1) {
													text = 1;
												}
												updateDoc(
													doc(db, "users", user.uid),
													{
														instaBookMinimum:
															parseInt(text),
													}
												)
													.then(() => {})
													.catch((error) => {});
											}}
											min={1}
										/>
									</View>
								</View>

								{/* Right side */}
								<View
									style={{
										display: "flex",
										flexDirection: !isLg ? "row" : "column",
										width: isLg ? 330 : "100%",
									}}
								>
									<View
										style={{
											border: "1px solid " + border,
											borderRadius: defaultStyles.radius,
											padding: 15,
											width: isLg ? 330 : "100%",
										}}
									>
										{/* Icon  */}
										<AntDesign
											name="questioncircleo"
											size={32}
											color={text}
											style={{ marginBottom: 10 }}
										/>

										{/* Title */}
										<Text style={styles.infoSubtitle}>
											What is InstaBook?
										</Text>
										{/* Description */}
										<Text style={styles.infoDescription}>
											InstaBook gives you the ability to
											be instantly booked by students
											looking for on-demand help. To use
											InstaBook, simply hit the InstaBook
											button on the top header. Make sure
											to turn on InstaBook when you're
											ready to be booked!
										</Text>
										{/* Divider  */}
										<View
											style={{
												width: "100%",
												height: 1,
												backgroundColor: border,
												marginTop: 15,
												marginBottom: 15,
											}}
										></View>

										{/* Icon  */}

										<AntDesign
											name="videocamera"
											size={32}
											color={text}
											style={{ marginBottom: 10 }}
										/>

										{/* Title */}
										<Text style={styles.infoSubtitle}>
											How does it work?
										</Text>
										{/* Description */}
										<Text style={styles.infoDescription}>
											When a student books a session with
											you you will be redirected to a
											video session, you will start the
											session after you both discuss the
											details in the 5 minute window
											provided before the session starts.
											The standard rate will be your
											hourly rate multiplied by the length
											of the session however if the amount
											is less than the minimum fee you
											will be receive the minimum fee.
										</Text>

										{/* Link to learn about super tutor program */}
										<TouchableOpacity
											style={styles.infoLink}
											onPress={() => {
												// console.log('Open tutorial');
												tutorial();
											}}
										>
											Learn more
										</TouchableOpacity>
									</View>
									{isMd && <View style={{ flex: 1 }}></View>}
								</View>
							</View>
						</View>
						{/* Last updated in bottom left corner */}
						{/* <Text style={{color: primary, fontSize: 12, fontFamily: defaultStyles.regularFont, position: 'absolute', bottom: 0, left: 0, marginBottom: 15, padding: isSm ? 45: 15,}}>Last updated: {lastUpdated}</Text> */}
					</View>
					{/* Footer  */}
					{isSm ? <Footer></Footer> : null}
				</ScrollView>
			)}
		</SafeAreaView>
	);
};
