// Imports
import { A } from "@expo/html-elements";
import {
	AntDesign,
	Feather,
	FontAwesome,
	FontAwesome5,
} from "@expo/vector-icons";
import { Link } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import * as Sharing from "expo-sharing";
import { getAuth } from "firebase/auth";
import {
	addDoc,
	collection,
	doc,
	getDocs,
	limit,
	onSnapshot,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
	ActivityIndicator,
	Alert,
	Image,
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../../../components/AuthHeader";
import { Header } from "../../../components/Header";
import { TutorReview } from "../../../components/TutorReview";
import { TutorSession } from "../../../components/TutorSession";
import { useColorsContext, useStyles } from "../../../utils/styles";

import Footer from "../../../components/Footer";
import { EditProfileNew } from "../Profile/EditProfileNew";
// Import paper plane icon
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SendIcon from "@mui/icons-material/Send";
import FadeIn from "react-native-fade-in-image";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { BookSessionModal } from "../../../components/BookSessionModal";
import { LoadingPage } from "../../../components/LoadingPage";
import { ReportModal } from "../../../components/ReportModal";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useSessionContext } from "../../../contexts/sessionContext";
import { useUserContext } from "../../../contexts/userContext";
import moment from 'moment-timezone';
import { provinces, statesUSA } from '../../../config/locations';


const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";
const shareLink = dev
	? "https://tutred-staging.vercel.app"
	: "https://tutred.com";

const iconsForSubjects = {
	Art: "paint-brush",
	Biology: "heartbeat",
	Chemistry: "flask",
	"Computer Science": "desktop",
	Economics: "money-bill",
	"English Language Arts": "book",
	"Environmental Science": "leaf",
	Geography: "map",
	"Health Education": "heartbeat",
	History: "history",
	"Social Studies": "globe",
	Psychology: "male",
	Science: "flask",
	Sociology: "users",
	Mathematics: "calculator",
	Music: "music",
	Physics: "flask",
	"Foreign Language": "language",
	Philosophy: "bookmark",
};

const provinceToTimeZone = {
	"Alberta": "America/Edmonton",
	"British Columbia": "America/Vancouver",
	"Manitoba": "America/Winnipeg",
	"New Brunswick": "America/Moncton",
	"Newfoundland and Labrador": "America/St_Johns",
	"Northwest Territories": "America/Yellowknife",
	"Nova Scotia": "America/Halifax",
	"Nunavut": "America/Iqaluit",
	"Ontario": "America/Toronto",
	"Prince Edward Island": "America/Halifax",
	"Quebec": "America/Montreal",
	"Saskatchewan": "America/Regina",
	"Yukon": "America/Whitehorse",
};

const stateToTimeZone = {
	"Alabama": "America/Chicago",
	"Alaska": "America/Anchorage",
	"Arizona": "America/Phoenix",
	"Arkansas": "America/Chicago",
	"California": "America/Los_Angeles",
	"Colorado": "America/Denver",
	"Connecticut": "America/New_York",
	"Delaware": "America/New_York",
	"Florida": "America/New_York",
	"Georgia": "America/New_York",
	"Hawaii": "Pacific/Honolulu",
	"Idaho": "America/Boise",
	"Illinois": "America/Chicago",
	"Indiana": "America/Indiana/Indianapolis",
	"Iowa": "America/Chicago",
	"Kansas": "America/Chicago",
	"Kentucky": "America/New_York",
	"Louisiana": "America/Chicago",
	"Maine": "America/New_York",
	"Maryland": "America/New_York",
	"Massachusetts": "America/New_York",
	"Michigan": "America/Detroit",
	"Minnesota": "America/Chicago",
	"Mississippi": "America/Chicago",
	"Missouri": "America/Chicago",
	"Montana": "America/Denver",
	"Nebraska": "America/Chicago",
	"Nevada": "America/Los_Angeles",
	"New Hampshire": "America/New_York",
	"New Jersey": "America/New_York",
	"New Mexico": "America/Denver",
	"New York": "America/New_York",
	"North Carolina": "America/New_York",
	"North Dakota": "America/Chicago",
	"Ohio": "America/New_York",
	"Oklahoma": "America/Chicago",
	"Oregon": "America/Los_Angeles",
	"Pennsylvania": "America/New_York",
	"Rhode Island": "America/New_York",
	"South Carolina": "America/New_York",
	"South Dakota": "America/Chicago",
	"Tennessee": "America/Chicago",
	"Texas": "America/Chicago",
	"Utah": "America/Denver",
	"Vermont": "America/New_York",
	"Virginia": "America/New_York",
	"Washington": "America/Los_Angeles",
	"West Virginia": "America/New_York",
	"Wisconsin": "America/Chicago",
	"Wyoming": "America/Denver",
};




const subjectsForSkills = {
	Algebra: "Mathematics",
	Geometry: "Mathematics",
	Calculus: "Mathematics",
	Statistics: "Mathematics",
	Probability: "Mathematics",
	Trigonometry: "Mathematics",
	Reading: "English Language Arts",
	Writing: "English Language Arts",
	Grammar: "English Language Arts",
	Literature: "English Language Arts",
	"Creative Writing": "English Language Arts",
	Poetry: "English Language Arts",
	Journalism: "English Language Arts",
	"Speech and Debate": "English Language Arts",
	Biology: "Science",
	Chemistry: "Science",
	Physics: "Science",
	"Earth Science": "Science",
	Astronomy: "Science",
	"Environmental Science": "Science",
	"Anatomy and Physiology": "Science",
	Botany: "Science",
	Zoology: "Science",
	History: "Social Studies",
	Geography: "Social Studies",
	Civics: "Social Studies",
	Government: "Social Studies",
	Economics: "Social Studies",
	Anthropology: "Social Studies",
	Sociology: "Social Studies",
	Psychology: "Social Studies",
	Fitness: "Physical Education",
	Sports: "Physical Education",
	"Team Games": "Physical Education",
	"Individual Sports": "Physical Education",
	Dance: "Physical Education",
	Yoga: "Physical Education",
	Pilates: "Physical Education",
	Drawing: "Art",
	Painting: "Art",
	Sculpture: "Art",
	Ceramics: "Art",
	Printmaking: "Art",
	"Graphic Design": "Art",
	"Digital Art": "Art",
	Animation: "Art",
	Photography: "Art",
	"Music Theory": "Music",
	Singing: "Music",
	"Playing Instruments": "Music",
	"Music Composition": "Music",
	"Music History": "Music",
	"Music Appreciation": "Music",
	Grammar: "Foreign Language",
	Vocabulary: "Foreign Language",
	Reading: "Foreign Language",
	Writing: "Foreign Language",
	Speaking: "Foreign Language",
	Listening: "Foreign Language",
	Culture: "Foreign Language",
	Programming: "Computer Science",
	Algorithms: "Computer Science",
	"Data Structures": "Computer Science",
	"Computer Networks": "Computer Science",
	"Web Development": "Computer Science",
	"Software Engineering": "Computer Science",
	Python: "Computer Science",
	Java: "Computer Science",
	"C++": "Computer Science",
	"C#": "Computer Science",
	JavaScript: "Computer Science",
	"World History": "History",
	"US History": "History",
	"European History": "History",
	"Asian History": "History",
	"African History": "History",
	"Latin American History": "History",
	"Middle Eastern History": "History",
	"Physical Geography": "Geography",
	"Human Geography": "Geography",
	Cartography: "Geography",
	"Geographic Information Systems (GIS)": "Geography",
	"Cell Biology": "Biology",
	Ecology: "Biology",
	Genetics: "Biology",
	Microbiology: "Biology",
	Physiology: "Biology",
	Botany: "Biology",
	"Organic Chemistry": "Chemistry",
	"Inorganic Chemistry": "Chemistry",
	"Physical Chemistry": "Chemistry",
	"Analytical Chemistry": "Chemistry",
	Biochemistry: "Chemistry",
	Mechanics: "Physics",
	Thermodynamics: "Physics",
	Electromagnetism: "Physics",
	Optics: "Physics",
	"Quantum Mechanics": "Physics",
	Microeconomics: "Economics",
	Macroeconomics: "Economics",
	"International Economics": "Economics",
	"Financial Economics": "Economics",
	"Developmental Psychology": "Psychology",
	"Abnormal Psychology": "Psychology",
	"Social Psychology": "Psychology",
	"Cognitive Psychology": "Psychology",
	"Social Stratification": "Sociology",
	Criminology: "Sociology",
	"Race and Ethnicity": "Sociology",
	"Gender Studies": "Sociology",
	"Social Movements": "Sociology",
	"Culture and Society": "Sociology",
	"Climate Science": "Environmental Science",
	"Conservation Biology": "Environmental Science",
	Sustainability: "Environmental Science",
	"Environmental Policy": "Environmental Science",
	Ethics: "Philosophy",
	Epistemology: "Philosophy",
	Metaphysics: "Philosophy",
	Logic: "Philosophy",
	"Political philosophy": "Philosophy",
	Nutrition: "Health Education",
	"Sexual health": "Health Education",
	"Mental health": "Health Education",
	"Substance abuse prevention": "Health Education",
	"First aid and safety": "Health Education",
	"Disease prevention": "Health Education",
	"Physical fitness and wellness": "Health Education",
	"Health advocacy and communication": "Health Education",
};

const subjectDescriptions = {
	Mathematics:
		"Mathematics is the study of numbers, quantities, and shapes. It involves various branches such as algebra, geometry, calculus, statistics, probability, and trigonometry.",
	"English Language Arts":
		"English Language Arts encompasses the study of reading, writing, grammar, literature, creative writing, poetry, journalism, and speech and debate.",
	Science:
		"Science involves the systematic study of the natural world, including branches like biology, chemistry, physics, earth science, astronomy, environmental science, anatomy and physiology, botany, and zoology.",
	"Social Studies":
		"Social Studies explores the study of human society and social relationships. It includes subjects like history, geography, civics, government, economics, anthropology, sociology, and psychology.",
	"Physical Education":
		"Physical Education focuses on physical fitness, sports, team games, individual sports, dance, yoga, and pilates to promote health and well-being.",
	Art: "Art involves creative expression and visual communication through various mediums such as drawing, painting, sculpture, ceramics, printmaking, graphic design, digital art, animation, and photography.",
	Music: "Music involves the study of music theory, singing, playing instruments, music composition, music history, and music appreciation.",
	"Foreign Language":
		"Foreign Language focuses on the study of grammar, vocabulary, reading, writing, speaking, listening, and culture of languages other than one's native language.",
	"Computer Science":
		"Computer Science deals with the study of computers, programming, algorithms, data structures, computer networks, web development, software engineering, and various programming languages like Python, Java, C++, C#, and JavaScript.",
	History:
		"History involves the study of past events, people, and civilizations. It includes world history, US history, European history, Asian history, African history, Latin American history, and Middle Eastern history.",
	Geography:
		"Geography is the study of the Earth's physical features, climate, human societies, and their interactions. It includes physical geography, human geography, cartography, and geographic information systems (GIS).",
	Biology:
		"Biology is the study of living organisms, their structure, function, evolution, and relationships with their environment. It includes cell biology, ecology, genetics, microbiology, physiology, zoology, and botany.",
	Chemistry:
		"Chemistry deals with the composition, properties, and reactions of substances. It includes organic chemistry, inorganic chemistry, physical chemistry, analytical chemistry, and biochemistry.",
	Physics:
		"Physics involves the study of matter, energy, and the fundamental forces of the universe. It includes mechanics, thermodynamics, electromagnetism, optics, and quantum mechanics.",
	Economics:
		"Economics focuses on the production, distribution, and consumption of goods and services. It includes microeconomics, macroeconomics, international economics, and financial economics.",
	Psychology:
		"Psychology is the study of the human mind and behavior. It includes developmental psychology, abnormal psychology, social psychology, and cognitive psychology.",
	Sociology:
		"Sociology explores human society and social behavior. It includes social stratification, criminology, race and ethnicity, gender studies, social movements, culture, and society.",
	"Environmental Science":
		"Environmental Science examines the environment and its interactions with living organisms. It includes climate science, conservation biology, sustainability, and environmental policy.",
	Philosophy:
		"Philosophy involves the study of fundamental questions about existence, knowledge, values, reason, and reality. It includes ethics, epistemology, metaphysics, logic, and political philosophy.",
	"Health Education":
		"Health Education focuses on promoting physical and mental health, covering topics such as nutrition, sexual health, mental health, substance abuse prevention, first aid and safety, disease prevention, physical fitness and wellness, and health advocacy and communication.",
};

// Import linking

export const TutorProfile = ({ navigation, route }) => {
	// Firebase
	const { db } = useFirebaseContext();
	const auth = getAuth();
	const { isTutor, customerId, user, name, profile, favourites } =
		useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const { pendingSessions, createInstaBookSession } = useSessionContext();
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [reportModal, setReportModal] = useState(false);
	const [editProfile, setEditProfile] = useState(false);
	const [localTime, setLocalTime] = useState('');


	// Alert
	const customAlert = useAlert();

	// Responsive Breakpoints
	const isXxl = useMediaQuery({ query: "(min-width: 1395px)" });
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		purple,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// States and variables

	const urlId = route.params.tutor;

	const [tutorId, setTutorId] = useState(null);
	const [totalSessions, setTotalSessions] = useState(0);
	const [sessions, setSessions] = useState([]);
	const [reviews, setReviews] = useState([]);
	const [rating, setRating] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [shareAvailable, setShareAvailable] = useState(false);
	const [hoursSinceOnline, setHoursSinceOnline] = useState(null);
	const [tutor, setTutor] = useState(null);
	const [bookable, setBookable] = useState(false);

	// Random color for banner
	let randomColors = ["#0087ED", "#234EDB", "#FF3D51", "#FFB347", "#00AA54"];
	const [randomColor] = useState(
		randomColors[Math.floor(Math.random() * randomColors.length)]
	);

	const getCurrentTimeInLocation = (location) => {
		const timeZone = provinceToTimeZone[location] || stateToTimeZone[location] || 'UTC'; // Default to UTC if the location is not found
		return moment().tz(timeZone).format('h:mm A');
	};

	// FOR TESTING
	const isVerified = false;

	// Stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		menuText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
		},
		menuTextSmall: {
			color: text,
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 15,
		},

		currentTime: {
			fontSize: 16,
			color: "#555",
			marginTop: 5,
		},

		maxWidth: {
			flex: 1,
			backgroundColor: background,
			// display: 'flex',
			// flexDirection: Platform.OS === 'web' ? 'row' : null,
			// flexDirection: 'row',
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},

		mainSection: {
			flex: 1,
			position: "relative",
		},
		profileImg: {
			marginBottom: 5,
			borderRadius: 100,
			width: 130,
			height: 130,
			opacity: 1,
			marginRight: 20,
		},
		title: {
			fontSize: 32,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		online: {
			marginLeft: 15,
			fontSize: 14,
			color: "#00AA54",
			fontFamily: defaultStyles.boldFont,
		},
		banner: {
			shadowColor: "#171717",
			shadowOffset: { width: -2, height: 4 },
			shadowOpacity: 0.2,
			shadowRadius: 3,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			height: 50,
			backgroundColor: backgroundVariant,
		},
		verified: {
			fontSize: 14,
			color: green,
			fontFamily: defaultStyles.boldFont,
		},
		description: {
			marginTop: 5,
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
			maxWidth: 925,
			// paddingHorizontal: 15,
		},
		circleButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 45,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			border: "1px solid " + primary,
			backgroundColor: "transparent",
			width: 245,
		},
		button: {
			borderRadius: defaultStyles.buttonRadius,
			height: 45,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
			width: 245,
		},
		disabledButton: {
			opacity: 0.5,
		},
		buttonText: {
			color: "#fff",
			fontSize: 19,
			fontFamily: defaultStyles.titleFont,
		},
		tutorInfo: {
			fontSize: 16,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		tutorDescriptionTitles: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
		},
		showMore: {
			color: textVariant,
			fontFamily: defaultStyles.boldFont,
			fontSize: 16,
		},
		actionText: {
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			fontSize: 14,
			marginLeft: 5,
			textDecorationColor: textVariant,
			textDecorationLine: "underline",
		},
	});

	const CancelModal = ({ title, message, onClose, onAccept }) => {
		return (
			<View
				style={{
					zIndex: 999,
					width: 450,
					backgroundColor: background,
					borderRadius: defaultStyles.radius,
					padding: 15,
					border: "1px solid" + border,
				}}
			>
				<Text
					style={{
						color: text,
						fontSize: 19,
						fontFamily: defaultStyles.titleFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{title}
				</Text>

				<Text
					style={{
						color: text,
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{message}
				</Text>

				<View
					style={{
						flexDirection: "row",
						justifyContent: "center",
						marginTop: 30,
					}}
				>
					<TouchableOpacity
						style={{
							backgroundColor: border,
							width: 100,
							height: 40,
							marginLeft: 5,
							borderRadius: defaultStyles.buttonRadius,
							justifyContent: "center",
							alignItems: "center",
						}}
						onPress={() => {
							onClose();
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
							}}
						>
							Dismiss
						</Text>
					</TouchableOpacity>

					<TouchableOpacity
						style={{
							backgroundColor: primary,
							// width: ,
							height: 40,
							marginLeft: 5,
							paddingLeft: 10,
							paddingRight: 10,
							borderRadius: defaultStyles.buttonRadius,
							justifyContent: "center",
							alignItems: "center",
						}}
						onPress={() => {
							onAccept();
						}}
					>
						<Text
							style={{
								color: "#fff",
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
							}}
						>
							Take me there
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	const InfoModal = (title, description, onClose) => {
		return (
			<View
				style={{
					width: 450,
					backgroundColor: background,
					borderRadius: defaultStyles.radius,
					padding: 15,
					border: "1px solid" + border,
				}}
			>
				<Text
					style={{
						color: text,
						fontSize: 19,
						fontFamily: defaultStyles.titleFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{title}
				</Text>

				<Text
					style={{
						color: text,
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{description}
				</Text>

				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-around",
						marginTop: 20,
					}}
				>
					<TouchableOpacity
						onPress={() => {
							onClose();
						}}
						style={{
							backgroundColor: primary,
							width: 100,
							height: 40,
							borderRadius: defaultStyles.buttonRadius,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Text
							style={{
								color: "white",
								fontSize: 16,
								fontFamily: defaultStyles.regularFont,
								textAlign: "center",
							}}
						>
							Ok
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	// Get reviews & tutor
	useEffect(() => {
		// Set sharing
		Sharing.isAvailableAsync().then((result) => {
			setShareAvailable(result);
		});
		// Get tutor data
		const q = query(
			collection(db, "users"),
			where("URL", "==", parseInt(urlId)),
			where("isTutor", "==", true),
			where("isSignedUp", "==", true),
			limit(1)
		);
		onSnapshot(q, (querySnapshot) => {
			querySnapshot.forEach((doc) => {
				let tutor = doc.data();
				let today = new Date();
				var hoursSinceOnline;
				if (tutor.lastOnline == null) {
					hoursSinceOnline = null;
				} else {
					let lastOnline = new Date(tutor.lastOnline.toDate());
					hoursSinceOnline = Math.floor(
						(today - lastOnline) / (1000 * 60 * 60)
					);
				}
				// console.log(tutor, 'tutor');
				setTutorId(tutor.uid);
				setTutor(tutor);
				setHoursSinceOnline(hoursSinceOnline);
				if (!tutor.unavailable) {
					setBookable(true);
				}
				// if (tutor.stripe && tutor.stripe.confirmed) {
				//   setBookable(true);
				// }
			});
		});
	}, []);

	useEffect(() => {
		if (tutorId) {
			setLoaded(true);
			// Snapshot listener for total tutor sessions
			onSnapshot(
				query(
					collection(db, "sessions"),
					where("tutor", "==", tutorId)
				),
				where("status", "==", "completed"),
				(querySnapshot) => {
					let sessions = [];
					querySnapshot.forEach((doc) => {
						let session = doc.data();
						if (
							session.status == "completed" &&
							session.lengthInMinutes > 0
						) {
							sessions.push(session);
						}
					});
					setSessions(sessions);
					setTotalSessions(sessions.length);
				}
			);
			// Snapshot listener for tutor reviews
			const unsubscribe = onSnapshot(
				query(collection(db, "reviews"), where("tutor", "==", tutorId)),
				(querySnapshot) => {
					let reviews = [];
					querySnapshot.forEach((doc) => {
						let review = doc.data();
						reviews.push(review);
					});
					setReviews(reviews);
				}
			);
			return unsubscribe;
		}
	}, [tutorId]);

	// Get rating
	useEffect(() => {
		// Get rating
		let rating = 0;
		reviews.map((review) => {
			rating += review.rating;
		});
		rating = rating / reviews.length || 0;
		setRating(rating.toFixed(1));
	}, [reviews]);

	if (!loaded) {
		return <LoadingPage></LoadingPage>;
	}
	// Return 404
	if (!tutor && loaded) {
		return (
			<SafeAreaView style={styles.container}>
				<View style={styles.maxWidth}>
					<View
						style={[
							styles.mainSection,
							{
								alignItems: "center",
								justifyContent: "center",
							},
						]}
					>
						<Text style={styles.title}>404 Tutor not found</Text>
					</View>
				</View>
			</SafeAreaView>
		);
	}

	// Sort the ratings from highest rating to lowest
	reviews.sort((a, b) => {
		return b.rating - a.rating;
	});

	const availabilityObject = tutor.availability ? tutor.availability : null;

	if (editProfile) {
		return (
			<EditProfileNew
				setEditProfile={setEditProfile}
				editProfile={editProfile}
				urlId={urlId}
			></EditProfileNew>
		);
	}

	// Render
	return (
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}

			{/* If viewing your own profile show banner */}

			{user && user.uid === tutor.uid ? (
				<View style={[styles.banner]}>
					<Text
						style={{
							color: text,
							fontFamily: defaultStyles.boldFont,
							fontSize: 16,
						}}
					>
						You are viewing your profile as a student
						{/* Public icon */}
						<FontAwesome5
							name="globe-americas"
							size={16}
							color={primaryVariant}
							style={{ marginLeft: 5 }}
						></FontAwesome5>
					</Text>
				</View>
			) : null}

			<View style={styles.maxWidth}>
				{/* Tutor info section  */}
				<ScrollView
					showsVerticalScrollIndicator={false}
					style={styles.mainSection}
				>
					{/* MODAL  */}
					<BookSessionModal
						modalVisible={modalVisible}
						setModalVisible={setModalVisible}
						tutor={tutor}
						navigation={navigation}
					/>

					{/* REPORT MODAL  */}
					<ReportModal
						modalVisible={reportModal}
						setModalVisible={setReportModal}
						tutor={tutor}
						navigation={navigation}
					/>

					<View
						style={{
							display: "flex",
							// LAYOUT FLEX
							flexDirection: isLg ? "row" : "column",
							width: "100%",
							paddingVertical: 30,
							paddingHorizontal: isMd ? 60 : 30,
						}}
					>
						<View
							style={{
								// Set width for all breakpoints
								borderRightColor: border,
								borderRightWidth: !isLg ? 0 : 1,
								maxWidth: isLg ? 375 : "100%",
								minWidth: isLg ? 300 : "100%",
							}}
						>
							<View
								style={{
									flex: 1,
									paddingTop: 0,
									paddingBottom: !isSm ? 15 : 20,
									paddingLeft: !isSm ? 15 : 0,
									display: "flex",
									flexDirection: "column",
									minWidth: !isSm && "100%",
									borderBottomColor: border,
									borderBottomWidth: !isLg ? 1 : 0,
								}}
							>
								<View
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 30,
										paddingRight: 20,
									}}
								>
									<Link
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
										to={{
											screen: "Tutors",
										}}
									>
										<NavigateBeforeIcon
											style={{
												fontSize: 30,
												color: text,
												cursor: "pointer",
												marginLeft: -10,
											}}
										></NavigateBeforeIcon>

										<Text
											style={{
												color: text,
												fontSize: 19,
												fontFamily:
													defaultStyles.boldFont,
											}}
										>
											{!isTutor
												? "More tutors"
												: "Sessions"}
										</Text>
									</Link>

									{/* Report and Favourite */}
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											gap: 15,
										}}
									>
										{/* Report */}
										<TouchableOpacity
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
											}}
											onPress={() => {
												if (
													user &&
													user.uid == tutor.uid
												) {
													customAlert.error(
														"[Error] You can't report yourself"
													);
												} else {
													if (!user) {
														customAlert.error(
															"[Error] Please sign in to report a tutor"
														);
													}
													setReportModal(true);
												}
											}}
										>
											{/* Icon */}
											<FontAwesome
												name="flag-o"
												size={14}
												color={textVariant}
											></FontAwesome>

											<Text style={styles.actionText}>
												Report
											</Text>
										</TouchableOpacity>

										{/* Favourite */}
										{favourites.includes(tutor.uid) ? (
											<TouchableOpacity
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
												}}
												onPress={() => {
													if (!user) {
														customAlert.error(
															"[Error] Please sign in to favourite a tutor"
														);
													}
													if (
														user &&
														user.uid == tutor.uid
													) {
														customAlert.error(
															"[Error] You can't favourite yourself"
														);
													} else {
														// Remove tutor ID from favourite array and update user doc
														var favs = favourites;
														var index =
															favs.indexOf(
																tutor.uid
															);
														if (index > -1) {
															favs.splice(
																index,
																1
															);
														}

														const userRef = doc(
															db,
															"users",
															user.uid
														);
														updateDoc(userRef, {
															favourites: favs,
														});
													}
												}}
											>
												{/* Icon */}
												<FontAwesome
													name="heart"
													size={14}
													color={primaryVariant}
												></FontAwesome>

												<Text style={styles.actionText}>
													Unfavourite
												</Text>
											</TouchableOpacity>
										) : (
											<TouchableOpacity
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
												}}
												onPress={() => {
													if (
														user &&
														user.uid == tutor.uid
													) {
														customAlert.error(
															"[Error] You can't favourite yourself"
														);
													}
													if (!user) {
														customAlert.error(
															"[Error] Please sign in to favourite a tutor"
														);
													} else {
														// Add tutor ID to favourite array and update user doc
														var newFavs =
															favourites;
														newFavs.push(tutor.uid);
														const userRef = doc(
															db,
															"users",
															user.uid
														);
														updateDoc(userRef, {
															favourites: newFavs,
														});
													}
												}}
											>
												{/* Icon */}
												<FontAwesome
													name="heart-o"
													size={14}
													color={textVariant}
												></FontAwesome>

												<Text style={styles.actionText}>
													Favourite
												</Text>
											</TouchableOpacity>
										)}
									</View>
								</View>

								<FadeIn>
									<Image
										style={styles.profileImg}
										source={{
											uri: tutor.picture
												? tutor.picture
												: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
										}}
									/>
								</FadeIn>
								{/* Name and online badge */}
								<View>
									<View
										style={{
											// marginTop: 20,
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Text style={styles.title}>
											{tutor.name.split(" ").length > 1
												? `${tutor.name.split(" ")[0]
												} ${tutor.name.split(
													" "
												)[1][0]
												}.`
												: tutor.name}
										</Text>
										{hoursSinceOnline !== null &&
											hoursSinceOnline <= 1 ? (
											<Text style={styles.online}>
												<FontAwesome
													name="circle"
													size={12}
													color={"#00AA54"}
												/>{" "}
												Online
											</Text>
										) : null}
									</View>

							

									{/* Verification */}
									{tutor.isVerified && (
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												marginTop: 10,
											}}
										>
											<Text style={styles.verified}>
												<FontAwesome
													style={{ marginRight: 10 }}
													name="check-circle"
													size={16}
													color={green}
												/>{" "}
												Credentials verified by Tutred
											</Text>
										</View>
									)}

									{/* Super tutor */}
									{tutor.superTutor && (
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												marginTop: 5,
											}}
										>
											<Text style={styles.verified}>
												<FontAwesome5
													style={{ marginRight: 10 }}
													name="award"
													size={16}
													color={primary}
												/>{" "}
												Super Tutor
											</Text>
										</View>
									)}

									{/* Total sessions */}
									<View
										style={{
											marginTop: 10,
											flexDirection: isMd
												? "row"
												: "column",
										}}
									>
										<Text
											style={[
												styles.tutorInfo,
												{
													marginRight: 15,
													marginTop: isMd ? 0 : 5,
												},
											]}
										>
											<AntDesign
												style={{ marginRight: 10 }}
												name="laptop"
												size={16}
												color={textVariant}
											/>
											{totalSessions} Completed Session(s)
										</Text>
									</View>

									{/* Languages list, */}
									<View
										style={{
											marginTop: 5,
											flexDirection: isMd
												? "row"
												: "column",
										}}
									>
										<Text
											style={[
												styles.tutorInfo,
												{
													marginRight: 15,
													marginTop: isMd ? 0 : 5,
												},
											]}
										>
											<Feather
												style={{ marginRight: 10 }}
												name="globe"
												size={16}
												color={textVariant}
											/>
											{Object.keys(tutor.languages).map(
												(language, index) => {
													if (
														index ==
														Object.keys(
															tutor.languages
														).length -
														1
													) {
														language = language;
													} else {
														language =
															language + ", ";
													}
													return <>{language}</>;
												}
											)}
										</Text>
									</View>

									{/* Tutor info -Location, Rating, Sessions, Join date */}
									<View
										style={{
											marginTop: 5,
											display: "flex",
											flexDirection: "column",
										}}
									>
									 <View style={{ flexDirection: "row", alignItems: "center" }}>

										<Text style={[styles.tutorInfo]}>
											<AntDesign
												style={{ marginRight: 10 }}
												name="enviromento"
												size={16}
												color={textVariant}
											/>
											{tutor.location.province}
										</Text>

											{/* Display Current Time */}
									<Text style={[styles.currentTime, { marginLeft: 10 }]}>
										{getCurrentTimeInLocation(tutor.location.province || tutor.location.state)}
									</Text>
									</View>


										<View
											style={{
												display: "flex",
												flexDirection: "row",
											}}
										>
											{rating > 0 && (
												<Text
													style={[
														styles.tutorInfo,
														styles.tutorRating,
														{ marginTop: 5 },
													]}
												>
													<FontAwesome
														style={{
															marginRight: 5,
														}}
														name="star"
														size={14}
														color={yellow}
													/>
													{rating} ({reviews.length})
												</Text>
											)}
										</View>

										<View
											style={{
												marginTop: 15,
												flexDirection: isSm
													? "row"
													: "column",
											}}
										>
											<View
												style={{ flexDirection: "row" }}
											>
												{user &&
													!isTutor &&
													user.uid !== tutor.uid && (
														<TouchableOpacity
															disabled={
																!user ||
																user.uid ==
																tutor.uid ||
																isTutor ||
																!bookable
															}
															onPress={() => {
																// Check if the user has a chat with this tutor
																// collection('chats').where('tutor', '==', tutor.uid).where('user', '==', user.uid).get()
																const chatsRef =
																	collection(
																		db,
																		"chats"
																	);
																const q = query(
																	chatsRef,
																	where(
																		"tutor.uid",
																		"==",
																		tutor.uid
																	),
																	where(
																		"user.uid",
																		"==",
																		user.uid
																	)
																);
																getDocs(q).then(
																	(
																		querySnapshot
																	) => {
																		if (
																			querySnapshot
																				.docs
																				.length >
																			0
																		) {
																			// // Navigate to the chat
																			var hoursSinceOnline;
																			if (
																				tutor.lastOnline
																			) {
																				hoursSinceOnline =
																					Math.floor(
																						(new Date() -
																							tutor.lastOnline.toDate()) /
																						1000 /
																						60 /
																						60
																					);
																			} else {
																				hoursSinceOnline =
																					null;
																			}

																			// Navigate to chat nested in chats navigator
																			navigation.navigate(
																				"Chats",
																				{
																					screen: "Chats",
																					params: {
																						chat: querySnapshot
																							.docs[0]
																							.id,
																						lo: hoursSinceOnline,
																					},
																				}
																			);
																		} else {
																			// Create the chat in firestore
																			const chatsRef =
																				collection(
																					db,
																					"chats"
																				);
																			var hoursSinceOnline;

																			if (
																				tutor.lastOnline
																			) {
																				hoursSinceOnline =
																					Math.floor(
																						(new Date() -
																							tutor.lastOnline.toDate()) /
																						1000 /
																						60 /
																						60
																					);
																			} else {
																				hoursSinceOnline =
																					null;
																			}

																			// Add Doc
																			addDoc(
																				chatsRef,
																				{
																					tutor: {
																						name: tutor.name,
																						image: tutor.picture
																							? tutor.picture
																							: null,
																						uid: tutor.uid,
																						id: tutor.uid,
																					},
																					user: {
																						name: name,
																						image: profile
																							? profile
																							: null,
																						uid: user.uid,
																						id: user.uid,
																					},
																					created:
																						new Date(),
																					lastUpdated:
																						new Date(),
																					lastMessage:
																						{},
																				}
																			).then(
																				(
																					docRef
																				) => {
																					// Chat object for navigation

																					// Navigate to the chat
																					// navigation.navigate('Chats', {screen: 'Chat', params: {chat: chatObject, hoursSinceOnline: hoursSinceOnline}} )
																					navigation.navigate(
																						"Chats",
																						{
																							screen: "Chats",
																							params: {
																								chat: docRef.id,
																								lo: hoursSinceOnline,
																							},
																						}
																					);
																				}
																			);
																		}
																	}
																);
															}}
															style={[
																styles.circleButton,
																{
																	marginTop:
																		isSm
																			? 0
																			: 0,
																	marginRight:
																		isSm
																			? 20
																			: 0,
																	opacity:
																		bookable
																			? 1
																			: 0.7,
																},
															]}
														>
															<Text
																style={[
																	styles.buttonText,
																	{
																		color: primary,
																		marginRight: 10,
																	},
																]}
															>
																Message
															</Text>

															<SendIcon
																style={{
																	fontSize: 20,
																	color: primary,
																}}
															></SendIcon>
														</TouchableOpacity>
													)}
											</View>
										</View>

										{/* Book buttons */}
										<View
											style={{
												display: "flex",
												flexDirection: "row",
												flexWrap: "wrap",
												alignItems: "center",
												gap: 8,
												width: "100%",
												marginTop: 10,
											}}
										>
											{!user && (
												<TouchableOpacity
													onPress={() => {
														if (user) {
															setModalVisible(
																!modalVisible
															);
														} else {
															if (
																Platform.OS ===
																"web"
															) {
																customAlert.error(
																	"[Error Booking] Please sign in to book a tutor"
																);
															} else {
																Alert.alert(
																	"Error booking tutor",
																	"Please sign in to book a tutor",
																	[
																		{
																			text: "OK",
																			onPress:
																				() =>
																					console.log(
																						"OK Pressed"
																					),
																		},
																	]
																);
															}
														}
													}}
													style={[
														styles.button,
														{
															marginTop: isSm
																? 0
																: 15,
														},
													]}
												>
													<Text
														style={
															styles.buttonText
														}
													>
														Book ${tutor.rate}/hr
													</Text>
												</TouchableOpacity>
											)}

											{user &&
												tutor &&
												user.uid !== tutor.uid &&
												tutor.instaBook &&
												bookable &&
												hoursSinceOnline <= 1 && (
													<TouchableOpacity
														onPress={() => {
															setLoading(true);
															// Confirm alert
															confirmAlert({
																title: "Book Tutor",
																customUI: ({
																	onClose,
																}) => {
																	return (
																		<View
																			style={{
																				width: 450,
																				backgroundColor:
																					background,
																				borderRadius:
																					defaultStyles.radius,
																				padding: 15,
																				border:
																					"1px solid" +
																					border,
																			}}
																		>
																			<Text
																				style={{
																					color: text,
																					fontSize: 19,
																					fontFamily:
																						defaultStyles.titleFont,
																					textAlign:
																						"center",
																					marginTop: 15,
																				}}
																			>
																				Would
																				you
																				like
																				to
																				start
																				a
																				meeting
																				with
																				this
																				tutor?
																			</Text>

																			<View
																				style={{
																					flexDirection:
																						"row",
																					justifyContent:
																						"center",
																					marginTop: 30,
																				}}
																			>
																				<TouchableOpacity
																					style={{
																						backgroundColor:
																							green,
																						width: 100,
																						height: 40,
																						marginLeft: 5,
																						borderRadius:
																							defaultStyles.buttonRadius,
																						justifyContent:
																							"center",
																						alignItems:
																							"center",
																					}}
																					onPress={() => {
																						onClose();
																						// SEND Authorization request to server (if failed means no card...)
																						// If success, create instant session
																						fetch(
																							`${hostname}/api/authorize-charge`,
																							{
																								method: "POST",
																								headers:
																								{
																									"Content-Type":
																										"application/json",
																									Authorization:
																										"Bearer " +
																										user.accessToken,
																								},
																								body: JSON.stringify(
																									{
																										amount: tutor.rate,
																										customerId:
																											customerId,
																										accountId:
																											tutor
																												.stripe
																												.accountId,
																									}
																								),
																							}
																						)
																							.then(
																								(
																									res
																								) =>
																									res.json()
																							)
																							.then(
																								(
																									data
																								) => {
																									if (
																										data.success
																									) {
																										let paymentIntentId =
																											data.paymentIntentId;
																										// Create session ID from timestamp and random string
																										let sessionId =
																											new Date().getTime() +
																											Math.random()
																												.toString(
																													36
																												)
																												.substring(
																													7
																												);

																										// Schedule the session from API
																										// https://localhost:8000/api/
																										// https://tutred-10235aff3fe9.herokuapp.com
																										fetch(
																											`${hostname}/api/schedule`,
																											{
																												method: "POST",
																												headers:
																												{
																													"Content-Type":
																														"application/json",
																													Authorization:
																														"Bearer " +
																														user.accessToken,
																												},
																												body: JSON.stringify(
																													{
																														event: "end-session",
																														// Date is today YYYY-MM-DD
																														date: new Date()
																															.toISOString()
																															.slice(
																																0,
																																10
																															),
																														// Time is hour:minute in AM/PM
																														time: new Date().toLocaleTimeString(
																															"en-US",
																															{
																																hour: "numeric",
																																minute: "numeric",
																																hour12: true,
																															}
																														),
																														data: {
																															sessionId:
																																sessionId,
																															uid: user.uid,
																															length: 10,
																														},
																													}
																												),
																											}
																										)
																											.then(
																												(
																													res
																												) =>
																													res.json()
																											)
																											.then(
																												(
																													data
																												) => {
																													if (
																														data.success
																													) {
																														createInstaBookSession(
																															sessionId,
																															user.uid,
																															name,
																															profile,
																															tutor,
																															paymentIntentId,
																															customerId
																														)
																															.then(
																																() => {
																																	// TODO: Hit email API
																																	fetch(
																																		`${hostname}/api/email/session`,
																																		{
																																			method: "POST",
																																			headers:
																																			{
																																				"Content-Type":
																																					"application/json",
																																				Authorization:
																																					"Bearer " +
																																					user.accessToken,
																																			},
																																			body: JSON.stringify(
																																				{
																																					email: tutor.email,
																																					other: name,
																																					name: tutor.name.split(
																																						" "
																																					)[0],
																																				}
																																			),
																																		}
																																	);

																																	customAlert.success(
																																		"[InstaBook Session] Now creating session please wait..."
																																	);
																																	// navigation.navigate('Sessions', {screen: 'Meeting', params: {sessionId: sessionId}})
																																	window.location.href = `/sessions/${sessionId}`;
																																	setLoading(
																																		false
																																	);
																																}
																															)
																															.catch(
																																(
																																	error
																																) => {
																																	console.log(
																																		error
																																	);
																																	if (
																																		Platform.OS ===
																																		"web"
																																	) {
																																		customAlert.error(
																																			"[Error with Instabook] Error creating instant session"
																																		);
																																	} else {
																																		Alert.alert(
																																			"Error creating instant session",
																																			error,
																																			[
																																				{
																																					text: "OK",
																																					onPress:
																																						() =>
																																							console.log(
																																								"OK Pressed"
																																							),
																																				},
																																			]
																																		);
																																	}
																																}
																															);
																													} else {
																														customAlert.error(
																															data.error
																														);
																													}
																												}
																											)
																											.catch(
																												(
																													error
																												) => {
																													customAlert.error(
																														"[Error with InstaBook] Network error when creating session... Please try again"
																													);
																												}
																											);
																									} else {
																										setLoading(
																											false
																										);

																										confirmAlert(
																											{
																												customUI:
																													({
																														onClose,
																													}) => {
																														return (
																															<CancelModal
																																onClose={() => {
																																	onClose();
																																}}
																																title="Failed to Book Tutor"
																																message={
																																	"In order to book a tutor, you must add a payment method to your account, please click the button below to add a payment method you will be redirected to the settings page. Once you have added a payment method, please try booking the tutor again."
																																}
																																onAccept={() => {
																																	onClose();
																																	navigation.navigate(
																																		"Home",
																																		{
																																			screen: "Profile",
																																		}
																																	);
																																}}
																															></CancelModal>
																														);
																													},
																											}
																										);

																										// {
																										//   Platform.OS == 'web'
																										//     ? customAlert.error(data.error)
																										//     : Alert.alert('Error booking tutor',  data.error, [
																										//       { text: 'OK', onPress: () => console.log('OK Pressed') },
																										//     ])
																										// }
																									}
																								}
																							)
																							.catch(
																								(
																									error
																								) => {
																									setLoading(
																										false
																									);
																									{
																										Platform.OS ==
																											"web"
																											? customAlert.error(
																												"[Error Booking] Error booking tutor, please try again!"
																											)
																											: Alert.alert(
																												"Error booking tutor",
																												"Please ensure you have a payment method set",
																												[
																													{
																														text: "OK",
																														onPress:
																															() =>
																																console.log(
																																	"OK Pressed"
																																),
																													},
																												]
																											);
																									}
																								}
																							);
																					}}
																				>
																					<Text
																						style={{
																							color: "#fff",
																							fontSize: 16,
																							fontFamily:
																								defaultStyles.boldFont,
																						}}
																					>
																						Ok
																					</Text>
																				</TouchableOpacity>

																				<TouchableOpacity
																					style={{
																						backgroundColor:
																							redVariant,
																						width: 100,
																						height: 40,
																						marginLeft: 5,
																						borderRadius:
																							defaultStyles.buttonRadius,
																						justifyContent:
																							"center",
																						alignItems:
																							"center",
																					}}
																					onPress={() => {
																						setLoading(
																							false
																						);
																						onClose();
																					}}
																				>
																					<Text
																						style={{
																							color: "#fff",
																							fontSize: 16,
																							fontFamily:
																								defaultStyles.boldFont,
																						}}
																					>
																						Cancel
																					</Text>
																				</TouchableOpacity>
																			</View>
																		</View>
																	);
																},
															});
														}}
														disabled={
															loading
																? true
																: false
														}
														style={[
															styles.button,
															{
																opacity: loading
																	? 0.5
																	: 1,
																backgroundColor:
																	green,
																marginRight:
																	isSm
																		? 15
																		: 0,
																marginTop: isSm
																	? 0
																	: 15,
															},
														]}
													>
														<Text
															style={
																styles.buttonText
															}
														>
															{loading ? (
																<>
																	<ActivityIndicator
																		size="small"
																		color="#fff"
																	/>
																</>
															) : (
																<>
																	Instabook
																	<FontAwesome
																		name="check"
																		size={
																			20
																		}
																		color={
																			"#fff"
																		}
																		style={{
																			marginLeft: 5,
																		}}
																	></FontAwesome>
																</>
															)}
														</Text>
													</TouchableOpacity>
												)}

											{user &&
												tutor &&
												user.uid !== tutor.uid && (
													<TouchableOpacity
														disabled={!bookable}
														onPress={() => {
															if (user) {
																setModalVisible(
																	!modalVisible
																);
															} else {
																if (
																	Platform.OS ===
																	"web"
																) {
																	customAlert.error(
																		"[Error Booking] Please sign in to book a tutor"
																	);
																} else {
																	Alert.alert(
																		"Error booking tutor",
																		"Please sign in to book a tutor",
																		[
																			{
																				text: "OK",
																				onPress:
																					() =>
																						console.log(
																							"OK Pressed"
																						),
																			},
																		]
																	);
																}
															}
														}}
														style={[
															styles.button,
															{
																marginTop: isSm
																	? 0
																	: 15,
																opacity:
																	bookable
																		? 1
																		: 0.7,
															},
														]}
													>
														<Text
															style={
																styles.buttonText
															}
														>
															{bookable
																? `Book $${tutor.rate}/hr`
																: "Tutor unavailable"}
														</Text>
													</TouchableOpacity>
												)}

											{isTutor &&
												user &&
												user.uid === tutor.uid && (
													<TouchableOpacity
														onPress={() => {
															setEditProfile(
																true
															);
														}}
														style={[
															styles.button,
															{
																marginTop: isSm
																	? 0
																	: 15,
															},
														]}
													>
														<Text
															style={
																styles.buttonText
															}
														>
															Edit profile
														</Text>
													</TouchableOpacity>
												)}
										</View>

										{/* Share buttons */}
										<View
											style={{
												maxWidth: 245,
												marginTop: 15,
												flexDirection: "row",
												justifyContent: "center",
											}}
										>
											<TouchableOpacity
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
												onPress={() => {
													Clipboard.setString(
														`${shareLink}/tutors/` +
														tutor.uid
													);
													customAlert.success(
														"[Share Tutor] Copied tutor profile link to your clipboard."
													);
												}}
											>
												<FontAwesome5
													style={{
														fontSize: 16,
														color: text,
													}}
													name="clipboard"
												></FontAwesome5>
											</TouchableOpacity>

											<A
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginLeft: 10,
												}}
												target="_blank"
												href={
													`sms:&body=Check out this tutor on Tutred! ${shareLink}/tutors/` +
													urlId
												}
											>
												<AntDesign
													style={{
														fontSize: 16,
														color: text,
													}}
													name="message1"
												></AntDesign>
											</A>

											<A
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginLeft: 10,
												}}
												target="_blank"
												href={
													`https://twitter.com/intent/tweet?text=Check out this tutor on Tutred! ${shareLink}/tutors/` +
													urlId
												}
											>
												<FontAwesome5
													style={{
														fontSize: 16,
														color: text,
													}}
													name="twitter"
												></FontAwesome5>
											</A>

											<A
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginLeft: 10,
												}}
												target="_blank"
												href={
													`https://www.facebook.com/sharer/sharer.php?u=${shareLink}/tutors/` +
													urlId
												}
											>
												<FontAwesome5
													style={{
														fontSize: 16,
														color: text,
													}}
													name="facebook"
												></FontAwesome5>
											</A>
										</View>
									</View>
								</View>
							</View>
						</View>

						{/* Right Section  */}

						<View
							style={{
								flex: 1,
								display: "flex",
								flexDirection: "column",
								alignContent: "flex-start",
								justifyContent: "flex-start",
								padding: 30,
							}}
						>
							{/* Featured Reviews / See All Reviews */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									// alignContent: 'cent',
									justifyContent: "space-between",
								}}
							>
								<Text
									style={{
										fontSize: 16,
										color: primary,
										fontFamily: defaultStyles.boldFont,
										marginTop: isMd ? 15 : 0,
									}}
								>
									{reviews.length > 0
										? "Featured Review"
										: "Be the first to review this tutor!"}
								</Text>
							</View>

							<Text
								style={{
									fontSize: 24,
									color: text,
									fontFamily: defaultStyles.boldFont,
									marginTop: isMd ? 10 : 0,
								}}
							>
								{reviews.length > 0
									? reviews[0].review
									: "Feel free to leave some kind words after your tutoring session!"}
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: textVariant,
									fontFamily: defaultStyles.regularFont,
									// marginTop: 10,
								}}
							>
								{reviews.length > 0
									? reviews[0].user.name
									: "-Tutred Team"}
							</Text>

							{/* Row of stars */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									justifyContent: "flex-start",
									marginTop: 10,
								}}
							>
								{reviews.length > 0 &&
									[...Array(reviews[0].rating)].map(
										(star, i) => (
											<FontAwesome
												key={i}
												name="star"
												size={20}
												color={yellow}
												style={{ marginRight: 5 }}
											></FontAwesome>
										)
									)}
							</View>

							{/* <Text
                style={{
                  fontSize: 16,
                  color: textVariant,
                  fontFamily: defaultStyles.regularFont,
                  marginTop: 10,
                  // flex: 1,
                }}
              >
                Get a random Review with 3.5 or higher stars and a Min Review
                with 3.5 or higher stars Get a random Review with 3.5 or
                higher stars Get a random Review with 3.5 or higher stars
              </Text> */}

							<Text
								style={{
									color: primary,
									fontFamily: defaultStyles.boldFont,
									marginTop: 30,
									fontSize: 16,
								}}
							>
								About{" "}
								{tutor.name.split(" ").length > 1
									? `${tutor.name.split(" ")[0]} ${tutor.name.split(" ")[1][0]
									}.`
									: tutor.name}
							</Text>

							{/* Divider  */}
							<View
								style={{
									width: "100%",
									height: 1,
									backgroundColor: border,
									marginTop: 10,
									marginBottom: 10,
								}}
							></View>

							{/* Description */}
							<View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									flex: 1,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Bio
								</Text>
								<View
									style={{
										maxWidth: "85%",
										flex: 1,
									}}
								>
									<Text style={styles.description}>
										{showMore
											? tutor.description
											: tutor.description.substring(
												0,
												500
											)}{" "}
										{tutor.description.length > 500 ? (
											<Text
												onPress={() => {
													setShowMore(!showMore);
												}}
												style={styles.showMore}
											>
												{showMore
													? "Show less "
													: "Show more"}
											</Text>
										) : null}
									</Text>
								</View>
							</View>

							{/* Education  */}
							<View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									marginTop: 15,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Education
								</Text>{" "}
								<View
									style={{
										width: "85%",
										display: "flex",
										flexDirection: "column",
										flexWrap: "wrap",
									}}
								>
									{/* Degrees */}
									{tutor.degrees.length > 0 ? (
										<>
											{tutor.degrees.map(
												(degree, index) => {
													// Return Degree Name, School, and Year
													return (
														<View
															key={`degree-${index}`}
															style={{
																flexDirection:
																	"row",
															}}
														>
															<Text
																style={{
																	fontSize: 16,
																	color: text,
																	fontFamily:
																		defaultStyles.regularFont,
																	marginRight: 10,
																}}
															>
																{degree.name} -
															</Text>
															<Text
																style={{
																	fontSize: 16,
																	color: text,
																	fontFamily:
																		defaultStyles.regularFont,
																	marginRight: 10,
																}}
															>
																{degree.school}{" "}
																-
															</Text>

															{degree.type ? (
																<Text
																	style={{
																		fontSize: 16,
																		color: text,
																		fontFamily:
																			defaultStyles.regularFont,
																		marginRight: 10,
																	}}
																>
																	{
																		degree.type
																	}{" "}
																	-
																</Text>
															) : null}

															<Text
																style={{
																	fontSize: 16,
																	color: text,
																	fontFamily:
																		defaultStyles.regularFont,
																	marginRight: 10,
																}}
															>
																{degree.year}
															</Text>

															{/* Verification info badge */}
															{tutor.isVerified &&
																tutor.degrees
																	.length >
																0 && (
																	<Text
																		style={{
																			fontSize: 16,
																			color: green,
																			fontFamily:
																				defaultStyles.regularFont,
																			marginRight: 10,
																			paddingVertical: 2,
																		}}
																	>
																		Verified{" "}
																		<FontAwesome
																			name="check"
																			size={
																				16
																			}
																			color={
																				green
																			}
																		/>
																	</Text>
																)}
														</View>
													);
												}
											)}
										</>
									) : (
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
											}}
										>
											No Listed Degrees...
										</Text>
									)}
								</View>
							</View>

							{/* Subjects */}
							<View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									marginTop: 15,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Subjects
								</Text>
								<View
									style={{
										width: "85%",
										display: "flex",
										flexDirection: "row",
										flexWrap: "wrap",
										gap: 4,
									}}
								>
									{Object.keys(tutor.subjects).length > 0 ? (
										<>
											{Object.keys(tutor.subjects).map(
												(subject, index) => {
													return (
														<TouchableOpacity
															style={{
																paddingTop: 5,
																paddingBottom: 5,
																paddingLeft: 15,
																paddingRight: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																justifyContent:
																	"center",
																backgroundColor:
																	primaryVariant,
																borderRadius:
																	defaultStyles.buttonRadius,
															}}
															onPress={() => {
																confirmAlert({
																	customUI: ({
																		onClose,
																	}) => {
																		return InfoModal(
																			subject,
																			subjectDescriptions[
																			subject
																			],
																			onClose
																		);
																	},
																});
															}}
														>
															{/* Icon */}
															<FontAwesome5
																key={`tutor-subject-icon-${index}`}
																name={
																	iconsForSubjects[
																	subject
																	]
																}
																size={12}
																color={"#fff"}
																style={{
																	marginRight: 5,
																}}
															></FontAwesome5>

															<Text
																key={`tutor-subject-${index}`}
																style={{
																	color: "#fff",
																	fontSize: 16,
																	fontFamily:
																		defaultStyles.regularFont,
																}}
															>
																{subject}
															</Text>

															{/* Small info icon */}
															<FontAwesome5
																name="info-circle"
																size={12}
																color={"#fff"}
																style={{
																	marginLeft: 5,
																}}
															></FontAwesome5>
														</TouchableOpacity>
													);
												}
											)}
										</>
									) : (
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
											}}
										>
											No Subjects...
										</Text>
									)}
								</View>
							</View>

							{/* Skills */}
							<View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									marginTop: 15,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Skills
								</Text>{" "}
								<View
									style={{
										width: "85%",
										display: "flex",
										flexDirection: "row",
										flexWrap: "wrap",
										gap: 4,
									}}
								>
									{/* Map thru tutors skills */}
									{tutor.skills.length > 0 ? (
										tutor.skills.map((skill, index) => {
											let iconSubject =
												subjectsForSkills[skill];
											let iconName =
												iconsForSubjects[iconSubject];
											return (
												<TouchableOpacity
													style={{
														paddingTop: 5,
														paddingBottom: 5,
														paddingLeft: 15,
														paddingRight: 15,
														flexDirection: "row",
														alignItems: "center",
														justifyContent:
															"center",
														backgroundColor: purple,
														borderRadius:
															defaultStyles.buttonRadius,
													}}
													onPress={() => {
														confirmAlert({
															customUI: ({
																onClose,
															}) => {
																return InfoModal(
																	iconSubject,
																	subjectDescriptions[
																	iconSubject
																	],
																	onClose
																);
															},
														});
													}}
												>
													{/* Icon */}
													<FontAwesome5
														key={`tutor-subject-icon-${index}`}
														name={iconName}
														size={12}
														color={"#fff"}
														style={{
															marginRight: 5,
														}}
													></FontAwesome5>

													<Text
														key={`skill-${index}`}
														style={{
															color: "#fff",
															fontSize: 16,
															fontFamily:
																defaultStyles.regularFont,
														}}
													>
														{skill}
													</Text>

													{/* Small info icon */}
													<FontAwesome5
														name="info-circle"
														size={12}
														color={"#fff"}
														style={{
															marginLeft: 5,
														}}
													></FontAwesome5>
												</TouchableOpacity>
											);
										})
									) : (
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
											}}
										>
											No Skills Listed...
										</Text>
									)}
								</View>
							</View>

							{/* Availabilty */}
							<View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									marginTop: 15,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Availability
								</Text>{" "}
								<View style={{ width: "85%" }}>
									<View
										style={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Text
											style={{
												color: text,
												fontFamily:
													defaultStyles.regularFont,
												fontSize: 16,
											}}
										>
											Monday:{" "}
											<Text
												style={{
													color: text,
													// fontFamily: defaultStyles.regularFont,
													fontSize: 16,
												}}
											>
												{tutor.alwaysAvailable ? (
													"Available all day"
												) : (
													<></>
												)}
												{tutor.unavailable ? (
													"Unavailable"
												) : (
													<></>
												)}
												{availabilityObject &&
													availabilityObject.monday &&
													availabilityObject.monday
														.start &&
													availabilityObject.monday
														.end ? (
													<>
														{
															availabilityObject
																.monday.start
														}
														{" - "}
														{
															availabilityObject
																.monday.end
														}
													</>
												) : (
													""
												)}
											</Text>
										</Text>
										<Text
											style={{
												color: text,
												fontFamily:
													defaultStyles.regularFont,
												fontSize: 16,
											}}
										>
											Tuesday:{" "}
											<Text
												style={{
													color: text,
													// fontFamily: defaultStyles.regularFont,
													fontSize: 16,
												}}
											>
												{tutor.alwaysAvailable ? (
													"Available all day"
												) : (
													<></>
												)}
												{tutor.unavailable ? (
													"Unavailable"
												) : (
													<></>
												)}
												{availabilityObject &&
													availabilityObject.tuesday &&
													availabilityObject.tuesday
														.start &&
													availabilityObject.tuesday
														.end ? (
													<>
														{
															availabilityObject
																.tuesday.start
														}
														{" - "}
														{
															availabilityObject
																.tuesday.end
														}
													</>
												) : (
													""
												)}
											</Text>
										</Text>
										<Text
											style={{
												color: text,
												fontFamily:
													defaultStyles.regularFont,
												fontSize: 16,
											}}
										>
											Wednesday:{" "}
											<Text
												style={{
													color: text,
													fontFamily:
														defaultStyles.regularFont,
													fontSize: 16,
												}}
											>
												{tutor.alwaysAvailable ? (
													"Available all day"
												) : (
													<></>
												)}
												{tutor.unavailable ? (
													"Unavailable"
												) : (
													<></>
												)}
												{availabilityObject &&
													availabilityObject.wednesday &&
													availabilityObject.wednesday
														.start &&
													availabilityObject.wednesday
														.end ? (
													<>
														{
															availabilityObject
																.wednesday.start
														}
														{" - "}
														{
															availabilityObject
																.wednesday.end
														}
													</>
												) : (
													""
												)}
											</Text>
										</Text>
										<Text
											style={{
												color: text,
												fontFamily:
													defaultStyles.regularFont,
												fontSize: 16,
											}}
										>
											Thursday:{" "}
											<Text
												style={{
													color: text,
													fontFamily:
														defaultStyles.regularFont,
													fontSize: 16,
												}}
											>
												{tutor.alwaysAvailable ? (
													"Available all day"
												) : (
													<></>
												)}
												{tutor.unavailable ? (
													"Unavailable"
												) : (
													<></>
												)}
												{availabilityObject &&
													availabilityObject.thursday &&
													availabilityObject.thursday
														.start &&
													availabilityObject.thursday
														.end ? (
													<>
														{
															availabilityObject
																.thursday.start
														}
														{" - "}
														{
															availabilityObject
																.thursday.end
														}
													</>
												) : (
													""
												)}
											</Text>
										</Text>
										<Text
											style={{
												color: text,
												fontFamily:
													defaultStyles.regularFont,
												fontSize: 16,
											}}
										>
											Friday:{" "}
											<Text
												style={{
													color: text,
													fontFamily:
														defaultStyles.regularFont,
													fontSize: 16,
												}}
											>
												{tutor.alwaysAvailable ? (
													"Available all day"
												) : (
													<></>
												)}
												{tutor.unavailable ? (
													"Unavailable"
												) : (
													<></>
												)}
												{availabilityObject &&
													availabilityObject.friday &&
													availabilityObject.friday
														.start &&
													availabilityObject.friday
														.end ? (
													<>
														{
															availabilityObject
																.friday.start
														}
														{" - "}
														{
															availabilityObject
																.friday.end
														}
													</>
												) : (
													""
												)}
											</Text>
										</Text>
										<Text
											style={{
												color: text,
												fontFamily:
													defaultStyles.regularFont,
												fontSize: 16,
											}}
										>
											Saturday:{" "}
											<Text
												style={{
													color: text,
													fontFamily:
														defaultStyles.regularFont,
													fontSize: 16,
												}}
											>
												{tutor.alwaysAvailable ? (
													"Available all day"
												) : (
													<></>
												)}
												{tutor.unavailable ? (
													"Unavailable"
												) : (
													<></>
												)}
												{availabilityObject &&
													availabilityObject.saturday &&
													availabilityObject.saturday
														.start &&
													availabilityObject.saturday
														.end ? (
													<>
														{
															availabilityObject
																.saturday.start
														}
														{" - "}
														{
															availabilityObject
																.saturday.end
														}
													</>
												) : (
													""
												)}
											</Text>
										</Text>
										<Text
											style={{
												color: text,
												fontFamily:
													defaultStyles.regularFont,
												fontSize: 16,
											}}
										>
											Sunday:{" "}
											<Text
												style={{
													color: text,
													fontFamily:
														defaultStyles.regularFont,
													fontSize: 16,
												}}
											>
												{tutor.alwaysAvailable ? (
													"Available all day"
												) : (
													<></>
												)}
												{tutor.unavailable ? (
													"Unavailable"
												) : (
													<></>
												)}
												{availabilityObject &&
													availabilityObject.sunday &&
													availabilityObject.sunday
														.start &&
													availabilityObject.sunday
														.end ? (
													<>
														{
															availabilityObject
																.sunday.start
														}
														{" - "}
														{
															availabilityObject
																.sunday.end
														}
													</>
												) : (
													""
												)}
											</Text>
										</Text>
									</View>
								</View>
							</View>

							{/* Divider  */}
							<View
								style={{
									width: "100%",
									height: 1,
									backgroundColor: border,
									marginTop: 15,
									marginBottom: 10,
								}}
							></View>
							{/* Past Sessions  */}
							<View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									marginTop: 15,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Past Sessions
								</Text>{" "}
								<View style={{ width: "85%" }}>
									{/* Map thru tutors skills  */}
									{sessions.length < 1 ? (
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
											}}
										>
											No sessions yet...
										</Text>
									) : (
										<ScrollView
											style={{
												maxHeight: 350,
											}}
										>
											{sessions.map((session, index) => {
												return (
													<TutorSession
														key={`session-${index}`}
														user={session.user}
														session={session}
													></TutorSession>
												);
											})}
										</ScrollView>
									)}
								</View>
							</View>

							{/* Reviews  */}
							<View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									marginTop: 15,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Reviews
								</Text>
								<View
									style={{
										width: "85%",
									}}
								>
									{reviews.length < 1 ? (
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
											}}
										>
											No reviews yet...
										</Text>
									) : (
										<ScrollView
											style={{
												maxHeight: 350,
											}}
										>
											{reviews.map((review, index) => {
												return (
													<TutorReview
														key={`review-${index}`}
														user={review.user}
														review={review}
													></TutorReview>
												);
											})}
										</ScrollView>
									)}
								</View>
							</View>
						</View>
					</View>

					{/* Footer */}
					{isSm ? (
						<View style={{ marginTop: 100, width: "100%" }}>
							<Footer></Footer>{" "}
						</View>
					) : null}
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};
