// Imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

const Stack = createNativeStackNavigator();

// Import screens
import { Chats } from "../../screens/loggedIn/Chats/Chats";

// Components
export const ChatsStack = ({ navigation, route, params }) => {
	return (
		<Stack.Navigator initialRouteName="Chats">
			<Stack.Screen
				options={{ headerShown: false }}
				name="Chats"
				component={Chats}
			/>
		</Stack.Navigator>
	);
};
