import React from "react";
import { useAlert } from "react-alert";
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../components/AuthHeader";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import { useColorsContext, useStyles } from "../utils/styles";

import { useUserContext } from "../contexts/userContext";

export const Privacy = ({ navigation }) => {
	const { user } = useUserContext();

	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	const customAlert = useAlert();

	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: Platform.OS === "web" ? "flex" : "none", // Use "flex" on web and "none" on mobile
			flexDirection: Platform.OS === "web" ? "row" : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		contentContainer: {
			flexGrow: 1,
			justifyContent: "space-between",
		},
		headingBig: {
			fontSize: 32,
			fontWeight: 900,
			color: text,
			marginBottom: 30,
		},
		heading: {
			fontSize: 24,
			fontWeight: "bold",
			color: text,
			marginBottom: 10,
		},
		paragraph: {
			fontSize: 16,
			color: text,
			marginBottom: 20,
		},
		link: {
			color: primary,
			textDecorationLine: "underline",
		},
	});

	return (
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}
			<View style={styles.maxWidth}>
				<ScrollView
					contentContainerStyle={styles.contentContainer}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				>
					<View
						style={{
							padding: 30,
							maxWidth: 1200,
							margin: "auto",
						}}
					>
						<Text style={styles.headingBig}>Privacy Policy</Text>
						<Text style={styles.heading}>
							Collecting and Using Your Personal Data
						</Text>
						<Text style={styles.paragraph}>
							Types of Data Collected
						</Text>
						<Text style={styles.paragraph}>Personal Data</Text>
						<Text style={styles.paragraph}>
							While using Our Service, We may ask You to provide
							Us with certain personally identifiable information
							that can be used to contact or identify You.
							Personally identifiable information may include, but
							is not limited to: email address.
						</Text>
						<Text style={styles.paragraph}>Usage Data</Text>
						<Text style={styles.paragraph}>
							Usage Data may include information such as Your
							Device's Internet Protocol address (e.g. IP
							address), browser type, browser version, the pages
							of our Service that You visit, the time and date of
							Your visit, the time spent on those pages, unique
							device identifiers and other diagnostic data.
						</Text>
						<Text style={styles.paragraph}>
							When You access the Service by or through a mobile
							device, We may collect certain information
							automatically, including, but not limited to, the
							type of mobile device You use, Your mobile device
							unique ID, the IP address of Your mobile device,
							Your mobile operating system, the type of mobile
							Internet browser You use, unique device identifiers
							and other diagnostic data.
						</Text>
						<Text style={styles.paragraph}>
							We may also collect information that Your browser
							sends whenever You visit our Service or when You
							access the Service by or through a mobile device.
						</Text>
						<Text style={styles.paragraph}>
							Tracking Technologies and Cookies
						</Text>
						<Text style={styles.paragraph}>
							We use Cookies and similar tracking technologies to
							track the activity on Our Service and store certain
							information. Tracking technologies used are beacons,
							tags, and scripts to collect and track information
							and to improve and analyze Our Service. The
							technologies We use may include:
						</Text>
						<Text style={styles.paragraph}>
							- Cookies or Browser Cookies. A cookie is a small
							file placed on Your Device. You can instruct Your
							browser to refuse all Cookies or to indicate when a
							Cookie is being sent. However, if You do not accept
							Cookies, You may not be able to use some parts of
							our Service. Unless you have adjusted Your browser
							setting so that it will refuse Cookies, our Service
							may use Cookies.
							<br></br>- Flash Cookies. Certain features of our
							Service may use local stored objects (or Flash
							Cookies) to collect and store information about Your
							preferences or Your activity on our Service. Flash
							Cookies are not managed by the same browser settings
							as those used for Browser Cookies.
							<br></br>- Web Beacons. Certain sections of our
							Service and our emails may contain small electronic
							files known as web beacons (also referred to as
							clear gifs, pixel tags, and single-pixel gifs) that
							permit the Company, for example, to count users who
							have visited those pages or opened an email and for
							other related website statistics (for example,
							recording the popularity of a certain section and
							verifying system and server integrity).
						</Text>
						{/* ... (Rest of the content) ... */}
						<Text style={styles.heading}>
							Use of Your Personal Data
						</Text>
						<Text style={styles.paragraph}>
							The Company may use Personal Data for the following
							purposes:
						</Text>
						<Text style={styles.paragraph}>
							- To provide and maintain our Service, including to
							monitor the usage of our Service.
							<br></br>- To manage Your Account: to manage Your
							registration as a user of the Service. The Personal
							Data You provide can give You access to different
							functionalities of the Service that are available to
							You as a registered user.
							<br></br>- For the performance of a contract: the
							development, compliance and undertaking of the
							purchase contract for the products, items or
							services You have purchased or of any other contract
							with Us through the Service.
							<br></br>- To contact You: To contact You by email,
							telephone calls, SMS, or other equivalent forms of
							electronic communication, such as a mobile
							application's push notifications regarding updates
							or informative communications related to the
							functionalities, products or contracted services,
							including the security updates, when necessary or
							reasonable for their implementation.
							<br></br>- To provide You with news, special offers
							and general information about other goods, services
							and events which we offer that are similar to those
							that you have already purchased or enquired about
							unless You have opted not to receive such
							information.
							<br></br>- To manage Your requests: To attend and
							manage Your requests to Us.
							<br></br>- For business transfers: We may use Your
							information to evaluate or conduct a merger,
							divestiture, restructuring, reorganization,
							dissolution, or other sale or transfer of some or
							all of Our assets, whether as a going concern or as
							part of bankruptcy, liquidation, or similar
							proceeding, in which Personal Data held by Us about
							our Service users is among the assets transferred.
							<br></br>- For other purposes: We may use Your
							information for other purposes, such as data
							analysis, identifying usage trends, determining the
							effectiveness of our promotional campaigns and to
							evaluate and improve our Service, products,
							services, marketing and your experience.
						</Text>
						<Text style={styles.paragraph}>
							We may share Your personal information in the
							following situations:
						</Text>
						<Text style={styles.paragraph}>
							- With Service Providers: We may share Your personal
							information with Service Providers to monitor and
							analyze the use of our Service, to contact You.
							<br></br>- For business transfers: We may share or
							transfer Your personal information in connection
							with, or during negotiations of, any merger, sale of
							Company assets, financing, or acquisition of all or
							a portion of Our business to another company.
							<br></br>- With Affiliates: We may share Your
							information with Our affiliates, in which case we
							will require those affiliates to honor this Privacy
							Policy. Affiliates include Our parent company and
							any other subsidiaries, joint venture partners or
							other companies that We control or that are under
							common control with Us.
							<br></br>- With business partners: We may share Your
							information with Our business partners to offer You
							certain products, services or promotions.
							<br></br>- With other users: when You share personal
							information or otherwise interact in the public
							areas with other users, such information may be
							viewed by all users and may be publicly distributed
							outside.
							<br></br>- With Your consent: We may disclose Your
							personal information for any other purpose with Your
							consent.
						</Text>
						{/* ... (Rest of the content) ... */}
						<Text style={styles.heading}>
							Retention of Your Personal Data
						</Text>
						<Text style={styles.paragraph}>
							The Company will retain Your Personal Data only for
							as long as is necessary for the purposes set out in
							this Privacy Policy. We will retain and use Your
							Personal Data to the extent necessary to comply with
							our legal obligations (for example, if we are
							required to retain your data to comply with
							applicable laws), resolve disputes, and enforce our
							legal agreements and policies.
						</Text>
						<Text style={styles.paragraph}>
							The Company will also retain Usage Data for internal
							analysis purposes. Usage Data is generally retained
							for a shorter period of time, except when this data
							is used to strengthen the security or to improve the
							functionality of Our Service, or We are legally
							obligated to retain this data for longer time
							periods.
						</Text>
						{/* ... (Rest of the content) ... */}
						<Text style={styles.heading}>
							Transfer of Your Personal Data
						</Text>
						<Text style={styles.paragraph}>
							Your information, including Personal Data, is
							processed at the Company's operating offices and in
							any other places where the parties involved in the
							processing are located. It means that this
							information may be transferred to — and maintained
							on — computers located outside of Your state,
							province, country or other governmental jurisdiction
							where the data protection laws may differ than those
							from Your jurisdiction.
						</Text>
						<Text style={styles.paragraph}>
							Your consent to this Privacy Policy followed by Your
							submission of such information represents Your
							agreement to that transfer.
						</Text>
						<Text style={styles.paragraph}>
							The Company will take all steps reasonably necessary
							to ensure that Your data is treated securely and in
							accordance with this Privacy Policy and no transfer
							of Your Personal Data will take place to an
							organization or a country unless there are adequate
							controls in place including the security of Your
							data and other personal information.
						</Text>
						{/* ... (Rest of the content) ... */}
						<Text style={styles.heading}>
							Delete Your Personal Data
						</Text>
						<Text style={styles.paragraph}>
							You have the right to delete or request that We
							assist in deleting the Personal Data that We have
							collected about You.
						</Text>
						<Text style={styles.paragraph}>
							Our Service may give You the ability to delete
							certain information about You from within the
							Service.
						</Text>
						<Text style={styles.paragraph}>
							You may update, amend, or delete Your information at
							any time by signing in to Your Account, if you have
							one, and visiting the account settings section that
							allows you to manage Your personal information. You
							may also contact Us to request access to, correct,
							or delete any personal information that You have
							provided to Us.
						</Text>
						<Text style={styles.paragraph}>
							Please note, however, that We may need to retain
							certain information when we have a legal obligation
							or lawful basis to do so.
						</Text>
						{/* ... (Rest of the content) ... */}
						<Text style={styles.heading}>
							Disclosure of Your Personal Data
						</Text>
						<Text style={styles.paragraph}>
							Business Transactions
						</Text>
						<Text style={styles.paragraph}>
							If the Company is involved in a merger, acquisition
							or asset sale, Your Personal Data may be
							transferred. We will provide notice before Your
							Personal Data is transferred and becomes subject to
							a different Privacy Policy.
						</Text>
						<Text style={styles.paragraph}>Law enforcement</Text>
						<Text style={styles.paragraph}>
							Under certain circumstances, the Company may be
							required to disclose Your Personal Data if required
							to do so by law or in response to valid requests by
							public authorities (e.g. a court or a government
							agency).
						</Text>
						<Text style={styles.paragraph}>
							Other legal requirements
						</Text>
						<Text style={styles.paragraph}>
							The Company may disclose Your Personal Data in the
							good faith belief that such action is necessary to:
						</Text>
						<Text style={styles.paragraph}>
							- Comply with a legal obligation
						</Text>
						<Text style={styles.paragraph}>
							- Protect and defend the rights or property of the
							Company
						</Text>
						<Text style={styles.paragraph}>
							- Prevent or investigate possible wrongdoing in
							connection with the Service
						</Text>
						<Text style={styles.paragraph}>
							- Protect the personal safety of Users of the
							Service or the public
						</Text>
						<Text style={styles.paragraph}>
							- Protect against legal liability
						</Text>
						{/* ... (Rest of the content) ... */}
						<Text style={styles.heading}>
							Security of Your Personal Data
						</Text>
						<Text style={styles.paragraph}>
							The security of Your Personal Data is important to
							Us, but remember that no method of transmission over
							the Internet, or method of electronic storage is
							100% secure. While We strive to use commercially
							acceptable means to protect Your Personal Data, We
							cannot guarantee its absolute security.
						</Text>
						<Text style={styles.paragraph}>
							Links to Other Websites
						</Text>
						<Text style={styles.paragraph}>
							Our Service may contain links to other websites that
							are not operated by Us. If You click on a
							third-party link, You will be directed to that third
							party's site. We strongly advise You to review the
							Privacy Policy of every site You visit.
						</Text>
						<Text style={styles.paragraph}>
							We have no control over and assume no responsibility
							for the content, privacy policies or practices of
							any third-party sites or services.
						</Text>
						{/* ... (Rest of the content) ... */}
						<Text style={styles.heading}>
							Changes to this Privacy Policy
						</Text>
						<Text style={styles.paragraph}>
							We may update Our Privacy Policy from time to time.
							We will notify You of any changes by posting the new
							Privacy Policy on this page and informing You via
							email or text message.
						</Text>
						<Text style={styles.paragraph}>
							You are advised to review this Privacy Policy
							periodically for any changes. Changes to this
							Privacy Policy are effective when they are posted on
							this page.
						</Text>
						<Text style={styles.heading}>Contact Us</Text>
						<Text style={styles.paragraph}>
							If you have any questions about this Privacy Policy,
							You can contact us:
						</Text>
						<Text style={styles.paragraph}>
							- By email: support@tutred.com
						</Text>
					</View>
					{isSm ? <Footer /> : null}
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};
