import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import TutorBackground from "../../assets/Tutor-Section.jpg";
import Button from "@mui/material/Button";
import { useStyles, useColorsContext } from "../../utils/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { TutorSignUpModal } from "../../components/TutorSignUpModal";


function ForTutors() {
    const [tutorModal, setTutorModal] = useState(false);
    const defaultStyles = useStyles();
    const { primary } = useColorsContext();
	return (
        <>
        {tutorModal && (
				<TutorSignUpModal
					loginModal={tutorModal}
					setLoginModal={setTutorModal}
				/>
			)}
		<Container fluid className="tutors-section">
			<Container>
                    <Card sx={{ position: 'relative', height: '20vw', borderRadius: 5 }}>
                        <img src={TutorBackground} alt="Card image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        <CardContent sx={{ position: 'absolute', top: 0, left: 0, textAlign: 'left', color: 'white', padding: '1.04167vw' }}>
                            <h5 className="card-title">For Tutors</h5>
                            <h1 className="Tutors-Title"> Discover new <strong className="main-name">Teaching</strong> Opportunities</h1>
                        </CardContent>
                        <CardContent sx={{ position: 'absolute', bottom: 0, left: 0, textAlign: 'left', color: 'white', padding: '1.04167vw' }}>
                            <h1 className="Tutors-body">At Tutred you have the flexibility <br/> to decide your rates and availability!</h1>
                            <Button variant="contained" 
                            sx={{borderRadius: defaultStyles.buttonRadius, 
                            fontFamily: defaultStyles.boldFont, 
                            textTransform: 'none', 
                            marginTop: '0.78125vw', 
                            bgcolor: primary }} 
                            disableElevation
                            onClick={() => {setTutorModal(true);}}>Become a Tutor</Button>
                        </CardContent>
                    </Card>
			</Container>
		</Container>
        </>
	);
}

export default ForTutors;
