import { FontAwesome } from '@expo/vector-icons';
import { Picker } from '@react-native-picker/picker';
import {
  collection,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import React, { useEffect, useState, useRef } from 'react';
import {
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';

// File imports
import Footer from '../../../components/Footer';
import { useFirebaseContext } from '../../../contexts/firebaseContext';
import { useUserContext } from '../../../contexts/userContext';
import { useColorsContext, useStyles } from '../../../utils/styles';
import { Sessions } from '../Sessions/Sessions';

// Components
import { Header as AuthHeader } from '../../../components/AuthHeader';
import { BlankPage } from '../../../components/BlankPage';
import { FiltersList } from '../../../components/FiltersList';
import { FiltersModal } from '../../../components/FiltersModal';
import { Header } from '../../../components/Header';
import { SubjectFilterIcon } from '../../../components/SubjectFilterIcon';
import { TutorListCard } from '../../../components/TutorListCard';

export const Tutors = ({ navigation, route, params }) => {
  // Appearance

  // Colors and styles
  const {
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
  } = useColorsContext();
  const defaultStyles = useStyles();

  // Media queries
  const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMd = useMediaQuery({ query: '(min-width: 768px)' });
  const isSm = useMediaQuery({ query: '(min-width: 640px)' });

  // Firebase
  const { db } = useFirebaseContext();
  const { user, isTutor, favourites } = useUserContext();

  // States and variables
  const [modalVisible, setModalVisible] = useState(false);

  const useParamSubject = useRef(true);

  const [tutors, setTutors] = useState([]);
  const [subjects] = useState([
    {
      id: 0,
      subject: 'Art',
      icon: 'paint-brush',
    },
    {
      id: 11,
      subject: 'Biology',
      icon: 'heartbeat',
    },
    {
      id: 12,
      subject: 'Chemistry',
      icon: 'flask',
    },
    {
      id: 8,
      subject: 'Computer Science',
      icon: 'desktop',
    },
    {
      id: 14,
      subject: 'Economics',
      icon: 'money',
    },
    {
      id: 1,
      subject: 'English Language Arts',
      icon: 'book',
    },
    {
      id: 17,
      subject: 'Environmental Science',
      icon: 'leaf',
    },
    {
      id: 10,
      subject: 'Geography',
      icon: 'map',
    },
    {
      id: 19,
      subject: 'Health Education',
      icon: 'heartbeat',
    },
    {
      id: 9,
      subject: 'History',
      icon: 'history',
    },
    {
      id: 3,
      subject: 'Social Studies',
      icon: 'globe',
    },
    {
      id: 15,
      subject: 'Psychology',
      icon: 'male',
    },
    {
      id: 2,
      subject: 'Science',
      icon: 'flask',
    },
    {
      id: 16,
      subject: 'Sociology',
      icon: 'users',
    },
    {
      id: 4,
      subject: 'Mathematics',
      icon: 'calculator',
    },
    {
      id: 6,
      subject: 'Music',
      icon: 'music',
    },
    {
      id: 13,
      subject: 'Physics',
      icon: 'flask',
    },
    {
      id: 7,
      subject: 'Foreign Language',
      icon: 'language',
    },
    {
      id: 18,
      subject: 'Philosophy',
      icon: 'bookmark',
    },
  ]);
  const [sortValue, setSortValue] = useState('online');
  const [filters, setFilters] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(0);

  // Max rating filter state
  const [minRating, setMinRating] = useState(0);
  const [maxRating, setMaxRating] = useState(0);

  // Stylesheet
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      backgroundColor: background,
      display: 'flex',
      //   flexDirection: isMd ? 'row' : 'column',
      flexDirection: 'column',
      width: '100%',
    },
    shadow: {
      // borderBottomColor: border,
      // borderBottomWidth: 1,
      // shadowColor: '#171717',
      // shadowOffset: {width: -2, height: 4},
      // shadowOpacity: 0.2,
      // shadowRadius: 3,
    },
    title: {
      color: text,
      fontFamily: defaultStyles.boldFont,
      fontSize: 24,
      marginBottom: 10,

    },
    searchBox: {
      width: 'fit-content',
      height: 60,
      backgroundColor: backgroundVariant,
      borderRadius: defaultStyles.radius,
      height: '100%',
      padding: 30,
      flexDirection: 'row',
      alignItems: 'center',
    },
    filtersApplied: {
      position: 'absolute',
      top: -10,
      left: -10,
      borderRadius: 100,
      backgroundColor: primaryVariant,
      width: 25,
      height: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filtersAppliedText: {
      color: '#fff',
      fontFamily: defaultStyles.boldFont,
      fontSize: 12,
    },
    search: {
      flex: 1,
      marginLeft: 5,
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },  modalTitle: {
      fontSize: 18,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    results: {
      fontFamily: defaultStyles.regularFont,
      fontSize: 14,
      color: textVariant,
      marginRight: 15,
    },
    picker: {
      fontSize: 16,
      borderRadius: defaultStyles.radius,
      fontFamily: defaultStyles.boldFont,
      // padding: 12,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      paddingLeft: 15,
      paddingRight: 15,
      color: text,
      backgroundColor: backgroundVariant,
      border: '1px solid ' + border,
    },
  });
  
  useEffect(() => {
    useParamSubject.current = true;
  }, [route]);

  // On filters change update tutors displayed
  useEffect(() => {
    fetchTutors();
  }, [filters, sortValue, route]);

  // Fetch tutors from database
  const fetchTutors = async ( paramfilter ) => {
    var tutorsRef = collection(db, 'users');
    var q = query(tutorsRef);
    q = query(q, where('isTutor', '==', true));
    q = query(q, where('isSignedUp', '==', true));
    // q = query(q, where('stripe.confirmed', '!=', false));
    // q = query(q, where("isOnboarded", "!=", false));

    // let queries = [];
    var filtersApplied = 0;
    var skills = [];
    var filteredSubjects = [];
    var minPrice = 0;
    var maxPrice = 0;
    var languages = [];
    
    // Go through filters and set queries
    if (filters.length > 0) {
      // Set filters
      filters.map((filter) => {
        // Subject filter
        if (filter.name == 'subject') {

          if(useParamSubject.current && route.params !== undefined)
          {
            var paramSubject = subjects.find(s=> s.id === route.params.subjectId);
            if(paramSubject !== undefined && !filter.value.includes(paramSubject.subject)){
              filter.value.push(paramSubject.subject);
            }
            useParamSubject.current = false;
          }
          
          if (filter.value.length > 0) {
            // q = query(q, where(`subjects.${filter.value}`, "==", true));
            filter.value.map((subject) => {
              filteredSubjects.push(subject);
            });
            filtersApplied++;
          }
        }
        // Skills filter
        else if (filter.name == 'skills') {
          if (filter.value.length > 0) {
            filtersApplied++;
            filter.value.map((skill) => {
              skills.push(skill);
            });
          }
        }

        // Instabook
        else if (filter.name == 'instabook') {
            if (filter.value == true) {
                q = query(q, where('instaBook', '==', true));
                filtersApplied++;
            }
        }

        // Super tutor
        else if (filter.name == 'superTutor') {
            if (filter.value == true) {
                q = query(q, where('superTutor', '==', true));
                filtersApplied++;
            }
        }
                
        // Language filter
        else if (filter.name == 'language') {
          if (filter.value.length > 0) {
            filtersApplied++;
            // If languages is not already an array
            if (!Array.isArray(filter.value)) {
              languages.push(filter.value);
            }
            else {
              languages = filter.value
            }
          }
        }

        // Price filter
        else if (filter.name == 'minPrice' && filter.value > 0) {
          minPrice = filter.value;
          filtersApplied++;
        } 

        // Price filter
        else if (filter.name == 'maxPrice' && filter.value > 0) {
          maxPrice = filter.value;
          filtersApplied++;
        }

        // Rating filter
        else if (filter.name == 'minRating' && filter.value > 0) {
          setMinRating(filter.value);
          filtersApplied++;
        }
        
        else if (filter.name == 'minRating' && filter.value <= 0) {
          setMinRating(0);
        }

        // Rating filter
        else if (filter.name == 'maxRating' && filter.value > 0) {
          setMaxRating(filter.value);
          filtersApplied++;
        }

        else if (filter.name == 'maxRating' && filter.value <= 0) {
          setMaxRating(0);
        }

        // Location filter
        else if (filter.name == 'location') {
          if (filter.value != 'all') {
            q = query(q, where('location.province', '==', filter.value));
            filtersApplied++;
          }
        }


        // Verified
        else if (filter.name == 'verified') {
          if (filter.value) {
            q = query(q, where('isVerified', '==', true));
            filtersApplied++;
          }
        }
      });

      // Set filters count
      setFiltersApplied(filtersApplied);
    } else {
      // Set filters count
      setFiltersApplied(0);
    }

    // Snapshot with queries
    onSnapshot(q, (snapshot) => {
      let tutors = [];
      snapshot.forEach((tutor) => {


        // Filter out skills
        let tutorData = tutor.data();

        // Filter out subjects
        if (filteredSubjects.length > 0) {
          // Get tutor subjects array by keys of value true in subjects object
          let tutorSubjects = Object.keys(tutorData.subjects).filter(
            (key) => tutorData.subjects[key] == true
          );
          let tutorSubjectsMatch = 0;
          filteredSubjects.map((subject) => {
            if (tutorSubjects.includes(subject)) {
              tutorSubjectsMatch++;
            }
          });

          if (tutorSubjectsMatch > 0) {
            tutors.push(tutorData);
          }
        }

        // tutor.skills
        if (skills.length > 0) {
          let tutorSkills = tutorData.skills;
          let tutorSkillsMatch = 0;
          skills.map((skill) => {
            if (tutorSkills.includes(skill)) {
              tutorSkillsMatch++;
            }
          });

          if (tutorSkillsMatch > 0) {
            tutors.push(tutorData);
          }
        }

        // Filter by language
        if (languages.length > 0) {
          let tutorLanguages = Object.keys(tutorData.languages);
          let tutorLanguagesMatch = 0;
          languages.map((language) => {
            if (tutorLanguages.includes(language)) {
              tutorLanguagesMatch++;
            }
          });

          if (tutorLanguagesMatch > 0) {
            tutors.push(tutorData);
          }
        }

        if (filteredSubjects.length == 0 && skills.length == 0 && languages.length == 0) {
          tutors.push(tutorData);
        }


      });

      // Filter min price
      if (minPrice > 0) {
        tutors = tutors.filter((tutor) => tutor.rate >= minPrice);
      }
      // Filter max price
      if (maxPrice > 0) {
        tutors = tutors.filter((tutor) => tutor.rate <= maxPrice);
      }

      if (sortValue == 'online') {
        tutors.sort((a, b) => {
          let aOnline = a.lastOnline;
          let bOnline = b.lastOnline;
          return bOnline - aOnline;
        });
      }

      // Sort by price
      else if (sortValue == 'low-rate') {
        tutors.sort((a, b) => {
          let aRate = a.rate;
          let bRate = b.rate;
          return aRate - bRate;
        });
      }

      // Sort by price
      else if (sortValue == 'high-rate') {
        tutors.sort((a, b) => {
          let aRate = a.rate;
          let bRate = b.rate;
          return bRate - aRate;
        });
      }


      setTutors(tutors);
    });
  };

  if (isTutor === null) {
    return <BlankPage navigation={navigation}></BlankPage>;
  }


  // If tutor return the sessions page
  if (isTutor) {
    return <Sessions navigation={navigation}></Sessions>;
  }


  return (
    <SafeAreaView style={styles.container}>
      {user ? (
        <Header navigation={navigation}></Header>
      ) : (
        <AuthHeader navigation={navigation}></AuthHeader>
      )}
      <View style={styles.maxWidth}>
        {
            !isSm && (
                <View
                    style={[
                        {
                        backgroundColor: background,
                        maxHeight: 150,
                        flex: 1,
                        padding: !isSm ? 15 : 45,
                        },
                        styles.shadow,
                    ]}
                >
                    <FiltersModal
                        setFilters={setFilters}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                    ></FiltersModal>

                    {/* List of subjects */}
                    <View
                        style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        }}
                    >
                        <ScrollView
                        className='horizontalScroll'
                        style={{ padding: 15 }}
                        horizontal
                        showsHorizontalScrollIndicator={true}
                        >
                        <FlatList
                            data={subjects}
                            keyExtractor={(item) => item.id}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                            renderItem={({ item, index }) => (
                            <TouchableOpacity
                                onPress={() => {
                                let newFilters = filters;
                                let filterIndex = newFilters.findIndex((filter) => {
                                    return filter.name == 'subject';
                                });

                                // If subject filter does not exist add
                                if (filterIndex == -1) {
                                    newFilters.push({
                                    name: 'subject',
                                    value: [item.subject],
                                    });
                                }
                                // If subject filter exists update the value array with new subject or remove it if it already exists
                                else {
                                    let subjectIndex = newFilters[
                                    filterIndex
                                    ].value.findIndex((subject) => {
                                    return subject == item.subject;
                                    });

                                    if (subjectIndex == -1) {
                                    newFilters[filterIndex].value.push(item.subject);
                                    } else {
                                    newFilters[filterIndex].value.splice(subjectIndex, 1);
                                    }
                                }

                                // Set filters
                                setFilters(newFilters);
                                fetchTutors();
                                }}
                            >
                                <SubjectFilterIcon
                                active={
                                    filters.filter((filter) => {
                                    return (
                                        filter.name == 'subject' &&
                                        filter.value.includes(item.subject)
                                    );
                                    }).length > 0
                                }
                                subject={item}
                                ></SubjectFilterIcon>
                            </TouchableOpacity>
                            )}
                        ></FlatList>
                        </ScrollView>

                        {/* Search box */}
                        <TouchableOpacity
                        style={{}}
                        onPress={() => {
                            setModalVisible(true);
                        }}
                        >
                        <View
                            style={[
                            styles.searchBox,
                            {
                                border:
                                filtersApplied > 0
                                    ? '1px solid' + primaryVariant
                                    : '1px solid' + border,
                            },
                            ]}
                        >
                            <FontAwesome name='filter' size={20} color='#999'></FontAwesome>

                            {filtersApplied > 0 && (
                            <View style={styles.filtersApplied}>
                                <Text style={styles.filtersAppliedText}>
                                {filtersApplied}
                                </Text>
                            </View>
                            )}
                        </View>
                        </TouchableOpacity>
                    </View>
                  </View>
            )
        }

        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
        >
          <View style={{ padding: !isSm ? 15 : 45, paddingTop: 25}}>
            {/* Sorting & Results */}
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                maxWidth: '100%'
                // justifyContent: 'space-between',
              }}
            >
              
              <View style={{width:300}}>
                <Text style={{
                  fontFamily: defaultStyles.boldFont,
                  fontSize: 24,
                  color: textVariant,

                }}>
                  Filters
                </Text>
              </View>

              <View style={{flex:1}}>
                {/* <Text style={{
                  fontFamily: defaultStyles.boldFont,
                  fontSize: 18,
                  color: textVariant,
                  marginLeft: 30
                }}>
                  {tutors.length} Tutors
                </Text> */}
              </View>

              <View style={{display:'flex', flexDirection:'row', alignItems:'center', marginLeft: -135}}>
                <Text style={{
                        fontFamily: defaultStyles.regularFont,
                        fontSize: 16,
                        color: textVariant,
                        marginRight:10
                    }}>
                        Sort:
                    </Text>
                <Picker
                  style={styles.picker}
                  selectedValue={sortValue}
                  onValueChange={(itemValue, itemIndex) =>
                    setSortValue(itemValue)
                  }
                >
                  <Picker.Item label='Online' value='online' />
                  <Picker.Item label='Low Price' value='low-rate' />
                  <Picker.Item label='High Price' value='high-rate' />
                </Picker>
              </View>

            </View>

            

            {/* Left side filters */}
            <View
                style={{
                    display: 'flex',
                    flexDirection: !isSm ? 'column' : 'row',
                }}
            >
                
                {isSm && (
                    <View
                        style={[
                        {
                            width: 300,
                            marginRight:30,
                            borderRightWidth: 1,
                            borderRightColor: border,
                            paddingRight: 30,
                        },
                        styles.shadow,
                        ]}
                    >
                        <FiltersList
                          setFilters={setFilters}
                          modalVisible={modalVisible}
                          setModalVisible={setModalVisible}
                        ></FiltersList>
                    </View>
                    )
                }

                
                {
                    tutors.length > 0 && (
                      <FlatList
                          style={{ display:"flex",
                      }}
                          
                          data={tutors}
                          renderItem={({ item, index }) => (
                              <TutorListCard 
                              favourite={
                                favourites.includes(item.id)
                              }
                              key={`tutor-${index}`} navigation={navigation} tutor={item} minRating={minRating} maxRating={maxRating}></TutorListCard>
                          )}
                          estimatedItemSize={100}
                      />
                    )
                }

            </View>
     
          </View>

          {/* Footer  */}
          {isSm ? <Footer></Footer> : null}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};