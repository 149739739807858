// Package imports
import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";
// import { useUserContext } from '../contexts/userContext';

// Booking card component
export const BookingCard = (props) => {
	// Conver time from 24 hours to 12
	function tConvert(time) {
		// Check correct time format and split into components
		time = time
			.toString()
			.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) {
			// If time format correct
			time = time.slice(1); // Remove full string match value
			time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(""); // return adjusted time or original string
	}

	// const {isTutor} = useUserContext();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		purple,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		card: {
			borderBottomColor: border,
			borderBottomWidth: 1,
			marginBottom: 15,
			padding: 15,
		},
		imgContainer: {
			width: 50,
			height: 50,
			borderRadius: defaultStyles.buttonRadius,
			backgroundColor: "transparent",
			marginRight: 15,
			marginVertical: "auto",
		},
		img: {
			width: "100%",
			height: "100%",
			borderRadius: 100,
		},
		subTitles: {
			color: textVariant,
			fontSize: 14,
		},
		studentName: {
			color: text,
			fontSize: 18,
			fontFamily: defaultStyles.boldFont,
		},
		info: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		icon: {
			width: 30,
			height: 30,
			borderRadius: defaultStyles.buttonRadius,
			marginRight: 15,
			justifyContent: "center",
			alignItems: "center",
			marginVertical: "auto",
			marginLeft: 5,
		},
	});

	return (
		<View style={styles.card}>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<View style={styles.imgContainer}>
					<Image
						source={{
							uri: props.studentImg
								? props.studentImg
								: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
						}}
						style={styles.img}
					/>
				</View>
				<View
					style={{
						flexDirection: "column",
						marginVertical: "auto",
					}}
				>
					<Text style={styles.subTitles}>
						<Text style={styles.studentName}>
							{props.studentName.split(" ").length > 1
								? props.studentName.split(" ")[0] +
								  " " +
								  props.studentName.split(" ")[1][0] +
								  "."
								: props.studentName}
						</Text>
						{props.status == "confirmed" && (
							<Text
								style={{
									fontFamily: defaultStyles.regularFont,
									color: primary,
									marginLeft: 5,
									fontSize: 12,
								}}
							>
								Booked
							</Text>
						)}

						{props.status == "pending" && (
							<Text
								style={{
									fontFamily: defaultStyles.regularFont,
									color: yellow,
									marginLeft: 5,
									fontSize: 12,
								}}
							>
								Pending
							</Text>
						)}

						{props.status == "in progress" ||
						props.status == "live" ? (
							<Text
								style={{
									fontFamily: defaultStyles.regularFont,
									color: green,
									marginLeft: 5,
									fontSize: 12,
								}}
							>
								In Progress
							</Text>
						) : null}

						{props.status == "cancelled" ||
							(props.status == "canceled" && (
								<Text
									style={{
										fontFamily: defaultStyles.regularFont,
										color: "#FF3D51",
										marginLeft: 5,
										fontSize: 12,
									}}
								>
									Cancelled
								</Text>
							))}

						{props.status == "completed" && (
							<Text
								style={{
									fontFamily: defaultStyles.regularFont,
									color: purple,
									marginLeft: 5,
									fontSize: 12,
								}}
							>
								Completed
							</Text>
						)}
					</Text>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							paddingTop: 10,
						}}
					>
						<Text style={[styles.info, { marginRight: 15 }]}>
							<FontAwesome
								style={{ marginRight: 5 }}
								name="pencil"
								size={14}
								color={textVariant}
							/>
							{props.subject}
						</Text>

						<Text style={[styles.info, { marginRight: 15 }]}>
							<FontAwesome
								style={{ marginRight: 5 }}
								name="clock-o"
								size={14}
								color={textVariant}
							/>
							{props.time}
						</Text>

						<Text
							style={[styles.info, { marginTop: isMd ? 0 : 5 }]}
						>
							<FontAwesome
								style={{ marginRight: 5 }}
								name="calendar"
								size={14}
								color={textVariant}
							/>
							{props.date}
						</Text>
					</View>
				</View>
			</View>
		</View>
	);
};
