import { A } from "@expo/html-elements";
import { Feather, FontAwesome5 } from "@expo/vector-icons";
import { StyleSheet, Text, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import apple from "../assets/black.svg";
import google from "../assets/google.png";
import { useColorsContext, useStyles } from "../utils/styles";

const links = [
	{
		name: "About",
		link: "",
	},
	{
		name: "Policies",
		link: "/privacy",
	},
	{
		name: "Press",
		link: "",
	},
];

const links2 = [
	{
		name: "Book a tutor",
		link: "/",
	},
	{
		name: "Student listings",
		link: "/listings",
	},
	{
		name: "FAQ",
		link: "",
	},
];

const links3 = [
	{
		name: "Become a tutor",
		link: "",
	},
	{
		name: "Super Tutor",
		link: "",
	},
	{},
];

const socials = [
	{
		name: "twitter",
		link: "https://twitter.com/TutredTeam",
		icon: "twitter",
		color: "#1da1f2",
	},
	{
		name: "instagram",
		link: "https://www.instagram.com/TutredInc",
		icon: "instagram",
		color: "#e1306c",
	},
	{
		name: "linkedin",
		link: "https://ca.linkedin.com/company/tutred",
		icon: "linkedin",
		color: "#0e76a8",
	},
	{
		name: "reddit",
		link: "https://www.reddit.com/user/TutredTeam",
		icon: "reddit",
		color: "#ff4500",
	},
];

export default function Footer() {
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Media queries
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		footer: {
			zIndex: 5,
			// backgroundColor: backgroundVariant,
			backgroundColor: "rgb(35, 120, 230)",
			display: "flex",
			marginTop: 30,
			padding: !isSm ? 15 : 45,
		},
		title: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			marginBottom: 30,
		},
		link: {
			color: "#fff",
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 10,
			cursor: "pointer",
		},
		icons: {
			display: "flex",
			flexDirection: "row",
			gap: 15,
			justifyContent: "flex-end",
			marginBottom: 10,
		},
		copyright: {
			color: "#eee",
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
		},
	});

	return (
		<View style={styles.footer}>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<View>
					<Text style={styles.title}>Tutred</Text>

					{links.map((link, index) => {
						return (
							<A href={link.link} target="_blank" key={index}>
								<Text style={styles.link}>{link.name}</Text>
							</A>
						);
					})}
				</View>

				{isMd && (
					<View style={{ marginLeft: 60 }}>
						<Text style={styles.title}>Explore</Text>

						{links2.map((link, index) => {
							return (
								<A href={link.link} target="_blank" key={index}>
									<Text style={styles.link}>{link.name}</Text>
								</A>
							);
						})}
					</View>
				)}

				{isMd && (
					<View style={{ marginLeft: 60 }}>
						<Text style={styles.title}>Tutors</Text>

						{links3.map((link, index) => {
							return (
								<A href={link.link} target="_blank" key={index}>
									<Text style={styles.link}>{link.name}</Text>
								</A>
							);
						})}
					</View>
				)}

				{isMd && (
					<View style={{ marginLeft: 30 }}>
						<A href="">
							<Text style={styles.title}>Blog</Text>
						</A>
					</View>
				)}

				{isMd && (
					<View style={{ marginLeft: 30 }}>
						<A href="">
							<Text style={styles.title}>Help Center</Text>
						</A>
					</View>
				)}

				{/* Links */}
				<View
					style={{
						marginLeft: "auto",
					}}
				>
					<View style={styles.icons}>
						{socials.map((social, index) => {
							return (
								<A
									key={index}
									href={social.link}
									target="_blank"
									style={{ cursor: "pointer" }}
								>
									<FontAwesome5
										name={social.icon}
										size={19}
										color={"#fff"}
									/>
								</A>
							);
						})}
					</View>
					<img
						src={google}
						style={{
							width: 150,
						}}
					/>

					<img
						src={apple}
						style={{
							width: 150,
						}}
					/>
				</View>
			</View>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 15,
					marginTop: 30,
				}}
			>
				<Text style={styles.copyright}>© Tutred 2023</Text>
				<A href="/tos">
					<Text style={styles.copyright}>Terms of Service</Text>
				</A>

				<A href="/privacy">
					<Text style={styles.copyright}>Privacy Policy</Text>
				</A>

				<View
					style={{
						marginLeft: "auto",
						display: "flex",
						alignItems: "center",
						flexDirection: "row",
						gap: 15,
					}}
				>
					<Text
						style={[
							styles.copyright,
							{ fontFamily: defaultStyles.boldFont },
						]}
					>
						<Feather
							style={{ marginRight: 2.5 }}
							name="globe"
							size={14}
							color="#eee"
						/>
						English
					</Text>

					<Text
						style={[
							styles.copyright,
							{ fontFamily: defaultStyles.boldFont },
						]}
					>
						<Feather
							style={{ marginRight: 2.5 }}
							name="dollar-sign"
							size={14}
							color="#eee"
						/>
						CAD
					</Text>
				</View>
			</View>
		</View>
	);
}
