import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../../components/AuthHeader";
import Footer from "../../components/Footer";
import { Header } from "../../components/Header";
import { useColorsContext, useStyles } from "../../utils/styles";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import studentStudying from "../../assets/StudentStudying.jpg";
import { SignUpModal } from "../../components/SignUpModal";
import Main2 from "./Main2";
import ForStudents from "./ForStudentsCard";
import Main3 from "./Main3";
import ForTutors from "./ForTutorsCard";

import { useUserContext } from "../../contexts/userContext";
import "./Main.css";

export const Main = ({ navigation }) => {

	const { user } = useUserContext();
	const {
		primary,
		background,
		text} = useColorsContext();
	const defaultStyles = useStyles();
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	const [signUpModal, setSignUpModal] = useState(false);

	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: Platform.OS === "web" ? "flex" : "none", // Use "flex" on web and "none" on mobile
			flexDirection: Platform.OS === "web" ? "row" : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		contentContainer: {
			flexGrow: 1,
			justifyContent: "space-between",
		},
		headingBig: {
			fontSize: 32,
			fontWeight: 900,
			color: text,
			marginBottom: 30,
		},
		heading: {
			fontSize: 24,
			fontWeight: "bold",
			color: text,
			marginBottom: 10,
		},
		paragraph: {
			fontSize: 16,
			color: text,
			marginBottom: 20,
		},
		link: {
			color: primary,
			textDecorationLine: "underline",
		},
	});

	return (
		<>
		{signUpModal && (
				<SignUpModal
					loginModal={signUpModal}
					setLoginModal={setSignUpModal}
				/>
			)}
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}
			<View style={styles.maxWidth}>
				<ScrollView
					contentContainerStyle={styles.contentContainer}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				>
					<View>
						<section>
							<Container fluid className="home-section" id="home">
								<Container className="home-content">
									<Card sx={{ display: "flex", bgcolor: "#f9f9f9", borderColor: "#f9f9f9", borderRadius: 5 }} variant="outlined">
										<Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
											<CardContent sx={{ flex: "1 0 auto" }} className="home-header">
												<h1 style={{ paddingBottom: 15, }} className="heading" mar>
													Explore a new way of
													learning!{" "}
												</h1>
												<h1 className="heading-name">Connect to the right<strong className="main-name">{" "}Tutor{" "}</strong>instantly</h1>
												<Stack direction="row" spacing={2} className="heading-button">
													<Button variant="contained" sx={{
														borderRadius: defaultStyles.buttonRadius,
														fontFamily: defaultStyles.boldFont,
														textTransform: "none",
														bgcolor: primary,
													}} disableElevation
														onClick={() => {setSignUpModal(true);}}>Get Started</Button>
													<Button variant="contained" sx={{
														borderRadius: defaultStyles.buttonRadius,
														fontFamily: defaultStyles.boldFont,
														textTransform: "none",
														bgcolor: primary,
													}} 	disableElevation
														onClick={() => navigation.navigate("Tutors", {screen:"Tutors"})}>Browse Tutors</Button>
												</Stack>
											</CardContent>
										</Box>
										<CardMedia component="img" image={studentStudying} alt="student studying" style={{ maxHeight: "23.44vw" }} sx={{ width: '26.04vw' }} />
									</Card>
								</Container>
							</Container>
							<ForStudents/>
							<Main2 />
							<Main3 />
							<ForTutors />
						</section>
					</View>
					{isSm ? <Footer /> : null}
				</ScrollView>
			</View>
		</SafeAreaView>
		</>
	);
};
