import { Picker } from "@react-native-picker/picker";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FlatList, ScrollView, StyleSheet, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";

// File imports
import Footer from "../../../components/Footer";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";
import { Sessions } from "../Sessions/Sessions";

// Components
import { Header as AuthHeader } from "../../../components/AuthHeader";
import { BlankPage } from "../../../components/BlankPage";
import { Header } from "../../../components/Header";
import { TutorListCard } from "../../../components/TutorListCard";

export const Favourites = ({ navigation, route, params }) => {
	// Appearance

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Media queries
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Firebase
	const { db } = useFirebaseContext();
	const { user, isTutor, favourites } = useUserContext();
	const [sortValue, setSortValue] = useState("online");
	const [tutors, setTutors] = useState([]);

	// Stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			//   flexDirection: isMd ? 'row' : 'column',
			flexDirection: "column",
			width: "100%",
		},
		shadow: {
			// borderBottomColor: border,
			// borderBottomWidth: 1,
			// shadowColor: '#171717',
			// shadowOffset: {width: -2, height: 4},
			// shadowOpacity: 0.2,
			// shadowRadius: 3,
		},
		title: {
			color: text,
			fontFamily: defaultStyles.boldFont,
			fontSize: 24,
			marginBottom: 10,
		},
		searchBox: {
			width: "fit-content",
			height: 60,
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			height: "100%",
			padding: 30,
			flexDirection: "row",
			alignItems: "center",
		},
		filtersApplied: {
			position: "absolute",
			top: -10,
			left: -10,
			borderRadius: 100,
			backgroundColor: primaryVariant,
			width: 25,
			height: 25,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		filtersAppliedText: {
			color: "#fff",
			fontFamily: defaultStyles.boldFont,
			fontSize: 12,
		},
		search: {
			flex: 1,
			marginLeft: 5,
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		results: {
			fontFamily: defaultStyles.regularFont,
			fontSize: 14,
			color: textVariant,
			marginRight: 15,
		},
		picker: {
			fontSize: 16,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			// padding: 12,
			paddingTop: 2.5,
			paddingBottom: 2.5,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	// On filters change update tutors displayed
	useEffect(() => {
		fetchTutors();
	}, [sortValue, favourites]);

	// Fetch tutors from database
	const fetchTutors = async () => {
		var tutorsRef = collection(db, "users");
		var q = query(tutorsRef);
		q = query(q, where("isTutor", "==", true));
		q = query(q, where("isSignedUp", "==", true));

		// Snapshot with queries
		onSnapshot(q, (snapshot) => {
			let tutors = [];
			snapshot.forEach((tutor) => {
				// Filter out skills
				let tutorData = tutor.data();

				if (favourites.includes(tutorData.id)) {
					tutors.push(tutorData);
				}
			});

			if (sortValue == "online") {
				tutors.sort((a, b) => {
					let aOnline = a.lastOnline;
					let bOnline = b.lastOnline;
					return bOnline - aOnline;
				});
			}

			// Sort by price
			else if (sortValue == "low-rate") {
				tutors.sort((a, b) => {
					let aRate = a.rate;
					let bRate = b.rate;
					return aRate - bRate;
				});
			}

			// Sort by price
			else if (sortValue == "high-rate") {
				tutors.sort((a, b) => {
					let aRate = a.rate;
					let bRate = b.rate;
					return bRate - aRate;
				});
			}

			setTutors(tutors);
		});
	};

	if (isTutor === null) {
		return <BlankPage navigation={navigation}></BlankPage>;
	}

	// If tutor return the sessions page
	if (isTutor) {
		return <Sessions navigation={navigation}></Sessions>;
	}

	return (
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}
			<View style={styles.maxWidth}>
				<ScrollView
					contentContainerStyle={{
						flexGrow: 1,
						justifyContent: "space-between",
					}}
					showsVerticalScrollIndicator={false}
					style={{ flex: 1 }}
				>
					<View style={{ padding: !isSm ? 15 : 45, paddingTop: 25 }}>
						{/* Sorting & Results */}
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<View style={{ flex: 1 }}>
								<Text style={styles.title}>My Favourites</Text>
							</View>

							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Text
									style={{
										fontFamily: defaultStyles.regularFont,
										fontSize: 16,
										color: textVariant,
										marginRight: 10,
									}}
								>
									Sort:
								</Text>
								<Picker
									style={styles.picker}
									selectedValue={sortValue}
									onValueChange={(itemValue, itemIndex) =>
										setSortValue(itemValue)
									}
								>
									<Picker.Item
										label="Online"
										value="online"
									/>
									<Picker.Item
										label="Low Price"
										value="low-rate"
									/>
									<Picker.Item
										label="High Price"
										value="high-rate"
									/>
								</Picker>
							</View>
						</View>

						{/* Left side filters */}
						<View
							style={{
								display: "flex",
								flexDirection: !isSm ? "column" : "row",
							}}
						>
							{tutors.length > 0 && (
								<FlatList
									style={{ display: "flex" }}
									data={tutors}
									renderItem={({ item, index }) => (
										<TutorListCard
											favourite={favourites.includes(
												item.id
											)}
											key={`tutor-${index}`}
											navigation={navigation}
											tutor={item}
											minRating={0}
											maxRating={0}
										></TutorListCard>
									)}
									estimatedItemSize={100}
								/>
							)}

							{tutors.length == 0 && (
								<View
									style={{
										flex: 1,
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Text
										style={{
											fontFamily:
												defaultStyles.regularFont,
											fontSize: 16,
											color: textVariant,
											marginRight: 10,
										}}
									>
										Favourite a tutor to see them here!
									</Text>
								</View>
							)}
						</View>
					</View>

					{isSm ? <Footer></Footer> : null}
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};
