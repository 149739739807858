import { useActionSheet } from "@expo/react-native-action-sheet";
import { AntDesign } from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadBytesResumable,
} from "firebase/storage";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { TouchableOpacity } from "react-native-web";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../../utils/styles";

// Document picker
import * as DocumentPicker from "expo-document-picker";

// Make ID string for image name
const makeid = (length) => {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(
			Math.floor(Math.random() * charactersLength)
		);
	}
	return result;
};

export const Input = ({ onSend, onSendImage }) => {
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Media queries
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Alert
	const customAlert = useAlert();

	const styles = StyleSheet.create({
		footer: {
			backgroundColor: backgroundVariant,
			padding: 12,
			paddingHorizontal: 20,
			border: "1px solid " + border,
			display: "flex",
			flexDirection: "row",
			borderRadius: 100,
			alignItems: "center",
		},
		media: {
			marginRight: 10,
		},
		input: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: text,
			flex: 1,
			marginRight: 15,
			outlineStyle: "none",
		},
		send: {
			color: primaryVariant,
			fontWeight: "bold",
			fontSize: 16,
			textAlign: "right",
			fontFamily: defaultStyles.boldFont,
		},
	});

	const { showActionSheetWithOptions } = useActionSheet();

	const [message, setMessage] = useState("");

	const pickImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});

		if (!result.cancelled) {
			// Send message of image
			// onSendImage(result.uri);
			// return;

			// Upload image to firebase storage
			const storage = getStorage();
			let imageFile = await fetch(result.uri);
			let name =
				imageFile._bodyBlob && imageFile._bodyBlob._data
					? imageFile._bodyBlob._data.name + "-" + makeid(8)
					: makeid(64);
			let imageBlob = await imageFile.blob();
			const storageRef = ref(storage, `chats/${name}`);

			let task = uploadBytesResumable(storageRef, imageBlob);
			task.on(
				"state_changed",
				(snapshot) => {},
				(error) => {
					Platform.OS === "web"
						? customAlert.error(error)
						: Alert.alert("Error uploading image!", error, [
								{
									text: "OK",
									onPress: () => console.log("OK Pressed"),
								},
						  ]);
				},
				() => {
					getDownloadURL(task.snapshot.ref).then((downloadURL) => {
						onSendImage(downloadURL);
					});
				}
			);
		}
	};

	const pickFile = async () => {
		let result = await DocumentPicker.getDocumentAsync({
			type: "*/*",
			copyToCacheDirectory: true,
		});

		if (!result.cancelled) {
			// Upload file to firebase storage
			const storage = getStorage();
			let file = await fetch(result.uri);
			let name =
				file._bodyBlob && file._bodyBlob._data
					? file._bodyBlob._data.name + "-" + makeid(8)
					: makeid(64);
			let blob = await file.blob();
			const storageRef = ref(storage, `chats/${name}`);

			let task = uploadBytesResumable(storageRef, blob);
			task.on(
				"state_changed",
				(snapshot) => {},
				(error) => {
					Platform.OS === "web"
						? customAlert.error(error)
						: Alert.alert("Error uploading file!", error, [
								{
									text: "OK",
									onPress: () => console.log("OK Pressed"),
								},
						  ]);
				},
				() => {
					getDownloadURL(task.snapshot.ref).then((downloadURL) => {
						onSend(downloadURL);
					});
				}
			);
		}
	};

	return (
		<View style={styles.footer}>
			{/* Left gallery icon */}
			<TouchableOpacity
				style={styles.media}
				onPress={() => {
					showActionSheetWithOptions(
						{
							options: ["Image", "Files", "Cancel"],
							destructiveButtonIndex: 2,
							tintColor: text,
							containerStyle: {
								backgroundColor: backgroundVariant,
								borderRadius: defaultStyles.radius,
								padding: 10,
								margin: 5,
								width: isMd ? "70%" : "100%",
								marginLeft: "auto",
								marginRight: "auto",
								marginBottom: isMd ? 30 : 0,
							},
							useModal: true,
							title: "Options",
							titleTextStyle: {
								fontFamily: defaultStyles.titleFont,
								color: textVariant,
								fontSize: 16,
							},
							textStyle: {
								fontFamily: defaultStyles.boldFont,
								color: text,
								fontSize: 16,
							},
							destructiveColor: redVariant,
						},
						(index) => {
							switch (index) {
								case 0:
									pickImage();
									break;
								case 1:
									pickFile();
								case 2:
									break;
							}
						}
					);
				}}
			>
				{/* <FontAwesome5 name="image" size={19} color={textVariant} /> */}
				<AntDesign name="pluscircleo" size={19} color={textVariant} />
			</TouchableOpacity>

			{/* Message text input */}
			<TextInput
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						onSend(message);
						setMessage("");
					}
				}}
				value={message}
				onChange={(e) => {
					setMessage(e.target.value);
				}}
				placeholderTextColor={textVariant}
				style={styles.input}
				textContentType="text"
				placeholder="Message..."
			></TextInput>

			{/* Display send if typed */}
			{message.length > 0 && (
				<TouchableOpacity
					onPress={() => {
						onSend(message);
						setMessage("");
					}}
				>
					<Text style={styles.send}>Send</Text>
				</TouchableOpacity>
			)}
		</View>
	);
};
