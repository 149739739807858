import { useState, useEffect,  createContext, useContext } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useColorScheme } from 'react-native';

export const useStyles = () => {
    return {
        radius: 12,
        titleFont: "Satoshi_900",
        boldFont: "Satoshi_700",
        regularFont: "Satoshi_500",
        lightFont: "Satoshi_400",
        buttonRadius: 100
    };
}

const colorsContext = createContext();

// Export colors as a module
export const ColorsContextProvider = ({ children }) => {

    // Load dark mode from settings
    const [darkMode, setDarkMode] = useState(useColorScheme() === "dark");
    const [primary, setPrimary] = useState("");
    const [primaryVariant, setPrimaryVariant] = useState("");
    const [red, setRed] = useState("");
    const [redVariant, setRedVariant] = useState("");
    const [background, setBackground] = useState("");
    const [backgroundVariant, setBackgroundVariant] = useState("");
    const [text, setText] = useState("");
    const [textVariant, setTextVariant] = useState("");
    const [border, setBorder] = useState("");
    const [yellow, setYellow] = useState("");
    const [green, setGreen] = useState("");
    const [purple, setPurple] = useState("");

    useEffect(() => {
        // Load settings from local storage,  TODO:// update this to save and load the setting for individual users in firestore.
        AsyncStorage.getItem("darkMode")
        .then((localDarkMode) => {
            if (localDarkMode) {
                setDarkMode(localDarkMode === "true");
            }
        });
    });

    useEffect(() => {
        updateColors();
    }, [darkMode]);

    const updateColors = () => { 
        // Update colors based on dark mode
        setPrimary(darkMode ? "#234EDB" : "#234EDB");
        setPrimaryVariant(darkMode ? "#0087ED" : "#0087ED");
        setRed(darkMode ? "#090909" : "#191919");
        setRedVariant(darkMode ? "#090909" : "#191919");
        setBackground(darkMode ? "#000000" : "#FFFFFF");
        setBackgroundVariant(darkMode ? "#090909" : "#FFFFFF");
        setText(darkMode ? "#FFFFFF" : "#000000");
        setTextVariant(darkMode ? "#d6d6d6" : "#191919");
        setBorder(darkMode ? "#121212" : "#EEEEEE");
        setYellow(darkMode ? "#FFB347" : "#FFB347");
        setGreen(darkMode ? "#234EDB" : "#234EDB");
        setPurple(darkMode ? "#866aeb" : "#866aeb");
    } 


    const toggleDarkMode = () => {
        // Save dark mode to local storage
        AsyncStorage.setItem("darkMode", !darkMode ? "true" : "false");
        setDarkMode(!darkMode);
    }

    return (
        <colorsContext.Provider
          value={{ primary, primaryVariant, red, redVariant, background, backgroundVariant, text, textVariant, border, yellow, green, purple, darkMode, toggleDarkMode, setDarkMode }}
        >
          {children}
        </colorsContext.Provider>
    );

};

export function useColorsContext() {
    return useContext(colorsContext);
}