// // Package imports
import { A } from "@expo/html-elements";
import { FontAwesome } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import { Picker } from "@react-native-picker/picker";
import NumericTextInput from "@wwdrew/react-native-numeric-textinput";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
	CheckBox,
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";

import { doc, updateDoc } from "firebase/firestore";
import SelectSearch from "react-select-search";

// File imports
import { useFirebaseContext } from "../contexts/firebaseContext";
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";

// Import data from configs
import { countries, provinces, statesUSA } from "../config/locations";

import { subjects } from "../config/subjects";

import { schools } from "../config/schools";

import { languages } from "../config/languages";

const times = [
	"12:00 AM",
	"12:30 AM",
	"1:00 AM",
	"1:30 AM",
	"2:00 AM",
	"2:30 AM",
	"3:00 AM",
	"3:30 AM",
	"4:00 AM",
	"4:30 AM",
	"5:00 AM",
	"5:30 AM",
	"6:00 AM",
	"6:30 AM",
	"7:00 AM",
	"7:30 AM",
	"8:00 AM",
	"8:30 AM",
	"9:00 AM",
	"9:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
	"11:30 AM",
	"12:00 PM",
	"12:30 PM",
	"1:00 PM",
	"1:30 PM",
	"2:00 PM",
	"2:30 PM",
	"3:00 PM",
	"3:30 PM",
	"4:00 PM",
	"4:30 PM",
	"5:00 PM",
	"5:30 PM",
	"6:00 PM",
	"6:30 PM",
	"7:00 PM",
	"7:30 PM",
	"8:00 PM",
	"8:30 PM",
	"9:00 PM",
	"9:30 PM",
	"10:00 PM",
	"10:30 PM",
	"11:00 PM",
	"11:30 PM",
];

const timeToMinutes = (timeString) => {
	const [time, period] = timeString.split(" ");
	const [hours, minutes] = time.split(":");
	let convertedHours = parseInt(hours);
	const convertedMinutes = parseInt(minutes);

	if (period === "PM" && convertedHours !== 12) {
		convertedHours += 12;
	}

	return convertedHours * 60 + convertedMinutes;
};

const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";
// const hostname = 'http://localhost:8000';

export const Onboarding = ({ name, user, visible, setVisible }) => {
	// CONTEXT
	const { db } = useFirebaseContext();

	const { birthday } = useUserContext();
	// import user context

	// DATA
	const [provinceValues, setProvinceValues] = useState([]);

	const [countryValues, setCountryValues] = useState([{}]);

	const [subjectValues, setSubjectValues] = useState([]);

	const [skillValues, setSkillValues] = useState([]);

	const [universityValues, setUniversityValues] = useState([]);
	const [degreeValues, setDegreeValues] = useState([]);

	const [languageValues, setLanguageValues] = useState([]);

	const [showMonday, setShowMonday] = useState(false);
	const [showTuesday, setShowTuesday] = useState(false);
	const [showWednesday, setShowWednesday] = useState(false);
	const [showThursday, setShowThursday] = useState(false);
	const [showFriday, setShowFriday] = useState(false);
	const [showSaturday, setShowSaturday] = useState(false);
	const [showSunday, setShowSunday] = useState(false);

	const [address, setAddress] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [phone, setPhone] = useState("");
	const [ssn, setSSN] = useState("");

	// TODO: Edit availability values
	const [alwaysAvailable, setAlwaysAvailable] = useState(true);
	const [mondayStart, setMondayStart] = useState(null);
	const [mondayEnd, setMondayEnd] = useState(null);
	const [tuesdayStart, setTuesdayStart] = useState(null);
	const [tuesdayEnd, setTuesdayEnd] = useState(null);
	const [wednesdayStart, setWednesdayStart] = useState(null);
	const [wednesdayEnd, setWednesdayEnd] = useState(null);
	const [thursdayStart, setThursdayStart] = useState(null);
	const [thursdayEnd, setThursdayEnd] = useState(null);
	const [fridayStart, setFridayStart] = useState(null);
	const [fridayEnd, setFridayEnd] = useState(null);
	const [saturdayStart, setSaturdayStart] = useState(null);
	const [saturdayEnd, setSaturdayEnd] = useState(null);
	const [sundayStart, setSundayStart] = useState(null);
	const [sundayEnd, setSundayEnd] = useState(null);

	const years = [
		{ year: "In progress", enabled: true },
		{ year: 2023, enabled: true },
		{ year: 2022, enabled: true },
		{ year: 2021, enabled: true },
		{ year: 2020, enabled: true },
		{ year: 2019, enabled: true },
		{ year: 2018, enabled: true },
		{ year: 2017, enabled: true },
		{ year: 2016, enabled: true },
		{ year: 2015, enabled: true },
		{ year: 2014, enabled: true },
		{ year: 2013, enabled: true },
		{ year: 2012, enabled: true },
		{ year: 2011, enabled: true },
		{ year: 2010, enabled: true },
		{ year: 2009, enabled: true },
		{ year: 2008, enabled: true },
		{ year: 2007, enabled: true },
		{ year: 2006, enabled: true },
		{ year: 2005, enabled: true },
		{ year: 2004, enabled: true },
		{ year: 2003, enabled: true },
		{ year: 2002, enabled: true },
		{ year: 2001, enabled: true },
		{ year: 2000, enabled: true },
		{ year: 1999, enabled: true },
		{ year: 1998, enabled: true },
		{ year: 1997, enabled: true },
		{ year: 1996, enabled: true },
		{ year: 1995, enabled: true },
		{ year: 1994, enabled: true },
		{ year: 1993, enabled: true },
		{ year: 1992, enabled: true },
		{ year: 1991, enabled: true },
		{ year: 1990, enabled: true },
		{ year: "<1989", enabled: true },
	];

	const options = [
		{ name: "Swedish", value: "sv" },
		{ name: "English", value: "en" },
		{ name: "French", value: "fr" },
		{ name: "German", value: "de" },
		{ name: "Spanish", value: "es" },
		{ name: "Italian", value: "it" },
		{ name: "Portuguese", value: "pt" },
	];

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// State
	const [view, setView] = useState(1); // Change ui content & progress bar
	const [city, setCity] = useState("");
	const [selectedProvince, setSelectedProvince] = useState("");
	const [selectedCountry, setSelectedCountry] = useState("CA");
	const [selectedUniversity, setSelectedUniversity] = useState("");
	const [selectedDegree, setSelectedDegree] = useState("");
	const [selectedCompletionYear, setSelectedCompletionYear] = useState("");
	const [selectedDegreeType, setSelectedDegreeType] = useState("");
	const [selectedSubject, setSelectedSubject] = useState([]);
	const [selectedSkill, setSelectedSkill] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState([]);
	const [selectedRate, setSelectedRate] = useState(0);
	const [description, setDescription] = useState("");
	const [degreeArray, setDegreeArray] = useState([]);
	const [isSelected, setSelection] = useState(false);

	// Submit form to db
	const completeProfile = () => {
		let userId = user.uid;
		let email = user.email;

		var languagesValue = {};
		var subjectsValue = {};
		// Map through languages array and create object
		selectedLanguage.map((lang) => {
			languagesValue[lang] = true;
		});
		// Map through subjects array and create object
		selectedSubject.map((subject) => {
			subjectsValue[subject] = true;
		});

		// Validate availability
		var availability = {
			monday: showMonday
				? {}
				: {
						start: null,
						end: null,
				  },
			tuesday: showTuesday
				? {}
				: {
						start: null,
						end: null,
				  },
			wednesday: showWednesday
				? {}
				: {
						start: null,
						end: null,
				  },
			thursday: showThursday
				? {}
				: {
						start: null,
						end: null,
				  },
			friday: showFriday
				? {}
				: {
						start: null,
						end: null,
				  },
			saturday: showSaturday
				? {}
				: {
						start: null,
						end: null,
				  },
			sunday: showSunday
				? {}
				: {
						start: null,
						end: null,
				  },
		};

		if (!alwaysAvailable) {
			// Add availability to object
			if (showMonday) {
				if (mondayStart && mondayEnd) {
					// Validate times
					if (timeToMinutes(mondayStart) < timeToMinutes(mondayEnd)) {
						availability.monday.start = mondayStart;
						availability.monday.end = mondayEnd;
					} else {
						return customAlert.error(
							"[Onboarding Error] Invalid availability times."
						);
					}
				}
			}

			if (showTuesday) {
				if (tuesdayStart && tuesdayEnd) {
					// Validate times
					if (
						timeToMinutes(tuesdayStart) < timeToMinutes(tuesdayEnd)
					) {
						availability.tuesday.start = tuesdayStart;
						availability.tuesday.end = tuesdayEnd;
					} else {
						return customAlert.error(
							"[Onboarding Error] Invalid availability times."
						);
					}
				}
			}

			if (showWednesday) {
				if (wednesdayStart && wednesdayEnd) {
					// Validate times
					if (
						timeToMinutes(wednesdayStart) <
						timeToMinutes(wednesdayEnd)
					) {
						availability.wednesday.start = wednesdayStart;
						availability.wednesday.end = wednesdayEnd;
					} else {
						return customAlert.error(
							"[Onboarding Error] Invalid availability times."
						);
					}
				}
			}

			if (showThursday) {
				if (thursdayStart && thursdayEnd) {
					// Validate times
					if (
						timeToMinutes(thursdayStart) <
						timeToMinutes(thursdayEnd)
					) {
						availability.thursday.start = thursdayStart;
						availability.thursday.end = thursdayEnd;
					} else {
						return customAlert.error(
							"[Onboarding Error] Invalid availability times."
						);
					}
				}
			}

			if (showFriday) {
				if (thursdayStart && thursdayEnd) {
					// Validate times
					if (timeToMinutes(fridayStart) < timeToMinutes(fridayEnd)) {
						availability.friday.start = fridayStart;
						availability.friday.end = fridayEnd;
					} else {
						return customAlert.error(
							"[Onboarding Error] Invalid availability times."
						);
					}
				}
			}

			if (showSaturday) {
				if (saturdayStart && saturdayEnd) {
					// Validate times
					if (
						timeToMinutes(saturdayStart) <
						timeToMinutes(saturdayEnd)
					) {
						availability.saturday.start = saturdayStart;
						availability.saturday.end = saturdayEnd;
					} else {
						return customAlert.error(
							"[Onboarding Error] Invalid availability times."
						);
					}
				}
			}

			if (showSunday) {
				if (sundayStart && sundayEnd) {
					// Validate times
					if (timeToMinutes(sundayStart) < timeToMinutes(sundayEnd)) {
						availability.sunday.start = sundayStart;
						availability.sunday.end = sundayEnd;
					} else {
						return customAlert.error(
							"[Onboarding Error] Invalid availability times."
						);
					}
				}
			}
		}

		let urlId = Math.floor(100000 + Math.random() * 900000);
		// Hit api to create stripe account
		fetch(`${hostname}/api/create-account`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
				name: name,
				dob: birthday,
				address: address,
				postalCode: postalCode,
				city: city,
				province: selectedProvince,
				country: selectedCountry,
				phone: phone,
				idNumber: ssn,
				tutorUrl: urlId,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				if (!data.success) {
					console.log("err msg", data.message);
					return customAlert.error(
						`[Onboarding Error] ${data.message}}`
					);
				}
				let accountId = data.account.id;

				let profileObject = {
					URL: urlId,
					isOnboarded: true,
					isVerified: false,

					isSignedUp: true,
					stripe: {
						accountId: accountId,
						confirmed: false,
					},
					location: {
						city: city,
						province: selectedProvince,
						country: selectedCountry,
					},
					rate: selectedRate,
					languages: languagesValue,
					subjects: subjectsValue,
					skills: selectedSkill,
					degrees: degreeArray,
					description: description,
					availability: availability,
					alwaysAvailable: alwaysAvailable,
				};

				setVisible(!visible);
				updateDoc(doc(db, "users", userId), profileObject)
					.then(() => {
						customAlert.success(
							"[Onboarding Complete] Thank you for completing your profile!"
						);
					})
					.catch((error) => {
						customAlert.error(
							"[Onboarding Error] Error completing profile. Please try again later."
						);
					});
			})
			.catch((err) => {});
	};

	const degreeInfo = {
		school: selectedUniversity,
		name: selectedDegree,
		type: selectedDegreeType,
		year: selectedCompletionYear,
	};

	// Alert
	const customAlert = useAlert();

	// Reset degree values
	const resetDegreeValues = () => {
		setSelectedDegree("");
		setSelectedUniversity("");
		setSelectedCompletionYear("");
		setSelectedDegreeType("");
	};

	useEffect(() => {
		// Add cities, provinces, subjects, and skills to objects

		if (countries) {
			var countryValues = [];
			countries.map((country) => {
				countryValues.push({
					country: country,
				});
			});
			setCountryValues(countryValues);
		}

		if (selectedCountry === "US") {
			if (statesUSA) {
				var provinceValues = [];
				statesUSA.map((state) => {
					provinceValues.push({
						province: state,
					});
				});
				setProvinceValues(provinceValues);
			}
		} else {
			if (provinces) {
				var provinceValues = [];
				provinces.map((province) => {
					provinceValues.push({
						province: province,
					});
				});
				setProvinceValues(provinceValues);
			}
		}

		// Subjects
		if (subjects) {
			var subjectValues = [];
			let subjectKeys = Object.keys(subjects);
			subjectKeys.map((subject) => {
				subjectValues.push({
					subject: subject,
					enabled: true,
				});
			});

			// Sort alphabetically
			subjectValues.sort((a, b) => {
				if (a.subject < b.subject) {
					return -1;
				}
				if (a.subject > b.subject) {
					return 1;
				}
				return 0;
			});

			// Set to top of array
			subjectValues.unshift();
			setSubjectValues(subjectValues);
		}

		// Languages
		if (languages) {
			var languageValues = [];
			languages.map((language) => {
				languageValues.push({
					lang: language,
					enabled: true,
				});
			});
			setLanguageValues(languageValues);
		}

		if (schools) {
			var schoolValues = [];
			schools.map((school) => {
				schoolValues.push({
					school: school,
				});
			});
			setUniversityValues(schoolValues);
		}

		let degreesLocal = [
			"Bachelor of Arts",
			"Bachelor of Business Administration",
			"Bachelor of Commerce",
			"Bachelor of Computer Science",
			"Bachelor of Education",
			"Bachelor of Engineering",
			"Bachelor of Fine Arts",
			"Bachelor of Health Sciences",
			"Bachelor of Journalism",
			"Bachelor of Laws (LLB)",
			"Bachelor of Music",
			"Bachelor of Nursing",
			"Bachelor of Science",
			"Bachelor of Social Work",
			"Bachelor of Technology",
			"Bachelor of Tourism and Hospitality Management",
		];
		let degrees = [];
		degreesLocal.map((degree) => {
			degrees.push({
				name: degree,
			});
		});
		setDegreeValues(degrees);
	}, [selectedCountry]);

	useEffect(() => {
		// Get skills for selected subjects
		if (subjects) {
			var skillValues = [];
			let subjectKeys = Object.keys(subjects);
			subjectKeys.map((subject) => {
				if (selectedSubject.includes(subject)) {
					let skills = subjects[subject];
					skills.map((skill) => {
						skillValues.push({
							skill: skill,
							enabled: true,
						});
					});
				}
			});
			skillValues.sort((a, b) => {
				if (a.skill < b.skill) {
					return -1;
				}
				if (a.skill > b.skill) {
					return 1;
				}
				return 0;
			});
			setSkillValues(skillValues);
		}
	}, [selectedSubject]);

	// Appearance
	const {
		darkMode,
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	if (darkMode) {
		// Import darkmode CSS
		require("../DarkSearchInput.css");
	} else {
		// Import lightmode CSS
		require("../LightSearchInput.css");
	}

	// Styles
	const styles = StyleSheet.create({
		container: {
			position: "absolute",
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: "transparent",
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			flexDirection: "column",
			display: "flex",
		},

		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},

		icon: {
			width: 50,
			height: 50,
			borderRadius: defaultStyles.radius,
			backgroundColor: text,
			marginRight: 15,
			justifyContent: "center",
			alignItems: "center",
			marginVertical: "auto",
			marginLeft: 5,
			color: text,
		},

		// Modal
		centeredView: {
			flex: 1,
			width: "100vw",
			height: "100vh",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "transparent",
			backdropFilter: "blur(5px)", // background blur on web
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: "75%",
			// height: isMd ? '80%' : '90%',
			height: isXl || isLg || isMd || isSm ? "85%" : "85%",

			padding: 15,
			shadowColor: background,
			shadowOffset: {
				width: 0,
				height: 2,
			},
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			marginBottom: 5,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
			marginTop: 10,
		},
		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		marginTop: {
			marginTop: 30,
		},
		img: {
			width: 75,
			height: 75,
			borderRadius: defaultStyles.buttonRadius,
			border: "1px solid " + border,
		},

		modalBody: {
			padding: 30,
			paddingBottom: 0,
			flex: 1,
		},
		modalContent: {
			flex: 1,
		},
		onboardingTitle: {
			color: text,
			fontSize: 20,
			fontFamily: defaultStyles.titleFont,
			marginBottom: 5,
		},
		welcomeTitle: {
			color: text,
			fontSize: 22,
			fontFamily: defaultStyles.titleFont,
			marginBottom: 15,
		},
		welcomeSubtitle: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 15,
		},
		progressContainer: {
			backgroundColor: backgroundVariant,
			width: "100%",
			height: 15,
			display: "flex",
			flexDirection: "row",
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		continueButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 35,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
			marginHorizontal: 5,
			marginTop: 20,
			marginBottom: 10,
		},
		cancelButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 35,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: backgroundVariant,
			marginHorizontal: 5,
			border: `1px solid ${border}`,
		},
		buttonGroup: {
			width: "100%",
			paddingHorizontal: 30,
			paddingVertical: 10,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.regularFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			fontSize: 16,
		},
		fieldTitle: {
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		marginTop: {
			marginTop: 10,
		},
		backButtonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		iconContainer: {
			height: 50,
			width: 50,
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.buttonRadius,
			border: `1px solid ${border}`,
			justifyContent: "center",
			alignItems: "center",
			marginVertical: "auto",
			marginBottom: 10,
		},
		inputContainer: {
			flex: 1,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			gap: 15,
		},
		input: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			// marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 200,
		},
		addDegreeButton: {
			color: primary,
			fontSize: 14,
			fontFamily: defaultStyles.boldFont,
			marginTop: 10,
		},
		container: {
			flex: 1,
			alignItems: "center",
			justifyContent: "center",
		},
		checkboxContainer: {
			flexDirection: "row",
		},
		checkbox: {
			alignSelf: "center",
			border: `1px solid ${border}`,
			backgroundColor: background,
		},
		label: {
			margin: 8,
			color: text,
			fontFamily: defaultStyles.boldFont,
			fontSize: 14,
		},
	});

	// Degree component
	const DegreeCard = ({ year, degree, school, type }) => {
		return (
			<View
				style={{
					//   height: 75,
					padding: 10,
					minWidth: 150,
					borderRadius: defaultStyles.radius,
					backgroundColor: backgroundVariant,
					border: `.5px solid ${border}`,
					marginTop: 10,
					marginRight: 10,
				}}
			>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<View>
						<Text
							style={{
								color: primary,
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
								marginBottom: 2.5,
							}}
						>
							{degree}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginBottom: 5,
							}}
						>
							{type}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
							}}
						>
							{school}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{year}
						</Text>
					</View>
					<View>
						<TouchableOpacity
							onPress={() => {
								let newArray = [...degreeArray];
								newArray = newArray.filter(function (item) {
									return item.year !== year;
								});
								// Set state to mutated array
								setDegreeArray(newArray);
							}}
						>
							<CloseOutlined
								style={{
									fontSize: 14,
									color: text,
									padding: 10,
								}}
							></CloseOutlined>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};

	// Skills / subject / language component
	const WordCard = ({ word, array }) => {
		return (
			<View
				style={{
					marginRight: 5,
					marginTop: 10,
					backgroundColor: backgroundVariant,
					borderRadius: defaultStyles.buttonRadius,
					padding: 5,
					border: `.5px solid ${border}`,
					display: "flex",
					flexDirection: "row",
					alignContent: "center",
					alignItems: "center",
					justifyContent: "space-evenly",
					// maxWidth: 150,
					marginBottom: 10,
					height: 50,
				}}
			>
				<Text
					style={{
						fontSize: 14,
						color: textVariant,
						fontFamily: defaultStyles.regularFont,
						padding: 5,
					}}
				>
					{word}
				</Text>
				<TouchableOpacity
					style={{ paddingHorizontal: 5 }}
					onPress={() => {
						// Remove from subjects array
						if (array == "subject") {
							let newArray = [...selectedSubject];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedSubject(newArray);
						}
						// Remove from skills array
						else if (array == "skill") {
							let newArray = [...selectedSkill];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedSkill(newArray);
						}
						// Remove from languages array
						else if (array == "languages") {
							let newArray = [...selectedLanguage];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedLanguage(newArray);
						}
					}}
				>
					<CloseOutlined
						style={{ fontSize: 14, color: text }}
					></CloseOutlined>
				</TouchableOpacity>
			</View>
		);
	};

	if (!visible) {
		return null;
	} else {
		return (
			<View style={styles.container}>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
								paddingRight: 10, // Add some right padding to the View
							}}
						>
							<Text
								style={{
									marginTop: 15,
									marginBottom: 15,
									textAlign: "center",
									fontSize: 16,
									fontFamily: defaultStyles.boldFont,
									color: primaryVariant,
									flex: 1, // Use flex to center the Text component
								}}
							>
								{view === 1 && <>20%</>}
								{view === 2 && <>40%</>}
								{view === 3 && <>60%</>}
								{view === 4 && <>80%</>}
								{view === 5 && <>90%</>}
								{view === 6 && <>100%</>}
							</Text>

							{/* Dissmiss button */}
							<TouchableOpacity
								onPress={() => {
									setVisible(!visible);
									// setModalVisible(!modalVisible);
								}}
							>
								<CloseOutlined
									style={{ fontSize: 16, color: text }}
								></CloseOutlined>
							</TouchableOpacity>
						</View>
						{/* Progress bar */}
						<View style={styles.progressContainer}>
							{/* stepperOne  */}
							<View
								style={{
									width: "17%",
									height: "100%",
									backgroundColor:
										view >= 1
											? "#234edc"
											: backgroundVariant,
								}}
							></View>
							{/* stepperTwo  */}
							<View
								style={{
									width: "17%",
									height: "100%",
									backgroundColor:
										view >= 2 ? "#1f46c6" : background,
								}}
							></View>
							{/* stepperThree  */}
							<View
								style={{
									width: "17%",
									height: "100%",
									backgroundColor:
										view >= 3 ? "#1c3eb0" : background,
								}}
							></View>
							{/* stepperFour  */}
							<View
								style={{
									width: "17%",
									height: "100%",
									backgroundColor:
										view >= 4 ? "#18369a" : background,
								}}
							></View>
							{/* stepperFive  */}
							<View
								style={{
									width: "17%",
									height: "100%",
									backgroundColor:
										view >= 5 ? "#152e84" : background,
								}}
							></View>
							{/* stepperSix  */}
							<View
								style={{
									width: "15%",
									height: "100%",
									backgroundColor:
										view >= 6 ? "#11276e" : background,
								}}
							></View>
						</View>

						{/* View one  */}
						{view == 1 ? (
							<>
								<View style={styles.modalBody}>
									<ScrollView
										style={{ width: "100%" }}
										showsVerticalScrollIndicator={false}
									>
										<Text style={styles.welcomeTitle}>
											Welcome to
											<Text style={{ color: primary }}>
												{" "}
												Tutred &#127881;
											</Text>
										</Text>

										<Text style={styles.welcomeSubtitle}>
											Let's get started by creating your
											profile.
										</Text>

										<Text style={styles.welcomeSubtitle}>
											Students have unlimited learning
											opportunities through instant,
											on-demand, drop-in access to the
											right tutors - anytime and anywhere,
											all from a safe and secure online
											learning environment
										</Text>
									</ScrollView>
								</View>
								{/* <center>
                    <iframe
                      width='90%'
                      height='600px'
                      src={introVid}
                      title='YouTube video player'
                      frameborder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowfullscreen
                    ></iframe>
                  </center> */}
								{/* Button group  */}
								<View style={styles.buttonGroup}>
									<TouchableOpacity
										style={styles.continueButton}
										onPress={() => setView(2)}
									>
										<Text style={styles.buttonText}>
											{" "}
											Get Started{" "}
										</Text>
									</TouchableOpacity>
								</View>
							</>
						) : null}

						{/* View two  */}
						{view == 2 ? (
							<>
								<View style={styles.modalBody}>
									<ScrollView
										style={{ width: "100%" }}
										showsVerticalScrollIndicator={false}
									>
										<View style={styles.iconContainer}>
											<FontAwesome
												name="map-pin"
												size={14}
												color={textVariant}
												style={{
													fontSize: 18,
													color: textVariant,
												}}
											/>
										</View>
										<Text style={styles.onboardingTitle}>
											Personal Information
										</Text>
										<Text style={styles.details}>
											Fill in your your personal
											information. Only the proximity of
											your city will be shown, the rest of
											the information will be kept private
											and is used for verification
											purposes. Learn more about our{" "}
											<A
												href={"/"}
												target="_blank"
												style={{
													textDecorationStyle:
														"solid",
													textDecorationColor:
														primary,
													textDecorationLine:
														"underline",
												}}
											>
												privacy policy.
											</A>
										</Text>

										<View
											style={{
												width: "100%",
												paddingVertical: 10,
											}}
										>
											<Picker
												selectedValue={selectedCountry}
												style={styles.picker}
												onValueChange={(
													itemValue,
													itemIndex
												) =>
													setSelectedCountry(
														itemValue
													)
												}
											>
												<Picker.Item
													label={"Canada"}
													value={"CA"}
												/>
												<Picker.Item
													label={"United States"}
													value={"US"}
												/>
											</Picker>

											<SelectSearch
												// autoComplete={false}
												multiple={false}
												search={true}
												options={provinceValues.map(
													(item, i) => ({
														name: provinceValues[i]
															.province,
														value: provinceValues[i]
															.province,
													})
												)}
												value={selectedProvince}
												onChange={(value) =>
													setSelectedProvince(value)
												}
												name="province"
												placeholder={
													selectedCountry == "US"
														? "Choose your state"
														: "Choose your province"
												}
											/>

											<View
												style={[
													styles.inputContainer,
													{
														marginTop: 15,
													},
												]}
											>
												{/* CVV */}
												<TextInput
													style={[
														styles.input,
														{ flex: 1 },
													]}
													onChangeText={setCity}
													value={city}
													placeholder="City"
													placeholderTextColor={
														textVariant
													}
												/>
											</View>

											<View
												style={[
													styles.inputContainer,
													{
														marginTop: 15,
													},
												]}
											>
												{/* CVV */}
												<TextInput
													style={[
														styles.input,
														{ flex: 1 },
													]}
													onChangeText={setAddress}
													value={address}
													placeholder="Address"
													placeholderTextColor={
														textVariant
													}
												/>
												{/* Postal Code */}

												<TextInput
													style={[
														styles.input,
														{ flex: 1 },
													]}
													placeholder={
														selectedCountry == "US"
															? "Zip Code"
															: "Postal Code"
													}
													placeholderTextColor={
														textVariant
													}
													onChangeText={(text) => {
														setPostalCode(text);
													}}
													value={postalCode}
												></TextInput>
											</View>

											{selectedCountry === "US" && (
												<>
													<View
														style={[
															styles.inputContainer,
															{
																marginTop: 15,
															},
														]}
													>
														{/* Phone */}
														<TextInput
															style={[
																styles.input,
																{ flex: 1 },
															]}
															onChangeText={
																setPhone
															}
															value={phone}
															placeholder="Phone Number"
															placeholderTextColor={
																textVariant
															}
															type="number"
														/>
													</View>

													<View
														style={[
															{
																marginTop: 15,
															},
														]}
													>
														{/* LAST 4 SIN */}
														<TextInput
															style={[
																styles.input,
																{ flex: 1 },
															]}
															placeholder={
																"ID Number"
															}
															placeholderTextColor={
																textVariant
															}
															type="number"
															onChangeText={(
																text
															) => {
																setSSN(text);
															}}
															value={ssn}
															maxLength={9}
														></TextInput>
														<Text
															style={{
																color: textVariant,
																fontSize: 12,
																marginTop: 5,
															}}
														>
															Must be a government
															issued ID. This is
															used for KYC
															verification.
														</Text>
													</View>
												</>
											)}
										</View>
									</ScrollView>
								</View>
								<View style={styles.buttonGroup}>
									<TouchableOpacity
										style={styles.continueButton}
										onPress={() => {
											if (
												city == "" ||
												selectedProvince == "" ||
												address == "" ||
												postalCode == "" ||
												selectedCountry == ""
											) {
												customAlert.error(
													"[Onboarding Error] Fill in all fields!"
												);
											} else if (
												selectedCountry === "US" &&
												(phone == "" || ssn == "")
											) {
												customAlert.error(
													"[Onboarding Error] As a US citizen, you must fill in your phone number and SSN!"
												);
											} else {
												setView(3);
											}
										}}
									>
										<Text style={styles.buttonText}>
											{" "}
											Continue{" "}
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										style={styles.cancelButton}
										onPress={() => setView(1)}
									>
										<Text style={styles.backButtonText}>
											{" "}
											Back{" "}
										</Text>
									</TouchableOpacity>
								</View>
							</>
						) : null}

						{/* View 3  */}
						{view == 3 ? (
							<>
								<View style={styles.modalBody}>
									<ScrollView
										style={{ width: "100%" }}
										showsVerticalScrollIndicator={false}
									>
										{/* Icon  */}
										<View style={styles.iconContainer}>
											<FontAwesome
												name="book"
												size={14}
												color={textVariant}
												style={{
													fontSize: 18,
													color: textVariant,
												}}
											/>
										</View>
										<Text style={styles.onboardingTitle}>
											Skills & languages
										</Text>
										<Text style={styles.details}>
											Fill in your educational
											qualifications.
										</Text>
										<View
											style={{
												width: "100%",
												paddingVertical: 10,
											}}
										>
											{/* Subject  */}
											<Text
												style={[
													styles.fieldTitle,
													styles.marginTop,
												]}
											>
												Subjects
											</Text>
											<Text style={styles.details}>
												Choose what you will tutor
											</Text>
											{/* Show selected subjects   */}
											<View
												style={{
													display: "flex",
													flexDirection: "row",
													flexWrap: "wrap",
												}}
											>
												{selectedSubject.map(
													(doc, index) => {
														return (
															<WordCard
																key={`subject-word-card-${index}`}
																word={
																	selectedSubject[
																		index
																	]
																}
																array={
																	"subject"
																}
															></WordCard>
														);
													}
												)}
											</View>
											{/* <Picker
                      style={styles.picker}
                      selectedValue={
                        selectedSubject.length == 0
                          ? subjectValues[0].subject
                          : selectedSubject[selectedSubject.length - 1]
                      }
                      onValueChange={(itemValue, itemIndex) => {
                        if (selectedSubject.includes(itemValue)) {
                          return null;
                        } else {
                          setSelectedSubject([...selectedSubject, itemValue]);
                        }
                      }}
                    >
                      {subjectValues.map((item, i) => (
                        <Picker.Item
                          label={subjectValues[i].subject}
                          value={subjectValues[i].subject}
                          enabled={subjectValues[i].enabled}
                        />
                      ))}
                    </Picker> */}
											<SelectSearch
												autoComplete={false}
												closeOnSelect={false}
												closeOnBlur={true}
												multiple={true}
												search={true}
												options={subjectValues.map(
													(item, i) => ({
														name: subjectValues[i]
															.subject,
														value: subjectValues[i]
															.subject,
														key: i,
													})
												)}
												value={selectedSubject}
												onChange={(value) =>
													setSelectedSubject(value)
												}
												name="subject"
												placeholder="Choose your subjects"
												// Hide select-search-select box if not focused
											/>
											{/* Skills  */}
											<View style={styles.marginTop}>
												<Text
													style={[
														styles.fieldTitle,
														styles.marginTop,
													]}
												>
													Skills
												</Text>
												<Text style={styles.details}>
													Choose your skills for your
													subjects
												</Text>
												{/* Show selected skills   */}
												<View
													style={{
														display: "flex",
														flexDirection: "row",
														flexWrap: "wrap",
													}}
												>
													{selectedSkill.map(
														(doc, index) => {
															return (
																<WordCard
																	key={`skill-word-card-${index}`}
																	word={
																		selectedSkill[
																			index
																		]
																	}
																	array="skill"
																></WordCard>
															);
														}
													)}
												</View>
												{/* <Picker
                        style={styles.picker}
                        selectedValue={
                          selectedSkill.length == 0
                            ? skillValues[0].skill
                            : selectedSkill[selectedSkill.length - 1]
                        }
                        onValueChange={(itemValue, itemIndex) => {
                          if (selectedSkill.includes(itemValue)) {
                            return null;
                          } else {
                            setSelectedSkill([...selectedSkill, itemValue]);
                          }
                        }}
                      >
                        {skillValues.map((item, i) => (
                          <Picker.Item
                            key={`skills-picker-${i}`}
                            label={skillValues[i].skill}
                            value={skillValues[i].skill}
                            enabled={skillValues[i].enabled}
                          />
                        ))}
                      </Picker> */}
												<SelectSearch
													autoComplete={false}
													closeOnSelect={true}
													multiple={true}
													search={true}
													options={skillValues.map(
														(item, i) => ({
															name: skillValues[i]
																.skill,
															value: skillValues[
																i
															].skill,
															key: i,
														})
													)}
													value={selectedSkill}
													onChange={(value) =>
														setSelectedSkill(value)
													}
													name="skill"
													placeholder="Choose your skills"
												/>
											</View>

											{/* Languages  */}
											<View style={styles.marginTop}>
												<Text
													style={[
														styles.fieldTitle,
														styles.marginTop,
													]}
												>
													Languages
												</Text>
												<Text style={styles.details}>
													Choose languages you are
													fluent in
												</Text>
												{/* Show selected languages   */}
												<View
													style={{
														display: "flex",
														flexDirection: "row",
														flexWrap: "wrap",
													}}
												>
													{selectedLanguage.map(
														(doc, index) => {
															return (
																<WordCard
																	key={`lang-word-card-${index}`}
																	word={
																		selectedLanguage[
																			index
																		]
																	}
																	array="languages"
																></WordCard>
															);
														}
													)}
												</View>
												{/* <Picker
                          style={styles.picker}
                          selectedValue={
                            selectedLanguage.length == 0
                              ? languageValues[0].lang
                              : selectedLanguage[selectedLanguage.length - 1]
                          }
                          onValueChange={(itemValue, itemIndex) => {
                            if (selectedLanguage.includes(itemValue)) {
                              return null;
                            } else {
                              setSelectedLanguage([...selectedLanguage, itemValue]);
                            }
                          }}
                        >
                          {languageValues.map((item, i) => (
                            <Picker.Item
                              key={`lang-picker-${i}`}
                              label={languageValues[i].lang}
                              value={languageValues[i].lang}
                              enabled={languageValues[i].enabled}
                            />
                          ))}
                        </Picker> */}
												<SelectSearch
													// autoComplete={false}
													closeOnSelect={true}
													multiple={true}
													search={true}
													options={languageValues.map(
														(item, i) => ({
															name: languageValues[
																i
															].lang,
															value: languageValues[
																i
															].lang,
															key: i,
														})
													)}
													value={selectedLanguage}
													onChange={(value) =>
														setSelectedLanguage(
															value
														)
													}
													name="languages"
													placeholder="Choose your languages"
												/>
											</View>
										</View>
									</ScrollView>
								</View>
								{/*  Button group  */}
								<View style={styles.buttonGroup}>
									<TouchableOpacity
										onPress={() => {
											// Validate that one subject is selected and at least one language is selected
											if (selectedSubject.length == 0) {
												customAlert.error(
													"[Onboarding Error] Please select at least one subject"
												);
												return;
											}
											if (selectedLanguage.length == 0) {
												customAlert.error(
													"[Onboarding Error] Please select at least one language"
												);
												return;
											}
											setView(4);
										}}
										style={styles.continueButton}
									>
										<Text style={styles.buttonText}>
											{" "}
											Continue{" "}
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => setView(2)}
										style={styles.cancelButton}
									>
										<Text style={styles.backButtonText}>
											{" "}
											Back{" "}
										</Text>
									</TouchableOpacity>
								</View>
							</>
						) : null}

						{/* View 4  */}
						{view == 4 ? (
							<>
								<View style={styles.modalBody}>
									<ScrollView
										style={{ width: "100%" }}
										showsVerticalScrollIndicator={false}
									>
										{/* Icon  */}
										<View style={styles.iconContainer}>
											<FontAwesome
												name="graduation-cap"
												size={14}
												color={textVariant}
												style={{
													fontSize: 18,
													color: textVariant,
												}}
											/>
										</View>
										<Text style={styles.onboardingTitle}>
											Your qualifications
										</Text>
										<Text style={styles.details}>
											Fill in your academic
											qualifications, add up to two
											degrees.
										</Text>

										{/* Show added degrees  */}
										{
											<View
												style={{
													display: "flex",
													flexDirection: "row",
													flexWrap: "wrap",
												}}
											>
												{degreeArray.map(
													(doc, index) => {
														return (
															<DegreeCard
																key={`degree-card-${index}`}
																school={
																	degreeArray[
																		index
																	].school
																}
																year={
																	degreeArray[
																		index
																	].year
																}
																degree={
																	degreeArray[
																		index
																	].name
																}
																type={
																	degreeArray[
																		index
																	].type
																}
															></DegreeCard>
														);
													}
												)}
											</View>
										}

										<View
											style={{
												width: "100%",
												paddingVertical: 10,
											}}
										>
											{/* University  */}
											<Text
												style={[
													styles.fieldTitle,
													styles.marginTop,
												]}
											>
												University
											</Text>
											{/* <Picker
                        style={styles.picker}
                        selectedValue={
                          selectedUniversity == ''
                            ? universityValues[0].school
                            : selectedUniversity
                        }
                        onValueChange={(itemValue, itemIndex) =>
                          setSelectedUniversity(itemValue)
                        }
                      >
                        {universityValues.map((item, i) => (
                          <Picker.Item
                            key={`uni-picker-${i}`}
                            label={universityValues[i].school}
                            value={universityValues[i].school}
                            enabled={
                              isSelected ? false : universityValues[i].enabled
                            }
                          />
                        ))}
                      </Picker> */}
											<SelectSearch
												// autoComplete={false}
												search={true}
												options={universityValues.map(
													(item, i) => ({
														name: universityValues[
															i
														].school,
														value: universityValues[
															i
														].school,
														key: i,
													})
												)}
												value={selectedUniversity}
												onChange={(value) =>
													setSelectedUniversity(value)
												}
												name="university"
												placeholder="Choose your university"
											/>

											{/* Field of study  */}
											<Text
												style={[
													styles.fieldTitle,
													styles.marginTop,
												]}
											>
												Degree
											</Text>
											{/* <Picker
                        style={styles.picker}
                        selectedValue={
                          selectedDegree == ''
                            ? degreeValues[0].name
                            : selectedDegree
                        }
                        onValueChange={(itemValue, itemIndex) =>
                          setSelectedDegree(itemValue)
                        }
                      >
                        {degreeValues.map((item, i) => (
                          <Picker.Item
                            key={`degree-picker-${i}`}
                            label={degreeValues[i].name}
                            value={degreeValues[i].name}
                            enabled={isSelected ? false : degreeValues[i].enabled}
                          />
                        ))}
                      </Picker> */}
											<SelectSearch
												// autoComplete={false}
												search={true}
												options={degreeValues.map(
													(item, i) => ({
														name: degreeValues[i]
															.name,
														value: degreeValues[i]
															.name,
														key: i,
													})
												)}
												value={selectedDegree}
												onChange={(value) =>
													setSelectedDegree(value)
												}
												name="degree"
												placeholder="Choose your degree"
											/>

											{/* Degree Type */}
											<Text
												style={[
													styles.fieldTitle,
													styles.marginTop,
												]}
											>
												Degree Type
											</Text>

											<SelectSearch
												// autoComplete={false}
												search={false}
												options={[
													{
														name: "Associates Degree",
														value: "Associates Degree",
														key: 1,
													},
													{
														name: "Bachelors Degree",
														value: "Bachelors Degree",
														key: 2,
													},
													{
														name: "Undergraduate Degree",
														value: "Undergraduate Degree",
														key: 3,
													},
													{
														name: "Masters Degree",
														value: "Masters Degree",
														key: 4,
													},
													{
														name: "Doctorate Degree",
														value: "Doctorate Degree",
														key: 5,
													},
													{
														name: "Professional Degree",
														value: "Professional Degree",
														key: 6,
													},
												]}
												value={selectedDegreeType}
												onChange={(value) =>
													setSelectedDegreeType(value)
												}
												name="degree type"
												placeholder="Choose your degree type"
											/>

											{/* Year */}
											<Text
												style={[
													styles.fieldTitle,
													styles.marginTop,
												]}
											>
												Completion Year
											</Text>
											{/* <Picker
                        style={styles.picker}
                        selectedValue={
                          selectedCompletionYear == ''
                            ? years[0].year
                            : selectedCompletionYear
                        }
                        onValueChange={(itemValue, itemIndex) =>
                          setSelectedCompletionYear(itemValue)
                        }
                      >
                        {years.map((item, i) => (
                          <Picker.Item
                            key={`year-picker-${i}`}
                            label={years[i].year}
                            value={years[i].year}
                            enabled={isSelected ? false : years[i].enabled}
                          />
                        ))}
                      </Picker> */}
											<SelectSearch
												// autoComplete={false}
												search={true}
												options={years.map(
													(item, i) => ({
														name: years[i].year,
														value: years[i].year,
														key: i,
													})
												)}
												value={selectedCompletionYear}
												onChange={(value) =>
													setSelectedCompletionYear(
														value
													)
												}
												name="year"
												placeholder="Choose your year"
											/>

											{/* Add degree  */}
											<TouchableOpacity
												onPress={() => {
													if (
														degreeInfo.name == "" ||
														degreeInfo.year == "" ||
														degreeInfo.school ==
															"" ||
														degreeInfo.degreeType ==
															""
													) {
														customAlert.error(
															"[Onboarding Error] You must fill in all fields!"
														);
														return;
													}

													setDegreeArray([
														...degreeArray,
														degreeInfo,
													]);
													resetDegreeValues();
												}}
											>
												<Text
													style={
														styles.addDegreeButton
													}
												>
													{" "}
													Add Degree{" "}
												</Text>
											</TouchableOpacity>

											<View
												style={styles.checkboxContainer}
											>
												<CheckBox
													value={isSelected}
													onValueChange={() => {
														setDegreeArray([]);
														setSelection(
															!isSelected
														);
													}}
													style={styles.checkbox}
												/>
												<Text style={styles.label}>
													I have no post secondary
													experience
												</Text>
											</View>
										</View>
									</ScrollView>
								</View>
								{/*  Button group  */}
								<View style={styles.buttonGroup}>
									<TouchableOpacity
										onPress={() => {
											if (isSelected) {
												resetDegreeValues();
												setDegreeArray([]);
												setView(5);
												return;
											} else if (
												degreeArray.length === 0
											) {
												customAlert.error(
													"[Onboarding Error] Please enter a degree or select no experience!"
												);
											} else {
												setView(5);
											}
										}}
										style={styles.continueButton}
									>
										<Text style={styles.buttonText}>
											{" "}
											Continue{" "}
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => setView(3)}
										style={styles.cancelButton}
									>
										<Text style={styles.backButtonText}>
											{" "}
											Back{" "}
										</Text>
									</TouchableOpacity>
								</View>
							</>
						) : null}

						{view == 5 ? (
							<>
								<View style={styles.modalBody}>
									<ScrollView
										style={{ width: "100%" }}
										showsVerticalScrollIndicator={false}
									>
										<View style={styles.iconContainer}>
											<FontAwesome
												name="calendar-o"
												size={14}
												color={textVariant}
												style={{
													fontSize: 18,
													color: textVariant,
												}}
											/>
										</View>
										<Text style={styles.onboardingTitle}>
											Availability
										</Text>
										<Text style={styles.details}>
											Fill in your tutoring availability.
										</Text>

										<View
											style={{
												width: "100%",
												paddingVertical: 10,
											}}
										>
											{/* Always available toggle */}
											<View
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													marginBottom: 15,
												}}
											>
												<Text
													style={{
														color: text,
														fontFamily:
															defaultStyles.boldFont,
														fontSize: 16,
														marginRight: 10,
													}}
												>
													Always available
												</Text>
												<TouchableOpacity
													style={{
														width: 20,
														height: 20,
														backgroundColor:
															alwaysAvailable
																? primary
																: backgroundVariant,
														border:
															"1px solid" +
															border,
														borderRadius:
															defaultStyles.radius,
														marginRight: 10,
													}}
													onPress={() => {
														if (alwaysAvailable) {
															setAlwaysAvailable(
																false
															);
														} else {
															setAlwaysAvailable(
																true
															);
														}
													}}
												></TouchableOpacity>
											</View>

											{!alwaysAvailable && (
												<>
													{/* MONDAY */}
													<View
														style={{
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showMonday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() =>
																setShowMonday(
																	!showMonday
																)
															}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Monday
														</Text>
													</View>

													{showMonday && (
														<View
															style={{
																marginTop: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																gap: 2,
																flexWrap:
																	"wrap",
															}}
														>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setMondayStart(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		mondayStart
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>

															<Text
																style={{
																	color: text,
																	fontFamily:
																		defaultStyles.boldFont,
																	fontSize: 19,
																}}
															>
																{" "}
																to -{" "}
															</Text>

															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setMondayEnd(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		mondayEnd
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>
														</View>
													)}

													{/* TUESDAY */}
													<View
														style={{
															marginTop: 15,
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showTuesday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() =>
																setShowTuesday(
																	!showThursday
																)
															}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Tuesday
														</Text>
													</View>

													{showTuesday && (
														<View
															style={{
																marginTop: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																gap: 2,
																flexWrap:
																	"wrap",
															}}
														>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setTuesdayStart(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		tuesdayStart
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>

															<Text
																style={{
																	color: text,
																	fontFamily:
																		defaultStyles.boldFont,
																	fontSize: 19,
																}}
															>
																{" "}
																to -{" "}
															</Text>

															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setTuesdayEnd(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		tuesdayEnd
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>
														</View>
													)}

													{/* WEDNESDAY */}
													<View
														style={{
															marginTop: 15,
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showWednesday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() =>
																setShowWednesday(
																	!showWednesday
																)
															}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Wednesday
														</Text>
													</View>

													{showWednesday && (
														<View
															style={{
																marginTop: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																gap: 2,
																flexWrap:
																	"wrap",
															}}
														>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setWednesdayStart(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		wednesdayStart
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>

															<Text
																style={{
																	color: text,
																	fontFamily:
																		defaultStyles.boldFont,
																	fontSize: 19,
																}}
															>
																{" "}
																to -{" "}
															</Text>

															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setWednesdayEnd(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		wednesdayEnd
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>
														</View>
													)}

													{/* THURSDAY */}
													<View
														style={{
															marginTop: 15,
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showThursday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() =>
																setShowThursday(
																	!showThursday
																)
															}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Thursday
														</Text>
													</View>

													{showThursday && (
														<View
															style={{
																marginTop: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																gap: 2,
																flexWrap:
																	"wrap",
															}}
														>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setThursdayStart(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		thursdayStart
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>

															<Text
																style={{
																	color: text,
																	fontFamily:
																		defaultStyles.boldFont,
																	fontSize: 19,
																}}
															>
																{" "}
																to -{" "}
															</Text>

															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setThursdayEnd(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		thursdayEnd
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>
														</View>
													)}

													{/* FRIDAY */}
													<View
														style={{
															marginTop: 15,
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showFriday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() =>
																setShowFriday(
																	!showFriday
																)
															}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Friday
														</Text>
													</View>

													{showFriday && (
														<View
															style={{
																marginTop: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																gap: 2,
																flexWrap:
																	"wrap",
															}}
														>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setFridayStart(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		fridayStart
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>

															<Text
																style={{
																	color: text,
																	fontFamily:
																		defaultStyles.boldFont,
																	fontSize: 19,
																}}
															>
																{" "}
																to -{" "}
															</Text>

															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setFridayEnd(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		fridayEnd
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>
														</View>
													)}

													{/* SATURDAY */}
													<View
														style={{
															marginTop: 15,
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showSaturday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() =>
																setShowSaturday(
																	!showSaturday
																)
															}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Saturday
														</Text>
													</View>

													{showSaturday && (
														<View
															style={{
																marginTop: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																gap: 2,
																flexWrap:
																	"wrap",
															}}
														>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setSaturdayStart(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		saturdayStart
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>

															<Text
																style={{
																	color: text,
																	fontFamily:
																		defaultStyles.boldFont,
																	fontSize: 19,
																}}
															>
																{" "}
																to -{" "}
															</Text>

															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setSaturdayEnd(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		saturdayEnd
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>
														</View>
													)}

													{/* SUNDAY */}
													<View
														style={{
															marginTop: 15,
															display: "flex",
															flexDirection:
																"row",
														}}
													>
														{/* checkbox  */}
														<TouchableOpacity
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	showSunday
																		? primary
																		: backgroundVariant,
																border:
																	"1px solid" +
																	border,
																borderRadius:
																	defaultStyles.radius,
																marginRight: 10,
															}}
															onPress={() =>
																setShowSunday(
																	!showSunday
																)
															}
														></TouchableOpacity>

														<Text
															style={{
																color: text,
																fontFamily:
																	defaultStyles.boldFont,
																fontSize: 16,
															}}
														>
															Sunday
														</Text>
													</View>

													{showSunday && (
														<View
															style={{
																marginTop: 15,
																flexDirection:
																	"row",
																alignItems:
																	"center",
																gap: 2,
																flexWrap:
																	"wrap",
															}}
														>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setSundayStart(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		sundayStart
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>

															<Text
																style={{
																	color: text,
																	fontFamily:
																		defaultStyles.boldFont,
																	fontSize: 19,
																}}
															>
																{" "}
																to -{" "}
															</Text>

															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}
															>
																<select
																	style={{
																		flex: 1,
																		colorScheme:
																			darkMode
																				? "dark"
																				: "light",
																		borderRadius:
																			defaultStyles.radius,
																		fontFamily:
																			defaultStyles.boldFont,
																		paddingTop: 10,
																		paddingBottom: 10,
																		paddingLeft: 15,
																		paddingRight: 15,
																		color: text,
																		backgroundColor:
																			backgroundVariant,
																		border:
																			"1px solid " +
																			border,
																		fontSize: 16,
																	}}
																	onChange={(
																		e
																	) => {
																		setSundayEnd(
																			e
																				.target
																				.value
																		);
																	}}
																	value={
																		sundayEnd
																	}
																>
																	<option
																		value=""
																		selected
																		disabled
																	>
																		Select a
																		time
																	</option>
																	{times.map(
																		(
																			timeO,
																			index
																		) => {
																			return (
																				<option
																					value={
																						timeO
																					}
																				>
																					{
																						timeO
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</View>
														</View>
													)}
												</>
											)}
										</View>
									</ScrollView>
								</View>
								<View style={styles.buttonGroup}>
									<TouchableOpacity
										style={styles.continueButton}
										onPress={() => {
											if (!alwaysAvailable) {
												if (
													!showMonday &&
													!showTuesday &&
													!showWednesday &&
													!showThursday &&
													!showFriday &&
													!showSaturday &&
													!showSunday
												) {
													return customAlert.error(
														"[Onboarding Error] You must select at least one day of the week!"
													);
												}

												// Add availability to object
												if (showMonday) {
													console.log(
														"checking monday"
													);
													if (
														mondayStart &&
														mondayEnd
													) {
														console.log(
															"both exists"
														);
														// Validate times
														if (
															timeToMinutes(
																mondayStart
															) >
															timeToMinutes(
																mondayEnd
															)
														) {
															return customAlert.error(
																"[Onboarding Error] Invalid availability times."
															);
														}
													} else {
														return customAlert.error(
															"[Onboarding Error] Invalid availability times."
														);
													}
												}

												if (showTuesday) {
													if (
														tuesdayStart &&
														tuesdayEnd
													) {
														// Validate times
														if (
															timeToMinutes(
																tuesdayStart
															) >
															timeToMinutes(
																tuesdayEnd
															)
														) {
															return customAlert.error(
																"[Onboarding Error] Invalid availability times."
															);
														}
													} else {
														setVisible(!visible);
														return customAlert.error(
															"[Onboarding Error] Invalid availability times."
														);
													}
												}

												if (showWednesday) {
													if (
														wednesdayStart &&
														wednesdayEnd
													) {
														// Validate times
														if (
															timeToMinutes(
																wednesdayStart
															) >
															timeToMinutes(
																wednesdayEnd
															)
														) {
															return customAlert.error(
																"[Onboarding Error] Invalid availability times."
															);
														}
													} else {
														return customAlert.error(
															"[Onboarding Error] Invalid availability times."
														);
													}
												}

												if (showThursday) {
													if (
														thursdayStart &&
														thursdayEnd
													) {
														// Validate times
														if (
															timeToMinutes(
																thursdayStart
															) >
															timeToMinutes(
																thursdayEnd
															)
														) {
															return customAlert.error(
																"[Onboarding Error] Invalid availability times."
															);
														}
													} else {
														setVisible(!visible);
														return customAlert.error(
															"[Onboarding Error] Invalid availability times."
														);
													}
												}

												if (showFriday) {
													if (
														fridayStart &&
														fridayEnd
													) {
														// Validate times
														if (
															timeToMinutes(
																fridayStart
															) >
															timeToMinutes(
																fridayEnd
															)
														) {
															return customAlert.error(
																"[Onboarding Error] Invalid availability times."
															);
														}
													} else {
														return customAlert.error(
															"[Onboarding Error] Invalid availability times."
														);
													}
												}

												if (showSaturday) {
													if (
														saturdayStart &&
														saturdayEnd
													) {
														// Validate times
														if (
															timeToMinutes(
																saturdayStart
															) >
															timeToMinutes(
																saturdayEnd
															)
														) {
															return customAlert.error(
																"[Onboarding Error] Invalid availability times."
															);
														}
													} else {
														return customAlert.error(
															"[Onboarding Error] Invalid availability times."
														);
													}
												}

												if (showSunday) {
													if (
														sundayStart &&
														sundayEnd
													) {
														// Validate times
														if (
															timeToMinutes(
																sundayStart
															) >
															timeToMinutes(
																sundayEnd
															)
														) {
															return customAlert.error(
																"[Onboarding Error] Invalid availability times."
															);
														}
													} else {
														return customAlert.error(
															"[Onboarding Error] Invalid availability times."
														);
													}
												}
											}
											setView(6);
										}}
									>
										<Text style={styles.buttonText}>
											{" "}
											Continue{" "}
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										style={styles.cancelButton}
										onPress={() => setView(4)}
									>
										<Text style={styles.backButtonText}>
											{" "}
											Back{" "}
										</Text>
									</TouchableOpacity>
								</View>
							</>
						) : null}

						{view == 6 ? (
							<>
								<View style={styles.modalBody}>
									<ScrollView
										style={{ width: "100%" }}
										showsVerticalScrollIndicator={false}
									>
										{/* Icon  */}
										<View style={styles.iconContainer}>
											<FontAwesome
												name="user"
												size={14}
												color={textVariant}
												style={{
													fontSize: 18,
													color: textVariant,
												}}
											/>
										</View>
										<Text style={styles.onboardingTitle}>
											Tutor profile
										</Text>
										<Text style={styles.details}>
											Information visible to students.
										</Text>
										<View
											style={{
												width: "100%",
												paddingVertical: 10,
											}}
										>
											{/* Price  */}
											<Text
												style={[
													styles.fieldTitle,
													styles.marginTop,
													{ marginBottom: 10 },
												]}
											>
												Rate per hour
											</Text>

											<NumericTextInput
												style={[styles.input, styles]}
												type="currency"
												locale="en-Us"
												currency="CAD"
												decimalPlaces={2}
												value={selectedRate}
												onUpdate={(value) =>
													setSelectedRate(value)
												}
												placeholder="$0.00"
											/>

											{/* Description  */}
											<Text
												style={[
													styles.fieldTitle,
													styles.marginTop,
												]}
											>
												Bio
											</Text>
											<TextInput
												placeholderTextColor="#999999"
												style={styles.descriptionInput}
												placeholder="I am experienced and able to help with...."
												multiline={true}
												value={description}
												onChange={(e) => {
													setDescription(
														e.target.value
													);
												}}
												maxLength={1000}
											></TextInput>
											<Text
												style={[
													styles.details,
													{ padding: 5 },
												]}
											>
												{description.length} / 1000
											</Text>
										</View>
									</ScrollView>
								</View>
								{/*  Button group  */}
								<View style={styles.buttonGroup}>
									<Text
										style={{
											color: textVariant,
											fontSize: 12,
											textAlign: "center",
											fontFamily:
												defaultStyles.regularFont,
										}}
									>
										This is information is all configurable
										in your profile after.
									</Text>
									<TouchableOpacity
										onPress={() => {
											if (
												selectedRate == 0 ||
												description == ""
											) {
												customAlert.error(
													"[Onboarding Error] Fill in all fields!"
												);
											} else {
												// setView(6);
												completeProfile();
											}
										}}
										style={styles.continueButton}
									>
										<Text style={styles.buttonText}>
											{" "}
											Complete Profile{" "}
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => setView(5)}
										style={styles.cancelButton}
									>
										<Text style={styles.backButtonText}>
											{" "}
											Back{" "}
										</Text>
									</TouchableOpacity>
								</View>
							</>
						) : null}

						{/* View 6  */}
						{/* {view == 6 ? (
              <>
                
                <View style={styles.modalBody}>
                  <ScrollView
                    style={{ width: '100%' }}
                    showsVerticalScrollIndicator={false}
                  >
                    <View style={styles.iconContainer}>
                      
                      <FontAwesome
                        name='credit-card'
                        size={14}
                        color={textVariant}
                        style={{ fontSize: 18, color: textVariant }}
                      />
                    </View>
                    <Text style={styles.onboardingTitle}>
                      Billing & payments
                    </Text>
                    <Text style={styles.details}>
                      Fill in your information to make and receive payments.
                    </Text>
                  </ScrollView>
                </View>
                <View style={styles.buttonGroup}>
                  <TouchableOpacity
                    style={styles.continueButton}
                    onPress={() => {
                      customAlert.success('Profile has been completed!');
                    }}
                  >
                    <Text style={styles.buttonText}>Complete profile</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={() => {
                      setView(4);
                    }}
                  >
                    <Text style={styles.backButtonText}> Back </Text>
                  </TouchableOpacity>
                </View>
              </>
            ) : null} */}
					</View>
				</View>
			</View>
		);
	}
};

// {view == 6 ? (
//     <>
//
//       <View style={styles.modalBody}>
//         <ScrollView
//           style={{ width: '100%' }}
//           showsVerticalScrollIndicator={false}
//         >
//           {/* Icon  */}
//           <View style={styles.iconContainer}>
//
//             <FontAwesome
//               name='user'
//               size={14}
//               color={textVariant}
//               style={{ fontSize: 18, color: textVariant }}
//             />
//           </View>
//           <Text style={styles.onboardingTitle}>Tutor profile</Text>
//           <Text style={styles.details}>Information visible to students.</Text>
//           <View style={{ width: '100%', paddingVertical: 10 }}>
//             {/* Price  */}
//             <Text
//               style={[
//                 styles.fieldTitle,
//                 styles.marginTop,
//                 { marginBottom: 10 },
//               ]}
//             >
//               Rate per hour
//             </Text>

//             <NumericTextInput
//               style={[styles.input, styles]}
//               type='currency'
//               locale='en-Us'
//               currency='CAD'
//               decimalPlaces={2}
//               value={selectedRate}
//               onUpdate={(value) => setSelectedRate(value)}
//               placeholder='$0.00'
//             />

//             {/* Description  */}
//             <Text style={[styles.fieldTitle, styles.marginTop]}>Bio</Text>
//             <TextInput
//               placeholderTextColor='#999999'
//               style={styles.descriptionInput}
//               placeholder='I am experienced and able to help with....'
//               multiline={true}
//               value={description}
//               onChange={(e) => {
//                 setDescription(e.target.value);
//               }}
//               // maxLength={1000}
//             ></TextInput>
//             <Text style={[styles.details, { padding: 5 }]}>
//               {description.length}
//             </Text>
//           </View>
//         </ScrollView>
//       </View>
//       {/*  Button group  */}
//       <View style={styles.buttonGroup}>
//         <Text
//           style={{
//             color: textVariant,
//             fontSize: 12,
//             textAlign: 'center',
//             fontFamily: defaultStyles.regularFont,
//           }}
//         >
//           This is information is all configurable in your profile after.
//         </Text>
//         <TouchableOpacity
//           onPress={() => {
//             if (selectedRate == 0 || description == '') {
//               customAlert.error('Fill in all fields!');
//             } else if (selectedRate > 1000) {
//               customAlert.error('Rate can not exceed $250!');
//             } else if (description.length < 50) {
//               customAlert.error('Bio must be at least 50 characters!');
//             } else {
//               // setView(6);
//               completeProfile();
//             }
//           }}
//           style={styles.continueButton}
//         >
//           <Text style={styles.buttonText}> Complete Profile </Text>
//         </TouchableOpacity>
//         <TouchableOpacity
//           onPress={() => setView(4)}
//           style={styles.cancelButton}
//         >
//           <Text style={styles.backButtonText}> Back </Text>
//         </TouchableOpacity>
//       </View>
//     </>
//   ) : null;
// }
