// Import necessary libraries and components
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useFonts } from "expo-font";
import { ChatsStack } from "./navigators/loggedIn/ChatsStack";
import { ProfileStack } from "./navigators/loggedIn/ProfileStack";
import { SessionsStack } from "./navigators/loggedIn/SessionsStack";
import { TutorsStack } from "./navigators/loggedIn/TutorsStack";
import { Privacy } from "./screens/Privacy";
import { Main } from "./screens/LandingPage/Main";
import { TOS } from "./screens/Tos";
import { Login } from "./screens/loggedOut/Login";
import { Register } from "./screens/loggedOut/Register";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { Provider, positions } from "react-alert";
import { Platform } from "react-native";

let Helmet;

if (Platform.OS === "web") {
	Helmet = require("react-helmet").Helmet;
}

import { MenuProvider } from "react-native-popup-menu";
import { SafeAreaProvider } from "react-native-safe-area-context";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { useMediaQuery } from "react-responsive";
import { AlertTemplate } from "./components/Alert";
import { FirebaseContextProvider } from "./contexts/firebaseContext";
import { SessionContextProvider } from "./contexts/sessionContext";
import { UserContextProvider, useUserContext } from "./contexts/userContext";
import { ListingsStack } from "./navigators/ListingsStack";
import {
	ColorsContextProvider,
	useColorsContext,
	useStyles,
} from "./utils/styles";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { dark } from "@mui/material/styles/createPalette";

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

// This object sets up deep linking within the app
const linking = {
	prefixes: [
		/* your linking prefixes */
	],
	config: {
		screens: {
			Auth: {
				screens: {
					Login: "login",
					Signup: "register",
				},
			},
			Home: {
				screens: {
					Privacy: "privacy",
					TOS: "tos",
					Main: "main",
					Tutors: {
						screens: {
							Tutors: "tutors/",
							TutorProfile: {
								path: "tutors/:tutor",
								parse: {
									tutor: (tutor) => `${tutor}`,
								},
							},
							Favourites: {
								path: "tutors/favourites",
							},
						},
					},
					Sessions: {
						screens: {
							Sessions: "sessions",
							Meeting: {
								path: "sessions/:sessionId",
								parse: {
									sessionId: (sessionId) => `${sessionId}`,
								},
							},
						},
					},
					Listings: {
						screens: {
							// Add a path to Listings
							Listings: "listings",
							StudentProfile: {
								path: "students/:student",
								parse: {
									student: (student) => `${student}`,
								},
							},
						},
					},
					Chats: {
						screens: {
							Chats: "chats",
						},
					},
					Profile: {
						screens: {
							Profile: "settings",
							Account: {
								path: "settings/account",
							},
							Notifications: {
								path: "settings/notifications",
							},
							EditProfile: {
								path: "settings/profile",
							},
							PaymentProfile: {
								path: "settings/payment",
							},
							Performance: {
								path: "settings/performance",
							},
							InstaBook: {
								path: "settings/instabook",
							},
						},
					},
				},
			},
		},
	},
};

// Navigator for authentication (Login and Signup)
const AuthNavigator = () => {
	return (
		<Stack.Navigator initialRouteName="Login">
			<Stack.Screen
				name="Login"
				component={Login}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Signup"
				component={Register}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
};

// Main navigator for the home screen, which includes the bottom tab bar navigation
const HomeNavigator = () => {
	// Fetch styles and user context for dynamic UI changes

	const defaultStyles = useStyles();
	const { darkMode, text, textVariant, border, backgroundVariant, setDarkMode} =
		useColorsContext();
	const { user, isTutor } = useUserContext();
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// If user is on web & screen is bigger than small hide nav
	let hideNav = Platform.OS === "web" && isSm ? "none" : null;

	if(user == null)
	{
		setDarkMode(false);
	}

	return (
		<>
			{Platform.OS === "web" && (
				<Helmet>
					{darkMode ? (
						<style>
							{`
              ::-webkit-scrollbar {
                height: 12px;
              }
              ::-webkit-scrollbar-track {
                background: #1F1F1F;
                border-radius: 3px;
              }
              ::-webkit-scrollbar-thumb {
                background: #3D3D3D;
                border-radius: 100px;
              }
              ::-webkit-scrollbar-thumb:hover {
                background: #5B5B5B;
              }
              
            `}
						</style>
					) : (
						<style>
							{`
              ::-webkit-scrollbar {
                height: 12px;
              }
              ::-webkit-scrollbar-track {
                background: #F9F9F9;
                border-radius: 3px;
              }
              ::-webkit-scrollbar-thumb {
                background: #D6D6D6;
                border-radius: 100px;
              }
              ::-webkit-scrollbar-thumb:hover {
                background: #BFBFBF;
              }
            `}
						</style>
					)}
				</Helmet>
			)}

			{/* 	// Depending on whether the user is a tutor or not, display different navigation tabs */}

			<Tab.Navigator
			screenOptions={{
				tabBarStyle: {
					display: hideNav ? "none" : null,
					height: 80,
					borderTop: "1px solid" + border,
					backgroundColor: backgroundVariant,
				},
			}}
			tabBarOptions={{
				activeTintColor: text,
				inactiveTintColor: textVariant,
				backgroundColor: backgroundVariant,
				labelStyle: {
					fontSize: 14,
					fontFamily: defaultStyles.boldFont,
				},
			}}
		>
				 { user == null && 
					<Tab.Screen
						name="Tutred"
						component={Main}
						options={{
							headerShown: false,
							// Hide tab bar
							tabBarButton: () => null,
						}}
					/>
					}

					<Tab.Screen
						name="Tutors"
						component={TutorsStack}
						options={{
							headerShown: false,
							tabBarIcon: ({ color, size }) => (
								<MaterialCommunityIcons
									name="school"
									color={color}
									size={size}
								/>
							),
						}}
					/>

					<Tab.Screen
						name="Sessions"
						component={SessionsStack}
						options={{
							headerShown: false,
							tabBarIcon: ({ color, size }) => (
								<MaterialCommunityIcons
									name="video"
									color={color}
									size={size}
								/>
							),
						}}
					/>

					<Tab.Screen
						name="Listings"
						component={ListingsStack}
						options={{
							headerShown: false,
							tabBarIcon: ({ color, size }) => (
								<MaterialCommunityIcons
									name="book-open-page-variant"
									color={color}
									size={size}
								/>
							),
						}}
					/>

					<Tab.Screen
						name="Chats"
						component={ChatsStack}
						options={{
							headerShown: false,
							tabBarIcon: ({ color, size }) => (
								<MaterialCommunityIcons
									name="chat"
									color={color}
									size={size}
								/>
							),
						}}
					/>

					<Tab.Screen
						name="Profile"
						component={ProfileStack}
						options={{
							headerShown: false,
							tabBarIcon: ({ color, size }) => (
								<MaterialCommunityIcons
									name="account"
									color={color}
									size={size}
								/>
							),
						}}
					/>

					<Tab.Screen
						name="Privacy"
						component={Privacy}
						options={{
							headerShown: false,
							// Hide tab bar
							tabBarButton: () => null,
						}}
					/>

					<Tab.Screen
						name="TOS"
						component={TOS}
						options={{
							headerShown: false,
							// Hide tab bar
							tabBarButton: () => null,
						}}
					/>
				</Tab.Navigator>
		</>
	);
};

// Wrapper for navigators, using react-navigation
const NavigationWrapper = ({ children }) => {
	return (
		<NavigationContainer linking={linking}>
			<MenuProvider>
				<SafeAreaProvider style={{ flex: 1 }}>
					<Stack.Navigator initialRouteName="Home">
						<Stack.Screen
							name="Home"
							component={HomeNavigator}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name="Auth"
							component={AuthNavigator}
							options={{ headerShown: false }}
						/>
					</Stack.Navigator>
				</SafeAreaProvider>
			</MenuProvider>
		</NavigationContainer>
	);
};

// Main App component
export default function App() {
	// Alert options
	// dont timeout
	const options = {
		position: positions.TOP_RIGHT,
		timeout: 10000,
	};

	// Fonts
	const [fontsLoaded] = useFonts({
		Satoshi_400: require("./assets/fonts/Satoshi_400.ttf"),
		Satoshi_500: require("./assets/fonts/Satoshi_500.ttf"),
		Satoshi_700: require("./assets/fonts/Satoshi_700.ttf"),
		Satoshi_900: require("./assets/fonts/Satoshi_900.ttf"),
	});

	// Using various providers to wrap the application, ensuring context and other functionalities are available throughout the app
	return (
		// Firebase context provider for Firebase functionalities
		<FirebaseContextProvider>
			{/* // Colors context provider for dynamic styling based on themes */}
			<ColorsContextProvider>
				{/* // Alert provider for displaying alerts across the app */}
				<Provider template={AlertTemplate} {...options}>
					{/* // User context provider to make user data available
					throughout the app */}
					<UserContextProvider>
						{/* // Session context provider for session-related
						functionalities */}
						<SessionContextProvider>
							{/* // Action sheet provider, useful for displaying pop-up menus or sheets (conditionally using custom ones on web) */}
							<ActionSheetProvider
								useCustomActionSheet={
									Platform.OS === "web" ? true : false
								}
							>
								{/* // The navigation setup of the app */}
								<NavigationWrapper></NavigationWrapper>
							</ActionSheetProvider>
						</SessionContextProvider>
					</UserContextProvider>
				</Provider>
			</ColorsContextProvider>
		</FirebaseContextProvider>
	);
}
