import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

import { useUserContext } from "../../contexts/userContext";

// Import pages
import { Favourites } from "../../screens/loggedIn/Tutors/Favourites";
import { TutorProfile } from "../../screens/loggedIn/Tutors/TutorProfile";
import { Tutors } from "../../screens/loggedIn/Tutors/Tutors";

const Stack = createNativeStackNavigator();

export const TutorsStack = ({ navigation, route, params }) => {
	const { isTutor } = useUserContext();

	return (
		<Stack.Navigator initialRouteName="Tutors">
			<Stack.Screen
				options={{ headerShown: false }}
				name="Tutors"
				component={Tutors}
			/>

			<Stack.Group screenOptions={{ presentation: "modal" }}>
				<Stack.Screen
					name="TutorProfile"
					options={{ headerShown: false }}
					component={TutorProfile}
				></Stack.Screen>

				<Stack.Screen
					name="Favourites"
					options={{ headerShown: false }}
					component={Favourites}
				></Stack.Screen>
			</Stack.Group>
		</Stack.Navigator>
	);
};
