import { AntDesign } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import { Link } from "@react-navigation/native";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
	Image,
	Modal,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

export const ListingModal = ({
	navigateToChat,
	db,
	listing,
	user,
	isTutor,
	modalVisible,
	setModalVisible,
}) => {
	var datePosted = new Date(listing.createdAt.toDate());
	var hoursAgo = Math.floor((new Date() - datePosted) / 1000 / 60 / 60);
	const [studentURL, setStudentURL] = useState(null);

	useEffect(() => {
		if (listing) {
			getStudentURL(listing.user.uid);
		}
	}, [listing]);

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	const customAlert = useAlert();

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "50%"
				: isLg
				? "60%"
				: isMd
				? "70%"
				: isSm
				? "80%"
				: "90%",
			height: "80%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			// justifyContent: 'space-between',
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			// position: 'absolute',
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 32,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		body: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: text,
			marginTop: 10,
		},
	});

	const getStudentURL = async (userId) => {
		// Get TUTOR doc from users collection
		const q = query(collection(db, "users"), where("uid", "==", userId));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			console.log("found student url", doc.data().URL);
			setStudentURL(doc.data().URL);
		});
	};

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(!modalVisible);
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={styles.modalHeader}>
						{/* Close filters button */}
						<TouchableOpacity
							style={styles.closeButton}
							onPress={() => {
								setModalVisible(!modalVisible);
							}}
						>
							<CloseOutlined
								style={{ fontSize: 22, color: text }}
							></CloseOutlined>
						</TouchableOpacity>
					</View>

					<ScrollView
						style={{ paddingTop: 15, flex: 1 }}
						showsVerticalScrollIndicator={false}
						contentContainerStyle={{
							flexGrow: 1,
							justifyContent: "space-between",
						}}
					>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							{/* Left side image */}
							<Image
								source={{
									uri: listing.user.avatar
										? listing.user.avatar
										: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
								}}
								style={{
									borderRadius: 100,
									width: 150,
									height: 150,
								}}
							/>

							{/* Right side name and content */}
							<View
								style={{
									flex: 1,
									marginLeft: 30,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
							>
								{/* Name */}
								<Text
									style={{
										fontSize: 24,
										fontFamily: defaultStyles.titleFont,
										color: text,
									}}
								>
									{listing.title}
								</Text>

								{/* listing title */}
								<Link
									onPress={() => {
										setModalVisible(!modalVisible);
									}}
									to={`/students/${studentURL}`}
								>
									<Text
										style={{
											fontSize: 19,
											fontFamily: defaultStyles.boldFont,
											color: text,
										}}
									>
										By:{" "}
										{listing.user.name.split(" ").length > 1
											? listing.user.name.split(" ")[0] +
											  " " +
											  listing.user.name
													.split(" ")[1]
													.charAt(0) +
											  "."
											: listing.user.name}
									</Text>
								</Link>

								{/* Listing and user information (side by side flexboxes) */}

								<Text
									style={{
										fontSize: 19,
										fontFamily: defaultStyles.boldFont,
										color: text,
										marginTop: 30,
									}}
								>
									Details:
								</Text>

								<View
									style={{
										marginTop: 15,
										display: "flex",
										flexDirection: "column",
										gap: 15,
									}}
								>
									{/* Subject */}
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											width: "100%",
										}}
									>
										{/* Icon */}
										<AntDesign
											style={{ marginRight: 5 }}
											name="book"
											size={16}
											color={text}
										/>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											Subject:{" "}
										</Text>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											{listing.subject}
										</Text>
									</View>

									{/* Skills */}
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											width: "100%",
										}}
									>
										{/* Icon */}
										<AntDesign
											style={{ marginRight: 5 }}
											name="bulb1"
											size={16}
											color={text}
										/>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											Skills:{" "}
										</Text>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											{listing.skills.map(
												(skill, index) => {
													return index ===
														listing.skills.length -
															1
														? skill
														: skill + ", ";
												}
											)}
										</Text>
									</View>
								</View>

								<View
									style={{
										display: "flex",
										flexDirection: "column",
										gap: 15,
										marginTop: 15,
									}}
								>
									{/* Language */}
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											width: "100%",
										}}
									>
										<AntDesign
											style={{ marginRight: 5 }}
											name="earth"
											size={16}
											color={text}
										/>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											Languages:{" "}
										</Text>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											{listing.languages &&
												listing.languages.map(
													(language, index) => {
														return index ===
															listing.languages
																.length -
																1
															? language
															: language + ", ";
													}
												)}
										</Text>
									</View>

									{/* Location */}
									{/* <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%'
                                    }}>
                                        <AntDesign style={{marginRight:5}} name="enviromento" size={16} color={text} />
                                        <Text style={{
                                            fontSize: 16,
                                            fontFamily: defaultStyles.regularFont,
                                            color: text,
                                        }}>Location: </Text>
                                        <Text style={{
                                            fontSize: 16,
                                            fontFamily: defaultStyles.regularFont,
                                            color: text,
                                        }}>
                                            {listing.location ? listing.location : 'N/A'}
                                        </Text>
                                    </View> */}
								</View>

								<View
									style={{
										display: "flex",
										flexDirection: "row",
										gap: 15,
										marginTop: 15,
									}}
								>
									{/* Date posted */}
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											width: "50%",
										}}
									>
										{/* Icon */}
										<AntDesign
											name="calendar"
											style={{ marginRight: 5 }}
											size={16}
											color={text}
										/>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											Date Posted:{" "}
										</Text>
										<Text
											style={{
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
												color: text,
											}}
										>
											{datePosted.toLocaleString(
												"default",
												{ month: "long" }
											) +
												" " +
												datePosted.getDate() +
												", " +
												datePosted.getFullYear()}
										</Text>
									</View>
								</View>

								{/* Description with dotted border */}
								<Text
									style={{
										fontSize: 19,
										fontFamily: defaultStyles.boldFont,
										color: text,
										marginTop: 30,
									}}
								>
									Question:
								</Text>
								<View
									style={{
										marginTop: 15,
										borderStyle: "dashed",
										borderWidth: 1,
										borderColor: border,
										borderRadius: defaultStyles.radius,
									}}
								>
									<Text
										style={{
											fontSize: 16,
											fontFamily:
												defaultStyles.regularFont,
											color: text,
											padding: 15,
										}}
									>
										{listing.description}
									</Text>
								</View>
							</View>
						</View>

						{/* BOTTOM OF BODY */}

						<View
							style={{
								display: "flex",
								flexDirection: "row",
								borderTopColor: border,
								borderTopWidth: 1,
								paddingTop: 15,
							}}
						>
							{/* Message button or delete */}

							{user && user.uid === listing.user.uid ? (
								<TouchableOpacity
									style={{
										backgroundColor: green,
										borderRadius:
											defaultStyles.buttonRadius,
										padding: 10,
										width: 250,
										alignItems: "center",
										marginLeft: "auto",
									}}
									onPress={() => {
										deleteDoc(
											doc(db, "listings", listing.id)
										);
										// Minimize modal
										setModalVisible(!modalVisible);
										setTimeout(() => {
											customAlert.success(
												"[Listing Deleted] You have successfully deleted your listing."
											);
										}, 200);
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 16,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										Delete
									</Text>
								</TouchableOpacity>
							) : (
								<TouchableOpacity
									style={{
										backgroundColor: green,
										borderRadius:
											defaultStyles.buttonRadius,
										padding: 10,
										width: 250,
										alignItems: "center",
										marginLeft: "auto",
									}}
									onPress={() => {
										if (!user) {
											// Dismiss modal
											setModalVisible(!modalVisible);
											setTimeout(() => {
												customAlert.error(
													"[Please Sign In] You must have an account to chat with a tutor."
												);
											}, [200]);
											return;
										}

										const chatsRef = collection(
											db,
											"chats"
										);
										const q = query(
											chatsRef,
											where("tutor.uid", "==", user.uid),
											where(
												"user.uid",
												"==",
												listing.user.uid
											)
										);
										getDocs(q).then((querySnapshot) => {
											if (querySnapshot.docs.length > 0) {
												// TODO: ADD NEW MESSAGE TO EXISTING CHAT
												// Update lastMessage
												updateDoc(
													doc(
														db,
														"chats",
														querySnapshot.docs[0].id
													),
													{
														lastMessage: {
															text:
																"Hey there! I'm here to help you with your " +
																listing.title +
																" problem. Let's get started!",
															createdAt:
																new Date(),
															user: {
																_id: user.uid,
																uid: user.uid,
																name: user.name,
																// avatar: user.picture ? user.picture : null
																avatar: user.picture
																	? user.picture
																	: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
															},
														},
													}
												);

												let chatId =
													querySnapshot.docs[0].id;

												// Add new message to messages collection
												addDoc(
													collection(db, "messages"),
													{
														chat: chatId,
														text:
															"Hey there! I'm here to help you with your " +
															listing.title +
															" problem. Let's get started!",
														createdAt: new Date(),
														user: {
															_id: user.uid,
															uid: user.uid,
															name: user.name,
															avatar: user.picture
																? user.picture
																: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
														},
													}
												);

												// Dismiss modal
												setModalVisible(!modalVisible);
												// window.location.href = `/chats/${chatId}`;
												setTimeout(() => {
													customAlert.success(
														"[Chat Created] You can now chat with " +
															listing.user.name +
															" about " +
															listing.title +
															"."
													);
													navigateToChat(chatId);
												}, 200);
												return;
											} else {
												// Create the chat in firestore
												const chatsRef = collection(
													db,
													"chats"
												);

												// Add Doc
												addDoc(chatsRef, {
													tutor: {
														name: user.name,
														image: user.picture
															? user.picture
															: null,
														uid: user.uid,
														id: user.uid,
													},
													user: {
														name: listing.user.name,
														image: listing.user
															.avatar
															? listing.user
																	.avatar
															: null,
														uid: listing.user.uid,
														id: listing.user.uid,
													},
													created: new Date(),
													lastUpdated: new Date(),
													lastMessage: {
														text:
															"Hey there! I'm here to help you with your " +
															listing.title +
															" problem. Let's get started!",
														createdAt:
															serverTimestamp(),
														user: {
															_id: user.uid,
															uid: user.uid,
															name: user.name,
															avatar: user.picture
																? user.picture
																: null,
														},
													},
												}).then((docRef) => {
													addDoc(
														collection(
															db,
															"messages"
														),
														{
															chat: docRef.id,
															text:
																"Hey there! I'm here to help you with your " +
																listing.title +
																" problem. Let's get started!",
															createdAt:
																serverTimestamp(),
															user: {
																_id: user.uid,
																uid: user.uid,
																name: user.name,
																avatar: user.picture
																	? user.picture
																	: null,
															},
														}
													);

													// Chat object for navigation

													// Navigate to the chat
													// navigation.navigate('Chats', {screen: 'Chats', params: {chat: docRef.id, lo: null}})
													setModalVisible(
														!modalVisible
													);
													setTimeout(() => {
														customAlert.success(
															"[Chat Created] You can now chat with " +
																listing.user
																	.name +
																" about " +
																listing.title +
																"."
														);
														navigateToChat(
															docRef.id
														);
													}, 200);
												});
											}
										});
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 16,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										Message
									</Text>
								</TouchableOpacity>
							)}
						</View>
					</ScrollView>
				</View>
			</View>
		</Modal>
	);
};
