import React from "react";
import Card from "react-bootstrap/Card";
import { useNavigation } from '@react-navigation/native';


function SubjectCards(props) {
    const navigation = useNavigation();
    return(
        <Card className="subject-card-view" onClick={() => navigation.navigate("Tutors", {screen:"Tutors", params: {subjectId: props.subjectId}})} style={{ cursor: "pointer" }}>
            <Card.Img variant="top" src={props.imgPath} alt="card-img" style={{maxHeight:'100px'}} />
            <Card.Body>
                <Card.Title className="subject-card-title">{props.title}</Card.Title>
                <Card.Text style={{ textAlign: "justify"}}>
                    {props.description}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default SubjectCards;