import {
	addDoc,
	collection,
	doc,
	limit,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";

// File imports
import { useFirebaseContext } from "./firebaseContext";
import { useUserContext } from "./userContext";

// Create context
const sessionContext = createContext();

// Export sessions as a module
export const SessionContextProvider = ({ navigation, children }) => {
	// Get logged in user
	const { user, isTutor } = useUserContext();

	// Sessions
	const [pendingSessions, setPendingSessions] = useState([]);
	const [bookedSessions, setBookedSessions] = useState([]);
	const [pastSessions, setPastSessions] = useState([]);
	const [liveSessions, setLiveSessions] = useState([]);
	const [liveSessionIds, setLiveSessionIds] = useState([]);
	const [totalSessions, setTotalSessions] = useState(0);
	const [totalMins, setTotalMins] = useState(0);

	// Set db instance
	const { db } = useFirebaseContext();

	// Get booked sessions
	const getBookedSessions = async function () {
		var q;
		if (isTutor) {
			q = query(
				collection(db, "sessions"),
				where("status", "in", ["confirmed", "live", "in progress"]),
				where("tutor", "==", user.uid),
				orderBy("createdAt", "desc")
			);
		} else {
			q = query(
				collection(db, "sessions"),
				where("status", "in", ["confirmed", "live", "in progress"]),
				where("user.uid", "==", user.uid),
				orderBy("createdAt", "desc")
			);
		}
		const unsubscribe = onSnapshot(q, (snapshot) =>
			setBookedSessions(
				snapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}))
			)
		);

		return unsubscribe;
	};

	// Get pending sessions
	const getPendingSessions = async function () {
		if (isTutor) {
			const q = query(
				collection(db, "sessions"),
				where("status", "==", "pending"),
				where("tutor", "==", user.uid),
				orderBy("createdAt", "desc")
			);

			const unsubscribe = onSnapshot(q, (snapshot) => {
				setPendingSessions(
					snapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
				);
			});

			return unsubscribe;
		} else {
			const q = query(
				collection(db, "sessions"),
				where("status", "==", "pending"),
				where("user.uid", "==", user.uid),
				orderBy("createdAt", "desc")
			);

			const unsubscribe = onSnapshot(q, (snapshot) => {
				setPendingSessions(
					snapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
				);
			});

			return unsubscribe;
		}
	};

	// Get pending sessions
	const getPastSessions = async function () {
		var q;
		if (isTutor) {
			q = query(
				collection(db, "sessions"),
				where("status", "in", ["completed", "canceled"]),
				where("tutor", "==", user.uid),
				orderBy("createdAt", "desc"),
				limit(8)
			);
		} else {
			q = query(
				collection(db, "sessions"),
				where("status", "in", ["completed", "canceled"]),
				where("user.uid", "==", user.uid),
				orderBy("createdAt", "desc"),
				limit(8)
			);
		}

		const unsubscribe = onSnapshot(q, (snapshot) =>
			setPastSessions(
				snapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}))
			)
		);

		return unsubscribe;
	};

	// Get live sessions
	const getLiveSessions = async function () {
		if (isTutor) {
			const q = query(
				collection(db, "sessions"),
				where("status", "==", "live"),
				where("tutor", "==", user.uid),
				orderBy("createdAt", "desc")
			);

			const unsubscribe = onSnapshot(q, (snapshot) =>
				setLiveSessions(
					snapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
				)
			);

			return unsubscribe;
		}
	};

	// Run functions on load
	useEffect(() => {
		if (db && user && isTutor !== null) {
			getBookedSessions();
			getPendingSessions();
			getPastSessions();
			getLiveSessions();
			getTotalSessions();
			getTotalMins();
		}
	}, [db, user, isTutor]);

	useEffect(() => {
		// Check if the live session is in the past and fix the status if it is
		if (bookedSessions && bookedSessions.length > 0) {
			// Check by in progress or live
			var inProgressSessionsFiltered = bookedSessions.filter(
				(session) => session.status === "in progress"
			);
			var liveSessionsFiltered = bookedSessions.filter(
				(session) => session.status === "live"
			);

			if (inProgressSessionsFiltered.length > 0) {
				// Check if session is in the past by comparing it to date and time plus the length
				inProgressSessionsFiltered.map((session) => {
					var endAt = session.endAt;
					endAt = endAt.toDate();
					var now = new Date();
					if (endAt < now) {
						// Update session to completed
						updateDoc(
							doc(db, "sessions", session.id),
							{
								status: "canceled",
								canceledAt: serverTimestamp(),
							},
							{ merge: true }
						);
					}
				});
			}

			if (liveSessionsFiltered.length > 0) {
				// Check if session is in the past by comparing it to date and time plus the length
				liveSessionsFiltered.map((session) => {
					var endAt = session.endAt;
					endAt = endAt.toDate();
					var now = new Date();
					if (endAt < now) {
						// Update session to completed
						updateDoc(
							doc(db, "sessions", session.id),
							{
								status: "canceled",
								canceledAt: serverTimestamp(),
							},
							{ merge: true }
						);
					}
				});
			}
		}

		// Check if pending session is in the past and fix the status if it is by canceling it
		if (pendingSessions && pendingSessions.length > 0) {
			console.log("pendingSessions", pendingSessions);
			pendingSessions.map((session) => {
				var endAt = session.endAt;
				endAt = endAt.toDate();
				var now = new Date();
				if (endAt < now) {
					// Update session to completed
					updateDoc(
						doc(db, "sessions", session.id),
						{
							status: "canceled",
							canceledAt: serverTimestamp(),
						},
						{ merge: true }
					);
				}
			});
		}
	}, [bookedSessions, pendingSessions]);

	useEffect(() => {
		if (liveSessions && liveSessions.length > 0) {
			newLiveSessions();
		}
	}, [liveSessions]);

	const newLiveSessions = () => {
		var newSessionIds = liveSessions.map((session) => session.id);
		var newSession = null;
		// Iterate through new sessions and compare to liveSessionIds
		newSessionIds.map((id) => {
			if (!liveSessionIds.includes(id)) {
				newSession = id;
			}
		});

		if (newSession) {
			setLiveSessionIds(newSessionIds);
			updateDoc(
				doc(db, "sessions", newSession),
				{
					confirmedAt: serverTimestamp(),
				},
				{ merge: true }
			);

			// acceptSession(session.id);
			// cancel other liveSessions
			liveSessionIds.map((session) => {
				if (session.id !== newSession) {
					cancelSession(session.id);
				}
			});

			// Check if user is already in meeting
			if (window.location.href.includes(newSession)) {
				return;
			} else {
				// Redirect to meeting
				// TODO: Redirect for mobile
				window.location.href = `/sessions/${newSession}`;
				return;
			}
		}
	};

	const cancelSession = async (id) => {
		await updateDoc(
			doc(db, "sessions", id),
			{
				status: "canceled",
				canceledAt: serverTimestamp(),
			},
			{ merge: true }
		);
	};

	// var setWithMerge = cityRef.set({
	//   capital: true
	// }, { merge: true });

	// Accept session
	const acceptSession = async (id) => {
		await updateDoc(doc(db, "sessions", id), {
			status: "confirmed",
			confirmedAt: serverTimestamp(),
		});
	};

	// Create instsabook session
	const createInstaBookSession = async (
		sessionId,
		userId,
		name,
		profile,
		tutor,
		paymentIntentId,
		customerId
	) => {
		return new Promise((resolve, reject) => {
			// Create endAt date to 10 minutes from now
			const endAt = new Date();
			endAt.setMinutes(endAt.getMinutes() + 10);

			// Set doc from sessionId passed in
			setDoc(doc(db, "sessions", sessionId), {
				note: "InstaBook session",
				// Date is year-month-day (but today's date)
				// date: new Date().toISOString().slice(0, 10),
				date: new Date().toLocaleDateString("en-US", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				}),

				// Time is hour:minute in AM/PM
				time: new Date().toLocaleTimeString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				}),
				// Length of session in minutes
				minutes: tutor.instaBookLength ? tutor.instaBookLength : 15,
				// Status of session
				status: "live",
				// Timestamps for
				createdAt: serverTimestamp(),
				completedAt: null,
				started: false,
				// End time is 10 minutes from now
				endAt: endAt,
				// Information about session
				subject: "",
				skills: [],
				tutor: tutor.uid,
				tutorProfile: tutor,
				earnings: 0,
				user: {
					_id: userId,
					uid: userId,
					image: profile,
					name: name,
					customerId: customerId,
				},
				paymentIntentId: paymentIntentId,
			})
				.then(() => {
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	// Create a session
	const createSession = async (
		note,
		subject,
		date,
		time,
		skills,
		profile,
		name,
		userId,
		minutes,
		tutorId,
		tutor,
		paymentIntentId,
		customerId,
		autoAccept
	) => {
		// Set endAt as date & time + length of session (minutes)
		const endAt = new Date(date + " " + time);
		endAt.setMinutes(endAt.getMinutes() + minutes);

		console.log("endAt", endAt);

		// Add document to firestore using
		await addDoc(collection(db, "sessions"), {
			note: note,
			date: date,
			time: time,
			minutes: minutes,
			endAt: endAt,
			status: !autoAccept ? "pending" : "confirmed",
			confirmedAt: !autoAccept ? null : serverTimestamp(),
			subject: subject,
			skills: skills ? skills : [],
			tutor: tutorId,
			tutorProfile: tutor,
			earnings: 0,
			user: {
				_id: userId,
				uid: userId,
				image: profile,
				name: name,
				customerId: customerId,
			},
			createdAt: serverTimestamp(),
			completedAt: null,
			paymentIntentId: paymentIntentId,
		});
	};

	// Get Total sessions
	const getTotalSessions = () => {
		if (isTutor) {
			const q = query(
				collection(db, "sessions"),
				where("tutor", "==", user.uid)
			);

			const unsubscribe = onSnapshot(q, (snapshot) =>
				setTotalSessions(
					snapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
				)
			);

			return unsubscribe;
		} else {
			const q = query(
				collection(db, "sessions"),
				where("user.uid", "==", user.uid)
			);

			const unsubscribe = onSnapshot(q, (snapshot) =>
				setTotalSessions(
					snapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
				)
			);

			return unsubscribe;
		}
	};

	// Get total mins of completed sessions
	const getTotalMins = async function () {
		if (isTutor) {
			const q = query(
				collection(db, "sessions"),
				where("status", "==", "completed"),
				where(isTutor ? "tutor" : "user.uid", "==", user.uid)
			);

			const unsubscribe = onSnapshot(q, (snapshot) =>
				setTotalMins(
					snapshot.docs.map((doc) => ({
						mins: doc.data().lengthInMinutes
							? doc.data().lengthInMinutes
							: 0,
					}))
				)
			);

			return unsubscribe;
		} else {
			const q = query(
				collection(db, "sessions"),
				where("status", "==", "completed"),
				where("user.uid", "==", user.uid)
			);

			const unsubscribe = onSnapshot(q, (snapshot) =>
				setTotalMins(
					snapshot.docs.map((doc) => ({
						mins: doc.data().lengthInMinutes
							? doc.data().lengthInMinutes
							: 0,
					}))
				)
			);

			return unsubscribe;
		}
	};

	// Pass state to children components
	return (
		<sessionContext.Provider
			value={{
				pendingSessions,
				bookedSessions,
				pastSessions,
				liveSessions,
				createInstaBookSession,
				cancelSession,
				acceptSession,
				createSession,
				totalSessions,
				totalMins,
			}}
		>
			{children}
		</sessionContext.Provider>
	);
};

export function useSessionContext() {
	return useContext(sessionContext);
}
