import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

// Import pages
import { Listings } from "../screens/loggedIn/Listings/Listings";
import { StudentProfile } from "../screens/loggedIn/StudentProfile";

const Stack = createNativeStackNavigator();

export const ListingsStack = ({ navigation, route, params }) => {
	return (
		<Stack.Navigator initialRouteName="Listings">
			<Stack.Screen
				options={{ headerShown: false }}
				name="Listings"
				component={Listings}
			/>

			<Stack.Screen
				options={{ headerShown: false }}
				name="StudentProfile"
				component={StudentProfile}
			/>
		</Stack.Navigator>
	);
};
