const subjects = {
    "Mathematics": [
        "Algebra",
        "Geometry",
        "Calculus",
        "Statistics",
        "Probability",
        "Trigonometry"
      ],
      "English Language Arts": [
        "Reading",
        "Writing",
        "Grammar",
        "Literature",
        "Creative Writing",
        "Poetry",
        "Journalism",
        "Speech and Debate"
      ],
      "Science": [
        "Biology",
        "Chemistry",
        "Physics",
        "Earth Science",
        "Astronomy",
        "Environmental Science",
        "Anatomy and Physiology",
        "Botany",
        "Zoology"
      ],
      "Social Studies": [
        "History",
        "Geography",
        "Civics",
        "Government",
        "Economics",
        "Anthropology",
        "Sociology",
        "Psychology"
      ],
      "Physical Education": [
        "Fitness",
        "Sports",
        "Team Games",
        "Individual Sports",
        "Dance",
        "Yoga",
        "Pilates"
      ],
      "Art": [
        "Drawing",
        "Painting",
        "Sculpture",
        "Ceramics",
        "Printmaking",
        "Graphic Design",
        "Digital Art",
        "Animation",
        "Photography"
      ],
      "Music": [
        "Music Theory",
        "Singing",
        "Playing Instruments",
        "Music Composition",
        "Music History",
        "Music Appreciation"
      ],
      "Foreign Language": [
        "Grammar",
        "Vocabulary",
        "Reading",
        "Writing",
        "Speaking",
        "Listening",
        "Culture"
      ],
      "Computer Science": [
        "Programming",
        "Algorithms",
        "Data Structures",
        "Computer Networks",
        "Web Development",
        "Software Engineering",
        "Python",
        "Java",
        "C++",
        "C#",
        "JavaScript"
      ],
      "History": [
        "World History",
        "US History",
        "European History",
        "Asian History",
        "African History",
        "Latin American History",
        "Middle Eastern History"
      ],
      "Geography": [
        "Physical Geography",
        "Human Geography",
        "Cartography",
        "Geographic Information Systems (GIS)"
      ],
      "Biology": [
        "Cell Biology",
        "Ecology",
        "Genetics",
        "Microbiology",
        "Physiology",
        "Zoology",
        "Botany"
      ],
      "Chemistry": [
        "Organic Chemistry",
        "Inorganic Chemistry",
        "Physical Chemistry",
        "Analytical Chemistry",
        "Biochemistry"
      ],
      "Physics": [
        "Mechanics",
        "Thermodynamics",
        "Electromagnetism",
        "Optics",
        "Quantum Mechanics"
      ],
      "Economics": [
        "Microeconomics",
        "Macroeconomics",
        "International Economics",
        "Financial Economics"
      ],
      "Psychology": [
        "Developmental Psychology",
        "Abnormal Psychology",
        "Social Psychology",
        "Cognitive Psychology"
      ],
      "Sociology": [
        "Social Stratification",
        "Criminology",
        "Race and Ethnicity",
        "Gender Studies",
        "Social Movements",
        "Culture and Society"
      ],
      "Environmental Science": [
        "Climate Science",
        "Conservation Biology",
        "Sustainability",
        "Environmental Policy"
      ],
        "Philosophy": [
    "Ethics",
    "Epistemology",
    "Metaphysics",
    "Logic",
    "Political philosophy"
  ],
  "Health Education": [
    "Nutrition",
    "Sexual health",
    "Mental health",
    "Substance abuse prevention",
    "First aid and safety",
    "Disease prevention",
    "Physical fitness and wellness",
    "Health advocacy and communication"
  ]
}

module.exports = {
  subjects
}