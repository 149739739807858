// Package imports
import { CloseOutlined } from '@mui/icons-material';
import { Link } from '@react-navigation/native';
import {
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

// File imports
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useFirebaseContext } from '../contexts/firebaseContext';
import { useSessionContext } from '../contexts/sessionContext';
import { useUserContext } from '../contexts/userContext';
import { useColorsContext, useStyles } from '../utils/styles';
const dev = false;
const hostname = dev ? 'https://tutredstage-266226951372.herokuapp.com' : 'https://tutred-10235aff3fe9.herokuapp.com';

// Tutor Review component
export const SessionModal = ({ session, modalVisible, setModalVisible, navigation }) => {
  // Import user
  const { user } = useUserContext();
  const { db } = useFirebaseContext();

  // Convert 24 hour time 12
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }



  // get now date
  const today = new Date();
  const {isTutor} = useUserContext();
  const { cancelSession, acceptSession } = useSessionContext();
  const customAlert = useAlert();
  const [image, setImage] = useState("https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e");
  const [tutorURL, setTutorURL] = useState(null);
  const [studentURL, setStudentURL] = useState(null);

  useEffect(() => {
    if (isTutor !== null && session) {
      if (!isTutor) {
        getTutorURL(session.tutorProfile.id);
      } else {
        getStudentURL(session.user.uid);
      }
      if (isTutor && session.user.image) {
        setImage(session.user.image);
      } else if (!isTutor && session.tutorProfile.picture) {
        setImage(session.tutorProfile.picture);
      }
    }
  }, [session, isTutor]);


  const getTutorURL = async (tutorId) => {
    // Get TUTOR doc from users collection
    const q = query(collection(db, "users"), where("uid", "==", tutorId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        setTutorURL(doc.data().URL);
    });        
};

const getStudentURL = async (userId) => {
  // Get TUTOR doc from users collection
  const q = query(collection(db, "users"), where("uid", "==", userId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
      setStudentURL(doc.data().URL);
  });        
};



  // Colors and styles
  const {
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
  } = useColorsContext();
  const defaultStyles = useStyles();

  // Responsive Breakpoints
  const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMd = useMediaQuery({ query: '(min-width: 768px)' });
  const isSm = useMediaQuery({ query: '(min-width: 640px)' });


const CancelModal = ({title, message, onClose, onAccept}) => {
  return (
    <View style={{
        zIndex: 999,
        width: 450,
        backgroundColor: background,
        borderRadius: defaultStyles.radius,
        padding: 15,
        border: "1px solid" + border
    }}>
        <Text style={{
            color: text,
            fontSize: 19,
            fontFamily: defaultStyles.titleFont,
            textAlign: "center",
            marginTop: 15
        }}>{title}</Text>
        
        <Text style={{
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            textAlign: "center",
            marginTop: 15
        }}>{message}</Text>


        <View style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 30,
            }}>
            
            <TouchableOpacity style={{
                backgroundColor: border,
                width: 100,
                height: 40,
                marginLeft: 5,
                borderRadius: defaultStyles.buttonRadius,
                justifyContent: "center",
                alignItems: "center"
            }} onPress={() => {
                onClose();
            }}>
                <Text style={{
                    color: text,
                    fontSize: 16,
                    fontFamily: defaultStyles.boldFont
                }}>Dismiss</Text>
            </TouchableOpacity>

            <TouchableOpacity style={{
                backgroundColor: primary,
                width: 100,
                height: 40,
                marginLeft: 5,
                borderRadius: defaultStyles.buttonRadius,
                justifyContent: "center",
                alignItems: "center"
            }} onPress={() => {
                onAccept();
            }}>
                <Text style={{
                    color: "#fff",
                    fontSize: 16,
                    fontFamily: defaultStyles.boldFont
                }}>Cancel</Text>
            </TouchableOpacity>

        </View>

    </View>
    )
}

  // Cancel component
  // confirmAlert({
    // customUI: ({ onClose }) => {
    // }
// });

  // Create stylesheet
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      paddingTop: 30,
      paddingBottom: 30,
      backgroundColor: background,
      display: 'flex',
      flexDirection: Platform.OS === 'web' ? 'row' : null,
      padding: Platform.OS === 'web' ? 30 : null,

      width: '100%',
      margin: Platform.OS === 'web' ? 'auto' : null,
    },
    header: {
      marginTop: 30,
      flexDirection: 'column',
      display: 'flex',
    },
    bigTitle: {
      color: text,
      fontSize: 36,
      fontFamily: defaultStyles.titleFont,
    },
    title: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    layoutGrid: {
      marginTop: 60,
      display: !isLg ? 'row' : 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: '1fr',
      gridColumnGap: 50,
      gridRowGap: 0,
    },
    colOne: {
      gridArea: 1 / 1 / 2 / 2,
      color: text,
    },
    colTwo: {
      gridArea: 1 / 2 / 2 / 3,
      color: text,
      display: 'flex',
      paddingLeft: isLg ? 30 : null,
      marginTop: !isLg ? 30 : 0,
    },
    welcome: {
      fontSize: 16,
      color: text,
      fontFamily: defaultStyles.regularFont,
    },
    name: {
      color: primary,
    },
    icon: {
      width: 50,
      height: 50,
      borderRadius: defaultStyles.radius,
      backgroundColor: 'transparent',
      marginRight: 15,
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: 'auto',
      marginLeft: 5,
    },
    statsGrid: {
      display: 'flex',
      flexDirection: isMd ? 'row' : 'column',
      width: '100%',
      backgroundColor: background,
      border: '1px solid ' + border,
      borderRadius: defaultStyles.radius,
      padding: 30,
      marginTop: 30,
    },
    statsTitle: {
      color: textVariant,
      fontSize: 14,
      fontFamily: defaultStyles.regularFont,
    },
    statsIcon: {
      width: 60,
      height: 60,
      borderRadius: defaultStyles.radius,
      backgroundColor: backgroundVariant,
      marginRight: 10,
      justifyContent: 'center',
      alignItems: 'center',
    },
    statsText: {
      color: text,
      fontFamily: defaultStyles.boldFont,
      fontSize: 22,
    },
    id: {
      fontSize: 12,
      fontFamily: defaultStyles.regularFont,
      color: textVariant,
      fontWeight: 900,
    },
    // Modal
    centeredView: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalView: {
      backgroundColor: background,
      borderRadius: defaultStyles.radius,
      border: `1px solid ${border}`,
      width: isXl ? '40%' : isLg ? '50%' : isMd ? '60%' : isSm ? '70%' : '80%',
      height: isXl ? '55%' : isLg ? '60%' : isMd ? '70%' : isSm ? '80%' : '90%',
      padding: 15,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
    },
    closeButton: {
      marginRight: 15,
    },
    modalTitle: {
      fontSize: 18,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    joinButton: {
      borderRadius: defaultStyles.buttonRadius,
      height: 35,
      paddingHorizontal: 15,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: primary,
      marginRight:5,
    },
    cancelButton: {
      borderRadius: defaultStyles.buttonRadius,
      height: 35,
      paddingHorizontal: 15,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: primary,
    },
    title: {
      fontSize: 16,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    nameTitle: {
      fontSize: 16,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    buttonText: {
      color: '#fff',
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    img: {
      width: 80,
      height: 80,
      borderRadius: defaultStyles.buttonRadius,
      border: '1px solid ' + border,
    },
    details: {
      fontSize: 16,
      color: textVariant,
      fontFamily: defaultStyles.regularFont,
    },
    pickerInfoText: {
      fontSize: 12,
      color: textVariant,
      fontFamily: defaultStyles.lightFont,
      marginBottom: 1,
    },
    infoBorder: {
      borderLeftWidth: 5,
      borderLeftColor: border,
      paddingLeft: 10,
      borderBottomEndRadius: 20,
      minWidth: 250,
    },
    marginTop: { marginVertical: 20 },
    infoContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: !isMd ? 'column' : 'row',
      justifyContent: 'space-between',
    },
  });

  if (!session) {
    return null;
  }

  return (
    <View>
      <Modal
        key={1}
        animationType='fade'
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalHeader}>
              {/* Close filters button */}
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                <CloseOutlined
                  style={{ fontSize: 22, color: text }}
                ></CloseOutlined>
              </TouchableOpacity>

              {/* Title */}
              <Text style={styles.modalTitle}>
                {session.status == 'pending' ? 'Pending Session' : null}
                {session.status == "confirmed" ? "Upcoming Session" : null}
                {session.status == "in progress" || session.status == "live" ? "Session in Progress" : null}
              </Text>
            </View>

            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ paddingHorizontal: 30, paddingVertical: 30 }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Image source={{uri: image}} style={styles.img} />
                <View style={{ justifyContent: 'space-between', flexDirection: 'column', marginLeft: 15 }}>
                  <View>

                    {
                      !isTutor ? (
                        <Link 
                          onPress={() => {
                            setModalVisible(!modalVisible);
                          }}
                          to={`/tutors/${tutorURL}`}>
                          <Text
                          style={[
                            styles.nameTitle,
                            {
                              fontSize: 22,
                              fontFamily: defaultStyles.boldFont,
                            },
                          ]}
                          >
                          {
                            isTutor ?
                              <>
                              {session.user.name.split(" ").length > 1 ? session.user.name.split(" ")[0] + " " +  session.user.name.split(" ")[1][0]: session.user.name}
                              </>
                              : 
                              <>
                              {
                                session.tutorProfile.name.split(" ").length > 1 ? session.tutorProfile.name.split(" ")[0] + " " +  session.tutorProfile.name.split(" ")[1][0]: session.tutorProfile.name
                              }
                              </>
                          }
                          </Text>
                        </Link>
                      ) : (
                        <Link 
                          onPress={() => {
                            setModalVisible(!modalVisible);
                          }}
                          to={`/students/${studentURL}`}>
                          <Text
                          style={[
                            styles.nameTitle,
                            {
                              fontSize: 22,
                              fontFamily: defaultStyles.boldFont,
                            },
                          ]}
                          >
                          {
                            isTutor ?
                              <>
                              {session.user.name.split(" ").length > 1 ? session.user.name.split(" ")[0] + " " +  session.user.name.split(" ")[1][0]: session.user.name}
                              </>
                              : 
                              <>
                              {
                                session.tutorProfile.name.split(" ").length > 1 ? session.tutorProfile.name.split(" ")[0] + " " +  session.tutorProfile.name.split(" ")[1][0]: session.tutorProfile.name
                              }
                              </>
                          }
                          </Text>
                        </Link>
                      )
                    }

                  
                   
                  </View>
              

                  {/* Show Accept / Decline button whether pending or accepted  */}
                  <View>


                    {session.status == 'pending' ? (
                      <>
                        {
                          isTutor ? (
                            <View style={{ marginTop:5, display: 'flex', flexDirection: 'row' }}>
                              <TouchableOpacity
                                style={styles.joinButton}
                                onPress={() => {
                                  // POST to API to schedule session
                                  fetch(`${hostname}/api/schedule`, {
                                    method: 'POST',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': 'Bearer ' + user.accessToken
                                    },
                                    body: JSON.stringify({
                                      event: "start-session",
                                      date: session.date,
                                      time: session.time,
                                      data: {
                                        sessionId: session.id,
                                        uid: user.uid,
                                        length: session.minutes
                                      }
                                    })
                                  })
                                  .then(res => res.json())
                                  .then(data => {
                                    setModalVisible(false);
                                    setTimeout(() => {
                                        if (data.success) {
                                          acceptSession(session.id);
                                          {
                                            Platform.OS == 'web'
                                              ? customAlert.success('[Session Accepted] You have accepted the session.')
                                              : alert('Session accepted!');
                                          }
                                        } else {
                                          {
                                            Platform.OS == 'web'
                                              ? customAlert.error('[Session Error] Error accepting session.')
                                              : alert('Error accepting session.');
                                          }
                                        }
                                      }, 200);
                                  })
                                  .catch(err => {
                                    {
                                      Platform.OS == 'web'
                                        ? customAlert.error('[Session Error] Error accepting session.')
                                        : alert('Error accepting session.');
                                    }
                                  });

                                }}
                              >
                                <Text style={styles.buttonText}>Accept</Text>
                              </TouchableOpacity>
      
                              {/* Cancel session button */}
                              <TouchableOpacity
                                style={styles.cancelButton}
                                onPress={() => {
                                  setModalVisible(!modalVisible);
                                  confirmAlert({
                                    customUI: ({ onClose }) => {
                                      return (
                                        <CancelModal title={`Cancel session`} message={`Are you sure you would like to decline this session`} onClose={() => {{ onClose(); setModalVisible(true);}}} onAccept={() => {
                                          cancelSession(session.id);
                                          onClose();
                                          customAlert.success('[Session Cancelled] You have cancelled the session.');
                                        }}></CancelModal>
                                      );
                                    },
                                  });
                                }}
                              >
                                <Text style={styles.buttonText}>Decline</Text>
                              </TouchableOpacity>
                            </View>
                          ) : (
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                              <TouchableOpacity
                                style={styles.cancelButton}
                                onPress={() => {
                                  setModalVisible(!modalVisible);
                                  confirmAlert({
                                    customUI: ({ onClose }) => {
                                      return (
                                        <CancelModal title={`Cancel session`} message={`Are you sure you would like to cancel the session with ${session.tutorProfile.name.split(" ")[0]}?`} onClose={() => {{ onClose(); setModalVisible(true);}}} onAccept={() => {
                                          cancelSession(session.id);
                                          onClose();
                                          customAlert.success('[Session Cancelled] Session has been cancelled.');
                                        }}></CancelModal>
                                      );
                                    },
                                  });
                                }}
                              >
                                <Text style={styles.buttonText}>Cancel Request</Text>
                              </TouchableOpacity>
                            </View>
                          )
                        }
                      </>

                    ) : (


                      <View style={{ display: 'flex', flexDirection: 'row' }}>

                        {/* If session date (2023-09-11) and time (3:30 PM) is now or past now then show join button */}
                        {
                            new Date(session.date + " " + session.time) <= new Date() ? ( 
                              <TouchableOpacity style={styles.joinButton}
                              onPress={() => {
                                // Dismiss modal
                                setModalVisible(!modalVisible);
                                // navigation.navigate('Meeting', { sessionId: session.id });
                                window.location.href = `/sessions/${session.id}`;
                              }}
                            >
                              <Text style={styles.buttonText}>Join meeting</Text>
                            </TouchableOpacity>
                          ) : null
                        }



                        {/* Cancel session button */}
                        {
                          session.status === "confirmed" || session.status === "live" ? (
                            <TouchableOpacity
                              style={styles.cancelButton}
                              onPress={() => {
                                setModalVisible(!modalVisible);
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <CancelModal title={`Cancel session`} message={`Are you sure you would like to cancel the session`} onClose={() => {{ onClose(); setModalVisible(true);}}} onAccept={() => {
                                        cancelSession(session.id);
                                        onClose();
                                        customAlert.success('[Session Cancelled] Session has been cancelled.');
                                      }}></CancelModal>
                                    );
                                  },
                                });
    
                              }}
                            >
                              <Text style={styles.buttonText}>Cancel</Text>
                          </TouchableOpacity>
                          ) : null
                        }

                      </View>
                    )}
                  </View>
                  <TouchableOpacity
                        style={{
                          backgroundColor: background,
                          borderWidth: 1,
                          borderColor: primary,
                          padding: 15,
                          height:35,
                          borderRadius: defaultStyles.buttonRadius,
                          marginTop: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        onPress={() => {
                          // TODO: Message logic
                        // Check if the user has a chat with this tutor
                        // collection('chats').where('tutor', '==', tutor.uid).where('user', '==', user.uid).get()
                        const chatsRef = collection(db, 'chats');
                        const q = query(
                          chatsRef,
                          where('tutor.uid', '==', session.tutorProfile.uid),
                          where('user.uid', '==', session.user.uid)
                        );
                        getDocs(q).then((querySnapshot) => {
                          if (querySnapshot.docs.length > 0) {
                            // // Navigate to the chat
                            var hoursSinceOnline = null;
                            // Navigate to chat nested in chats navigator
                            navigation.navigate('Chats', {
                              screen: 'Chats',
                              params: {
                                chat: querySnapshot.docs[0].id,
                                lo: hoursSinceOnline,
                              },
                            });
                            setModalVisible(!modalVisible);
                            return;
                          } else {
                            // Create the chat in firestore
                            const chatsRef = collection(db, 'chats');
                            var hoursSinceOnline = null;

                            // Add Doc
                            addDoc(chatsRef, {
                              tutor: {
                                name: session.tutorProfile.name,
                                image: session.tutorProfile.picture
                                  ? session.tutorProfile.picture
                                  : null,
                                uid: session.tutorProfile.uid,
                                id: session.tutorProfile.uid,
                              },
                              user: {
                                name: name,
                                image: session.user.image,
                                uid: session.user.uid,
                                id: session.user.uid,
                              },
                              created: new Date(),
                              lastUpdated: new Date(),
                              lastMessage: {},
                            }).then((docRef) => {
                              // Chat object for navigation

                              // Navigate to the chat
                              // navigation.navigate('Chats', {screen: 'Chat', params: {chat: chatObject, hoursSinceOnline: hoursSinceOnline}} )
                              navigation.navigate('Chats', {
                                screen: 'Chats',
                                params: {
                                  chat: docRef.id,
                                  lo: hoursSinceOnline,
                                },
                              });
                              setModalVisible(!modalVisible);
                              return;
                            });
                          }
                        });
                        }}
                      >
                        <Text style={{
                          color: primary,
                          fontSize: 16,
                          fontFamily: defaultStyles.boldFont,
                        }}>
                          Message
                        </Text>
                      </TouchableOpacity>

                </View>
              </View>

              {/* Container 1 */}
              <View style={styles.infoContainer}>
                {/* Subject  */}
                <View style={[styles.marginTop, styles.infoBorder]}>
                  <Text style={styles.title}>Subject</Text>
                  <Text style={styles.pickerInfoText}>Requested subject. </Text>
                  <Text style={styles.details}>{session.subject}</Text>
                </View>

                {/* Skills  */}
                <View style={[styles.marginTop, styles.infoBorder]}>
                  <Text style={styles.title}>Skills</Text>
                  <Text style={styles.pickerInfoText}>Requested skills. </Text>
                  <Text style={styles.details}>{session.skills}</Text>
                </View>
              </View>

              {/* Container 2 */}
              <View style={styles.infoContainer}>
                {/* Date  */}
                <View style={[styles.marginTop, styles.infoBorder]}>
                  <Text style={styles.title}>Date & Time</Text>
                  <Text style={styles.pickerInfoText}>
                    Meeting time & date.
                  </Text>
                  <Text style={styles.details}>
                    {session.date} - {tConvert(session.time)}
                  </Text>
                </View>

                {/* Length  */}
                <View style={[styles.marginTop, styles.infoBorder]}>
                  <Text style={styles.title}>Length of Session</Text>
                  <Text style={styles.pickerInfoText}>
                    Approx length of session.
                  </Text>
                  <Text style={styles.details}>{session.minutes} minutes</Text>
                </View>
              </View>

              {/* Notes  */}
              <View style={[styles.marginTop, styles.infoBorder]}>
                <Text style={styles.title}>Notes</Text>
                <Text style={styles.pickerInfoText}>
                  Description of session.
                </Text>
                <Text style={styles.details}>
                  {(session.note = null ? 'No note...' : session.note)}
                </Text>
              </View>

              <View>
                {/* <Text style={styles.id}>{`SessionId-${session.id}`}</Text> */}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  );
};
