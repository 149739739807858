const schools = [
    "University of British Columbia",
    "University of Calgary",
    "University of Alberta",
    "University of Saskatchewan",
    "University of Manitoba",
    "University of Toronto",
    "McGill University",
    "Université de Montréal",
    "University of Ottawa",
    "Queen's University",
    "Western University",
    "University of Waterloo",
    "Wilfrid Laurier University",
    "University of Windsor",
    "Dalhousie University",
    "Memorial University of Newfoundland",
    "Mount Allison University",
    "University of New Brunswick",
    "Acadia University",
    "Bishop's University",
    "St. Francis Xavier University",
    "University of Prince Edward Island",
    "Université Sainte-Anne",
    "Carleton University",
    "Concordia University",
    "Université Laval",
    "University of Regina",
    "Simon Fraser University",
    "University of Victoria",
    "University of Manitoba",
    "George Brown College",
    "Seneca College",
    "Humber College",
    "Fanshawe College",
    "Centennial College",
    "Algonquin College",
    "Conestoga College",
    "Sheridan College",
    "Durham College",
    "Fleming College",
    "Niagara College",
    "Collège Boréal",
    "Collège communautaire du Nouveau-Brunswick",
    "Collège La Cité",
    "Collège Lionel-Groulx",
    "Cégep de Sainte-Foy",
    "Cégep de l'Outaouais",
    "Cégep du Vieux Montréal",
    "Harvard University",
    "Stanford University",
    "Massachusetts Institute of Technology",
    "California Institute of Technology",
    "University of Chicago",
    "Princeton University",
    "Columbia University",
    "Yale University",
    "University of Pennsylvania",
    "Cornell University",
    "Johns Hopkins University",
    "University of California, Berkeley",
    "University of Michigan",
    "Northwestern University",
    "Duke University",
    "University of Wisconsin-Madison",
    "University of Washington",
    "University of Texas at Austin",
    "University of Illinois",
    "University of North Carolina",
    "University of California, Davis",
    "University of Minnesota",
    "University of Colorado Boulder",
    "University of California, Irvine",
    "University of Pittsburgh",
    "University of Maryland",
    "University of Massachusetts",
    "University of Virginia",
    "Ohio State University",
    "Rutgers University",
    "Pennsylvania State University",
    "University of Florida",
    "University of Southern California",
    "University of Arizona",
    "University of Iowa",
    "University of Utah",
    "Indiana University Bloomington",
    "University of Oregon",
    "University of Georgia",
    "University of Tennessee",
    "Michigan State University",
    "University of Alabama",
    "University of Kentucky",
    "University of South Carolina",
    "University of Connecticut",
    "University of New Hampshire",
    "University of Delaware",
    "University of Vermont"

]

module.exports = {
    schools
}