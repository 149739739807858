//Package imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Picker } from "@react-native-picker/picker";
import {
	Platform,
	Appearance,
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	ScrollView,
	Modal,
	Image,
	TextInput,
	Alert,
	ActivityIndicator,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { CloseOutlined } from "@mui/icons-material";
import {
	getFirestore,
	collection,
	updateDoc,
	doc,
	onSnapshot,
	limit,
	orderBy,
	addDoc,
	where,
	query,
	getDocs,
	getDoc,
	serverTimestamp,
} from "firebase/firestore";
import { FontAwesome } from "@expo/vector-icons";
import { useAlert } from "react-alert";
import { subjects } from "../config/subjects";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { CustomTimeInput } from "./CustomTimeInput";

// File imports
import { useColorsContext } from "../utils/styles";
import { useStyles } from "../utils/styles";
import { useSessionContext } from "../contexts/sessionContext";
import { useUserContext } from "../contexts/userContext";
import { useFirebaseContext } from "../contexts/firebaseContext";
// const dev = false;
const dev = false;

const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";
const times = [
	"12:00 AM",
	"12:30 AM",
	"1:00 AM",
	"1:30 AM",
	"2:00 AM",
	"2:30 AM",
	"3:00 AM",
	"3:30 AM",
	"4:00 AM",
	"4:30 AM",
	"5:00 AM",
	"5:30 AM",
	"6:00 AM",
	"6:30 AM",
	"7:00 AM",
	"7:30 AM",
	"8:00 AM",
	"8:30 AM",
	"9:00 AM",
	"9:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
	"11:30 AM",
	"12:00 PM",
	"12:30 PM",
	"1:00 PM",
	"1:30 PM",
	"2:00 PM",
	"2:30 PM",
	"3:00 PM",
	"3:30 PM",
	"4:00 PM",
	"4:30 PM",
	"5:00 PM",
	"5:30 PM",
	"6:00 PM",
	"6:30 PM",
	"7:00 PM",
	"7:30 PM",
	"8:00 PM",
	"8:30 PM",
	"9:00 PM",
	"9:30 PM",
	"10:00 PM",
	"10:30 PM",
	"11:00 PM",
	"11:30 PM",
];

const timeToMinutes = (timeString) => {
	const [time, period] = timeString.split(" ");
	const [hours, minutes] = time.split(":");
	let convertedHours = parseInt(hours);
	const convertedMinutes = parseInt(minutes);

	if (period === "PM" && convertedHours !== 12) {
		convertedHours += 12;
	}

	return convertedHours * 60 + convertedMinutes;
};

// Tutor Review component
export const BookSessionModal = ({
	modalVisible,
	setModalVisible,
	tutor,
	navigation,
}) => {
	// State
	const [tSubjects, setTSubjects] = useState([]);
	const [tSkills, setTSkills] = useState([]);
	const [autoAccept, setAutoAccept] = useState(false);
	const customAlert = useAlert();
	const [loading, setLoading] = useState(false);

	// State
	const [selectedSubject, setSelectedSubject] = useState("");
	const [selectedSkills, setSelectedSkills] = useState("");
	const [note, setNote] = useState("");
	const [minutes, setMinutes] = useState(30);
	const [date, setDate] = useState(null);
	const [time, setTime] = useState("");
	const [error, setError] = useState(null);

	// Context
	const { createSession } = useSessionContext();
	const { user, customerId, name, profile } = useUserContext();
	const { db } = useFirebaseContext();

	useEffect(() => {
		if (selectedSubject && selectedSubject != "") {
			let loadedSkills = [];
			let subjectSkills = subjects[selectedSubject];
			subjectSkills.forEach((skill) => {
				loadedSkills.push(skill);
			});
			setTSkills(loadedSkills);
		}
	}, [selectedSubject]);

	// Get tutors subjects / skills
	const getTutorSubjectAndSkills = (id) => {
		const q = query(
			collection(db, "users"),
			where("id", "==", id),
			where("isTutor", "==", true)
		);

		const unsubscribe = onSnapshot(q, (snapshot) =>
			snapshot.docs.map((doc) => {
				var subjects = [];
				Object.keys(doc.data().subjects).forEach((key) => {
					let value = Object.keys(doc.data().subjects)[key];
					subjects.push(key);
				});

				setTSubjects(subjects);
				setSelectedSubject(subjects[0]);
				// Set skills as all skills for subject
				setSelectedSkills(doc.data().skills[0]);
				// setTSkills(doc.data().skills);
				setAutoAccept(doc.data().autoAccept || false);
			})
		);

		return unsubscribe;
	};

	useEffect(() => {
		if (tutor) {
			getTutorSubjectAndSkills(tutor.id);
		}
	}, [tutor, modalVisible]);

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Reset values
	const resetValues = () => {
		setLoading(false);
		setSelectedSubject(tSubjects[0]);
		setSelectedSkills(tSkills[0]);
		setNote("");
		setMinutes(30);
		setDate(null);
		setTime("");
	};

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Data
	const meetingLength = [
		{ value: 30, label: "30 minutes" },
		{ value: 60, label: "1 hour" },
		{ value: 90, label: "1.5 hours" },
		{ value: 120, label: "2 hours" },
		{ value: 150, label: "2.5 hours" },
		{ value: 180, label: "3 hours" },
		{ value: 210, label: "3.5 hours" },
		{ value: 240, label: "4 hours" },
		{ value: 270, label: "4.5 hours" },
		{ value: 300, label: "5 hours" },
	];

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			padding: Platform.OS === "web" ? 30 : null,

			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},
		bigTitle: {
			color: text,
			fontSize: 36,
			fontFamily: defaultStyles.titleFont,
		},
		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		layoutGrid: {
			marginTop: 60,
			display: !isLg ? "row" : "grid",
			gridTemplateColumns: "repeat(2, 1fr)",
			gridTemplateRows: "1fr",
			gridColumnGap: 50,
			gridRowGap: 0,
		},
		colOne: {
			gridArea: 1 / 1 / 2 / 2,
			color: text,
		},
		colTwo: {
			gridArea: 1 / 2 / 2 / 3,
			color: text,
			display: "flex",
			paddingLeft: isLg ? 30 : null,
			marginTop: !isLg ? 30 : 0,
		},
		welcome: {
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
		},
		name: {
			color: primary,
		},
		icon: {
			width: 50,
			height: 50,
			borderRadius: defaultStyles.radius,
			backgroundColor: "transparent",
			marginRight: 15,
			justifyContent: "center",
			alignItems: "center",
			marginVertical: "auto",
			marginLeft: 5,
		},
		statsGrid: {
			display: "flex",
			flexDirection: isMd ? "row" : "column",
			width: "100%",
			backgroundColor: background,
			border: "1px solid " + border,
			borderRadius: defaultStyles.radius,
			padding: 30,
			marginTop: 30,
		},
		statsTitle: {
			color: textVariant,
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
		},

		statsIcon: {
			width: 60,
			height: 60,
			borderRadius: defaultStyles.radius,
			backgroundColor: backgroundVariant,
			marginRight: 10,
			justifyContent: "center",
			alignItems: "center",
		},
		statsText: {
			color: text,
			fontFamily: defaultStyles.boldFont,
			fontSize: 22,
		},
		id: {
			fontSize: 12,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
		},

		// modal
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "40%"
				: isLg
				? "50%"
				: isMd
				? "60%"
				: isSm
				? "70%"
				: "80%",
			height: "90%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			zIndex: 2,
		},
		modalHeader: {
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			position: "absolute",
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
			marginLeft: 35,
		},
		circleButton: {
			width: 30,
			height: 30,
			borderRadius: defaultStyles.radius,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			marginVertical: 10,
			marginRight: 15,
		},
		bookButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 35,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
			marginTop: 10,
		},
		nameTitle: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			backgroundColor: primary,
		},

		img: {
			width: 75,
			height: 75,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
		},
		details: {
			fontSize: 16,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
		noteInput: {
			height: 40,
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	const CancelModal = ({ title, message, onClose, onAccept }) => {
		return (
			<View
				style={{
					width: 450,
					backgroundColor: background,
					borderRadius: defaultStyles.radius,
					padding: 15,
					border: "1px solid" + border,
				}}
			>
				<Text
					style={{
						color: text,
						fontSize: 19,
						fontFamily: defaultStyles.titleFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{title}
				</Text>

				<Text
					style={{
						color: text,
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{message}
				</Text>

				<View
					style={{
						flexDirection: "row",
						justifyContent: "center",
						marginTop: 30,
					}}
				>
					<TouchableOpacity
						style={{
							backgroundColor: border,
							width: 100,
							height: 40,
							marginLeft: 5,
							borderRadius: defaultStyles.buttonRadius,
							justifyContent: "center",
							alignItems: "center",
						}}
						onPress={() => {
							onClose();
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
							}}
						>
							Dismiss
						</Text>
					</TouchableOpacity>

					<TouchableOpacity
						style={{
							backgroundColor: primary,
							height: 40,
							marginLeft: 5,
							paddingLeft: 10,
							paddingRight: 10,
							borderRadius: defaultStyles.buttonRadius,
							justifyContent: "center",
							alignItems: "center",
						}}
						onPress={() => {
							onAccept();
						}}
					>
						<Text
							style={{
								color: "#fff",
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
							}}
						>
							Take me there
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	return (
		<View>
			<Modal
				key={1}
				animationType="fade"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					setModalVisible(!modalVisible);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							{/* Close filters button */}
							<TouchableOpacity
								style={styles.closeButton}
								onPress={() => {
									setModalVisible(!modalVisible);
								}}
							>
								<CloseOutlined
									style={{ fontSize: 22, color: text }}
								></CloseOutlined>
							</TouchableOpacity>
							{/* Title */}
							<Text style={styles.modalTitle}>
								Schedule Session
							</Text>

							{/* Show Accept / Decline button whether pending or accepted  */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{/* Book session button */}
								<TouchableOpacity
									style={styles.bookButton}
									onPress={() => {
										if (
											date == null ||
											!time ||
											time == ""
										) {
											// Dismiss modal
											// setModalVisible(!modalVisible);
											setLoading(false);
											setError(
												"Please chose a time and date."
											);
											// {
											//   Platform.OS == 'web'
											//     ? customAlert.error('[Booking Error] Please chose a time and date.')
											//     : Alert.alert('Error booking tutor',  'Please chose a time and date.', [
											//       { text: 'OK', onPress: () => console.log('OK Pressed') },
											//     ])
											// }
											return;
										} else {
											// TODO: Check tutors availability and match with time
											console.log(tutor.alwaysAvailable);
											if (!tutor.alwaysAvailable) {
												// Check day of the week (monday, tuesday, etc)
												let day = new Date(
													date
												).getDay();
												let dayOfWeek = "";
												switch (day) {
													case 0:
														dayOfWeek = "monday";
														break;
													case 1:
														dayOfWeek = "tuesday";
														break;
													case 2:
														dayOfWeek = "wednesday";
														break;
													case 3:
														dayOfWeek = "thursday";
														break;
													case 4:
														dayOfWeek = "friday";
														break;
													case 5:
														dayOfWeek = "saturday";
														break;
													case 6:
														dayOfWeek = "sunday";
														break;

													default:
														break;
												}

												// Check if tutor is available on that day
												if (
													!tutor.availability[
														dayOfWeek
													] ||
													!tutor.availability[
														dayOfWeek
													].start ||
													!tutor.availability[
														dayOfWeek
													].end
												) {
													setError(
														"Please book a time when the tutor is available."
													);
													setLoading(false);
													return;
												}
												let startTime =
													tutor.availability[
														dayOfWeek
													].start;
												let endTime =
													tutor.availability[
														dayOfWeek
													].end;

												// Check if time is between start and end time
												if (
													timeToMinutes(time) <
														timeToMinutes(
															startTime
														) ||
													timeToMinutes(time) >
														timeToMinutes(endTime)
												) {
													setError(
														"Please book a time when the tutor is available."
													);
													setLoading(false);
													return;
												}

												// Check if endTime is before startTime + minutes
												if (
													timeToMinutes(endTime) <
													timeToMinutes(time) +
														parseInt(minutes)
												) {
													setError(
														"Please book a time when the tutor is available."
													);
													setLoading(false);
													return;
												}
											}

											// Set button to loading
											setLoading(true);

											// Calculate the amount of hours booked for and multiply by rate

											// SEND Authorization request to server (if failed means no card...)
											fetch(
												`${hostname}/api/authorize-charge`,
												{
													method: "POST",
													headers: {
														"Content-Type":
															"application/json",
														Authorization:
															"Bearer " +
															user.accessToken,
													},
													body: JSON.stringify({
														amount: tutor.rate,
														customerId: customerId,
														accountId:
															tutor.stripe
																.accountId,
													}),
												}
											)
												.then((res) => res.json())
												.then((data) => {
													if (data.success) {
														let paymentIntentId =
															data.paymentIntentId;
														// If yes create session
														createSession(
															note,
															selectedSubject,
															date,
															time,
															selectedSkills,
															profile,
															name,
															user.uid,
															minutes,
															tutor.id,
															tutor,
															paymentIntentId,
															customerId,
															autoAccept
														);
														resetValues();
														setModalVisible(
															!modalVisible
														);
														// TODO: Hit EMAIL API
														fetch(
															`${hostname}/api/email/session`,
															{
																method: "POST",
																headers: {
																	"Content-Type":
																		"application/json",
																	Authorization:
																		"Bearer " +
																		user.accessToken,
																},
																body: JSON.stringify(
																	{
																		email: tutor.email,
																		other: name,
																		name: tutor.name.split(
																			" "
																		)[0],
																	}
																),
															}
														);

														{
															Platform.OS == "web"
																? customAlert.success(
																		"[Session Booked] Thank you for booking a session!"
																  )
																: alert(
																		"Session Booked! See you soon."
																  );
														}
													} else {
														// If no show error
														setModalVisible(
															!modalVisible
														);
														setLoading(false);

														confirmAlert({
															customUI: ({
																onClose,
															}) => {
																return (
																	<CancelModal
																		onClose={() => {
																			onClose();
																		}}
																		title="Failed to Book Tutor"
																		message={
																			"In order to book a tutor, you must add a payment method to your account, please click the button below to add a payment method you will be redirected to the settings page. Once you have added a payment method, please try booking the tutor again."
																		}
																		onAccept={() => {
																			onClose();
																			// Navigate to profile
																			// navigation.navigate('Home', { screen: 'Profile' });
																			navigation.navigate(
																				"Home",
																				{
																					screen: "Profile",
																				}
																			);
																		}}
																	></CancelModal>
																);
															},
														});

														// {
														//   Platform.OS == 'web'
														//     ? customAlert.error(data.error)
														//     : Alert.alert('Error booking tutor',  data.error, [
														//       { text: 'OK', onPress: () => console.log('OK Pressed') },
														//     ])
														// }
													}
												})
												.catch((err) => {
													setModalVisible(
														!modalVisible
													);
													setLoading(false);
													{
														Platform.OS == "web"
															? customAlert.error(
																	"Error booking tutor, please try again!"
															  )
															: Alert.alert(
																	"Error booking tutor",
																	"Please ensure you have a payment method set",
																	[
																		{
																			text: "OK",
																			onPress:
																				() =>
																					console.log(
																						"OK Pressed"
																					),
																		},
																	]
															  );
													}
												});
										}
									}}
								>
									<Text
										disabled={loading ? true : false}
										style={[
											styles.buttonText,
											{
												opacity: loading ? 0.5 : 1,
											},
										]}
									>
										{loading ? (
											<ActivityIndicator
												size="small"
												color="#fff"
											/>
										) : (
											"Book Session"
										)}
									</Text>
								</TouchableOpacity>
							</View>
						</View>

						<ScrollView
							showsVerticalScrollIndicator={false}
							style={{
								paddingHorizontal: 20,
								paddingVertical: 15,
							}}
						>
							{/* Subject  */}
							<View style={styles.marginTop}>
								{error ? (
									<Text
										style={[
											styles.pickerInfoText,
											{
												marginBottom: 5,
												color: "#FF0000",
											},
										]}
									>
										{error}
									</Text>
								) : (
									<Text
										style={[
											styles.pickerInfoText,
											{ marginBottom: 5 },
										]}
									>
										Your payment will not be processed until
										the session has been completed.
									</Text>
								)}
								<Text style={styles.title}>Subject</Text>
								<Text style={styles.pickerInfoText}>
									Chose the subject you need help with.
								</Text>
								<Picker
									style={styles.picker}
									value={selectedSubject}
									onValueChange={(itemValue, itemIndex) => {
										setSelectedSubject(itemValue);
									}}
								>
									{tSubjects.map((item, index) => (
										<Picker.Item
											label={item}
											value={item}
										/>
									))}
								</Picker>
							</View>

							{/* Skills  */}
							<View style={styles.marginTop}>
								<Text style={styles.title}>Skills</Text>
								<Text style={styles.pickerInfoText}>
									Chose the skill that best describes what you
									need help with.
								</Text>
								<Picker
									style={styles.picker}
									selectedValue={selectedSkills}
									onValueChange={(itemValue, itemIndex) =>
										setSelectedSkills(itemValue)
									}
								>
									{tSkills.map((item, i) => (
										<Picker.Item
											label={tSkills[i]}
											value={tSkills[i]}
										/>
									))}
								</Picker>
							</View>

							{/* Minutes  */}
							<View style={styles.marginTop}>
								<Text style={styles.title}>Length</Text>
								<Text style={styles.pickerInfoText}>
									Length of meeting.{" "}
								</Text>
								<Picker
									style={styles.picker}
									selectedValue={minutes}
									onValueChange={(itemValue, itemIndex) =>
										setMinutes(itemValue)
									}
								>
									{meetingLength.map((item, i) => (
										<Picker.Item
											label={meetingLength[i].label}
											value={meetingLength[i].value}
										/>
									))}
								</Picker>
							</View>

							{/* Date  */}
							<View style={styles.marginTop}>
								<Text style={styles.title}>Date</Text>
								<Text style={styles.pickerInfoText}>
									Chose in your desired date.
								</Text>
								{Platform.OS == "web" ? (
									<input
										type="date"
										max="2023-12-31"
										min={new Date()
											.toISOString()
											.slice(0, 10)}
										style={{
											colorScheme: darkMode
												? "dark"
												: "light",
											marginTop: 10,
											borderRadius: defaultStyles.radius,
											fontFamily: defaultStyles.boldFont,
											paddingTop: 10,
											paddingBottom: 10,
											paddingLeft: 15,
											paddingRight: 15,
											color: text,
											backgroundColor: backgroundVariant,
											border: "1px solid " + border,
										}}
										onChange={(e) => {
											setDate(e.target.value);
										}}
										// min={new Date().toISOString().slice(0, 10)}
									></input>
								) : (
									<Text style={{ color: text }}>
										Coming soon...
									</Text>
								)}
							</View>

							{/* Time  */}
							<View style={styles.marginTop}>
								<Text style={styles.title}>Time</Text>
								<Text style={styles.pickerInfoText}>
									Type in your desired time.
								</Text>

								{Platform.OS == "web" ? (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<select
											style={{
												flex: 1,
												colorScheme: darkMode
													? "dark"
													: "light",
												marginTop: 10,
												borderRadius:
													defaultStyles.radius,
												fontFamily:
													defaultStyles.boldFont,
												paddingTop: 10,
												paddingBottom: 10,
												paddingLeft: 15,
												paddingRight: 15,
												color: text,
												backgroundColor:
													backgroundVariant,
												border: "1px solid " + border,
											}}
											// value={}
											onChange={(e) => {
												setTime(e.target.value);
											}}
											value={time}
										>
											<option value="" selected disabled>
												Select a time
											</option>

											{times.map((timeValue, index) => {
												return (
													<option value={timeValue}>
														{timeValue}
													</option>
												);
											})}
										</select>
									</View>
								) : (
									// <input
									//   type='time'

									//   style={{
									//     colorScheme: darkMode ? 'dark' : 'light',
									//     marginTop: 10,
									//     borderRadius: defaultStyles.radius,
									//     fontFamily: defaultStyles.boldFont,
									//     paddingTop: 10,
									//     paddingBottom: 10,
									//     paddingLeft: 15,
									//     paddingRight: 15,
									//     color: text,
									//     backgroundColor: backgroundVariant,
									//     border: '1px solid ' + border,
									//   }}
									//   onChange={(e) => {
									//     setTime(e.target.value);
									//   }}
									// ></input>

									<Text style={{ color: text }}>
										Coming soon...
									</Text>
								)}
							</View>

							{/* Note  */}
							<View style={styles.marginTop}>
								<Text style={styles.title}>Note</Text>
								<Text style={styles.pickerInfoText}>
									Leave a brief 2 sentence note for your
									tutor.
								</Text>
								<TextInput
									onChangeText={(e) => setNote(e)}
									placeholder="I need help with..."
									style={styles.noteInput}
									inputMode="text"
									placeholderTextColor={textVariant}
								/>
							</View>
						</ScrollView>
					</View>
				</View>
			</Modal>
		</View>
	);
};
