// Home Screen for logged in tutor
import { AntDesign, Feather } from '@expo/vector-icons';
import { Link } from '@react-navigation/native';
import NumericTextInput from '@wwdrew/react-native-numeric-textinput';
import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Picker, Platform, SafeAreaView, ScrollView, StyleSheet, Switch, Text, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { Header as AuthHeader } from '../../../components/AuthHeader';
import Footer from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { LoadingPage } from '../../../components/LoadingPage';
import { useFirebaseContext } from '../../../contexts/firebaseContext';
import { useUserContext } from '../../../contexts/userContext';
import { useColorsContext, useStyles } from '../../../utils/styles';
import RNPickerSelect from 'react-native-picker-select';
// import { CustomTimeInput } from '../../../components/CustomTimeInput';

// Firebase
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';

const times = [
    '12:00 AM', '12:30 AM',
    '1:00 AM', '1:30 AM',
    '2:00 AM', '2:30 AM',
    '3:00 AM', '3:30 AM',
    '4:00 AM', '4:30 AM',
    '5:00 AM', '5:30 AM',
    '6:00 AM', '6:30 AM',
    '7:00 AM', '7:30 AM',
    '8:00 AM', '8:30 AM',
    '9:00 AM', '9:30 AM',
    '10:00 AM', '10:30 AM',
    '11:00 AM', '11:30 AM',
    '12:00 PM', '12:30 PM',
    '1:00 PM', '1:30 PM',
    '2:00 PM', '2:30 PM',
    '3:00 PM', '3:30 PM',
    '4:00 PM', '4:30 PM',
    '5:00 PM', '5:30 PM',
    '6:00 PM', '6:30 PM',
    '7:00 PM', '7:30 PM',
    '8:00 PM', '8:30 PM',
    '9:00 PM', '9:30 PM',
    '10:00 PM', '10:30 PM',
    '11:00 PM', '11:30 PM'
]

const advanceNoticeOptions = [
    { label: '5 mins', value: '5 mins' },
    { label: '10 mins', value: '10 mins' },
    { label: '25 mins', value: '25 mins' },
    { label: '1 hr', value: '1 hr' },
    { label: '2 hrs', value: '2 hrs' },
];

const bufferPeriodOptions = [
    { label: '15 mins', value: '15 mins' },
    { label: '30 mins', value: '30 mins' },
    { label: '1 hr', value: '1 hr' },
    { label: '2 hrs', value: '2 hrs' },
];

const timeToMinutes = (timeString) => {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':');
    let convertedHours = parseInt(hours);
    const convertedMinutes = parseInt(minutes);
  
    if (period === 'PM' && convertedHours !== 12) {
      convertedHours += 12;
    }
  
    return convertedHours * 60 + convertedMinutes;
  }

const dev = false;
const hostname = dev ? 'https://tutredstage-266226951372.herokuapp.com' : 'https://tutred-10235aff3fe9.herokuapp.com';


const CustomTimeInput = ({
    time, 
    setTime
}) => {
    const [fullTime, setFullTime] = React.useState(time);

    // Colors and styles
    const {  darkMode, primary, primaryVariant, red, redVariant, background, backgroundVariant, text, textVariant, border, yellow, green } = useColorsContext();
    const defaultStyles = useStyles();

    // Responsive Breakpoints
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMd = useMediaQuery({ query: '(min-width: 768px)' });
    const isSm = useMediaQuery({ query: '(min-width: 640px)' });


    useEffect(() => {
        if (fullTime) {
            saveTime();
        }
    }, [fullTime])

    const saveTime = () => {
        setTime(fullTime);
    }

    return (
        <select
        style={{
                flex:1,
                colorScheme: darkMode ? 'dark' : 'light',
                borderRadius: defaultStyles.radius,
                fontFamily: defaultStyles.boldFont,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 15,
                paddingRight: 15,
                color: text,
                backgroundColor: backgroundVariant,
                border: '1px solid ' + border,
                fontSize:16
            }}
            onChange={(e) => {
                setFullTime(e.target.value)
            }}
            value={fullTime}
            >
            <option value='' selected disabled>Select a time</option>
            {
                times.map((timeO, index) => {
                    return (
                        <option value={timeO}>{timeO}</option>
                    )
                })
            }

        </select>
    )
}

// Account Screen
export const SessionSettings = ({navigation}) => {
    const { user, rate, autoAccept, availability, alwaysAvailable, unavailable} = useUserContext();

    const customAlert = useAlert();
    const { db } = useFirebaseContext();

    // User Context
    const auth = getAuth();
    const [loading, setLoading] = useState(true);
    const [localRate, setLocalRate] = useState(rate);
    const [userAvailability, setUserAvailability] = useState(availability ? availability : {
        monday: {
          start: null,
          end: null,
        },
        tuesday: {
          start: null,
          end: null,
        },
        wednesday: {
          start: null,
          end: null,
        },
        thursday: {
          start: null,
          end: null,
        },
        friday: {
          start: null,
          end: null,
        },
        saturday: {
          start: null,
          end: null,
        },
        sunday: {
          start: null,
          end: null,
        }
    });
    const [localAvailabilitySetting, setLocalAvailabilitySetting] = useState(alwaysAvailable ? 'always' : unavailable ? 'not' : 'custom');

    const [showMonday, setShowMonday] = useState(false);
    const [showTuesday, setShowTuesday] = useState(false);
    const [showWednesday, setShowWednesday] = useState(false);
    const [showThursday, setShowThursday] = useState(false);
    const [showFriday, setShowFriday] = useState(false);
    const [showSaturday, setShowSaturday] = useState(false);
    const [showSunday, setShowSunday] = useState(false);

    const [monday, setMonday] = useState({
    start: null,
    end: null,
    });
    
      const [tuesday, setTuesday] = useState({
        start: null,
        end: null,
      });
      const [wednesday, setWednesday] = useState({
        start: null,
        end: null,
      });
      const [thursday, setThursday] = useState({
        start: null,
        end: null,
      });
      const [friday, setFriday] = useState({
        start: null,
        end: null,
      });
    
      const [saturday, setSaturday] = useState({
        start: null,
        end: null,
      });
      const [sunday, setSunday] = useState({
        start: null,
        end: null,
      });
    

    // Colors and styles
    const {  primary, primaryVariant, red, redVariant, background, backgroundVariant, text, textVariant, border, yellow, green,toggleDarkMode, darkMode, } = useColorsContext();
    const defaultStyles = useStyles();

    // Responsive Breakpoints
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMd = useMediaQuery({ query: '(min-width: 768px)' });
    const isSm = useMediaQuery({ query: '(min-width: 640px)' });

    const [advanceNotice, setAdvanceNotice] = useState('1 hr');
	const [bufferPeriod, setBufferPeriod] = useState('30 mins');

    // Create stylesheet
    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1
        },
        maxWidth: {
            flex:1,
            backgroundColor: background,
            display: "flex",
            flexDirection: Platform.OS === "web" ? "row" : null,
            paddingRight:0, // Padding right is for the cover photo to stretch
            width:"100%",
            // maxWidth: Platform.OS === "web" ? 1350 : null,
            margin: Platform.OS === "web" ? "auto" : null,
        },
        mainSection: {
            flex:1,
            padding: isSm ? 45: 15,
            paddingTop:30
        },
        title: {
            fontSize: 36,
            fontFamily: defaultStyles.titleFont,
            color: text,
        }, 
        subtitle: {
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },
        description: {
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            color: textVariant
        },
        infoTitle: {
            textAlign:'left',
            fontSize: 22,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },
        infoSubtitle: {
            fontSize: 19,
            fontFamily: defaultStyles.boldFont,
            color: textVariant
        },
        infoDescription: {
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            color: textVariant,
            marginTop: 5,
            display: "flex",
            flex: 1,
        },
        infoLink: {
            fontSize: 14,
            fontFamily: defaultStyles.regularFont,
            color: primary,
            marginTop: 10
        },
        picker: {
            height: 50, 
            // width: 100, 
            color: text,
            borderRadius: defaultStyles.radius,
            fontFamily: defaultStyles.boldFont,
            paddingLeft: 15,
            paddingRight: 15,
            color: text,
            backgroundColor: backgroundVariant,
            border: '1px solid ' + border,
        },
        details: {
            fontSize: 16,
            color: text,
            fontFamily: defaultStyles.regularFont,
          },
    });


    useEffect(() => {
        if (user) {
            setLoading(false);
        }
    }, [user]);


    useEffect(() => {
        if (userAvailability) {
            if (userAvailability.monday && userAvailability.monday.start && userAvailability.monday.end) {
                setMonday({
                    start: userAvailability.monday.start,
                    end: userAvailability.monday.end
                });
                setShowMonday(true);
            }
            if (userAvailability.tuesday && userAvailability.tuesday.start && userAvailability.tuesday.end) {
                setTuesday({
                    start: userAvailability.tuesday.start,
                    end: userAvailability.tuesday.end
                });
                setShowTuesday(true);
            }
            if (userAvailability.wednesday && userAvailability.wednesday.start && userAvailability.wednesday.end) {
                setWednesday({
                    start: userAvailability.wednesday.start,
                    end: userAvailability.wednesday.end
                });
                setShowWednesday(true);
            }
            if (userAvailability.thursday && userAvailability.thursday.start && userAvailability.thursday.end) {
                setThursday({
                    start: userAvailability.thursday.start,
                    end: userAvailability.thursday.end
                });
                setShowThursday(true);
            }
            if (userAvailability.friday && userAvailability.friday.start && userAvailability.friday.end) {
                setFriday({
                    start: userAvailability.friday.start,
                    end: userAvailability.friday.end
                });
                setShowFriday(true);
            }
            if (userAvailability.saturday && userAvailability.saturday.start && userAvailability.saturday.end) {
                setSaturday({
                    start: userAvailability.saturday.start,
                    end: userAvailability.saturday.end
                });
                setShowSaturday(true);
            }
            if (userAvailability.sunday && userAvailability.sunday.start && userAvailability.sunday.end) {
                setSunday({
                    start: userAvailability.sunday.start,
                    end: userAvailability.sunday.end
                });
                setShowSunday(true);
            }
        }
    }, [userAvailability]);


    const updateSession = async () => {
      if (localRate > 1000) {
        customAlert.error('[Edit Profile Error] Please enter a rate less than $1000!');
        return;
      }
      if (localRate < 0) {
        customAlert.error('[Edit Profile Error] Please enter a rate greater than $0!');
        return;
      }

      var availability = {
        monday: {
          start: null,
          end: null,
        },
        tuesday: {
          start: null,
          end: null,
        },
        wednesday: {
          start: null,
          end: null,
        },
        thursday: {
          start: null,
          end: null,
        },
        friday: {
          start: null,
          end: null,
        },
        saturday: {
          start: null,
          end: null,
        },
        sunday: {
          start: null,
          end: null,
        }
      };
  
      // Validate availability dates
      if (localAvailabilitySetting === 'custom') {
        if (showMonday) {
          if (!monday.start || !monday.end) {
            customAlert.error('[Edit Profile Error] Please enter a start and end time for Monday!');
            return;
          }
    
          // Check if start time is before end time
          if (timeToMinutes(monday.start) >= timeToMinutes(monday.end)) {
            customAlert.error('[Edit Profile Error] Monday start time must be before end time!');
            return;
          }
    
          availability.monday.start = monday.start;
          availability.monday.end = monday.end;
        }
    
        if (showTuesday) {
          if (!tuesday.start || !tuesday.end) {
            customAlert.error('[Edit Profile Error] Please enter a start and end time for Tuesday!');
            return;
          }
    
          // Check if start time is before end time
          if (timeToMinutes(tuesday.start) >= timeToMinutes(tuesday.end)) {
            customAlert.error('[Edit Profile Error] Tuesday start time must be before end time!');
            return;
          }
          
          availability.tuesday.start = tuesday.start;
          availability.tuesday.end = tuesday.end;
        }
        
        if (showWednesday) {
          if (!wednesday.start || !wednesday.end) {
            customAlert.error('[Edit Profile Error] Please enter a start and end time for Wednesday!');
            return;
          }
    
          // Check if start time is before end time
          if (timeToMinutes(wednesday.start) >= timeToMinutes(wednesday.end)) {
            customAlert.error('[Edit Profile Error] Wednesday start time must be before end time!');
            return;
          }
          
          availability.wednesday.start = wednesday.start;
          availability.wednesday.end = wednesday.end;
        }
    
        if (showThursday) {
          if (!thursday.start  || !thursday.end) {
            customAlert.error('[Edit Profile Error] Please enter a start and end time for Thursday!');
            return;
          }
    
          // Check if start time is before end time
          if (timeToMinutes(thursday.start) >= timeToMinutes(thursday.end)) {
            customAlert.error('[Edit Profile Error] Thursday start time must be before end time!');
            return;
          }
          
          availability.thursday.start = thursday.start;
          availability.thursday.end = thursday.end;
        }
        
        if (showFriday) {
          if (!friday.start || !friday.end) {
            customAlert.error('[Edit Profile Error] Please enter a start and end time for Friday!');
            return;
          }
    
          // Check if start time is before end time
          if (timeToMinutes(friday.start) >= timeToMinutes(friday.end)) {
            customAlert.error('[Edit Profile Error] Friday start time must be before end time!');
            return;
          }
          
          availability.friday.start = friday.start;
          availability.friday.end = friday.end;
        }
    
    
        if (showSaturday) {
    
            
          if (!saturday.start || !saturday.end) {
            customAlert.error('[Edit Profile Error] Please enter a start and end time for Saturday!');
            return;
          }
    
          // Check if start time is before end time
          if (timeToMinutes(saturday.start) >= timeToMinutes(saturday.end)) {
            customAlert.error('[Edit Profile Error] Saturday start time must be before end time!');
            return;
          }
          
          availability.saturday.start = saturday.start;
          availability.saturday.end = saturday.end;
    
        }
    
    
        if (showSunday) {
          if (!sunday.start || !sunday.end) {
            customAlert.error('[Edit Profile Error] Please enter a start and end time for Sunday!');
            return;
          }
    
          // Check if start time is before end time
          if (timeToMinutes(sunday.start) >= timeToMinutes(sunday.end)) {
            customAlert.error('[Edit Profile Error] Sunday start time must be before end time!');
            return;
          }
          
          availability.sunday.start = sunday.start;
          availability.sunday.end = sunday.end;
        }
      }
     
      const userRef = collection(db, 'users');
      const q = query(userRef, where('uid', '==', user.uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, {
          rate: localRate,
          availability: availability,
          alwaysAvailable: localAvailabilitySetting === 'always' ? true : false,
          unavailable: localAvailabilitySetting === 'not' ? true : false,
        });
      });
      customAlert.success('[Profile Updated] The changes to your profile have been saved!');
  
    };

    return (
        <SafeAreaView style={styles.container}>
            {user ? (
                <Header navigation={navigation}></Header>
            ) : (
                <AuthHeader navigation={navigation}></AuthHeader>
            )}

            {/* Main Content */}
            {
                loading ? <LoadingPage /> : (
                    <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{backgroundColor: background}}
                    >
                        <View style={styles.maxWidth}>
                            <View style={[styles.mainSection]}>
                                {/* Title */}
                                <Text style={{
                                    color: text,
                                    fontSize: 16,
                                    fontFamily: defaultStyles.regularFont,
                                    marginBottom: 5
                                }}>
                                        <Link
                        // onMouseEnter={handlePressIn}
                        // onMouseLeave={handlePressOut}
                                        to={'/settings'}
                                    >
                                        <Text>Settings</Text>
                                    </Link> <Text style={{fontFamily: defaultStyles.boldFont, color: primary}}><Feather name="chevron-right" size={15} color={text}  /> Sessions</Text>
                                </Text>

                                <Text style={styles.title}>
                                    Session settings
                                </Text>
                                <View style={{display: 'flex', flexDirection: 'row'}}>
                                    <Text style={{color: text, fontSize: 16, fontFamily: defaultStyles.regularFont}}>
                                        Configure your rate, availability, and more.
                                    </Text>
                                </View>


                                <View style={{
                                    display:"flex",
                                    flexDirection: isLg ? "row" : "column",
                                    gap: 30
                                }}>
                                    <View style={{
                                        display:"flex",
                                        flexDirection:"column",
                                        flex:1,
                                        paddingRight: 30,
                                        marginTop: 30
                                    }}>


                                    {/* Put main Content here  */}
                                    {/* Session rate input */}
                                    <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex' , flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start'}}>
                                       <View style={{
                                        maxWidth:"70%"
                                       }}>
                                            <Text style={styles.subtitle}>Session rate</Text>
                                            <Text style={styles.description}> 
                                                Your hourly rate for sessions. Students will be charged this rate multiplied by the session length.
                                            </Text>
                                       </View>


                                       <NumericTextInput
                                            style={[styles.picker]}
                                            type='currency'
                                            locale='en-Us'
                                            currency='CAD'
                                            decimalPlaces={2}
                                            value={localRate}
                                            onUpdate={(value) => {
                                                setLocalRate(value);
                                            }}
                                            placeholder='$0.00'
                                        />
                                       
                                    </View>

    
                                    {/* Auto accept */}
                                    <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex' , flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start'}}>
                                       <View style={{
                                        maxWidth:"70%"
                                       }}>
                                            <Text style={styles.subtitle}>Auto-accept</Text>
                                            <Text style={styles.description}> 
                                                Automatically accept session requests from students.
                                            </Text>
                                       </View>
                                        <Switch
                                            trackColor={{ false: border, true: primary }}
                                            thumbColor={primaryVariant}
                                            ios_backgroundColor={border}
                                            onValueChange={() => {
                                                // Update user in database with new instabook value
                                                updateDoc(doc(db, 'users', user.uid, ), {
                                                    autoAccept: !autoAccept
                                                }).then(() => {
                                                }
                                                ).catch((error) => {
                                                    console.log(error);
                                                });
                                            }}
                                            value={autoAccept}
                                        />
                                    </View>

                                    {/* Availability */}
                                    <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex' , flexDirection:'column'}}>
                                        <View style={{
                                            display: 'flex' ,
                                            flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start'
                                        }}>
                                            <View style={{
                                                maxWidth:"70%"
                                            }}>
                                                <Text style={styles.subtitle}>Availability</Text>
                                                <Text style={styles.description}> 
                                                    Set your availability for students to book sessions with you.
                                                </Text>
                                            </View>

                                            {/* Picker */}
                                            <Picker
                                                selectedValue={localAvailabilitySetting}
                                                style={styles.picker}
                                                onValueChange={(itemValue, itemIndex) => {
                                                    setLocalAvailabilitySetting(itemValue);
                                                }}
                                            >
                                                <Picker.Item label="Always available" value="always" />
                                                <Picker.Item label="Custom availability" value="custom" />
                                                <Picker.Item label="Not available" value="not" />
                                            </Picker>


                                        </View>

                                        {
                                        localAvailabilitySetting === 'custom' && (
                                        <>
                                        {/* 
                                        <View
                                            style={{ marginTop: 15, display: 'flex', flexDirection: 'column' }}
                                          >
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Monday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  monday && monday.start && monday.end ? `${monday.start} - ${monday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Tuesday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  tuesday && tuesday.start && tuesday.end ? `${tuesday.start} - ${tuesday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Wednesday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  wednesday && wednesday.start && wednesday.end ? `${wednesday.start} - ${wednesday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Thursday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  thursday && thursday.start && thursday.end ? `${thursday.start} - ${thursday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Friday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  friday && friday.start && friday.end ? `${friday.start} - ${friday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Saturday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  saturday && saturday.start && saturday.end ? `${saturday.start} - ${saturday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Sunday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  sunday && sunday.start && sunday.end ? `${sunday.start} - ${sunday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                                          </View> */}

                                          {/* Advance Notice and Buffer Period */}
                                        {/* <View style={styles.availabilitySettings}>
                                            <Text style={styles.label}>Advance Notice</Text>
                                            <RNPickerSelect
                                                onValueChange={(value) => setAdvanceNotice(value)}
                                                items={advanceNoticeOptions}
                                                // style={pickerSelectStyles}
                                                value={advanceNotice}
                                            />
                                        </View>

                                        <View style={styles.availabilitySettings}>
                                            <Text style={styles.label}>Buffer Period</Text>
                                            <RNPickerSelect
                                                onValueChange={(value) => setBufferPeriod(value)}
                                                items={bufferPeriodOptions}
                                                // style={pickerSelectStyles}
                                                value={bufferPeriod}
                                            />
                                        </View> */}
                                          <View style={{ marginTop:15, display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: showMonday
                                                    ? primary
                                                    : backgroundVariant,
                                                border: '1px solid' + border,
                                                borderRadius: defaultStyles.radius,
                                                marginRight: 10,
                                                }}
                                                onPress={() => {
                                                if (showMonday) {
                                                    setMonday({ start: '', end: '' });
                                                    setShowMonday(false);
                                                } else {
                                                    setShowMonday(true);
                                                }
                                                }}
                                            ></TouchableOpacity>

                                            <Text
                                                style={{
                                                color: text,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                }}
                                            >
                                                Monday
                                            </Text>
                                            </View>

                                            {showMonday && (
                                            <View
                                                style={{
                                                marginTop:10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15,
                                                }}
                                            >
                                                {Platform.OS == 'web' ? (
                                                <>
                                                    <CustomTimeInput   
                                                    setTime={(time) => {
                                                        setMonday({ ...monday, start: time});
                                                    }}
                                                    time={userAvailability.monday.start}                   
                                                    ></CustomTimeInput>
                                                
                                                    <Text
                                                    style={{
                                                        color: text,
                                                        fontFamily: defaultStyles.boldFont,
                                                        fontSize: 12,
                                                    }}
                                                    >
                                                    to
                                                    </Text>

                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setMonday({ ...monday, end: time });
                                                    }}
                                                    time={userAvailability.monday.end}                   
                                                    ></CustomTimeInput>


                                                </>
                                                ) : (
                                                <Text style={{ color: text }}>Coming soon...</Text>
                                                )}
                                            </View>
                                            )}

                                            <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: showTuesday ? primary : backgroundVariant,
                                                border: '1px solid' + border,
                                                borderRadius: defaultStyles.radius,
                                                marginRight: 10,
                                                }}
                                                onPress={() => {
                                                if (showTuesday) {
                                                    setTuesday({ start: '', end: '' });
                                                    setShowTuesday(false);
                                                } else {
                                                    setShowTuesday(true);
                                                }
                                                }}
                                            ></TouchableOpacity>

                                            <Text
                                                style={{
                                                color: text,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                }}
                                            >
                                                Tuesday
                                            </Text>
                                            </View>

                                            {showTuesday && (
                                            <View
                                                style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15,
                                                }}
                                            >
                                                {Platform.OS == 'web' ? (
                                                <>
                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setTuesday({ ...tuesday, start: time });
                                                    }}
                                                    time={userAvailability.tuesday.start}
                                                    ></CustomTimeInput>

                                                    <Text
                                                    style={{
                                                        color: text,
                                                        fontFamily: defaultStyles.boldFont,
                                                        fontSize: 12,
                                                    }}
                                                    >
                                                    to
                                                    </Text>

                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setTuesday({ ...tuesday, end: time });
                                                    }}
                                                    time={userAvailability.tuesday.end}
                                                    ></CustomTimeInput>
                                                </>
                                                ) : (
                                                <Text style={{ color: text }}>Coming soon...</Text>
                                                )}
                                            </View>
                                            )}
                                            <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: showWednesday ? primary : backgroundVariant,
                                                border: '1px solid' + border,
                                                borderRadius: defaultStyles.radius,
                                                marginRight: 10,
                                                }}
                                                onPress={() => {
                                                if (showWednesday) {
                                                    setWednesday({ start: '', end: '' });
                                                    setShowWednesday(false);
                                                } else {
                                                    setShowWednesday(true);
                                                }
                                                }}
                                            ></TouchableOpacity>

                                            <Text
                                                style={{
                                                color: text,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                }}
                                            >
                                                Wednesday
                                            </Text>
                                            </View>

                                            {showWednesday && (
                                            <View
                                                style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15,
                                                }}
                                            >
                                                {Platform.OS == 'web' ? (
                                                <>
                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setWednesday({ ...wednesday, start: time });
                                                    }}
                                                    time={userAvailability.wednesday.start}
                                                    ></CustomTimeInput>

                                                    <Text
                                                    style={{
                                                        color: text,
                                                        fontFamily: defaultStyles.boldFont,
                                                        fontSize: 12,
                                                    }}
                                                    >
                                                    to
                                                    </Text>

                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setWednesday({ ...wednesday, end: time });
                                                    }}
                                                    time={userAvailability.wednesday.end}
                                                    ></CustomTimeInput>
                                                </>
                                                ) : (
                                                <Text style={{ color: text }}>Coming soon...</Text>
                                                )}
                                            </View>
                                            )}

                                            <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: showThursday ? primary : backgroundVariant,
                                                border: '1px solid' + border,
                                                borderRadius: defaultStyles.radius,
                                                marginRight: 10,
                                                }}
                                                onPress={() => {
                                                if (showThursday) {
                                                    setThursday({ start: '', end: '' });
                                                    setShowThursday(false);
                                                } else {
                                                    setShowThursday(true);
                                                }
                                                }}
                                            ></TouchableOpacity>

                                            <Text
                                                style={{
                                                color: text,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                }}
                                            >
                                                Thursday
                                            </Text>
                                            </View>

                                            {showThursday && (
                                            <View
                                                style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15,
                                                }}
                                            >
                                                {Platform.OS == 'web' ? (
                                                <>
                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setThursday({ ...thursday, start: time });
                                                    }}
                                                    time={userAvailability.thursday.start}
                                                    ></CustomTimeInput>

                                                    <Text
                                                    style={{
                                                        color: text,
                                                        fontFamily: defaultStyles.boldFont,
                                                        fontSize: 12,
                                                    }}
                                                    >
                                                    to
                                                    </Text>

                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setThursday({ ...thursday, end: time });
                                                    }}
                                                    time={userAvailability.thursday.end}
                                                    ></CustomTimeInput>
                                                </>
                                                ) : (
                                                <Text style={{ color: text }}>Coming soon...</Text>
                                                )}
                                            </View>
                                            )}

                                            <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: showFriday ? primary : backgroundVariant,
                                                border: '1px solid' + border,
                                                borderRadius: defaultStyles.radius,
                                                marginRight: 10,
                                                }}
                                                onPress={() => {
                                                if (showFriday) {
                                                    setFriday({ start: '', end: '' });
                                                    setShowFriday(false);
                                                } else {
                                                    setShowFriday(true);
                                                }
                                                }}
                                            ></TouchableOpacity>

                                            <Text
                                                style={{
                                                color: text,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                }}
                                            >
                                                Friday
                                            </Text>
                                            </View>

                                            {showFriday && (
                                            <View
                                                style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15,
                                                }}
                                            >
                                                {Platform.OS == 'web' ? (
                                                <>
                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setFriday({ ...friday, start: time });
                                                    }}
                                                    time={userAvailability.friday.start}
                                                    ></CustomTimeInput>

                                                    <Text
                                                    style={{
                                                        color: text,
                                                        fontFamily: defaultStyles.boldFont,
                                                        fontSize: 12,
                                                    }}
                                                    >
                                                    to
                                                    </Text>

                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setFriday({ ...friday, end: time });
                                                    }}
                                                    time={userAvailability.friday.end}
                                                    ></CustomTimeInput>
                                                </>
                                                ) : (
                                                <Text style={{ color: text }}>Coming soon...</Text>
                                                )}
                                            </View>
                                            )}

                                            <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: showSaturday ? primary : backgroundVariant,
                                                border: '1px solid' + border,
                                                borderRadius: defaultStyles.radius,
                                                marginRight: 10,
                                                }}
                                                onPress={() => {
                                                if (showSaturday) {
                                                    setSaturday({ start: '', end: '' });
                                                    setShowSaturday(false);
                                                } else {
                                                    setShowSaturday(true);
                                                }
                                                }}
                                            ></TouchableOpacity>

                                            <Text
                                                style={{
                                                color: text,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                }}
                                            >
                                                Saturday
                                            </Text>
                                            </View>

                                            {showSaturday && (
                                            <View
                                                style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15,
                                                }}
                                            >
                                                {Platform.OS == 'web' ? (
                                                <>
                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setSaturday({ ...saturday, start: time });
                                                    }}
                                                    time={userAvailability.saturday.start}
                                                    ></CustomTimeInput>

                                                    <Text
                                                    style={{
                                                        color: text,
                                                        fontFamily: defaultStyles.boldFont,
                                                        fontSize: 12,
                                                    }}
                                                    >
                                                    to
                                                    </Text>

                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setSaturday({ ...saturday, end: time });
                                                    }}
                                                    time={userAvailability.saturday.end}
                                                    ></CustomTimeInput>
                                                </>
                                                ) : (
                                                <Text style={{ color: text }}>Coming soon...</Text>
                                                )}
                                            </View>
                                            )}

                                            <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: showSunday ? primary : backgroundVariant,
                                                border: '1px solid' + border,
                                                borderRadius: defaultStyles.radius,
                                                marginRight: 10,
                                                }}
                                                onPress={() => {
                                                if (showSunday) {
                                                    setSunday({ start: '', end: '' });
                                                    setShowSunday(false);
                                                } else {
                                                    setShowSunday(true);
                                                }
                                                }}
                                            ></TouchableOpacity>

                                            <Text
                                                style={{
                                                color: text,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                }}
                                            >
                                                Sunday
                                            </Text>
                                            </View>

                                            {showSunday && (
                                            <View
                                                style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15,
                                                }}
                                            >
                                                {Platform.OS == 'web' ? (
                                                <>
                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setSunday({ ...sunday, start: time });
                                                    }}
                                                    time={userAvailability.sunday.start}
                                                    ></CustomTimeInput>

                                                    <Text
                                                    style={{
                                                        color: text,
                                                        fontFamily: defaultStyles.boldFont,
                                                        fontSize: 12,
                                                    }}
                                                    >
                                                    to
                                                    </Text>

                                                    <CustomTimeInput
                                                    setTime={(time) => {
                                                        setSunday({ ...sunday, end: time });
                                                    }}
                                                    time={userAvailability.sunday.end}
                                                    ></CustomTimeInput>
                                                </>
                                                ) : (
                                                <Text style={{ color: text }}>Coming soon...</Text>
                                                )}
                                            </View>
                                            )}
                                          </>
                                        )
                                        }


                                    </View>


                                    {/* Save button */}
                                    <TouchableOpacity
                                        style={{
                                            backgroundColor: primary,
                                            borderRadius: defaultStyles.buttonRadius,
                                            height:40,
                                            marginTop: 15,
                                            width: isMd ? 200 : "100%",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignSelf: isMd ? "flex-end" : null
                                        }}
                                        onPress={() => {
                                            // Update user in database with new instabook value
                                            updateSession();
                                        }}
                                    >
                                        <Text style={{
                                            color: background,
                                            fontFamily: defaultStyles.boldFont,
                                            fontSize: 16,
                                            textAlign: 'center'
                                        }}>Save</Text>
                                    </TouchableOpacity>
        
                                </View>


                                    {/* Right side */}
                                    <View style={{
                                                display:"flex",
                                                flexDirection: !isLg ? 'row': 'column',
                                                width: isLg ? 330 : "100%",
                                            }}>
                                                <View style={{
                                                    border: "1px solid " + border,
                                                    borderRadius: defaultStyles.radius,
                                                    padding: 15,
                                                    width: isLg ? 330 : "100%",
                                                }}>

                                                    {/* Icon  */}
                                                    <AntDesign
                                                        name="videocamera"
                                                        size={32}
                                                        color={text}
                                                        style={{marginBottom: 10}}
                                                    />

                                                    {/* Title */}
                                                    <Text style={styles.infoSubtitle}>How do sessions work?</Text>
                                                    {/* Description */}
                                                    <Text style={styles.infoDescription}>
                                                        Students can book sessions with you at your hourly rate. You can set your availability and students can book sessions with you during those times. You will be notified when a student books a session with you, if auto-accept is disabled you will have to accept session requests.
                                                    </Text>
                                                    {/* Divider  */}
                                                    <View style={{
                                                        width: "100%",
                                                        height: 1,
                                                        backgroundColor: border,
                                                        marginTop: 15,
                                                        marginBottom: 15
                                                    }}></View>


                                                    {/* Icon  */}
                                                    
                                         
                                                    <AntDesign
                                                        name="creditcard"
                                                        size={32}
                                                        color={text}
                                                        style={{marginBottom: 10}}
                                                    />

                                                    {/* Title */}
                                                    <Text style={styles.infoSubtitle}>How do the rates work?</Text>
                                                    {/* Description */}
                                                    <Text style={styles.infoDescription}>
                                                        When a student books a session with you they select a session length. The session length is multiplied by your hourly rate to calculate the total cost of the session. If the session length is extended while the session is in progress, the student will be charged for the additional time.
                                                    </Text>
                                                </View>
                                                {
                                                    isMd && (
                                                        <View style={{flex:1}}></View>
                                                    )
                                                }
        
                                            </View>   

                                </View>
                            </View>
                                {/* Last updated in bottom left corner */}
                                {/* <Text style={{color: primary, fontSize: 12, fontFamily: defaultStyles.regularFont, position: 'absolute', bottom: 0, left: 0, marginBottom: 15, padding: isSm ? 45: 15,}}>Last updated: {lastUpdated}</Text> */}
                        </View>
                        {/* Footer  */}
                      {isSm ? <Footer></Footer> : null}
                    </ScrollView>
                )
            }
      </SafeAreaView>
    )  
}