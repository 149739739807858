// Home Screen for logged in tutor
import { AntDesign, Feather } from "@expo/vector-icons";
import { Link } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
	Platform,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	Switch,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../../../components/AuthHeader";
import Footer from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { LoadingPage } from "../../../components/LoadingPage";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

// Firebase
import { deleteDoc, doc } from "firebase/firestore";

const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

// Account Screen
export const Notifications = ({ navigation }) => {
	const {
		user,
		name,
		URL,
		isTutor,
		notificationPreferences,
		toggleNotificationPreferences,
	} = useUserContext();

	if (!notificationPreferences) {
		return <LoadingPage />;
	}

	const customAlert = useAlert();

	// User Context
	const auth = getAuth();
	const [loading, setLoading] = useState(true);
	const [view, setView] = useState("account");

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		toggleDarkMode,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},
		mainSection: {
			flex: 1,
			padding: isSm ? 45 : 15,
			paddingTop: 30,
		},
		title: {
			fontSize: 36,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		subtitle: {
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		description: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
		},
		infoTitle: {
			textAlign: "left",
			fontSize: 22,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		infoSubtitle: {
			fontSize: 19,
			fontFamily: defaultStyles.boldFont,
			color: textVariant,
		},
		infoDescription: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
			marginTop: 5,
			display: "flex",
			flex: 1,
		},
		infoLink: {
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
			color: primary,
			marginTop: 10,
		},
	});

	useEffect(() => {
		if (user) {
			// Log email notifications
			// console.log('notificationPreferences.newMessage: ', notificationPreferences.emailNotifications);
			setLoading(false);
		}
	}, [user]);

	// Delete Account
	const deleteAccount = () => {
		// Delete account
		let deleted = window.confirm(
			"Are you sure you want to delete your account? This action cannot be undone."
		);
		if (deleted) {
			let userRef = doc(db, "users", user.uid);
			// Delete user from database
			deleteDoc(userRef)
				.then(() => {
					// Delete user
					user.delete()
						.then(() => {
							// User deleted
							// Delete user from auth
							customAlert.success(
								"[Account Deleted] We are sorry to see you go! We hope to see you again soon."
							);
							auth.signOut();
							window.location.reload();
						})
						.catch((error) => {
							// An error happened.
							auth.signOut();
							customAlert.error(
								"[Error Deleting Account] An error occured while deleting your account. Please try again logging in again."
							);
						});
				})
				.catch((error) => {
					auth.signOut();
					customAlert.error(
						"[Error Deleting Account] An error occured while deleting your account. Please try again logging in again."
					);
				});
		}
	};

	return (
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}

			{/* Main Content */}
			{loading ? (
				<LoadingPage />
			) : (
				<ScrollView
					showsVerticalScrollIndicator={false}
					style={{ backgroundColor: background }}
				>
					<View style={styles.maxWidth}>
						<View style={[styles.mainSection]}>
							{/* Title */}
							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									marginBottom: 5,
								}}
							>
								<Link
									// onMouseEnter={handlePressIn}
									// onMouseLeave={handlePressOut}
									to={"/settings"}
								>
									<Text>Settings</Text>
								</Link>{" "}
								<Text
									style={{
										fontFamily: defaultStyles.boldFont,
										color: primary,
									}}
								>
									<Feather
										name="chevron-right"
										size={15}
										color={text}
									/>{" "}
									Notifications
								</Text>
							</Text>

							<Text style={styles.title}>Notifications</Text>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<Text
									style={{
										color: text,
										fontSize: 16,
										fontFamily: defaultStyles.regularFont,
									}}
								>
									Update the notification preferences for your
									account.
								</Text>
							</View>

							{/* Top tab navigation */}
							<View
								style={{
									display: "flex",
									flexDirection: isLg ? "row" : "column",
									marginTop: 20,
									marginBottom: 20,
									gap: 30,
									borderBottomColor: border,
									borderBottomWidth: 1,
									paddingBottom: 15,
									flexDirection: isMd ? "row" : "column",
									alignItems: isMd ? "center" : "flex-start",
								}}
							>
								<TouchableOpacity
									onPress={() => {
										setView("account");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "account"
														? primary
														: textVariant,
												fontFamily:
													view === "account"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Account activity
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setView("sessions");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "sessions"
														? primary
														: textVariant,
												fontFamily:
													view === "sessions"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Sessions
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setView("messages");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "messages"
														? primary
														: textVariant,
												fontFamily:
													view === "messages"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Messsages
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setView("offers");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "offers"
														? primary
														: textVariant,
												fontFamily:
													view === "offers"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Offers and updates
									</Text>
								</TouchableOpacity>
							</View>

							<View
								style={{
									display: "flex",
									flexDirection: isLg ? "row" : "column",
									gap: 30,
								}}
							>
								<View
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										paddingRight: 30,
									}}
								>
									{/* ACCOUNT */}
									{view === "account" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Email
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive an email when
														there's an update to
														your account.
													</Text>
												</View>
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													disabled={true}
													value={true}
												/>
											</View>
										</View>
									)}
									{view === "account" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Text
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive a text when
														there's an update to
														your account.
													</Text>
												</View>
												{/* Coming soon */}
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													disabled={true}
													value={false}
												/>
											</View>
										</View>
									)}

									{/* SESSIONS */}
									{view === "sessions" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Email
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive an email when
														you receive a session
														request
													</Text>
												</View>
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													disabled={true}
													value={true}
												/>
											</View>
										</View>
									)}

									{view === "sessions" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Text
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive a text when you
														receive a session
														request.
													</Text>
												</View>
												{/* Coming soon */}
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													disabled={true}
													value={false}
												/>
											</View>
										</View>
									)}

									{/* MESSAGES */}
									{view === "messages" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Email
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive an email when
														you receive a new
														message.
													</Text>
												</View>
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													onValueChange={() => {
														toggleNotificationPreferences(
															"newMessage"
														);
														customAlert.success(
															"[Account] New message preference updated!"
														);
													}}
													value={
														notificationPreferences.newMessage
													}
												/>
											</View>
										</View>
									)}

									{view === "messages" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Text
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive a text when you
														receive a new message.
													</Text>
												</View>
												{/* Coming soon */}
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													disabled={true}
													value={false}
												/>
											</View>
										</View>
									)}

									{/* OFFERS */}
									{view === "offers" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Email
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive an email when
														there's new offers or
														updates to the platform.
													</Text>
												</View>
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													disabled={true}
													value={true}
												/>
											</View>
										</View>
									)}

									{view === "offers" && (
										<View
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View>
													<Text
														style={styles.subtitle}
													>
														Text
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Receive a text when
														there's new offers or
														updates to the platform.
													</Text>
												</View>
												{/* Coming soon */}
												<Switch
													style={{
														marginTop: !isMd && 15,
													}}
													trackColor={{
														false: "#767577",
														true: primary,
													}}
													thumbColor={primary}
													ios_backgroundColor={
														primary
													}
													disabled={true}
													value={false}
												/>
											</View>
										</View>
									)}
								</View>

								{/* Right side */}
								<View
									style={{
										display: "flex",
										flexDirection: !isLg ? "row" : "column",
										width: isLg ? 330 : "100%",
									}}
								>
									<View
										style={{
											border: "1px solid " + border,
											borderRadius: defaultStyles.radius,
											padding: 15,
											width: isLg ? 330 : "100%",
										}}
									>
										{/* Icon  */}
										<AntDesign
											name="notification"
											size={32}
											color={text}
											style={{ marginBottom: 10 }}
										/>

										{/* Title */}
										<Text style={styles.infoSubtitle}>
											Why turn on notifications?
										</Text>
										{/* Description */}
										<Text style={styles.infoDescription}>
											By enabling notifications, you will
											receive timely alerts based on your
											preferred settings whenever you
											receive new messages, session
											bookings, and other important
											updates. To enhance your experience,
											remember to activate notifications.
										</Text>

										{/* Divider  */}
										<View
											style={{
												width: "100%",
												height: 1,
												backgroundColor: border,
												marginTop: 15,
												marginBottom: 15,
											}}
										></View>

										{/* Icon  */}
										<AntDesign
											name="mail"
											size={32}
											color={text}
											style={{ marginBottom: 10 }}
										/>

										{/* Title */}
										<Text style={styles.infoSubtitle}>
											Where do the notifications go?
										</Text>
										{/* Description */}
										<Text style={styles.infoDescription}>
											Notifications will be sent to the
											email address associated with your
											account. You can view your account
											settings in the account settings
											page.
										</Text>

										{/* Link to learn about super tutor program */}
										<Link
											to={`/settings/account`}
											style={styles.infoLink}
										>
											Account settings
										</Link>
									</View>
									{isMd && <View style={{ flex: 1 }}></View>}
								</View>
							</View>
						</View>
						{/* Last updated in bottom left corner */}
						{/* <Text style={{color: primary, fontSize: 12, fontFamily: defaultStyles.regularFont, position: 'absolute', bottom: 0, left: 0, marginBottom: 15, padding: isSm ? 45: 15,}}>Last updated: {lastUpdated}</Text> */}
					</View>
					{/* Footer  */}
					{isSm ? <Footer></Footer> : null}
				</ScrollView>
			)}
		</SafeAreaView>
	);
};
