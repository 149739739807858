import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SubjectCards from "./SubjectCard";
import Carousel from 'react-multi-carousel';
import atom from "../../assets/atom.svg";
import chemistry from "../../assets/chemistry.svg";
import math from "../../assets/math.svg";
import dna from "../../assets/dna.svg";
import notebook from "../../assets/notebook.svg";
import { useStyles, useColorsContext } from "../../utils/styles";
import 'react-multi-carousel/lib/styles.css';

function Main3() {
	const defaultStyles = useStyles();
	const { primary } = useColorsContext();
	return (
		<Container fluid className="subject-section">
			<Container className="home-subject">
					<h1 className="subject-heading">
						Browse <strong className="main-name">Tutors </strong>by
						Subject
					</h1>
					<Carousel
						className="subjects-carousel"
						additionalTransfrom={0}
						arrows
						autoPlaySpeed={3000}
						centerMode={false}
						containerClass="container-with-dots"
						dotListClass=""
						draggable
						focusOnSelect={false}
						infinite
						itemClass=""
						keyBoardControl
						minimumTouchDrag={80}
						pauseOnHover
						renderArrowsWhenDisabled={false}
						renderButtonGroupOutside={false}
						renderDotsOutside={false}
						responsive={{
							desktop: {
								breakpoint: {
									max: 3000,
									min: 1024,
								},
								items: 4,
								partialVisibilityGutter: 40,
							},
							mobile: {
								breakpoint: {
									max: 464,
									min: 0,
								},
								items: 1,
								partialVisibilityGutter: 30,
							},
							tablet: {
								breakpoint: {
									max: 1024,
									min: 464,
								},
								items: 2,
								partialVisibilityGutter: 30,
							},
						}}
						rewind={false}
						rewindWithAnimation={false}
						rtl={false}
						shouldResetAutoplay
						showDots={false}
						sliderClass=""
						slidesToSlide={1}
						swipeable
					>
						<SubjectCards
							imgPath={math}
							title="Math"
							subjectId={4}
						/>
						<SubjectCards
							imgPath={chemistry}
							title="Chemistry"
							subjectId={12}
						/>
						<SubjectCards
							imgPath={atom}
							title="Physics"
							subjectId={13}
						/>
						<SubjectCards
							imgPath={dna}
							title="Biology"
							subjectId={11}
						/>
						<SubjectCards
							imgPath={notebook}
							title="English"
							subjectId={1}
						/>
					</Carousel>
			</Container>
		</Container>
		
	);
}

export default Main3;
