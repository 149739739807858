import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

function getTimeElapsed(date) {
	const elapsedMinutes = Math.floor((new Date() - date) / 60000); // elapsed time in minutes
	if (elapsedMinutes < 60) {
		return `${elapsedMinutes} ${
			elapsedMinutes === 1 ? "minute" : "minutes"
		} ago`;
	} else if (elapsedMinutes < 1440) {
		const elapsedHours = Math.floor(elapsedMinutes / 60);
		const remainingMinutes = elapsedMinutes % 60;
		return `${elapsedHours} ${elapsedHours === 1 ? "hour" : "hours"}${
			remainingMinutes > 0
				? ` and ${remainingMinutes} ${
						remainingMinutes === 1 ? "minute" : "minutes"
				  }`
				: ""
		} ago`;
	} else {
		const elapsedDays = Math.floor(elapsedMinutes / 1440);
		const remainingHours = Math.floor((elapsedMinutes % 1440) / 60);
		return `${elapsedDays} ${elapsedDays === 1 ? "day" : "days"} ago`;
	}
}

// Tutor Review component
export const StudentListing = ({ listing, user }) => {
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		reviewImg: {
			position: "block",
			marginVertical: 2,
			marginRight: 15,
			borderRadius: 100,
			width: 50,
			height: 50,
			opacity: 1,
		},
		reviewTitle: {
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},

		reviewDate: {
			fontFamily: defaultStyles.regularFont,
			fontSize: 16,
			color: textVariant,
		},
		reviewBody: {
			fontFamily: defaultStyles.regularFont,
			fontSize: 16,
			color: text,
			minHeight: 20,
		},
	});

	if (!listing) {
		return null;
	}

	return (
		<View
			style={{
				borderBottomWidth: 1,
				borderBottomColor: border,
				width: "100%",
				display: "flex",
				flexDirection: "row",
				paddingVertical: 15,
				flex: 1,
				// minHeight: 110,
			}}
		>
			<View style={{ flex: 1, display: "flex", flexDirection: "column" }}>
				<Text style={styles.reviewTitle}>{listing.title}</Text>

				<Text style={styles.reviewDate}>
					{/* Calculate how many days ago */}
					{getTimeElapsed(listing.createdAt.toDate())}
				</Text>

				<Text numberOfLines={2} style={styles.reviewBody}>
					{listing.description ? listing.description : "N/A"}
				</Text>
			</View>
		</View>
	);
};
