//Package imports
import { CloseOutlined } from "@mui/icons-material";
import { Picker } from "@react-native-picker/picker";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
	Modal,
	Platform,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";

// // File imports
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";
const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

export const ReportModal = ({
	modalVisible,
	setModalVisible,
	tutor,
	isChatScreen,
	studentName,
	studentID,
}) => {
	// State
	const [image, setImage] = useState(null);
	const [description, setDescription] = useState("");
	const [id, setId] = useState(null);
	const [transcript, setTranscript] = useState(null);
	const [degree, setDegree] = useState(null);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [report, setReport] = useState("Inappropriate Content");

	// Init alert
	const customAlert = useAlert();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	const { name, userId, user, isTutor, chat } = useUserContext();

	// handle submit
	const handleSubmit = () => {
		let payload;

		// Student payload
		if (!isTutor) {
			payload = {
				reportTutor: true,
				tutorEmail: isChatScreen
					? "ChatScreenPlaceholer@gmail.com"
					: tutor.email,
				tutorId: tutor.id,
				tutorName: tutor.name,
				studentEmail: user.email,
				studentId: userId,
				studentName: name,
				reason: report,
				description: description,
			};
		} else {
			payload = {
				reportTutor: false,
				tutorEmail: user.email,
				tutorId: tutor.id,
				tutorName: tutor.name,
				studentEmail: "ChatScreenPlaceholerAsTutor@gmail.com",
				studentId: studentID,
				studentName: studentName,
				reason: report,
				description: description,
			};
		}

		if (description === "") {
			setError("Please provide a description");
		} else {
			fetch(`${hostname}/api/report`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.json();
				})
				.then((data) => {
					// Reset Values
					setDescription("");
					setError(null);
					setSuccess(true);
					setTimeout(() => {
						setModalVisible(false);
						setSuccess(false);
					}, 7000);
				})
				.catch((err) => {
					console.log(err);
					setError("Something went wrong, please try again later");
				});
		}
	};

	// Instagram report options
	const reportOptions = [
		"Inappropriate Content",
		"Spam or Scam",
		"Intellectual Property Violation",
		"Hate Speech or Symbols",
		"Bullying or Harassment",
		"Other",
	];

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			padding: Platform.OS === "web" ? 30 : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},

		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "40%"
				: isLg
				? "50%"
				: isMd
				? "60%"
				: isSm
				? "70%"
				: "90%",
			height: isMd ? "50%" : "95%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: " space-between",
			marginBottom: 5,
		},
		closeButton: {
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		marginTop: { marginVertical: 20 },
		infoContainer: {
			flex: 1,
			display: "flex",
			flexDirection: !isMd ? "column" : "row",
			justifyContent: "space-between",
		},
		mainContainer: {
			flex: 1,
			padding: 30,
		},

		button: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			borderRadius: defaultStyles.buttonRadius,
			padding: 15,
			alignItems: "center",
		},
		buttonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 225,
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
	});

	return (
		<View>
			<Modal
				key={3}
				animationType="fade"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					setModalVisible(!modalVisible);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							<View style={{ flexDirection: "row" }}>
								{/* Close filters button */}
								<TouchableOpacity
									style={styles.closeButton}
									onPress={() => {
										setModalVisible(!modalVisible);
									}}
								>
									<CloseOutlined
										style={{ fontSize: 22, color: text }}
									></CloseOutlined>
								</TouchableOpacity>

								{/* Title */}
								{tutor && (
									<Text style={styles.modalTitle}>
										Report {tutor.name}
									</Text>
								)}
							</View>
							{!isMd || success ? null : (
								<TouchableOpacity
									style={{
										backgroundColor: primary,
										padding: 10,
										borderRadius:
											defaultStyles.buttonRadius,
									}}
									onPress={() => {
										handleSubmit();
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 14,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										Submit Report
									</Text>
								</TouchableOpacity>
							)}
						</View>
						<View style={styles.mainContainer}>
							<Text style={styles.title}>
								Please submit the required information through
								the reporting portal for further analysis. We
								assure you that all the information provided
								will remain confidential and secure.
							</Text>
							<Text
								style={{
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									color: text,
									marginVertical: 10,
								}}
							>
								Kindly note that our team will review your
								report in a timely manner.
							</Text>

							<Picker
								selectedValue={report}
								style={styles.picker}
								onValueChange={(itemValue, itemIndex) =>
									setReport(itemValue)
								}
							>
								{reportOptions.map((option) => (
									<Picker.Item
										label={option}
										value={option}
									/>
								))}
							</Picker>

							{/* Photo id  */}
							{/* <TouchableOpacity
                onPress={async () => {
                  // No permissions request is necessary for launching the image library
                  let result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    aspect: [4, 3],
                    quality: 1,
                  });

                  if (!result.cancelled) {
                    // Set local image
                    setId(result.uri);
                    // Upload image to firebase storage
                    const storage = getStorage();
                    let imageFile = await fetch(result.uri);
                    let name =
                      imageFile._bodyBlob && imageFile._bodyBlob._data
                        ? imageFile._bodyBlob._data.name
                        : makeid(12);
                    let imageBlob = await imageFile.blob();
                    const storageRef = ref(storage, `${name}`);
                    let task = uploadBytesResumable(storageRef, imageBlob);
                    task.on(
                      'state_changed',
                      (snapshot) => {},
                      (error) => {
                        Platform.OS === 'web'
                          ? customAlert.error(error)
                          : Alert.alert('Error uploading image!', error, [
                              {
                                text: 'OK',
                                onPress: () => console.log('OK Pressed'),
                              },
                            ]);
                      },
                      () => {
                        getDownloadURL(task.snapshot.ref).then(
                          (downloadURL) => {
                            setId(downloadURL);
                          }
                        );
                      }
                    );
                  }
                }}
                style={[
                  styles.button,
                  { backgroundColor: id != null ? green : backgroundVariant },
                ]}
              >
                <Text style={[styles.buttonText]}>Photo ID</Text>
              </TouchableOpacity> */}
							{/* <Text
                style={{
                  fontFamily: defaultStyles.regularFont,
                  fontSize: 12,
                }}
              >
                Any valid government issued photo ID.
              </Text> */}

							<TextInput
								placeholderTextColor="#999999"
								style={styles.descriptionInput}
								placeholder="Any additional information..."
								multiline={true}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								maxLength={300}
							></TextInput>
							<Text style={[styles.details, { padding: 5 }]}>
								{description.length} / 300
							</Text>
						</View>

						{error && (
							<Text
								style={{
									color: "#FF0000",
									fontFamily: defaultStyles.boldFont,
									fontSize: 14,
									textAlign: "center",
									marginBottom: 30,
								}}
							>
								{error}
							</Text>
						)}

						{success && (
							<Text
								style={{
									color: "green",
									fontFamily: defaultStyles.boldFont,
									fontSize: 14,
									textAlign: "center",
									marginBottom: 30,
								}}
							>
								Your report has been sent to our team. Please
								allow 7-10 business days for your report to be
								processed. Thank you!
							</Text>
						)}

						{isMd || success ? null : (
							<TouchableOpacity
								style={{
									backgroundColor: primary,
									padding: 10,
									borderRadius: defaultStyles.buttonRadius,
									alignContent: "center",
									justifyContent: "center",
									alignItems: "center",
								}}
								onPress={() => {
									handleSubmit();
								}}
							>
								<Text
									style={{
										color: "#fff",
										fontSize: 14,
										fontFamily: defaultStyles.boldFont,
									}}
								>
									Submit Request
								</Text>
							</TouchableOpacity>
						)}
					</View>
				</View>
			</Modal>
		</View>
	);
};
