import { FontAwesome } from "@expo/vector-icons";
import Slider from "@react-native-community/slider";
import { Picker } from "@react-native-picker/picker";
import { useEffect, useState } from "react";
import { StyleSheet, Switch, Text, TouchableOpacity, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

import { languages } from "../config/languages";
import { provinces } from "../config/locations";
import { subjects as subjectData } from "../config/subjects";

// const skills = [
//     { value: 'all', label: 'All' },
//     { value: 'Programming', label: 'Programming' },
//     { value: 'Javascript', label: 'Javascript' },
//     { value: 'Calculus', label: 'Calculus' },
//     { value: 'Algebra', label: 'Algebra' }
// ];

// const subjects = [
//     { id: 1, subject: 'All', icon: 'calculator', value: 'all' },
//     { id: 1, subject: 'Mathematics', icon: 'calculator', value: 'Mathematics' },
//     { id: 2, subject: 'Law', icon: 'gavel', value: 'Law' },
//     { id: 3, subject: 'Business', icon: 'briefcase', value: 'Business' },
//     { id: 4, subject: 'Geography', icon: 'map', value: 'Geography' },
//     { id: 6, subject: 'Media Studies', icon: 'camera', value: 'Media Studies' },
//     { id: 7, subject: 'Chemistry', icon: 'flask', value: 'Chemistry' },
//     { id: 8, subject: 'Biology', icon: 'leaf', value: 'Biology' },
//     { id: 12, subject: 'Linguistics', icon: 'book', value: 'Linguistics' },
//     {
//     id: 13,
//     subject: 'Physiotherapy',
//     icon: 'heartbeat',
//     value: 'Physiotherapy',
//     },
//     {
//     id: 15,
//     subject: 'Computer Science',
//     icon: 'laptop',
//     value: 'Computer Science',
//     },
//     {
//     id: 16,
//     subject: 'Civil Engineering',
//     icon: 'building',
//     value: 'Civil Engineering',
//     },
//     { id: 18, subject: 'Philosophy', icon: 'apple', value: 'Philosophy' },
//     { id: 19, subject: 'Astronomy', icon: 'space-shuttle', value: 'Astronomy' },
//     {
//     id: 20,
//     subject: 'Health Sciences',
//     icon: 'medkit',
//     value: 'Health Sciences',
//     },
// ];

// const majorCities = [
//     { value: 'all', label: 'All' },
//     { value: 'Toronto', label: 'Toronto' },
//     { value: 'Montréal', label: 'Montréal' },
//     { value: 'Vancouver', label: 'Vancouver' },
//     { value: 'Calgary', label: 'Calgary' },
//     { value: 'Edmonton', label: 'Edmonton' },
//     { value: 'Ottawa', label: 'Ottawa' },
//     { value: 'Winnipeg', label: 'Winnipeg' },
//     { value: 'Hamilton', label: 'Hamilton' },
//     { value: 'Kitchener', label: 'Kitchener' },
// ];

// const majorLanguages = [
//     { value: 'all', label: 'All' },
//     { value: 'English', label: 'English' },
//     { value: 'French', label: 'French' },
//     { value: 'Arabic', label: 'Arabic' },
// ];

const price = [
	{ value: "all", label: "All" },
	{ value: "10", label: "Less than $10/h" },
	{ value: "15", label: "Less than $15/h" },
	{ value: "25", label: "Less than $25/h" },
	{ value: "50", label: "Less than $50/h" },
	{ value: "75", label: "Less than $75/h" },
];

export const FiltersList = ({ modalVisible, setModalVisible, setFilters }) => {
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {},
		modalHeader: {
			flexDirection: "row",
			// justifyContent: 'space-between',
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			// position: 'absolute',
			marginRight: 15,
		},
		circleButton: {
			width: 40,
			height: 40,
			borderRadius: defaultStyles.buttonRadius,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			marginRight: 15,
			marginLeft: "auto",
		},
		applyButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 40,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
		},

		marginTop: {
			marginTop: 15,
		},
		title: {
			fontSize: 18,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	// States
	const [selectedSubject, setSelectedSubject] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [selectedRating, setSelectedRating] = useState(0);
	const [selectedLanguage, setSelectedLanguage] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState("all");
	const [selectedPrice, setSelectedPrice] = useState(0);
	const [sliderPosition, setSliderPosition] = useState(0);
	const [verified, setVerified] = useState(false);
	const [maxRating, setMaxRating] = useState(0);
	const [minRating, setMinRating] = useState(0);
	const [maxPrice, setMaxPrice] = useState(200);
	const [minPrice, setMinPrice] = useState(0);

	// States for picker data
	const [subjects, setSubjects] = useState([]);
	const [skills, setSkills] = useState([]);
	const [majorCities, setMajorCities] = useState([]);
	const [majorProvinces, setMajorProvinces] = useState([]);
	const [majorLanguages, setMajorLanguages] = useState([]);
	const [instabook, setInstabook] = useState(false);
	const [superTutor, setSuperTutor] = useState(false);

	// Functions
	const saveFilters = () => {
		setFilters([
			{ name: "subject", value: selectedSubject },
			{ name: "skills", value: selectedSkills },
			{ name: "language", value: selectedLanguage },
			{ name: "location", value: selectedLocation },
			{ name: "verified", value: verified },
			{ name: "instabook", value: instabook },
			{ name: "superTutor", value: superTutor },
			{
				name: "minPrice",
				value: minPrice,
			},
			{
				name: "maxPrice",
				value: maxPrice,
			},
			{
				name: "minRating",
				value: minRating,
			},
			{
				name: "maxRating",
				value: maxRating,
			},
		]);
	};

	useEffect(() => {
		if (languages) {
			var majorLanguages = [
				{
					value: "all",
					label: "All",
				},
			];
			languages.forEach((language) => {
				majorLanguages.push({
					value: language,
					label: language,
				});
			});
			setMajorLanguages(majorLanguages);
		}

		if (provinces) {
			var majorProvinces = [
				{
					value: "all",
					label: "All",
				},
			];
			provinces.forEach((province) => {
				majorProvinces.push({
					value: province,
					label: province,
				});
			});
			setMajorProvinces(majorProvinces);
		}

		if (subjectData) {
			var subjectsArr = [];
			let subjectKeys = Object.keys(subjectData);
			subjectKeys.forEach((subject) => {
				subjectsArr.push({
					subject: subject,
					value: subject,
				});
			});

			// Sort alphabetically
			subjectsArr.sort((a, b) => {
				if (a.subject < b.subject) {
					return -1;
				}

				if (a.subject > b.subject) {
					return 1;
				}

				return 0;
			});

			subjectsArr.unshift({
				subject: "All",
				value: "all",
			});

			setSubjects(subjectsArr);

			// Set skills
			var skillsArr = [];
			subjectKeys.forEach((subject) => {
				let skills = subjectData[subject];
				skills.forEach((skill) => {
					skillsArr.push({
						label: skill,
						value: skill,
					});
				});
			});

			// Sort alphabetically
			skillsArr.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}

				if (a.label > b.label) {
					return 1;
				}

				return 0;
			});

			skillsArr.unshift({
				label: "All",
				value: "all",
				enabled: false,
			});

			setSkills(skillsArr);
		}
	}, []);

	// If subjet is selected, set skills to only those skills
	useEffect(() => {
		if (selectedSubject.length > 0) {
			if (selectedSubject.includes("all")) {
				setSelectedSubject([]);
				var skillsArr = [];
				let subjectKeys = Object.keys(subjectData);
				subjectKeys.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			} else {
				var skillsArr = [];
				selectedSubject.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			}
		}
	}, [selectedSubject]);

	// If skills selected check if all is selected
	useEffect(() => {
		if (selectedSkills.length > 0) {
			if (selectedSkills.includes("all")) {
				setSelectedSkills([]);
			}
		}
	}, [selectedSkills]);

	// On value changes saveFilters
	useEffect(() => {
		saveFilters();
	}, [
		selectedSubject,
		selectedSkills,
		selectedRating,
		selectedLanguage,
		selectedLocation,
		selectedPrice,
		verified,
		instabook,
		superTutor,
		minPrice,
		maxPrice,
		minRating,
		maxRating,
	]);

	const [showSubjects, setShowSubjects] = useState(false);
	const [showSkills, setShowSkills] = useState(false);
	const [showRating, setShowRating] = useState(false);
	const [showLanguage, setShowLanguage] = useState(false);
	const [showLocation, setShowLocation] = useState(false);
	const [showPrice, setShowPrice] = useState(false);
	const [showVerified, setShowVerified] = useState(false);
	const [showInstabook, setShowInstabook] = useState(false);
	const [showSuperTutor, setShowSuperTutor] = useState(false);

	return (
		<View style={styles.modalView}>
			{/* Subject  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowSubjects(!showSubjects)}
			>
				<View
					style={[
						{
							maxHeight: showSubjects ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>Subject</Text>
					<Text style={styles.pickerInfoText}>
						Filter by subject.{" "}
					</Text>

					{/* Dropdown icon */}
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSubjects ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>

					{/* Render selected subjects */}

					<View
						style={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
						}}
					>
						{selectedSubject.length > 0
							? selectedSubject.map((item, i) => (
									<TouchableOpacity
										key={`filters-subject-${i}`}
										onPress={(e) => {
											// Ignore parent touchable opacity
											e.stopPropagation();

											let newSelectedSubject = [
												...selectedSubject,
											];
											newSelectedSubject.splice(i, 1);
											setSelectedSubject(
												newSelectedSubject
											);
										}}
									>
										<View
											style={{
												marginRight: 5,
												marginTop: 5,
												backgroundColor:
													backgroundVariant,
												border: `1px solid ${border}`,
												borderRadius:
													defaultStyles.radius,
												padding: 8,
												position: "relative",
											}}
										>
											<Text
												style={{
													color: textVariant,
													fontFamily:
														defaultStyles.regularFont,
												}}
											>
												{selectedSubject[i]}
											</Text>

											{/* Close icon */}
											<View
												style={{
													position: "absolute",
													top: -5,
													right: -5,
													backgroundColor:
														backgroundVariant,
													borderRadius: "50%",
													padding: 2,
												}}
											>
												<FontAwesome
													name="close"
													size={8}
													color={textVariant}
												/>
											</View>
										</View>
									</TouchableOpacity>
							  ))
							: null}
					</View>
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Picker
							style={styles.picker}
							selectedValue={
								selectedSubject.length > 0
									? selectedSubject[
											selectedSubject.length - 1
									  ]
									: "all"
							}
							onValueChange={(itemValue, itemIndex) =>
								setSelectedSubject((prevState) => [
									...prevState,
									itemValue,
								])
							}
						>
							{subjects.map((item, i) => (
								<Picker.Item
									key={i}
									label={subjects[i].subject}
									value={subjects[i].value}
								/>
							))}
						</Picker>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Price  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowPrice(!showPrice)}
			>
				<View
					style={[
						{
							maxHeight: showPrice ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>Price</Text>
					<Text style={styles.pickerInfoText}>
						Filter by hourly price.
					</Text>
					{/* Dropdown icon */}
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSubjects ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>

					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
						style={{ marginTop: 10 }}
					>
						<Text style={{ color: textVariant }}>
							Min{" "}
							{minPrice > 0 ? (
								<Text>${minPrice} </Text>
							) : (
								<Text>$0</Text>
							)}
						</Text>
						<Slider
							style={{ width: "100%", height: 40 }}
							step={5}
							value={minPrice}
							minimumValue={0}
							maximumValue={100}
							onValueChange={(val) => setMinPrice(val)}
							minimumTrackTintColor={primary}
							maximumTrackTintColor={border}
							thumbTintColor={primary}
						/>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
						style={{ marginTop: 10 }}
					>
						<Text style={{ color: textVariant }}>
							Max{" "}
							{maxPrice > 0 ? (
								<Text>${maxPrice} </Text>
							) : (
								<Text>$200</Text>
							)}
						</Text>
						<Slider
							style={{ width: "100%", height: 40 }}
							step={5}
							value={maxPrice}
							minimumValue={minPrice}
							maximumValue={200}
							onValueChange={(val) => setMaxPrice(val)}
							minimumTrackTintColor={primary}
							maximumTrackTintColor={border}
							thumbTintColor={primary}
						/>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Skills  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowSkills(!showSkills)}
			>
				<View
					style={[
						{
							maxHeight: showSkills ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSkills ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>
					<Text style={styles.title}>Skills</Text>
					<Text style={styles.pickerInfoText}>
						Filter by skills.{" "}
					</Text>

					{/* Render selected skill if any  */}
					<View
						style={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "row",
						}}
					>
						{selectedSkills.length > 0
							? selectedSkills.map((item, i) => (
									<TouchableOpacity
										onPress={(e) => {
											// Ignore parent touchable opacity
											e.stopPropagation();
											let newSkills =
												selectedSkills.filter(
													(skill) => skill !== item
												);
											setSelectedSkills(newSkills);
										}}
									>
										<View
											key={i}
											style={{
												marginRight: 5,
												marginTop: 5,
												backgroundColor:
													backgroundVariant,
												border: `1px solid ${border}`,
												borderRadius:
													defaultStyles.radius,
												padding: 8,
												position: "relative",
											}}
										>
											<Text
												style={{
													color: textVariant,
													fontFamily:
														defaultStyles.regularFont,
												}}
											>
												{selectedSkills[i]}
											</Text>
											{/* Close icon */}
											<View
												style={{
													position: "absolute",
													top: -5,
													right: -5,
													backgroundColor:
														backgroundVariant,
													borderRadius: "50%",
													padding: 2,
												}}
											>
												<FontAwesome
													name="close"
													size={8}
													color={textVariant}
												/>
											</View>
										</View>
									</TouchableOpacity>
							  ))
							: null}
					</View>
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Picker
							style={styles.picker}
							selectedValue={
								selectedSkills.length > 0
									? selectedSkills[selectedSkills.length - 1]
									: "all"
							}
							onValueChange={(itemValue, itemIndex) =>
								setSelectedSkills((prevState) => [
									...prevState,
									itemValue,
								])
							}
						>
							{skills.map((item, i) => (
								<Picker.Item
									key={i}
									label={skills[i].label}
									value={skills[i].value}
								/>
							))}
						</Picker>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Instabook */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowInstabook(!showInstabook)}
			>
				<View
					style={[
						{
							maxHeight: showInstabook ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>InstaBook</Text>
					<Text style={styles.pickerInfoText}>
						Filter by tutors who are available to tutor immediately!
					</Text>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSkills ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>

					{/* Toggle switch */}
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Switch
							style={{
								marginTop: 10,
							}}
							trackColor={{
								false: "#767577",
								true: primaryVariant,
							}}
							thumbColor={
								instabook ? primaryVariant : primaryVariant
							}
							ios_backgroundColor="#3e3e3e"
							onValueChange={(e) => {
								setInstabook(!instabook);
							}}
							value={instabook}
						/>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Verified */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowVerified(!showVerified)}
			>
				<View
					style={[
						{
							maxHeight: showVerified ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>Verified</Text>
					<Text style={styles.pickerInfoText}>
						Filter by tutors verified and certified by Tutred.
					</Text>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showVerified ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>

					{/* Toggle switch */}
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Switch
							style={{
								marginTop: 10,
							}}
							trackColor={{
								false: "#eeeeee",
								true: primaryVariant,
							}}
							thumbColor={
								verified ? primaryVariant : primaryVariant
							}
							ios_backgroundColor="#eeeeee"
							backgroundColor="#eeeeee"
							thumbTintColor={primaryVariant}
							onValueChange={(e) => {
								setVerified(!verified);
							}}
							value={verified}
						/>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Super Tutor */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowSuperTutor(!showSuperTutor)}
			>
				<View
					style={[
						{
							maxHeight: showSuperTutor ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>Super Tutor</Text>
					<Text style={styles.pickerInfoText}>
						Filter by tutors who earned the Super Tutor badge.
					</Text>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={
								showSuperTutor ? "chevron-up" : "chevron-down"
							}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>

					{/* Toggle switch */}
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Switch
							style={{
								marginTop: 10,
							}}
							trackColor={{
								false: "#eeeeee",
								true: primaryVariant,
							}}
							thumbColor={
								superTutor ? primaryVariant : primaryVariant
							}
							ios_backgroundColor="#eeeeee"
							backgroundColor="#eeeeee"
							thumbTintColor={primaryVariant}
							onValueChange={(e) => {
								setSuperTutor(!superTutor);
							}}
							value={superTutor}
						/>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Rating  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowRating(!showRating)}
			>
				<View
					style={[
						{
							maxHeight: showRating ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>
						Rating {/* Show as many stars as selected if > 0  */}
						{[...Array(selectedRating)].map((e, i) => {
							return (
								<FontAwesome
									key={i}
									style={{ marginRight: 5 }}
									name="star"
									size={18}
									color={yellow}
								/>
							);
						})}
					</Text>
					<Text style={styles.pickerInfoText}>
						Filter by rating.{" "}
					</Text>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSkills ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>

					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
						style={{ marginTop: 10 }}
					>
						<Text style={{ color: textVariant }}>
							Min
							{minRating > 0 ? (
								<Text>
									<FontAwesome
										name="star"
										size={16}
										color={yellow}
									/>
									{minRating}
								</Text>
							) : null}
						</Text>
						<Slider
							style={{ width: "100%", height: 40 }}
							step={1}
							value={minRating}
							minimumValue={0}
							maximumValue={5}
							onValueChange={(val) => setMinRating(val)}
							minimumTrackTintColor={primary}
							maximumTrackTintColor={border}
							thumbTintColor={primary}
						/>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
						style={{ marginTop: 10 }}
					>
						<Text style={{ color: textVariant }}>
							Max
							{maxRating > 0 ? (
								<Text>
									<FontAwesome
										name="star"
										size={16}
										color={yellow}
									/>
									{maxRating}
								</Text>
							) : null}
						</Text>
						<Slider
							style={{ width: "100%", height: 40 }}
							step={1}
							value={maxRating}
							minimumValue={minRating}
							maximumValue={5}
							onValueChange={(val) => setMaxRating(val)}
							minimumTrackTintColor={primary}
							maximumTrackTintColor={border}
							thumbTintColor={primary}
						/>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Location  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowLocation(!showLocation)}
			>
				<View
					style={[
						{
							maxHeight: showLocation ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>Location</Text>
					<Text style={styles.pickerInfoText}>
						Filter by province.{" "}
					</Text>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSkills ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Picker
							style={styles.picker}
							selectedValue={selectedLocation}
							onValueChange={(itemValue, itemIndex) =>
								setSelectedLocation(itemValue)
							}
						>
							{majorProvinces.map((item, i) => (
								<Picker.Item
									key={i}
									label={majorProvinces[i].label}
									value={majorProvinces[i].value}
								/>
							))}
						</Picker>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Language  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowLanguage(!showLanguage)}
			>
				<View
					style={[
						{
							maxHeight: showLanguage ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSkills ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>
					<Text style={styles.title}>Language</Text>
					<Text style={styles.pickerInfoText}>
						Filter by Languages.{" "}
					</Text>

					{/* Render selected skill if any  */}
					<View
						style={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "row",
						}}
					>
						{selectedLanguage.length > 0
							? selectedLanguage.map((item, i) => (
									<TouchableOpacity
										onPress={(e) => {
											// Ignore parent touchable opacity
											e.stopPropagation();
											let newLang =
												selectedLanguage.filter(
													(newLang) =>
														newLang !== item
												);
											setSelectedLanguage(newLang);
										}}
									>
										<View
											key={i}
											style={{
												marginRight: 5,
												marginTop: 5,
												backgroundColor:
													backgroundVariant,
												border: `1px solid ${border}`,
												borderRadius:
													defaultStyles.radius,
												padding: 8,
												position: "relative",
											}}
										>
											<Text
												style={{
													color: textVariant,
													fontFamily:
														defaultStyles.regularFont,
												}}
											>
												{selectedLanguage[i]}
											</Text>
											{/* Close icon */}
											<View
												style={{
													position: "absolute",
													top: -5,
													right: -5,
													backgroundColor:
														backgroundVariant,
													borderRadius: "50%",
													padding: 2,
												}}
											>
												<FontAwesome
													name="close"
													size={8}
													color={textVariant}
												/>
											</View>
										</View>
									</TouchableOpacity>
							  ))
							: null}
					</View>
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Picker
							style={styles.picker}
							selectedValue={
								selectedLanguage.length > 0
									? selectedLanguage[
											selectedLanguage.length - 1
									  ]
									: "all"
							}
							onValueChange={(itemValue, itemIndex) =>
								setSelectedLanguage((prevState) => [
									...prevState,
									itemValue,
								])
							}
						>
							{majorLanguages.map((item, i) => (
								<Picker.Item
									key={i}
									label={majorLanguages[i].label}
									value={majorLanguages[i].value}
								/>
							))}
						</Picker>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* <TouchableOpacity
        style={styles.marginTop}
        onPress={() => setShowLanguage(!showLanguage)}
      >
        <View
          style={[
            {
              maxHeight: showLanguage ? '100%' : 48,
              overflow: 'hidden',
              position: 'relative',
              borderBottomColor: border,
              borderBottomWidth: 1,
              paddingBottom: 10,
            },
          ]}
        >
          <Text style={styles.title}>Language</Text>
          <Text style={styles.pickerInfoText}>Filter by language. </Text>
          <View
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <FontAwesome
              name={showSkills ? 'chevron-up' : 'chevron-down'}
              size={12}
              color={textVariant}
            ></FontAwesome>
          </View>
          <TouchableOpacity
            onPress={(e) => {
              e.stopPropagation();
            }}
          >
            <Picker
              style={styles.picker}
              selectedValue={selectedLanguage}
              onValueChange={(itemValue, itemIndex) =>
                setSelectedLanguage(itemValue)
              }
            >
              {majorLanguages.map((item, i) => (
                <Picker.Item
                  key={i}
                  label={majorLanguages[i].label}
                  value={majorLanguages[i].value}
                />
              ))}
            </Picker>
          </TouchableOpacity>
        </View>
      </TouchableOpacity> */}
		</View>
	);
};
