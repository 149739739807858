import { FontAwesome } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

import { subjects as subjectData } from "../config/subjects";

export const FiltersList = ({ modalVisible, setModalVisible, setFilters }) => {
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {},
		modalHeader: {
			flexDirection: "row",
			// justifyContent: 'space-between',
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			// position: 'absolute',
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		circleButton: {
			width: 40,
			height: 40,
			borderRadius: defaultStyles.buttonRadius,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			marginRight: 15,
			marginLeft: "auto",
		},
		applyButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 40,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
		},

		marginTop: {
			marginTop: 15,
		},
		title: {
			fontSize: 18,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	// States
	const [selectedSubject, setSelectedSubject] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [selectedRating, setSelectedRating] = useState(0);
	const [selectedLanguage, setSelectedLanguage] = useState("all");
	const [selectedLocation, setSelectedLocation] = useState("all");
	const [selectedPrice, setSelectedPrice] = useState("all");
	const [sliderPosition, setSliderPosition] = useState(0);
	const [verified, setVerified] = useState(false);

	// States for picker data
	const [subjects, setSubjects] = useState([]);
	const [skills, setSkills] = useState([]);
	const [majorCities, setMajorCities] = useState([]);
	const [majorProvinces, setMajorProvinces] = useState([]);
	const [majorLanguages, setMajorLanguages] = useState([]);
	const [instabook, setInstabook] = useState(false);

	// Functions
	const saveFilters = () => {
		setFilters([
			{ name: "subject", value: selectedSubject },
			{ name: "skills", value: selectedSkills },
		]);
	};

	useEffect(() => {
		if (subjectData) {
			var subjectsArr = [];
			let subjectKeys = Object.keys(subjectData);
			subjectKeys.forEach((subject) => {
				subjectsArr.push({
					subject: subject,
					value: subject,
				});
			});

			// Sort alphabetically
			subjectsArr.sort((a, b) => {
				if (a.subject < b.subject) {
					return -1;
				}

				if (a.subject > b.subject) {
					return 1;
				}

				return 0;
			});

			subjectsArr.unshift({
				subject: "All",
				value: "all",
			});

			setSubjects(subjectsArr);

			// Set skills
			var skillsArr = [];
			subjectKeys.forEach((subject) => {
				let skills = subjectData[subject];
				skills.forEach((skill) => {
					skillsArr.push({
						label: skill,
						value: skill,
					});
				});
			});

			// Sort alphabetically
			skillsArr.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}

				if (a.label > b.label) {
					return 1;
				}

				return 0;
			});

			skillsArr.unshift({
				label: "All",
				value: "all",
				enabled: false,
			});

			setSkills(skillsArr);
		}
	}, []);

	// If subjet is selected, set skills to only those skills
	useEffect(() => {
		if (selectedSubject.length > 0) {
			if (selectedSubject.includes("all")) {
				setSelectedSubject([]);
				var skillsArr = [];
				let subjectKeys = Object.keys(subjectData);
				subjectKeys.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			} else {
				var skillsArr = [];
				selectedSubject.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			}
		}
	}, [selectedSubject]);

	// If skills selected check if all is selected
	useEffect(() => {
		if (selectedSkills.length > 0) {
			if (selectedSkills.includes("all")) {
				setSelectedSkills([]);
			}
		}
	}, [selectedSkills]);

	// On value changes saveFilters
	useEffect(() => {
		saveFilters();
	}, [selectedSubject, selectedSkills]);

	const [showSubjects, setShowSubjects] = useState(false);
	const [showSkills, setShowSkills] = useState(false);

	return (
		<View style={styles.modalView}>
			{/* Subject  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowSubjects(!showSubjects)}
			>
				<View
					style={[
						{
							maxHeight: showSubjects ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<Text style={styles.title}>Subject</Text>
					<Text style={styles.pickerInfoText}>
						Filter by subject.{" "}
					</Text>

					{/* Dropdown icon */}
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSubjects ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>

					{/* Render selected subjects */}

					<View
						style={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
						}}
					>
						{selectedSubject.length > 0
							? selectedSubject.map((item, i) => (
									<TouchableOpacity
										key={`filters-subject-${i}`}
										onPress={(e) => {
											// Ignore parent touchable opacity
											e.stopPropagation();

											let newSelectedSubject = [
												...selectedSubject,
											];
											newSelectedSubject.splice(i, 1);
											setSelectedSubject(
												newSelectedSubject
											);
										}}
									>
										<View
											style={{
												marginRight: 5,
												marginTop: 5,
												backgroundColor:
													backgroundVariant,
												border: `1px solid ${border}`,
												borderRadius:
													defaultStyles.radius,
												padding: 8,
												position: "relative",
											}}
										>
											<Text
												style={{
													color: textVariant,
													fontFamily:
														defaultStyles.regularFont,
												}}
											>
												{selectedSubject[i]}
											</Text>

											{/* Close icon */}
											<View
												style={{
													position: "absolute",
													top: -5,
													right: -5,
													backgroundColor:
														backgroundVariant,
													borderRadius: "50%",
													padding: 2,
												}}
											>
												<FontAwesome
													name="close"
													size={8}
													color={textVariant}
												/>
											</View>
										</View>
									</TouchableOpacity>
							  ))
							: null}
					</View>
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Picker
							style={styles.picker}
							selectedValue={
								selectedSubject.length > 0
									? selectedSubject[
											selectedSubject.length - 1
									  ]
									: "all"
							}
							onValueChange={(itemValue, itemIndex) =>
								setSelectedSubject((prevState) => [
									...prevState,
									itemValue,
								])
							}
						>
							{subjects.map((item, i) => (
								<Picker.Item
									key={i}
									label={subjects[i].subject}
									value={subjects[i].value}
								/>
							))}
						</Picker>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>

			{/* Skills  */}
			<TouchableOpacity
				style={styles.marginTop}
				onPress={() => setShowSkills(!showSkills)}
			>
				<View
					style={[
						{
							maxHeight: showSkills ? "100%" : 48,
							overflow: "hidden",
							position: "relative",
							borderBottomColor: border,
							borderBottomWidth: 1,
							paddingBottom: 10,
						},
					]}
				>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
					>
						<FontAwesome
							name={showSkills ? "chevron-up" : "chevron-down"}
							size={12}
							color={textVariant}
						></FontAwesome>
					</View>
					<Text style={styles.title}>Skills</Text>
					<Text style={styles.pickerInfoText}>
						Filter by skills.{" "}
					</Text>

					{/* Render selected skill if any  */}
					<View
						style={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "row",
						}}
					>
						{selectedSkills.length > 0
							? selectedSkills.map((item, i) => (
									<TouchableOpacity
										onPress={(e) => {
											// Ignore parent touchable opacity
											e.stopPropagation();
											let newSkills =
												selectedSkills.filter(
													(skill) => skill !== item
												);
											setSelectedSkills(newSkills);
										}}
									>
										<View
											key={i}
											style={{
												marginRight: 5,
												marginTop: 5,
												backgroundColor:
													backgroundVariant,
												border: `1px solid ${border}`,
												borderRadius:
													defaultStyles.buttonRadius,
												padding: 8,
												position: "relative",
											}}
										>
											<Text
												style={{
													color: textVariant,
													fontFamily:
														defaultStyles.regularFont,
												}}
											>
												{selectedSkills[i]}
											</Text>
											{/* Close icon */}
											<View
												style={{
													position: "absolute",
													top: -5,
													right: -5,
													backgroundColor:
														backgroundVariant,
													borderRadius: "50%",
													padding: 2,
												}}
											>
												<FontAwesome
													name="close"
													size={8}
													color={textVariant}
												/>
											</View>
										</View>
									</TouchableOpacity>
							  ))
							: null}
					</View>
					<TouchableOpacity
						onPress={(e) => {
							e.stopPropagation();
						}}
					>
						<Picker
							style={styles.picker}
							selectedValue={
								selectedSkills.length > 0
									? selectedSkills[selectedSkills.length - 1]
									: "all"
							}
							onValueChange={(itemValue, itemIndex) =>
								setSelectedSkills((prevState) => [
									...prevState,
									itemValue,
								])
							}
						>
							{skills.map((item, i) => (
								<Picker.Item
									key={i}
									label={skills[i].label}
									value={skills[i].value}
								/>
							))}
						</Picker>
					</TouchableOpacity>
				</View>
			</TouchableOpacity>
		</View>
	);
};
