// Imports
import { FontAwesome } from "@expo/vector-icons";
import Slider from "@react-native-community/slider";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {
	addDoc,
	collection,
	doc,
	onSnapshot,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import { LoadingPage } from "../../../components/LoadingPage";
import { Header as MeetingHeader } from "../../../components/MeetingHeader";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

export const Meeting = ({ navigation, route, params }) => {
	const sessionId = route.params.sessionId;
	const [session, setSession] = useState(null);
	const [completed, setCompleted] = useState(false);
	const [selectedRating, setSelectedRating] = useState(5);
	const [review, setReview] = useState("");
	const [status, setStatus] = useState("");
	const [started, setStarted] = useState(false);
	const [endAt, setEndAt] = useState(null);
	const [endCountDown, setEndCountDown] = useState(null);
	const [reviewSubmitted, setReviewSubmitted] = useState(false);
	const [existingReview, setExistingReview] = useState(null);
	var interval;

	const customAlert = useAlert();
	const { db } = useFirebaseContext();
	const { isTutor, user, userId, name, profile } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page

	// Appearance
	const {
		darkMode,
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
			// padding: !isSm ? 15 : 45
		},
		confirmation: {
			fontSize: 19,
			fontFamily: defaultStyles.boldFont,
			color: text,
			textAlign: "center",
		},
		confirmButton: {
			backgroundColor: primary,
			padding: 15,
			borderRadius: defaultStyles.buttonRadius,
			marginTop: 15,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: 150,
		},
		confirmButtonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		completedText: {
			fontSize: 19,
			fontFamily: defaultStyles.boldFont,
			color: text,
			textAlign: "center",
		},
		rating: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			marginTop: 15,
			border: "1px solid " + border,
			borderRadius: defaultStyles.radius,
			padding: 30,
			margin: "auto",
			height: "100%",
			width: isMd ? "60%" : "100%",
			maxWidth: 750,
		},
		reviewInput: {
			width: "100%",
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			padding: 15,
			marginTop: 15,
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			textAlignVertical: "top",
		},
	});

	// Hooks

	useEffect(() => {
		if (sessionId && user) {
			setSession(null);
			setStarted(false);
			setCompleted(false);
			setStatus("");

			// Pull snapshot for session
			const q = query(
				collection(db, "sessions"),
				where("__name__", "==", sessionId)
			);
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setSession(doc.data());
					setEndAt(doc.data().endAt);
					// Set timer for endAt
					if (doc.data().endAt) {
						// Set end countdown to minutes and seconds left between now and endAt timestamp
						let mins = Math.floor(
							(doc.data().endAt.seconds -
								new Date().getTime() / 1000) /
								60
						);
						let secs = Math.floor(
							(doc.data().endAt.seconds -
								new Date().getTime() / 1000) %
								60
						);
						setEndCountDown(mins + ":" + secs);
					}

					// Remove video iframe
					if (
						(status !== doc.data().status &&
							doc.data().status == "completed") ||
						doc.data().status == "canceled"
					) {
						removeVideoContainer();
					}
					setStatus(doc.data().status);
					if (doc.data().status === "completed") {
						// Remove iframe
						removeVideoContainer();
						setCompleted(true);
					}
				});
			});

			if (isTutor) {
				// Find review for this session from the tutor
				const q2 = query(
					collection(db, "reviews"),
					where("sessionId", "==", sessionId),
					where("fromTutor", "==", true)
				);
				const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
					querySnapshot.forEach((doc) => {
						setExistingReview(doc.data());
					});
				});
				return unsubscribe;
			} else {
				// Find if review exists
				const q2 = query(
					collection(db, "reviews"),
					where("sessionId", "==", sessionId),
					where("fromTutor", "==", false)
				);
				const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
					querySnapshot.forEach((doc) => {
						setExistingReview(doc.data());
					});
				});
				return unsubscribe;
			}
		}
	}, [sessionId, isTutor, user]);

	// Session completed
	useEffect(() => {
		if (completed) {
			// Remove video
			removeVideoContainer();
		}
	}, [completed]);

	// Session status updated
	useEffect(() => {
		// If status is completed then email the user
		if (status === "completed" && isTutor) {
			// Send email to user
			fetch(`${hostname}/api/email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					authorization: "Bearer " + user.accessToken,
				},
				body: JSON.stringify({
					reason: "session-ended",
				}),
			});
		}

		// If session is in progress or live then start the video call
		if (
			(!started && status === "in progress") ||
			status === "live" ||
			(status === "confirmed" &&
				new Date(session.date + " " + session.time) <= new Date())
		) {
			if (isTutor) {
				updateDoc(doc(db, "users", userId), {
					instaBook: false,
				});
			}
			setStarted(true);
			startVideoCall();
		}
	}, [status]);

	// End countdown timer
	useEffect(() => {
		// If endCountDown is null then return
		if (endCountDown) {
			// Update timer every second
			interval = setInterval(() => {
				if (endAt == null) return clearInterval(interval);
				// Send minutes and seconds between now and endAt
				let mins = Math.floor(
					(endAt.seconds - new Date().getTime() / 1000) / 60
				);
				let secs = Math.floor(
					(endAt.seconds - new Date().getTime() / 1000) % 60
				);
				// Make 1 digit numbers 2 digits
				var displayMin = mins;
				var displaySec = secs;
				if (displayMin < 10) {
					displayMin = "0" + displayMin;
				}
				if (displaySec < 10) {
					displaySec = "0" + displaySec;
				}
				setEndCountDown(displayMin + ":" + displaySec);
				if (mins <= 0 && secs <= 0) {
					setEndCountDown(0);
					clearInterval(interval);
					endSessionFromTimer(true);
					return;
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [endCountDown]);

	// Start video call
	const startVideoCall = () => {
		if (
			(document.getElementById("video-container") &&
				sessionId &&
				user &&
				session &&
				session.status == "in progress") ||
			session.status == "live" ||
			(session.status === "confirmed" &&
				new Date(session.date + " " + session.time) <= new Date())
		) {
			// Remove video iframe
			removeVideoContainer();
			// Start video call
			const config = {
				name: name ? name.split(" ")[0] : "User",
				meetingId: sessionId,
				apiKey: "d3fd4304-4db3-40d1-a050-dcb3ef691f64",
				containerId: "video-container",
				micEnabled: false,
				webcamEnabled: false,
				participantCanToggleSelfWebcam: true,
				participantCanToggleSelfMic: true,
				screenShareEnabled: true,
				participantCanLeave: false,
				raiseHandEnabled: false,
				theme: darkMode ? "DARK" : "LIGHT",
				recording: {
					enabled: true,
					visible: true,
					autoStart: true,
					recordingEnabled: true,
				},
				joinScreen: {
					visible: false,
				},
				whiteboardEnabled: true,
				joinWithoutUserInteraction: true,
				chatEnabled: true,
				pollEnabled: false,
				autoStartRecording: true,

				recording: {
					enabled: true,
					autoStart: true,
				},
				permissions: {
					pin: false,
					drawOnWhiteboard: true,
					toggleWhiteboard: true,
					toggleRecording: false,
					removeParticipant: false,
					endMeeting: false,
				},
			};
			const meeting = new VideoSDKMeeting();
			meeting.init(config);
			return;
		} else {
			// Check if session is still active
			if (
				status === "completed" ||
				status === "canceled" ||
				status === "in progress" ||
				status === "live" ||
				status === "confirmed"
			) {
				return;
			}
			setTimeout(() => {
				startVideoCall();
			}, 1000);
		}
	};

	// Start the session before the timer ends and the session is canceled (ONLY FOR INSTABOOK)
	const startSession = () => {
		if (status !== "in progress") {
			// Set the endAt timestamp to 30 minutes from now
			const endAt = new Date();
			var sessionLength = session.minutes ? session.minutes : 15;
			endAt.setMinutes(endAt.getMinutes() + sessionLength);

			updateDoc(doc(db, "sessions", sessionId), {
				status: "in progress",
				endAt: endAt,
			});
		}
	};

	// End session
	const endSession = () => {
		if (status === "in progress" || status === "live") {
			// Add a confirmation alert
			confirmAlert({
				title: "End Session",
				customUI: ({ onClose }) => {
					return (
						<View
							style={{
								width: 450,
								backgroundColor: background,
								borderRadius: defaultStyles.radius,
								padding: 15,
								border: "1px solid" + border,
							}}
						>
							<Text
								style={{
									color: text,
									fontSize: 19,
									fontFamily: defaultStyles.titleFont,
									textAlign: "center",
									marginTop: 15,
								}}
							>
								Are you sure you want to end this session?
							</Text>

							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									textAlign: "center",
									marginTop: 15,
								}}
							>
								{isTutor
									? "Ending this session will end the call and the student will be charged for the session."
									: "Ending this session will end the call and you will be charged for the session."}
							</Text>

							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-around",
									marginTop: 20,
								}}
							>
								<TouchableOpacity
									onPress={() => {
										onClose();
									}}
									style={{
										backgroundColor: "transparent",
										width: 100,
										height: 40,
										borderRadius:
											defaultStyles.buttonRadius,
										borderColor: border,
										borderWidth: 1,
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Text
										style={{
											color: text,
											fontSize: 16,
											fontFamily:
												defaultStyles.regularFont,
											textAlign: "center",
										}}
									>
										Cancel
									</Text>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										endSessionFromTimer(false);
										onClose();
									}}
									style={{
										backgroundColor: primary,
										width: 100,
										height: 40,
										borderRadius:
											defaultStyles.buttonRadius,
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Text
										style={{
											color: "white",
											fontSize: 16,
											fontFamily:
												defaultStyles.regularFont,
											textAlign: "center",
										}}
									>
										End
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					);
				},
			});
		}
	};

	// Extend session
	const extendSession = () => {
		if (status === "in progress") {
			// Add a confirmation alert
			confirmAlert({
				title: "Extend Session",
				customUI: ({ onClose }) => {
					return (
						<View
							style={{
								width: 450,
								backgroundColor: background,
								borderRadius: defaultStyles.radius,
								padding: 15,
								border: "1px solid" + border,
							}}
						>
							<Text
								style={{
									color: text,
									fontSize: 19,
									fontFamily: defaultStyles.titleFont,
									textAlign: "center",
									marginTop: 15,
								}}
							>
								Would you like to extend this session?
							</Text>

							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									textAlign: "center",
									marginTop: 15,
								}}
							>
								Need more time? Hit ok and 15 minutes will be
								added to your session time. You will be charged
								for any added time!
							</Text>

							<View
								style={{
									flexDirection: "row",
									justifyContent: "center",
									marginTop: 30,
								}}
							>
								<TouchableOpacity
									style={{
										backgroundColor: green,
										width: 100,
										height: 40,
										marginLeft: 5,
										borderRadius:
											defaultStyles.buttonRadius,
										justifyContent: "center",
										alignItems: "center",
									}}
									onPress={() => {
										// Add 15 mins to current endAt by converting endAt to a date object
										let newEndAt = new Date(
											endAt.seconds * 1000
										);
										newEndAt.setMinutes(
											newEndAt.getMinutes() + 15
										);
										updateDoc(
											doc(db, "sessions", sessionId),
											{
												endAt: newEndAt,
											}
										);
										onClose();
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 16,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										Ok
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										backgroundColor: redVariant,
										width: 100,
										height: 40,
										marginLeft: 5,
										borderRadius:
											defaultStyles.buttonRadius,
										justifyContent: "center",
										alignItems: "center",
									}}
									onPress={() => {
										onClose();
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 16,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										Cancel
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					);
				},
			});
		}
	};

	// Session ending because of timer
	const endSessionFromTimer = (fromTimer) => {
		if (status == "live") {
			updateDoc(doc(db, "sessions", sessionId), {
				status: "canceled",
				canceledAt: serverTimestamp(),
			});
		}

		if (status == "in progress") {
			// Get length of session based off the date & time fields of session compared to the current time
			var startAt;
			var date = session.date; // 2023-03-07
			var time = session.time; // 12:00 PM

			// Convert date and time to a date object
			var dateObj = new Date(date + " " + time);
			startAt = dateObj;

			// Convert endAt seconds to a date object
			var endAtDate;

			if (fromTimer) {
				endAtDate = new Date(endAt.seconds * 1000);
			} else {
				endAtDate = new Date();
			}

			// Get how many minutes the session lasted
			var length = Math.floor((endAtDate - startAt) / 1000 / 60);

			// Get the amount made by multiplying the length of the session by the tutor's rate (hourly rate / 60)
			var amount = Math.floor(length * (session.tutorProfile.rate / 60));

			// If amount is less than 1, set it to 1
			if (amount < 1) {
				amount = 1;
			}
			amount = amount.toFixed(2);

			// Charge user for session
			fetch(`${hostname}/api/capture-charge`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + user.accessToken,
				},
				body: JSON.stringify({
					amount: amount,
					accountId: session.tutorProfile.stripe.accountId,
					customerId: session.user.customerId,
					paymentIntentId: session.paymentIntentId,
					sessionId: sessionId,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					let paymentIntentId = data.paymentIntentId;
					let status = data.status;
					updateDoc(doc(db, "sessions", sessionId), {
						status: "completed",
						completedAt: serverTimestamp(),
						lengthInMinutes: parseFloat(length.toFixed(1)),
						earnings: parseFloat(amount),
						paymentIntentId: paymentIntentId,
						paymentStatus: status,
					});
				})
				.catch((err) => {
					updateDoc(doc(db, "sessions", sessionId), {
						status: "completed",
						completedAt: serverTimestamp(),
						lengthInMinutes: parseFloat(length.toFixed(1)),
						earnings: parseFloat(amount),
						paymentStatus: "failed",
					});
				});
		}
	};

	// Remove video iframe
	const removeVideoContainer = () => {
		var iframes = document.querySelectorAll("iframe");
		for (var i = 0; i < iframes.length; i++) {
			iframes[i].parentNode.removeChild(iframes[i]);
		}
	};

	// Render pages

	// Loading animation, if session is null then show loading animation
	if (!session || !user || isTutor === null) {
		return <LoadingPage></LoadingPage>;
	}

	// Session status set to cancel
	if (session.status === "canceled") {
		return (
			<SafeAreaView style={styles.container}>
				<MeetingHeader
					status={session.status}
					isTutor={isTutor}
					name={
						isTutor
							? session.user.name.split(" ")[0]
							: session.tutorProfile.name.split(" ")[0] + "."
					}
					image={
						isTutor
							? session.user.image
							: session.tutorProfile.picture
					}
					description={session.note}
					navigation={navigation}
				></MeetingHeader>
				<View style={styles.maxWidth}>
					<View
						style={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Text style={styles.completedText}>
							We're sorry it didn't work out!
						</Text>
					</View>
				</View>
			</SafeAreaView>
		);
	}

	// Session status set to completed
	if (session.status === "completed") {
		return (
			<SafeAreaView style={styles.container}>
				<MeetingHeader
					status={session.status}
					endAt={endAt}
					endCountDown={endCountDown}
					isTutor={isTutor}
					name={
						isTutor
							? session.user.name.split(" ")[0]
							: session.tutorProfile.name.split(" ")[0] + "."
					}
					image={
						isTutor
							? session.user.image
							: session.tutorProfile.picture
					}
					description={session.note}
					navigation={navigation}
				></MeetingHeader>

				<View style={styles.maxWidth}>
					<ScrollView
						contentContainerStyle={{ flexGrow: 1 }}
						showsVerticalScrollIndicator={false}
						style={{
							flex: 1,
							padding: !isSm ? 15 : 45,
							paddingTop: 0,
							marginTop: 30,
						}}
					>
						<View style={styles.completed}>
							{isTutor ? (
								<>
									<View
										style={{
											padding: 15,
											width: "100%",
											flex: 1,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<Text style={styles.completedText}>
											Session completed
										</Text>
										<Text style={styles.completedText}>
											${session.earnings} earned
										</Text>
									</View>

									{existingReview ? (
										<View>
											<View style={styles.rating}>
												<Text
													style={styles.completedText}
												>
													Your rating for{" "}
													{
														session.user.name.split(
															" "
														)[0]
													}
												</Text>
												<Text style={{ marginTop: 15 }}>
													{[
														...Array(
															existingReview.rating
														),
													].map((e, i) => {
														return (
															<FontAwesome
																style={{
																	marginRight: 5,
																}}
																name="star"
																size={18}
																color={yellow}
															/>
														);
													})}
												</Text>
												<Text
													style={{
														marginTop: 15,
														color: text,
													}}
												>
													{existingReview.review}
												</Text>
											</View>
										</View>
									) : (
										<View>
											<View style={styles.rating}>
												<Text
													style={styles.completedText}
												>
													Review{" "}
													{
														session.user.name.split(
															" "
														)[0]
													}
												</Text>
												<Text style={{ marginTop: 15 }}>
													{[
														...Array(
															selectedRating
														),
													].map((e, i) => {
														return (
															<FontAwesome
																style={{
																	marginRight: 5,
																}}
																name="star"
																size={18}
																color={yellow}
															/>
														);
													})}
												</Text>
												<Slider
													style={{
														width: "100%",
														height: 40,
													}}
													step={1}
													value={selectedRating}
													minimumValue={0}
													maximumValue={5}
													onValueChange={(val) =>
														setSelectedRating(val)
													}
													minimumTrackTintColor={
														primary
													}
													maximumTrackTintColor={
														border
													}
													thumbTintColor={primary}
												/>

												<TextInput
													style={styles.reviewInput}
													placeholder="Write a review"
													placeholderTextColor={
														textVariant
													}
													value={review}
													onChangeText={(text) =>
														setReview(text)
													}
												/>

												<TouchableOpacity
													disabled={
														reviewSubmitted
															? true
															: false
													}
													style={[
														styles.confirmButton,
														{ width: "100%" },
													]}
													onPress={() => {
														if (reviewSubmitted) {
															return;
														}
														setReviewSubmitted(
															true
														);
														// Save review
														addDoc(
															collection(
																db,
																"reviews"
															),
															{
																tutor: session
																	.user.uid,
																user: {
																	picture:
																		profile,
																	name: name,
																	uid: user.uid,
																},
																rating: selectedRating,
																review: review
																	? review
																	: "",
																created:
																	new Date(),
																sessionId:
																	sessionId,
																fromTutor: true,
															}
														)
															.then(() => {
																customAlert.success(
																	"[Review Submitted] Thank you for leaving your feedback!"
																);
																return;
															})
															.catch((error) => {
																setReviewSubmitted(
																	false
																);
																customAlert.error(
																	"[Error with Review] There was a network error... Please try again later."
																);
																return;
															});
													}}
												>
													<Text
														style={
															styles.confirmButtonText
														}
													>
														Submit
													</Text>
												</TouchableOpacity>
											</View>
										</View>
									)}
								</>
							) : (
								<>
									{existingReview ? (
										<View>
											<View style={styles.rating}>
												<Text
													style={styles.completedText}
												>
													Your rating for{" "}
													{
														session.tutorProfile.name.split(
															" "
														)[0]
													}
												</Text>
												<Text style={{ marginTop: 15 }}>
													{[
														...Array(
															existingReview.rating
														),
													].map((e, i) => {
														return (
															<FontAwesome
																style={{
																	marginRight: 5,
																}}
																name="star"
																size={18}
																color={yellow}
															/>
														);
													})}
												</Text>
												<Text
													style={{
														marginTop: 15,
														color: text,
													}}
												>
													{existingReview.review}
												</Text>
											</View>
										</View>
									) : (
										<View>
											<View style={styles.rating}>
												<Text
													style={styles.completedText}
												>
													Review{" "}
													{
														session.tutorProfile.name.split(
															" "
														)[0]
													}
												</Text>
												<Text style={{ marginTop: 15 }}>
													{[
														...Array(
															selectedRating
														),
													].map((e, i) => {
														return (
															<FontAwesome
																style={{
																	marginRight: 5,
																}}
																name="star"
																size={18}
																color={yellow}
															/>
														);
													})}
												</Text>
												<Slider
													style={{
														width: "100%",
														height: 40,
													}}
													step={1}
													value={selectedRating}
													minimumValue={0}
													maximumValue={5}
													onValueChange={(val) =>
														setSelectedRating(val)
													}
													minimumTrackTintColor={
														primary
													}
													maximumTrackTintColor={
														border
													}
													thumbTintColor={primary}
												/>

												<TextInput
													style={styles.reviewInput}
													placeholder="Write a review"
													placeholderTextColor={
														textVariant
													}
													value={review}
													onChangeText={(text) =>
														setReview(text)
													}
												/>

												<TouchableOpacity
													disabled={
														reviewSubmitted
															? true
															: false
													}
													style={[
														styles.confirmButton,
														{ width: "100%" },
													]}
													onPress={() => {
														if (reviewSubmitted) {
															return;
														}
														setReviewSubmitted(
															true
														);
														// Save review
														addDoc(
															collection(
																db,
																"reviews"
															),
															{
																tutor: session
																	.tutorProfile
																	.id,
																user: {
																	picture:
																		profile,
																	name: name,
																	uid: user.uid,
																},
																rating: selectedRating,
																review: review
																	? review
																	: "",
																created:
																	new Date(),
																sessionId:
																	sessionId,
																fromTutor: false,
															}
														)
															.then(() => {
																customAlert.success(
																	"[Review Submitted] Thank you for leaving your feedback!"
																);

																return;
															})
															.catch((error) => {
																setReviewSubmitted(
																	false
																);
																customAlert.error(
																	"[Error with Review] There was a network error... Please try again later."
																);
																return;
															});
													}}
												>
													<Text
														style={
															styles.confirmButtonText
														}
													>
														Submit
													</Text>
												</TouchableOpacity>
											</View>
										</View>
									)}
								</>
							)}
						</View>
					</ScrollView>
				</View>
			</SafeAreaView>
		);
	}

	// Session is in progress render video
	if (
		session.status === "in progress" ||
		session.status === "live" ||
		(session.status === "confirmed" &&
			new Date(session.date + " " + session.time) <= new Date())
	) {
		// Return meeting screen
		return (
			<SafeAreaView style={styles.container}>
				<MeetingHeader
					startSession={startSession}
					extendSession={extendSession}
					status={status}
					endAt={endAt}
					endSession={endSession}
					endCountDown={endCountDown}
					isTutor={isTutor}
					name={
						isTutor
							? session.user.name.split(" ")[0]
							: session.tutorProfile.name.split(" ")[0] + "."
					}
					image={
						isTutor
							? session.user.image
							: session.tutorProfile.picture
					}
					description={session.note}
					navigation={navigation}
				></MeetingHeader>

				<View style={styles.maxWidth}>
					<ScrollView
						contentContainerStyle={{ flexGrow: 1 }}
						showsVerticalScrollIndicator={false}
						style={{ flex: 1 }}
					>
						<View
							nativeID="video-container"
							style={{
								flex: 1,
								width: "100%",
							}}
						></View>
					</ScrollView>
				</View>
			</SafeAreaView>
		);
	}

	// Session is pending so render confirmation screen
	if (session.status === "pending") {
		return (
			<SafeAreaView style={styles.container}>
				<MeetingHeader
					status={session.status}
					isTutor={isTutor}
					name={
						isTutor ? (
							<>
								{session.user.name.split(" ").length > 1
									? session.user.name.split(" ")[0] +
									  " " +
									  session.user.name.split(" ")[1][0] +
									  "."
									: session.user.name.split(" ")[0]}
							</>
						) : (
							<>
								{session.tutorProfile.name.split(" ").length > 1
									? session.tutorProfile.name.split(" ")[0] +
									  " " +
									  session.tutorProfile.name.split(
											" "
									  )[1][0] +
									  "."
									: session.tutorProfile.name.split(" ")[0]}
							</>
						)
					}
					image={
						isTutor
							? session.user.image
							: session.tutorProfile.picture
					}
					description={session.note}
					navigation={navigation}
				></MeetingHeader>
				<View style={styles.maxWidth}>
					<View
						style={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Text style={styles.completedText}>
							Meeting has not started yet.
						</Text>
					</View>
				</View>
			</SafeAreaView>
		);
	}
};
