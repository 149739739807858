import React, { useEffect } from "react";
import {
	Menu,
	MenuOptions,
	MenuOption,
	MenuTrigger,
} from "react-native-popup-menu";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
	Switch,
	Image,
	View,
	StyleSheet,
	TouchableOpacity,
	Picker,
	Text,
	TextInput,
} from "react-native";

import { useColorsContext } from "../utils/styles";
import { useStyles } from "../utils/styles";
import { FontAwesome5 } from "@expo/vector-icons";
import { useFirebaseContext } from "../contexts/firebaseContext";
import { fonts } from "@rneui/base";
import {
	collection,
	deleteDoc,
	updateDoc,
	doc,
	addDoc,
	where,
	query,
	getDocs,
	serverTimestamp,
} from "firebase/firestore";

export const InstabookMenu = (props) => {
	const { db } = useFirebaseContext();
	let { instaBookMinimum, instaBookLength, instaBook, userId } = props;
	const [instaBookMinimumState, setInstaBookMinimumState] =
		React.useState(instaBookMinimum);

	useEffect(() => {
		setInstaBookMinimumState(instaBookMinimum);
	}, [instaBookMinimum]);

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	//  Create stylesheet
	const styles = StyleSheet.create({
		picker: {
			height: 50,
			// width: 100,
			color: text,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
		label: {
			fontFamily: defaultStyles.boldFont,
			color: text,
			marginBottom: 10,
			fontSize: 16,
		},
		description: {
			fontFamily: defaultStyles.regularFont,
			color: text,
			marginBottom: 10,
			fontSize: 12,
		},
	});

	const tutorial = () => {
		confirmAlert({
			title: "InstaBook",
			customUI: ({ onClose }) => {
				return (
					<View
						style={{
							width: "75%",
							maxWidth: 1050,
							margin: "auto",
							backgroundColor: background,
							borderRadius: 10,
							padding: 30,
							border: "1px solid" + border,
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 19,
								fontFamily: defaultStyles.titleFont,
								marginTop: 10,
							}}
						>
							How to use InstaBook
						</Text>

						{/* GIF GUIDE */}
						<Image
							source={require("../assets/guide.gif")}
							style={{
								height: 350,
								width: 350,
								margin: "auto",
								marginTop: 15,
								border: "1px solid" + primaryVariant,
								borderRadius: 10,
							}}
						/>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 30,
							}}
						>
							InstaBook gives you the ability to be instantly
							booked by students looking for on-demand help.
						</Text>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 8,
							}}
						>
							1. To use InstaBook, simply set the default length
							of your sessions, and the minimum fee you would like
							to charge. The standard rate will be your hourly
							rate multiplied by the length of the session however
							if the amount is less than the minimum fee you will
							be receive the minimum fee.
						</Text>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 8,
							}}
						>
							2. After configuring your settings you can flip on
							InstaBook and you will be able to be booked by
							students looking for on-demand help!
						</Text>

						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginTop: 8,
							}}
						>
							3. When a student books a session with you you will
							be redirected to a video session, you will start the
							session after you both discuss the details in the 5
							minute window provided before the session starts.
						</Text>

						<View
							style={{
								flexDirection: "row",
								// justifyContent: "space-around",
								marginTop: 20,
							}}
						>
							<TouchableOpacity
								onPress={() => {
									onClose();
								}}
								style={{
									backgroundColor: primary,
									width: 100,
									height: 40,
									borderRadius: defaultStyles.buttonRadius,
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Text
									style={{
										color: "white",
										fontSize: 16,
										fontFamily: defaultStyles.regularFont,
										textAlign: "center",
									}}
								>
									Got it
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				);
			},
		});
	};

	return (
		<Menu>
			<MenuTrigger>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: instaBook
							? primary
							: backgroundVariant,
						borderRadius: defaultStyles.buttonRadius,
						border: "1px solid" + primary,
						paddingVertical: 10,
						paddingHorizontal: 25,
						marginRight: 15,
					}}
				>
					<Text
						style={{
							color: darkMode || instaBook ? "#fff" : "#000",
							fontSize: 16,
							fontFamily: defaultStyles.boldFont,
						}}
					>
						InstaBook {instaBook ? "on" : "off"}
					</Text>
				</View>
			</MenuTrigger>

			<MenuOptions
				optionsContainerStyle={{
					backgroundColor: backgroundVariant,
					borderRadius: defaultStyles.radius,
					padding: 15,
					marginLeft: 0,
					marginTop: 60,
					width: 300,
					shadowColor: "rgba(0, 0, 0, 0.1)",
					shadowOpacity: 0.8,
					shadowOffset: { width: 2, height: 2 },
					shadowRadius: 4,
				}}
				customStyles={{
					optionText: {
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
					},
				}}
			>
				<MenuOption
					onSelect={(e) => {
						// console.log(e);
						// e.preventDefault();
						return;
					}}
					disableTouchable={true}
				>
					<View>
						{/* Label */}
						<Text style={styles.label}>Instabook</Text>

						{/* Description */}
						<Text style={styles.description}>
							Turn Instabook on/off
						</Text>

						<Switch
							size={20}
							trackColor={{ false: border, true: primary }}
							thumbColor={instaBook ? "#fff" : primary}
							onValueChange={() => {
								// Check if user has already activated instabook in past via localStorage
								if (
									localStorage.getItem("instaBook2") === null
								) {
									// If not, show tutorial
									tutorial();
									localStorage.setItem("instaBook2", true);
									return;
								}

								updateDoc(doc(db, "users", userId), {
									instaBook: !instaBook,
								})
									.then(() => {})
									.catch((error) => {});
							}}
							value={instaBook}
						/>
					</View>
				</MenuOption>

				<MenuOption
					onSelect={(e) => {
						// console.log(e);
						// e.preventDefault();
						return;
					}}
					disableTouchable={true}
				>
					<View
						style={{
							marginTop: 15,
						}}
					>
						{/* Label */}
						<Text style={styles.label}>InstaBook length</Text>

						{/* Description */}
						<Text style={styles.description}>
							Set the default length of your InstaBook sessions.
						</Text>

						<Picker
							selectedValue={instaBookLength}
							style={styles.picker}
							onValueChange={(itemValue, itemIndex) => {
								// // Update user in database with new instabook value
								updateDoc(doc(db, "users", userId), {
									instaBookLength: parseInt(itemValue),
								})
									.then(() => {})
									.catch((error) => {
										console.log(error);
									});
							}}
						>
							<Picker.Item label="15 mins" value="15" />
							<Picker.Item label="30 mins" value="30" />
							<Picker.Item label="45 mins" value="45" />
							<Picker.Item label="1 hour" value="60" />
							<Picker.Item label="1.5 hours" value="90" />
							<Picker.Item label="2 hours" value="120" />
						</Picker>
					</View>
				</MenuOption>

				<MenuOption
					onSelect={(e) => {
						// console.log(e);
						// e.preventDefault();
						return;
					}}
					disableTouchable={true}
				>
					<View
						style={{
							marginTop: 15,
						}}
					>
						{/* Label */}
						<Text style={styles.label}>Minimum fee ($)</Text>

						{/* Description */}
						<Text style={styles.description}>
							If the time of your session is less than the minimum
							fee, you will earn the minimum fee.
						</Text>

						<TextInput
							style={styles.picker}
							placeholder="Minimum fee"
							placeholderTextColor={textVariant}
							keyboardType="numeric"
							value={instaBookMinimumState}
							// value={minimumFee}
							onChangeText={(text) => {
								setInstaBookMinimumState(text);
								// setMinimumFee(text);
								// If minimum fee is less than 1 set it to 1
								if (parseInt(text) < 1) {
									text = 1;
								}
								updateDoc(doc(db, "users", userId), {
									instaBookMinimum: parseInt(text),
								})
									.then(() => {})
									.catch((error) => {});
							}}
							min={1}
						/>
					</View>
				</MenuOption>

				<MenuOption
					onSelect={(e) => {
						// console.log(e);
						// e.preventDefault();
						return;
					}}
					disableTouchable={true}
				>
					<View
						style={{
							marginTop: 15,
						}}
					>
						{/* Description */}
						<Text style={styles.description}>
							Click below for a quick tutorial on using InstaBook!
						</Text>

						{/* Action */}
						<TouchableOpacity
							onPress={() => {
								// console.log('Open tutorial');
								tutorial();
							}}
						>
							{/* Help Icon */}
							<FontAwesome5
								name="question-circle"
								size={24}
								color={primary}
								style={{}}
							/>
						</TouchableOpacity>
					</View>
				</MenuOption>
			</MenuOptions>
		</Menu>
	);
};
