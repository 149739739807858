// Imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import {
	Platform,
	ScrollView,
	Keyboard,
	TouchableWithoutFeedback,
	Alert,
	StyleSheet,
	View,
	TextInput,
	TouchableOpacity,
	KeyboardAvoidingView,
	Text,
} from "react-native";
import {
	getAuth,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { useColorsContext, useStyles } from "../../utils/styles";
import { Header } from "../../components/AuthHeader";
import { useMediaQuery } from "react-responsive";
import { useUserContext } from "../../contexts/userContext";
import { useAlert } from "react-alert";
import { Feather } from "@expo/vector-icons";
import Footer from "../../components/Footer";
import Hoverable from "../../utils/hover/Hoverable";
import { Link } from "@react-navigation/native";

// Components
export const Login = ({ navigation, route, params }) => {
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Media queries
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Alert
	const customAlert = useAlert();

	// States and variables
	const { user } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const auth = getAuth();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		if (user) {
			window.location.href = "/";
			if (user.isTutor) {
				navigation.navigate("Home", {
					screen: "Sessions",
				});
			} else {
				navigation.navigate("Home");
			}
		}
	}, [user]);

	// Sign in function
	const signIn = async () => {
		if (email && password) {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {
					// Signed in
					const user = userCredential.user;
					// ...
				})
				.catch(async (error) => {
					const errorCode = error.code;
					const errorMessage = error.message;
					if (errorCode == "auth/wrong-password") {
						Platform.OS === "web"
							? customAlert.error("[Login Error] Wrong password")
							: Alert.alert(
									"Error logging in!",
									"Wrong password",
									[
										{
											text: "OK",
											onPress: () =>
												console.log("OK Pressed"),
										},
									]
							  );
					} else if (errorCode == "auth/invalid-email") {
						Platform.OS === "web"
							? customAlert.error("[Login Error] Invalid email")
							: Alert.alert(
									"Error logging in!",
									"Invalid email",
									[
										{
											text: "OK",
											onPress: () =>
												console.log("OK Pressed"),
										},
									]
							  );
					} else if (errorCode == "auth/too-many-requests") {
						Platform.OS === "web"
							? customAlert.error(
									"[Login Error] Too many requests"
							  )
							: Alert.alert(
									"Error logging in!",
									"Too many requests",
									[
										{
											text: "OK",
											onPress: () =>
												console.log("OK Pressed"),
										},
									]
							  );
					} else if (errorCode == "auth/user-not-found") {
						Platform.OS === "web"
							? customAlert.error(
									"[Login Error] No account with this email exists"
							  )
							: Alert.alert(
									"Error logging in!",
									"User not found",
									[
										{
											text: "OK",
											onPress: () =>
												console.log("OK Pressed"),
										},
									]
							  );
					} else {
						Platform.OS === "web"
							? customAlert.error(
									"[Login Error] Error logging in!"
							  )
							: Alert.alert("Error logging in!", errorMessage, [
									{
										text: "OK",
										onPress: () =>
											console.log("OK Pressed"),
									},
							  ]);
					}
				});
		} else {
			Platform.OS === "web"
				? customAlert.error(
						"[Login Error] Please enter an email and password"
				  )
				: Alert.alert(
						"Error logging in!",
						"Please enter an email and password",
						[
							{
								text: "OK",
								onPress: () => console.log("OK Pressed"),
							},
						]
				  );
		}
	};

	// Stylesheet

	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		title: {
			marginBottom: 30,
			fontSize: 36,
			color: text,
			fontWeight: "bold",
			fontFamily: "Satoshi_900",
		},
		input: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
		},
		button: {
			backgroundColor: primary,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.buttonRadius,
			textAlign: "center",
			fontFamily: defaultStyles.boldFont,
		},
		link: {
			marginTop: 15,
			fontSize: 14,
			color: primaryVariant,
			fontFamily: defaultStyles.boldFont,
		},
	});

	// Return login screen
	return (
		<SafeAreaView style={styles.container}>
			<Header navigation={navigation}></Header>

			<View style={styles.maxWidth}>
				<ScrollView
					style={{ paddingTop: 120, flex: 1 }}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					contentContainerStyle={{
						flexGrow: 1,
						justifyContent: "space-between",
					}}
				>
					<View
						style={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							width: "100%",
							maxWidth: 950,
							margin: "auto",
							padding: 15,
							paddingBottom: 60,
						}}
					>
						<Text style={styles.title}>Login</Text>
						<TextInput
							value={email}
							onChangeText={(t) => {
								setEmail(t);
							}}
							placeholderTextColor="#999999"
							style={styles.input}
							textContentType="emailAddress"
							placeholder="Email"
						></TextInput>
						<View style={{ alignItems: "flex-end" }}>
							<View
								style={[
									styles.input,
									{
										width: "100%",
										display: "flex",
										flexDirection: "row-reverse",
									},
								]}
							>
								<TouchableOpacity
									onPress={() => {
										setShowPassword(!showPassword);
									}}
									style={{
										marginRight: 5,
										maxWidth: 30,
										flex: 1,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{showPassword ? (
										<Feather
											name="eye-off"
											size={16}
											color={textVariant}
										/>
									) : (
										<Feather
											name="eye"
											size={16}
											color={textVariant}
										/>
									)}
								</TouchableOpacity>

								<TextInput
									style={{
										fontFamily: defaultStyles.regularFont,
										color: text,
										fontSize: 16,
										flex: 1,
									}}
									value={password}
									onChangeText={(t) => {
										setPassword(t);
									}}
									placeholderTextColor="#999999"
									secureTextEntry={
										showPassword ? false : true
									}
									textContentType="password"
									placeholder="Password"
								></TextInput>
							</View>
							<TouchableOpacity
								onPress={() => {
									if (!email) {
										Platform.OS === "web"
											? customAlert.error(
													"[Login Error] Please enter your email"
											  )
											: Alert.alert(
													"Error sending password reset email!",
													"Please enter your email",
													[
														{
															text: "OK",
															onPress: () =>
																console.log(
																	"OK Pressed"
																),
														},
													]
											  );
										return;
									}
									sendPasswordResetEmail(auth, email)
										.then(() => {
											// Password reset email sent!
											// ..
											Platform.OS === "web"
												? customAlert.success(
														"[Password Reset] Check your inbox for a link to reset your password."
												  )
												: Alert.alert(
														"Password reset email sent!",
														"Check your inbox",
														[
															{
																text: "OK",
																onPress: () =>
																	console.log(
																		"OK Pressed"
																	),
															},
														]
												  );
										})
										.catch((error) => {
											const errorCode = error.code;
											const errorMessage = error.message;
											// ..
											Platform.OS === "web"
												? customAlert.error(
														"[Login Error] Error sending password reset email try again!"
												  )
												: Alert.alert(
														"Error sending password reset email!",
														errorMessage,
														[
															{
																text: "OK",
																onPress: () =>
																	console.log(
																		"OK Pressed"
																	),
															},
														]
												  );
										});
								}}
							>
								<Text style={styles.link}>
									Forgot Password?
								</Text>
							</TouchableOpacity>
						</View>

						<Hoverable>
							{(isHovered) => (
								<TouchableOpacity
									onPress={() => {
										signIn();
									}}
									style={[
										styles.button,
										{
											backgroundColor: isHovered
												? primaryVariant
												: primary,
										},
									]}
								>
									<Text
										style={{
											color: "#fff",
											fontFamily: defaultStyles.boldFont,
											fontSize: 16,
										}}
									>
										Login
									</Text>
								</TouchableOpacity>
							)}
						</Hoverable>

						<Link
							style={{ margin: "auto", marginTop: 60 }}
							to={{
								screen: "Signup",
							}}
						>
							<Text
								style={[
									{
										textAlign: "center",
										color: textVariant,
										fontFamily: defaultStyles.lightFont,
										fontSize: 14,
									},
								]}
							>
								Don't have an account?{" "}
								<Text style={styles.link}>Register</Text>
							</Text>
						</Link>
					</View>
					{/* Footer  */}
					<Footer></Footer>
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};
