// Package imports
import React, { useEffect, useState } from "react";
import tutredLogo from "../assets/tutred_logo.png";
import tutredLogoSmall from "../assets/tutred_logo_small.png";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
	MaterialIcons,
	FontAwesome,
	FontAwesome5,
	AntDesign,
	Feather,
} from "@expo/vector-icons";
import {
	Switch,
	Image,
	View,
	StyleSheet,
	TouchableOpacity,
	Text,
} from "react-native";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { getAuth } from "firebase/auth";
// Picker
import { Picker } from "@react-native-picker/picker";
import {
	Menu,
	MenuOptions,
	MenuOption,
	MenuTrigger,
} from "react-native-popup-menu";
import { InstabookMenu } from "./InstabookMenu";
import { useAlert } from "react-alert";
import {
	collection,
	deleteDoc,
	updateDoc,
	doc,
	addDoc,
	where,
	query,
	getDocs,
	serverTimestamp,
} from "firebase/firestore";
// import { A } from '@expo/html-elements';
import { Link } from "@react-navigation/native";

// File imports
import { useFirebaseContext } from "../contexts/firebaseContext";
import { useUserContext } from "../contexts/userContext";
import { useSessionContext } from "../contexts/sessionContext";
import { VerifyModal } from "./VerifyModal";
import { StripeAccountModal } from "./StripeAccountModal";
import { Onboarding } from "./Onboarding";

export const Header = ({ navigation, page, isFromSettings }) => {
	// Firebase
	const { db } = useFirebaseContext();

	// Appearance
	const {
		toggleDarkMode,
		darkMode,
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	const auth = getAuth();
	const {
		showStripeOnboarding,
		accountId,
		isStripeConnected,
		isTutor,
		isVerified,
		instaBook,
		userId,
		user,
		profile,
		isOnboarded,
		name,
		instaBookLength,
		instaBookMinimum,
		URL,
		unreadMessages,
	} = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const { pendingSessions } = useSessionContext();
	const customAlert = useAlert();
	const [verifyModal, setVerifyModal] = useState(false);
	const [stripeAccountModal, setStripeAccountModal] = useState(false);
	const [showOnboarding, setShowOnboarding] = useState(false);
	const [notifications, setNotifications] = useState(0);
	const [notificationsSet, setNotificationsSet] = useState(false);

	// const [image, setImage] = useState(profile);

	// Stylesheet
	const styles = StyleSheet.create({
		header: {
			backgroundColor: background,
			width: "100%",
			flexDirection: "row",
			paddingHorizontal: !isSm ? 15 : 45,
			paddingVertical: 20,
			alignItems: "center",
			justifyContent: "space-between",
			borderBottomWidth: 1,
			borderBottomColor: border,
			zIndex: 100,
		},
		menuText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
		},
		picker: {
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	const NavigationOption = ({
		name,
		icon,
		menuNavigation,
		link,
		notifications,
	}) => {
		const [isHovered, setIsHovered] = useState(false);

		const handlePressIn = () => {
			setIsHovered(true);
		};

		const handlePressOut = () => {
			setIsHovered(false);
		};
		return (
			<MenuOption
				onSelect={() => {
					if (menuNavigation) {
						menuNavigation();
					}
				}}
				style={{
					backgroundColor: isHovered ? border : backgroundVariant,
					borderRadius: defaultStyles.radius,
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginBottom: 10,
				}}
			>
				<Link
					onMouseEnter={handlePressIn}
					onMouseLeave={handlePressOut}
					to={link}
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: 10,
						borderRadius: defaultStyles.radius,
					}}
				>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							width: 25,
							height: 25,
							display: "flex",
							margin: 5,
						}}
					>
						<AntDesign
							size={19}
							name={icon}
							color={textVariant}
						></AntDesign>
					</View>
					<Text style={styles.menuText}>
						{name}{" "}
						{notifications ? (
							<Text
								style={{
									color: primary,
									fontFamily: defaultStyles.boldFont,
								}}
							>
								({notifications})
							</Text>
						) : null}
					</Text>
				</Link>
			</MenuOption>
		);
	};

	const ProfileNavigation = () => {
		const [isHovered, setIsHovered] = useState(false);

		const handlePressIn = () => {
			setIsHovered(true);
		};

		const handlePressOut = () => {
			setIsHovered(false);
		};

		return (
			<MenuOption
				onSelect={() => {
					if (isTutor) {
						navigation.navigate("Tutors", {
							screen: "TutorProfile",
							tutorId: URL,
						});
					} else {
						navigation.navigate("Listings", {
							screen: "StudentProfile",
							student: URL,
						});
					}
				}}
				style={{
					backgroundColor: isHovered ? border : backgroundVariant,
					borderRadius: defaultStyles.radius,
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginBottom: 10,
				}}
			>
				<Link
					onMouseEnter={handlePressIn}
					onMouseLeave={handlePressOut}
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: 10,
					}}
					to={isTutor ? `/tutors/${URL}` : `/students/${URL}`}
				>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							width: 25,
							height: 25,
							display: "flex",
							margin: 5,
						}}
					>
						<AntDesign
							size={19}
							name={"user"}
							color={textVariant}
						></AntDesign>
					</View>
					<Text style={styles.menuText}>Profile</Text>
				</Link>
			</MenuOption>
		);
	};

	useEffect(() => {
		if (isTutor) {
			var num = 0;
			if (isOnboarded) {
				if (!isVerified) {
					num += 1;
				}
				if (!isStripeConnected) {
					num += 1;
				}
			} else {
				num = 1;
			}
			setNotificationsSet(true);
			setNotifications(num);
		}
	}, [isOnboarded]);

	useEffect(() => {
		if (notificationsSet) {
			if (isTutor) {
				var num = 0;
				if (isOnboarded) {
					if (!isVerified) {
						num += 1;
					}
					if (!isStripeConnected) {
						num += 1;
					}
				} else {
					num = 1;
				}
				setNotifications(num);
			}
		}
	}, [isOnboarded, isVerified, isStripeConnected]);

	return (
		<View style={styles.header}>
			{/* Onboarding Modal  */}
			{showOnboarding && isTutor && (
				<View
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
						zIndex: 100,
					}}
				>
					<Onboarding
						name={name}
						user={user}
						visible={showOnboarding}
						setVisible={setShowOnboarding}
					></Onboarding>
				</View>
			)}
			{/* Logo left */}
			<Link
				to={{
					screen: "Tutors",
				}}
			>
				{isMd ? (
					<Image
						style={{ maxHeight: 30, height: 25, width: 200 }}
						source={{ uri: tutredLogo }}
					/>
				) : (
					<Image
						style={{ width: 160, minHeight: 20 }}
						source={{ uri: tutredLogoSmall }}
					/>
				)}
			</Link>

			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{/* Instabook toggle */}
				{isTutor && isOnboarded && (
					<InstabookMenu
						instaBook={instaBook}
						instaBookLength={instaBookLength}
						instaBookMinimum={instaBookMinimum}
						userId={userId}
					></InstabookMenu>
				)}

				{/* Welcome message */}
				{user && (
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							marginLeft: 15,
							display: isTutor && !isMd ? "none" : "flex",
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 16,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							Welcome, {name.split(" ")[0]}
						</Text>
					</View>
				)}

				{/* Initial Notifications */}
				{user && isTutor && (showStripeOnboarding || !isVerified) && (
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							marginLeft: 15,
							display: isTutor && !isMd ? "none" : "flex",
						}}
					>
						<Menu>
							<MenuTrigger
								className="pulse"
								style={{
									position: "relative",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									height: 45,
									width: 45,
									borderRadius: 100,
									border: "1px solid" + border,
									marginLeft: 15,
								}}
							>
								<Feather name="bell" size={16} color={text} />

								{/* Noti circle */}
								<View
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										backgroundColor: primary,
										borderRadius: 100,
										height: 15,
										width: 15,
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 10,
											textAlign: "center",
											fontFamily: defaultStyles.boldFont,
											lineHeight: 15,
										}}
									>
										{notifications}
									</Text>
								</View>
							</MenuTrigger>

							<MenuOptions
								optionsContainerStyle={{
									backgroundColor: backgroundVariant,
									borderRadius: defaultStyles.radius,
									paddingVertical: 15,
									paddingHorizontal: 10,
									marginLeft: -120,
									marginTop: 60,
									shadowColor: "rgba(0, 0, 0, 0.1)",
									shadowOpacity: 0.8,
									shadowOffset: { width: 2, height: 2 },
									shadowRadius: 4,
								}}
								customStyles={{
									optionText: {
										fontSize: 16,
										fontFamily: defaultStyles.regularFont,
									},
								}}
							>
								{!isOnboarded && isTutor && (
									<MenuOption
										style={{
											display: "flex",
										}}
										onSelect={() => {
											setShowOnboarding(true);
										}}
									>
										<View
											style={{
												flexDirection: "row",
											}}
										>
											<View
												style={{
													marginRight: 15,
													paddingTop: 6,
												}}
											>
												<View
													style={{
														borderRadius: 100,
														backgroundColor:
															primaryVariant,
														justifyContent:
															"center",
														alignItems: "center",
														width: 6,
														height: 6,
														display: "flex",
													}}
												></View>
											</View>

											<View
												style={{
													flex: 1,
													display: "flex",
													flexDirection: "column",
													flexWrap: "wrap",
												}}
											>
												<Text
													style={{
														color: text,
														fontSize: 14,
														fontFamily:
															defaultStyles.boldFont,
													}}
												>
													Complete profile
												</Text>
												<Text
													style={{
														color: textVariant,
														fontSize: 14,
														fontFamily:
															defaultStyles.regularFont,
													}}
												>
													Please complete your profile
													to be listed.
												</Text>
											</View>
										</View>
									</MenuOption>
								)}

								{isOnboarded &&
									isTutor &&
									showStripeOnboarding && (
										<>
											<MenuOption
												style={
													notifications > 1
														? {
																display: "flex",
																borderBottomColor:
																	border,
																borderBottomWidth: 1,
																marginBottom: 10,
																paddingBottom: 15,
														  }
														: {
																display: "flex",
														  }
												}
												onSelect={() => {
													setStripeAccountModal(true);
												}}
											>
												<View
													style={{
														flexDirection: "row",
													}}
												>
													<View
														style={{
															marginRight: 15,
															paddingTop: 6,
														}}
													>
														<View
															style={{
																borderRadius: 100,
																backgroundColor:
																	primaryVariant,
																justifyContent:
																	"center",
																alignItems:
																	"center",
																width: 6,
																height: 6,
																display: "flex",
															}}
														></View>
													</View>

													<View
														style={{
															flex: 1,
															display: "flex",
															flexDirection:
																"column",
															flexWrap: "wrap",
														}}
													>
														<Text
															style={{
																color: text,
																fontSize: 14,
																fontFamily:
																	defaultStyles.boldFont,
															}}
														>
															Connect Bank
														</Text>
														<Text
															style={{
																color: textVariant,
																fontSize: 14,
																fontFamily:
																	defaultStyles.regularFont,
															}}
														>
															Connect your bank
															account to receive
															payments
														</Text>
													</View>
												</View>
											</MenuOption>
										</>
									)}

								{isOnboarded && isTutor && !isVerified && (
									<MenuOption
										style={{
											display: "flex",
										}}
										onSelect={() => {
											setVerifyModal(true);
										}}
									>
										<View
											style={{
												flexDirection: "row",
											}}
										>
											<View
												style={{
													marginRight: 15,
													paddingTop: 6,
												}}
											>
												<View
													style={{
														borderRadius: 100,
														backgroundColor:
															primaryVariant,
														justifyContent:
															"center",
														alignItems: "center",
														width: 6,
														height: 6,
														display: "flex",
													}}
												></View>
											</View>

											<View
												style={{
													flex: 1,
													display: "flex",
													flexDirection: "column",
													flexWrap: "wrap",
												}}
											>
												<Text
													style={{
														color: text,
														fontSize: 14,
														fontFamily:
															defaultStyles.boldFont,
													}}
												>
													Get verified
												</Text>
												<Text
													style={{
														color: textVariant,
														fontSize: 14,
														fontFamily:
															defaultStyles.regularFont,
													}}
												>
													Submit a verification
													request to boost your
													profile!
												</Text>
											</View>
										</View>
									</MenuOption>
								)}
							</MenuOptions>
						</Menu>
					</View>
				)}

				{/* Profile pic with hamburger menu for navigation */}
				{isSm && (
					<Menu>
						<MenuTrigger
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								border: "1px solid" + border,
								borderRadius: 100,
								height: 45,
								marginLeft: 15,
								paddingHorizontal: 15,
							}}
						>
							<FontAwesome5
								name="bars"
								size={16}
								color={text}
								style={{ marginRight: 15 }}
							></FontAwesome5>

							<Image
								style={{
									height: 30,
									width: 30,
									borderRadius: 100,
									border: "1px solid" + border,
								}}
								source={
									profile
										? { uri: profile }
										: {
												uri: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
										  }
								}
							/>
						</MenuTrigger>

						<MenuOptions
							optionsContainerStyle={{
								backgroundColor: backgroundVariant,
								borderRadius: defaultStyles.radius,
								padding: 15,
								marginLeft: 0,
								marginTop: 60,
								shadowColor: "rgba(0, 0, 0, 0.1)",
								shadowOpacity: 0.8,
								shadowOffset: { width: 2, height: 2 },
								shadowRadius: 4,
							}}
							customStyles={{
								optionText: {
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
								},
							}}
						>
							{/* Navigation options: Sessions, Messages, Profile */}
							{isTutor && isOnboarded && (
								<NavigationOption
									name={
										pendingSessions &&
										pendingSessions.length > 0
											? `Sessions (${pendingSessions.length})`
											: `Sessions`
									}
									icon="laptop"
									link={"/sessions"}
									menuNavigation={() => {
										navigation.navigate("Sessions");
									}}
								></NavigationOption>
							)}

							{user && !isTutor && (
								<NavigationOption
									name={
										pendingSessions &&
										pendingSessions.length > 0
											? `Sessions (${pendingSessions.length})`
											: `Sessions`
									}
									icon="laptop"
									link={"/sessions"}
									menuNavigation={() => {
										navigation.navigate("Sessions");
									}}
								></NavigationOption>
							)}

							{/* Listings */}
							{isTutor && isOnboarded && (
								<NavigationOption
									name={"Student Listings"}
									icon="book"
									link={"/listings"}
									menuNavigation={() => {
										navigation.navigate("Listings");
									}}
								></NavigationOption>
							)}

							{!isTutor && user && (
								<NavigationOption
									name={"My Listings"}
									icon="book"
									link={"/listings"}
									menuNavigation={() => {
										navigation.navigate("Listings");
									}}
								></NavigationOption>
							)}

							{!isTutor && user && (
								<NavigationOption
									name={"Favourites"}
									icon="hearto"
									link={"/tutors/favourites"}
									menuNavigation={() => {
										navigation.navigate("Favourites");
									}}
								></NavigationOption>
							)}

							{isTutor && isOnboarded && (
								<NavigationOption
									name={"Inbox"}
									icon="mail"
									link={"/chats"}
									notifications={unreadMessages.length}
									menuNavigation={() => {
										navigation.navigate("Chats");
									}}
								></NavigationOption>
							)}

							{user && !isTutor && (
								<NavigationOption
									name={"Inbox"}
									icon="mail"
									notifications={unreadMessages.length}
									link={"/chats"}
									menuNavigation={() => {
										navigation.navigate("Chats");
									}}
								></NavigationOption>
							)}

							{/* Student Navigation Menu */}
							<>
								<NavigationOption
									name={"Settings"}
									icon="setting"
									link={"/settings"}
									menuNavigation={() => {
										navigation.navigate("Profile");
									}}
								></NavigationOption>
							</>

							{/* Divider */}
							<View
								style={{
									height: 1,
									width: "100%",
									backgroundColor: border,
									marginBottom: 10,
								}}
							></View>

							{user && isOnboarded && isTutor && (
								<ProfileNavigation />
							)}

							{user && !isTutor && <ProfileNavigation />}

							<MenuOption
								onSelect={() => {
									auth.signOut();
									window.location.reload();
								}}
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: 10,
								}}
							>
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
										width: 25,
										height: 25,
										display: "flex",
										margin: 5,
									}}
								>
									<AntDesign
										size={19}
										name="logout"
										color={textVariant}
									></AntDesign>
								</View>
								<Text style={styles.menuText}>Log out</Text>
							</MenuOption>
						</MenuOptions>
					</Menu>
				)}

				<VerifyModal
					verifyModal={verifyModal}
					setVerifyModal={setVerifyModal}
				></VerifyModal>

				<StripeAccountModal
					display={stripeAccountModal}
					setDisplay={setStripeAccountModal}
				></StripeAccountModal>
			</View>
		</View>
	);
};
