// Imports
import { A } from "@expo/html-elements";
import {
	AntDesign,
	Feather,
	FontAwesome,
	FontAwesome5,
} from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import * as Clipboard from "expo-clipboard";
import * as Sharing from "expo-sharing";
import { getAuth } from "firebase/auth";
import {
	collection,
	doc,
	getDocs,
	limit,
	onSnapshot,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
	Image,
	Picker,
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../../../components/AuthHeader";
import { Header } from "../../../components/Header";
import { useColorsContext, useStyles } from "../../../utils/styles";

import FadeIn from "react-native-fade-in-image";
import Footer from "../../../components/Footer";

import { BookSessionModal } from "../../../components/BookSessionModal";
import { LoadingPage } from "../../../components/LoadingPage";
import { ReportModal } from "../../../components/ReportModal";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useSessionContext } from "../../../contexts/sessionContext";
import { useUserContext } from "../../../contexts/userContext";

const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";
const shareLink = dev
	? "https://tutred-staging.vercel.app"
	: "https://tutred.com";

import { languages as languagesData } from "../../../config/languages";
import {
	provinces as provincesData,
	statesUSA,
} from "../../../config/locations";
import { schools } from "../../../config/schools";
import { subjects as subjectsData } from "../../../config/subjects";

export const EditProfileNew = ({
	urlId,
	editProfile,
	setEditProfile,
	navigation,
	route,
}) => {
	// Firebase
	const { db } = useFirebaseContext();
	const auth = getAuth();
	const { isTutor, customerId, user, name, profile, favourites } =
		useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const { pendingSessions, createInstaBookSession } = useSessionContext();
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [reportModal, setReportModal] = useState(false);

	// Alert
	const customAlert = useAlert();

	// Responsive Breakpoints
	const isXxl = useMediaQuery({ query: "(min-width: 1395px)" });
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		purple,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// States and variables

	const [tutorId, setTutorId] = useState(null);
	const [totalSessions, setTotalSessions] = useState(0);
	const [sessions, setSessions] = useState([]);
	const [reviews, setReviews] = useState([]);
	const [rating, setRating] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [shareAvailable, setShareAvailable] = useState(false);
	const [hoursSinceOnline, setHoursSinceOnline] = useState(null);
	const [tutor, setTutor] = useState(null);
	const [bookable, setBookable] = useState(false);
	const [view, setView] = useState("personal");

	// Profile values
	const [cities, setCities] = useState([
		{ city: "Select city", enabled: false },
	]);
	const [provinces, setProvinces] = useState([
		{ province: "Select province", enabled: false },
	]);
	const [tutorName, setTutorName] = useState(name);
	const [city, setCity] = useState("");
	const [selectedProvince, setSelectedProvince] = useState("");
	const [selectedCountry, setSelectedCountry] = useState("");

	// Tutor values
	const [subjects, setSubjects] = useState([
		{ subject: "Select subject", enabled: false, value: "Select subject" },
	]);
	const [skills, setSkills] = useState([
		{ skill: "Select skills", enabled: false },
	]);
	const [languages, setLanguages] = useState([
		{ lang: "Select language", enabled: false },
	]);
	const years = [
		{ year: "Select year", enabled: false },
		{ year: "In progress", enabled: true },
		{ year: 2023, enabled: true },
		{ year: 2022, enabled: true },
		{ year: 2021, enabled: true },
		{ year: 2020, enabled: true },
		{ year: 2019, enabled: true },
		{ year: 2018, enabled: true },
		{ year: 2017, enabled: true },
		{ year: 2016, enabled: true },
		{ year: 2015, enabled: true },
		{ year: 2014, enabled: true },
		{ year: 2013, enabled: true },
		{ year: 2012, enabled: true },
		{ year: 2011, enabled: true },
		{ year: 2010, enabled: true },
		{ year: 2009, enabled: true },
		{ year: 2008, enabled: true },
		{ year: 2007, enabled: true },
		{ year: 2006, enabled: true },
		{ year: 2005, enabled: true },
		{ year: 2004, enabled: true },
		{ year: 2003, enabled: true },
		{ year: 2002, enabled: true },
		{ year: 2001, enabled: true },
		{ year: 2000, enabled: true },
		{ year: 1999, enabled: true },
		{ year: 1998, enabled: true },
		{ year: 1997, enabled: true },
		{ year: 1996, enabled: true },
		{ year: 1995, enabled: true },
		{ year: 1994, enabled: true },
		{ year: 1993, enabled: true },
		{ year: 1992, enabled: true },
		{ year: 1991, enabled: true },
		{ year: 1990, enabled: true },
		{ year: 1989, enabled: true },
	];
	const [universities, setUniversities] = useState([
		{ school: "Select university", enabled: false },
	]);
	const degrees = [
		{ name: "Select degree", enabled: false },
		{ name: "Bachelor of Arts", enabled: true },
		{ name: "Bachelor of Business Administration", enabled: true },
		{ name: "Bachelor of Commerce", enabled: true },
		{ name: "Bachelor of Computer Science", enabled: true },
		{ name: "Bachelor of Education", enabled: true },
		{ name: "Bachelor of Engineering", enabled: true },
		{ name: "Bachelor of Fine Arts", enabled: true },
		{ name: "Bachelor of Health Sciences", enabled: true },
		{ name: "Bachelor of Journalism", enabled: true },
		{ name: "Bachelor of Laws (LLB)", enabled: true },
		{ name: "Bachelor of Music", enabled: true },
		{ name: "Bachelor of Nursing", enabled: true },
		{ name: "Bachelor of Science", enabled: true },
		{ name: "Bachelor of Social Work", enabled: true },
		{ name: "Bachelor of Technology", enabled: true },
		{
			name: "Bachelor of Tourism and Hospitality Management",
			enabled: true,
		},
	];
	const [selectedSubject, setSelectedSubject] = useState([]);
	const [selectedSkill, setSelectedSkill] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState([]);
	const [description, setDescription] = useState("");
	// Reset degree values
	const resetDegreeValues = () => {
		setSelectedDegree("");
		setSelectedUniversity("");
		setSelectedCompletionYear("");
		setSelectedDegreeType("");
		setCustomUniversity("");
	};

	// Education values
	const [degreeArray, setDegreeArray] = useState([]);
	const [selectedUniversity, setSelectedUniversity] = useState("");
	const [selectedDegree, setSelectedDegree] = useState("");
	const [selectedCompletionYear, setSelectedCompletionYear] = useState("");
	const [selectedDegreeType, setSelectedDegreeType] = useState("");
	const [customUniversity, setCustomUniversity] = useState("");

	// Degree info
	const degreeInfo = {
		school:
			selectedUniversity == "Other"
				? customUniversity
				: selectedUniversity,
		name: selectedDegree,
		type: selectedDegreeType,
		year: selectedCompletionYear,
	};

	useEffect(() => {
		if (subjectsData) {
			let array = [];
			let subjectKeys = Object.keys(subjectsData);
			subjectKeys.map((subject) => {
				array.push({
					label: subject,
					subject: subject,
					enabled: true,
				});
			});
			// Sort alphabetically
			array.sort((a, b) => (a.label > b.label ? 1 : -1));
			array.unshift({
				label: "Select a subject",
				subject: "Select a subject",
				enabled: false,
			});
			setSubjects(array);

			// Set skills
			let skillsArray = [];
			subjectKeys.map((subject) => {
				let subjectData = subjectsData[subject];
				subjectData.map((skill) => {
					skillsArray.push({
						label: skill,
						skill: skill,
						enabled: true,
					});
				});
			});

			// Sort alphabetically
			skillsArray.sort((a, b) => (a.label > b.label ? 1 : -1));
			skillsArray.unshift({
				label: "Select a skill",
				skill: "Select a skill",
				enabled: false,
			});
			setSkills(skillsArray);
		}

		if (languagesData) {
			let array = [];
			languagesData.map((language) => {
				array.push({
					label: language,
					lang: language,
					enabled: true,
				});
			});
			// Sort alphabetically
			array.sort((a, b) => (a.label > b.label ? 1 : -1));
			array.unshift({
				label: "Select a language",
				lang: "Select a language",
				enabled: false,
			});
			setLanguages(array);
		}

		if (selectedCountry == "US") {
			if (statesUSA) {
				let array = [];
				statesUSA.map((province) => {
					array.push({
						label: province,
						province: province,
						enabled: true,
					});
				});
				// Sort alphabetically
				array.sort((a, b) => (a.label > b.label ? 1 : -1));
				array.unshift({
					label: "Select a province",
					province: "Select a province",
					enabled: false,
				});
				setProvinces(array);
			}
		} else {
			if (provincesData) {
				let array = [];
				provincesData.map((province) => {
					array.push({
						label: province,
						province: province,
						enabled: true,
					});
				});
				// Sort alphabetically
				array.sort((a, b) => (a.label > b.label ? 1 : -1));
				array.unshift({
					label: "Select a province",
					province: "Select a province",
					enabled: false,
				});
				setProvinces(array);
			}

			if (schools) {
				let array = [];
				schools.map((school) => {
					array.push({
						label: school,
						school: school,
						enabled: true,
					});
				});
				// Sort alphabetically
				array.sort((a, b) => (a.label > b.label ? 1 : -1));
				array.unshift({
					label: "Select a school",
					school: "Select a school",
					enabled: false,
				});
				array.push({
					label: "Other",
					school: "Other",
					enabled: true,
				});
				setUniversities(array);
			}
		}
	}, [selectedCountry, selectedProvince]);

	// Stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		menuText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
		},
		menuTextSmall: {
			color: text,
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 15,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			// display: 'flex',
			// flexDirection: Platform.OS === 'web' ? 'row' : null,
			// flexDirection: 'row',
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},

		mainSection: {
			flex: 1,
			position: "relative",
		},
		subtitle: {
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		profileImg: {
			marginBottom: 5,
			borderRadius: 100,
			width: 130,
			height: 130,
			opacity: 1,
			marginRight: 20,
		},
		title: {
			fontSize: 32,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		online: {
			marginLeft: 15,
			fontSize: 14,
			color: "#00AA54",
			fontFamily: defaultStyles.boldFont,
		},
		banner: {
			shadowColor: "#171717",
			shadowOffset: { width: -2, height: 4 },
			shadowOpacity: 0.2,
			shadowRadius: 3,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			height: 50,
			backgroundColor: backgroundVariant,
		},
		verified: {
			fontSize: 14,
			color: green,
			fontFamily: defaultStyles.boldFont,
		},
		description: {
			marginTop: 5,
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
			maxWidth: 925,
			// paddingHorizontal: 15,
		},
		circleButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 45,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			border: "1px solid " + primary,
			backgroundColor: "transparent",
			width: 245,
		},
		button: {
			borderRadius: defaultStyles.buttonRadius,
			height: 45,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
			width: 245,
		},
		disabledButton: {
			opacity: 0.5,
		},
		buttonText: {
			color: "#fff",
			fontSize: 19,
			fontFamily: defaultStyles.titleFont,
		},
		tutorInfo: {
			fontSize: 16,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		tutorDescriptionTitles: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
		},
		showMore: {
			color: textVariant,
			fontFamily: defaultStyles.boldFont,
			fontSize: 16,
		},
		actionText: {
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			fontSize: 14,
			marginLeft: 5,
			textDecorationColor: textVariant,
			textDecorationLine: "underline",
		},
		input: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 5,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			paddingVertical: 15,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			fontSize: 16,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 150,
		},
		wordContainer: {
			marginTop: 10,
			display: "flex",
			flexDirection: "row",
			width: "90%",
			flexWrap: "wrap",
			marginBottom: -10,
		},
		addDegreeButton: {
			color: primary,
			fontSize: 14,
			fontFamily: defaultStyles.boldFont,
			marginTop: 10,
		},
	});

	// Skills / subject / language component
	const WordCard = ({ word, array }) => {
		return (
			<View
				style={{
					marginRight: 5,
					marginBottom: 5,
					backgroundColor: backgroundVariant,
					borderRadius: defaultStyles.buttonRadius,
					padding: 5,
					border: `.5px solid ${border}`,
					display: "flex",
					flexDirection: "row",
					alignContent: "center",
					alignItems: "center",
					justifyContent: "space-evenly",
					height: 30,
				}}
			>
				<Text
					style={{
						fontSize: 14,
						color: textVariant,
						fontFamily: defaultStyles.regularFont,
						padding: 5,
					}}
				>
					{word}
				</Text>
				<TouchableOpacity
					style={{ paddingHorizontal: 5 }}
					onPress={() => {
						// Remove from subjects array
						if (array == "subject") {
							let newArray = [...selectedSubject];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedSubject(newArray);
						}
						// Remove from skills array
						else if (array == "skill") {
							let newArray = [...selectedSkill];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedSkill(newArray);
						}
						// Remove from languages array
						else if (array == "languages") {
							let newArray = [...selectedLanguage];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSelectedLanguage(newArray);
						}
					}}
				>
					<CloseOutlined
						style={{ fontSize: 14, color: text }}
					></CloseOutlined>
				</TouchableOpacity>
			</View>
		);
	};

	// Degree component
	const DegreeCard = ({ year, degree, school, type }) => {
		return (
			<View
				style={{
					//   height: 75,
					padding: 10,
					minWidth: 150,
					borderRadius: defaultStyles.radius,
					backgroundColor: backgroundVariant,
					border: `.5px solid ${border}`,
					marginTop: 10,
					marginRight: 10,
				}}
			>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<View>
						<Text
							style={{
								color: primary,
								fontSize: 16,
								fontFamily: defaultStyles.boldFont,
								marginBottom: 2.5,
							}}
						>
							{degree}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
								marginBottom: 5,
							}}
						>
							{type}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
							}}
						>
							{school}
						</Text>
						<Text
							style={{
								color: text,
								fontSize: 14,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{year}
						</Text>
					</View>
					<View>
						<TouchableOpacity
							onPress={() => {
								let newArray = [...degreeArray];
								newArray = newArray.filter(function (item) {
									return item.year !== year;
								});
								// Set state to mutated array
								setDegreeArray(newArray);
							}}
						>
							<CloseOutlined
								style={{
									fontSize: 14,
									color: text,
									padding: 10,
								}}
							></CloseOutlined>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};

	// Get reviews & tutor
	useEffect(() => {
		// Set sharing
		Sharing.isAvailableAsync().then((result) => {
			setShareAvailable(result);
		});
		// Get tutor data
		const q = query(
			collection(db, "users"),
			where("URL", "==", parseInt(urlId)),
			where("isTutor", "==", true),
			where("isSignedUp", "==", true),
			limit(1)
		);
		onSnapshot(q, (querySnapshot) => {
			querySnapshot.forEach((doc) => {
				let tutor = doc.data();

				// Set profile values
				setCity(tutor.location.city);
				setSelectedProvince(tutor.location.province);
				setSelectedCountry(tutor.location.country);

				// Set tutor values
				setSelectedSubject(
					tutor.subjects ? Object.keys(tutor.subjects) : []
				);
				setSelectedSkill(tutor.skills);
				setSelectedLanguage(
					tutor.languages ? Object.keys(tutor.languages) : []
				);
				setDescription(tutor.description);

				// Education values
				setDegreeArray(doc.data().degrees);

				let today = new Date();
				var hoursSinceOnline;
				if (tutor.lastOnline == null) {
					hoursSinceOnline = null;
				} else {
					let lastOnline = new Date(tutor.lastOnline.toDate());
					hoursSinceOnline = Math.floor(
						(today - lastOnline) / (1000 * 60 * 60)
					);
				}
				setTutorId(tutor.uid);
				setTutor(tutor);
				setHoursSinceOnline(hoursSinceOnline);
				setBookable(true);
				// if (tutor.stripe && tutor.stripe.confirmed) {
				//   setBookable(true);
				// }
			});
		});
	}, []);

	useEffect(() => {
		if (tutorId) {
			setLoaded(true);
			// Snapshot listener for total tutor sessions
			onSnapshot(
				query(
					collection(db, "sessions"),
					where("tutor", "==", tutorId)
				),
				where("status", "==", "completed"),
				(querySnapshot) => {
					let sessions = [];
					querySnapshot.forEach((doc) => {
						let session = doc.data();
						if (
							session.status == "completed" &&
							session.lengthInMinutes > 0
						) {
							sessions.push(session);
						}
					});
					setSessions(sessions);
					setTotalSessions(sessions.length);
				}
			);
			// Snapshot listener for tutor reviews
			const unsubscribe = onSnapshot(
				query(collection(db, "reviews"), where("tutor", "==", tutorId)),
				(querySnapshot) => {
					let reviews = [];
					querySnapshot.forEach((doc) => {
						let review = doc.data();
						reviews.push(review);
					});
					setReviews(reviews);
				}
			);
			return unsubscribe;
		}
	}, [tutorId]);

	// Get rating
	useEffect(() => {
		// Get rating
		let rating = 0;
		reviews.map((review) => {
			rating += review.rating;
		});
		rating = rating / reviews.length || 0;
		setRating(rating.toFixed(1));
	}, [reviews]);

	if (!loaded) {
		return <LoadingPage></LoadingPage>;
	}
	// Return 404
	if (!tutor && loaded) {
		return (
			<SafeAreaView style={styles.container}>
				<View style={styles.maxWidth}>
					<View
						style={[
							styles.mainSection,
							{
								alignItems: "center",
								justifyContent: "center",
							},
						]}
					>
						<Text style={styles.title}>404 Tutor not found</Text>
					</View>
				</View>
			</SafeAreaView>
		);
	}

	// Sort the ratings from highest rating to lowest
	reviews.sort((a, b) => {
		return b.rating - a.rating;
	});

	//  update user info
	const updateUserInfo = async function () {
		// validate input
		if (selectedLanguage.length === 0) {
			customAlert.error(
				"[Edit Profile Error] Please select at least one language!"
			);
			return;
		}
		if (selectedSubject.length === 0) {
			customAlert.error(
				"[Edit Profile Error] Please select at least one subject!"
			);
			return;
		}
		if (degreeArray.length > 3) {
			customAlert.error(
				"[Edit Profile Error] Please enter at most three degrees!"
			);
			return;
		}

		// add boolleen to language and subject
		let languages = {};
		let subjects = {};
		selectedLanguage.forEach((language) => {
			languages[language] = true;
		});
		selectedSubject.forEach((subject) => {
			subjects[subject] = true;
		});
		const userRef = collection(db, "users");
		const q = query(userRef, where("uid", "==", user.uid));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			updateDoc(doc.ref, {
				name: tutorName,
				skills: selectedSkill,
				languages: languages,
				subjects: subjects,
				location: {
					city: city,
					province: selectedProvince,
					country: selectedCountry,
				},
				description: description,
				degrees: degreeArray,
			});
		});
		customAlert.success(
			"[Profile Updated] The changes to your profile have been saved!"
		);
	};

	// Render
	return (
		<SafeAreaView style={styles.container}>
			{user ? (
				<Header navigation={navigation}></Header>
			) : (
				<AuthHeader navigation={navigation}></AuthHeader>
			)}

			{/* If viewing your own profile show banner */}

			{user && user.uid === tutor.uid ? (
				<View style={[styles.banner]}>
					<Text
						style={{
							color: text,
							fontFamily: defaultStyles.boldFont,
							fontSize: 16,
						}}
					>
						You are in edit profile mode
						{/* Public icon */}
						<FontAwesome5
							name="user-edit"
							size={16}
							color={primaryVariant}
							style={{ marginLeft: 5 }}
						></FontAwesome5>
					</Text>
				</View>
			) : null}

			<View style={styles.maxWidth}>
				{/* Tutor info section  */}
				<ScrollView
					showsVerticalScrollIndicator={false}
					style={styles.mainSection}
				>
					{/* MODAL  */}
					<BookSessionModal
						modalVisible={modalVisible}
						setModalVisible={setModalVisible}
						tutor={tutor}
						navigation={navigation}
					/>

					{/* REPORT MODAL  */}
					<ReportModal
						modalVisible={reportModal}
						setModalVisible={setReportModal}
						tutor={tutor}
						navigation={navigation}
					/>

					<View
						style={{
							display: "flex",
							// LAYOUT FLEX
							flexDirection: isLg ? "row" : "column",
							width: "100%",
							paddingVertical: 30,
							paddingHorizontal: isMd ? 60 : 30,
						}}
					>
						<View
							style={{
								// Set width for all breakpoints
								borderRightColor: border,
								borderRightWidth: !isLg ? 0 : 1,
								maxWidth: isLg ? 375 : "100%",
								minWidth: isLg ? 300 : "100%",
							}}
						>
							<View
								style={{
									flex: 1,
									paddingTop: 0,
									paddingBottom: !isSm ? 15 : 20,
									paddingLeft: !isSm ? 15 : 0,
									display: "flex",
									flexDirection: "column",
									minWidth: !isSm && "100%",
									borderBottomColor: border,
									borderBottomWidth: !isLg ? 1 : 0,
								}}
							>
								<View
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 30,
										paddingRight: 20,
									}}
								>
									{/* Report and Favourite */}
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											gap: 15,
										}}
									>
										{/* Report */}
										<TouchableOpacity
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
											}}
											onPress={() => {
												if (
													user &&
													user.uid == tutor.uid
												) {
													customAlert.error(
														"[Error] You can't report yourself"
													);
												} else {
													if (!user) {
														customAlert.error(
															"[Error] Please sign in to report a tutor"
														);
													}
													setReportModal(true);
												}
											}}
										>
											{/* Icon */}
											<FontAwesome
												name="flag-o"
												size={14}
												color={textVariant}
											></FontAwesome>

											<Text style={styles.actionText}>
												Report
											</Text>
										</TouchableOpacity>

										{/* Favourite */}
										{favourites.includes(tutor.uid) ? (
											<TouchableOpacity
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
												}}
												onPress={() => {
													if (!user) {
														customAlert.error(
															"[Error] Please sign in to favourite a tutor"
														);
													}
													if (
														user &&
														user.uid == tutor.uid
													) {
														customAlert.error(
															"[Error] You can't favourite yourself"
														);
													} else {
														// Remove tutor ID from favourite array and update user doc
														var favs = favourites;
														var index =
															favs.indexOf(
																tutor.uid
															);
														if (index > -1) {
															favs.splice(
																index,
																1
															);
														}

														const userRef = doc(
															db,
															"users",
															user.uid
														);
														updateDoc(userRef, {
															favourites: favs,
														});
													}
												}}
											>
												{/* Icon */}
												<FontAwesome
													name="heart"
													size={14}
													color={primaryVariant}
												></FontAwesome>

												<Text style={styles.actionText}>
													Unfavourite
												</Text>
											</TouchableOpacity>
										) : (
											<TouchableOpacity
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
												}}
												onPress={() => {
													if (
														user &&
														user.uid == tutor.uid
													) {
														customAlert.error(
															"[Error] You can't favourite yourself"
														);
													}
													if (!user) {
														customAlert.error(
															"[Error] Please sign in to favourite a tutor"
														);
													} else {
														// Add tutor ID to favourite array and update user doc
														var newFavs =
															favourites;
														newFavs.push(tutor.uid);
														const userRef = doc(
															db,
															"users",
															user.uid
														);
														updateDoc(userRef, {
															favourites: newFavs,
														});
													}
												}}
											>
												{/* Icon */}
												<FontAwesome
													name="heart-o"
													size={14}
													color={textVariant}
												></FontAwesome>

												<Text style={styles.actionText}>
													Favourite
												</Text>
											</TouchableOpacity>
										)}
									</View>
								</View>

								<View
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										position: "relative",
										width: 130,
										height: 130,
									}}
								>
									<FadeIn>
										<Image
											style={styles.profileImg}
											source={{
												uri: tutor.picture
													? tutor.picture
													: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
											}}
										/>
									</FadeIn>

									{/* Edit profile image button */}
									<TouchableOpacity
										style={[
											{
												position: "absolute",
												top: 0,
												right: 0,
												width: 40,
												height: 40,
												borderRadius: 100,
												backgroundColor: background,
												border: "1px solid " + primary,
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											},
										]}
										onPress={() => {
											if (user && user.uid == tutor.uid) {
											}
										}}
									>
										<FontAwesome5
											name="pencil-alt"
											size={16}
											color={primary}
										></FontAwesome5>
									</TouchableOpacity>
								</View>

								{/* Name and online badge */}
								<View>
									<View
										style={{
											// marginTop: 20,
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Text style={styles.title}>
											{tutor.name.split(" ").length > 1
												? `${
														tutor.name.split(" ")[0]
												  } ${
														tutor.name.split(
															" "
														)[1][0]
												  }.`
												: tutor.name}
										</Text>
										{hoursSinceOnline !== null &&
										hoursSinceOnline <= 1 ? (
											<Text style={styles.online}>
												<FontAwesome
													name="circle"
													size={12}
													color={"#00AA54"}
												/>{" "}
												Online
											</Text>
										) : null}
									</View>

									{/* Verification */}
									{tutor.isVerified && (
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												marginTop: 10,
											}}
										>
											<Text style={styles.verified}>
												<FontAwesome
													style={{ marginRight: 10 }}
													name="check-circle"
													size={16}
													color={green}
												/>{" "}
												Credentials verified by Tutred
											</Text>
										</View>
									)}

									{/* Super tutor */}
									{tutor.superTutor && (
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												marginTop: 5,
											}}
										>
											<Text style={styles.verified}>
												<FontAwesome5
													style={{ marginRight: 10 }}
													name="award"
													size={16}
													color={primary}
												/>{" "}
												Super Tutor
											</Text>
										</View>
									)}

									{/* Total sessions */}
									<View
										style={{
											marginTop: 10,
											flexDirection: isMd
												? "row"
												: "column",
										}}
									>
										<Text
											style={[
												styles.tutorInfo,
												{
													marginRight: 15,
													marginTop: isMd ? 0 : 5,
												},
											]}
										>
											<AntDesign
												style={{ marginRight: 10 }}
												name="laptop"
												size={16}
												color={textVariant}
											/>
											{totalSessions} Completed Session(s)
										</Text>
									</View>

									{/* Languages list, */}
									<View
										style={{
											marginTop: 5,
											flexDirection: isMd
												? "row"
												: "column",
										}}
									>
										<Text
											style={[
												styles.tutorInfo,
												{
													marginRight: 15,
													marginTop: isMd ? 0 : 5,
												},
											]}
										>
											<Feather
												style={{ marginRight: 10 }}
												name="globe"
												size={16}
												color={textVariant}
											/>
											{Object.keys(tutor.languages).map(
												(language, index) => {
													if (
														index ==
														Object.keys(
															tutor.languages
														).length -
															1
													) {
														language = language;
													} else {
														language =
															language + ", ";
													}
													return <>{language}</>;
												}
											)}
										</Text>
									</View>

									{/* Tutor info -Location, Rating, Sessions, Join date */}
									<View
										style={{
											marginTop: 5,
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Text style={[styles.tutorInfo]}>
											<AntDesign
												style={{ marginRight: 10 }}
												name="enviromento"
												size={16}
												color={textVariant}
											/>
											{tutor.location.province}
										</Text>

										<View
											style={{
												display: "flex",
												flexDirection: "row",
											}}
										>
											{rating > 0 && (
												<Text
													style={[
														styles.tutorInfo,
														styles.tutorRating,
														{ marginTop: 5 },
													]}
												>
													<FontAwesome
														style={{
															marginRight: 5,
														}}
														name="star"
														size={14}
														color={yellow}
													/>
													{rating} ({reviews.length})
												</Text>
											)}
										</View>
										
										<View
											style={{
												marginTop: 15,
												flexDirection: "column",
											}}
										>
											{isTutor &&
												user &&
												user.uid === tutor.uid && (
													<TouchableOpacity
														disabled={!bookable}
														onPress={() => {
															updateUserInfo();
														}}
														style={[
															styles.button,
															{
																marginTop: isSm
																	? 0
																	: 15,
																opacity:
																	bookable
																		? 1
																		: 0.7,
															},
														]}
													>
														<Text
															style={
																styles.buttonText
															}
														>
															Save changes
														</Text>
													</TouchableOpacity>
												)}

											<TouchableOpacity
												disabled={!bookable}
												onPress={() => {
													setEditProfile(false);
												}}
												style={[
													styles.button,
													{
														marginTop: 5,
														backgroundColor:
															background,
														border:
															"1px solid " +
															primary,
													},
												]}
											>
												<Text
													style={[
														styles.buttonText,
														{
															color: primary,
														},
													]}
												>
													Cancel
												</Text>
											</TouchableOpacity>
										</View>

										{/* Share buttons */}
										<View
											style={{
												maxWidth: 245,
												marginTop: 15,
												flexDirection: "row",
												justifyContent: "center",
											}}
										>
											<TouchableOpacity
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
												onPress={() => {
													Clipboard.setString(
														`${shareLink}/tutors/` +
															tutor.uid
													);
													customAlert.success(
														"[Share Tutor] Copied tutor profile link to your clipboard."
													);
												}}
											>
												<FontAwesome5
													style={{
														fontSize: 16,
														color: text,
													}}
													name="clipboard"
												></FontAwesome5>
											</TouchableOpacity>

											<A
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginLeft: 10,
												}}
												target="_blank"
												href={
													`sms:&body=Check out this tutor on Tutred! ${shareLink}/tutors/` +
													urlId
												}
											>
												<AntDesign
													style={{
														fontSize: 16,
														color: text,
													}}
													name="message1"
												></AntDesign>
											</A>

											<A
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginLeft: 10,
												}}
												target="_blank"
												href={
													`https://twitter.com/intent/tweet?text=Check out this tutor on Tutred! ${shareLink}/tutors/` +
													urlId
												}
											>
												<FontAwesome5
													style={{
														fontSize: 16,
														color: text,
													}}
													name="twitter"
												></FontAwesome5>
											</A>

											<A
												style={{
													width: 35,
													height: 35,
													borderRadius: 100,
													borderColor: text,
													borderWidth: 1,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginLeft: 10,
												}}
												target="_blank"
												href={
													`https://www.facebook.com/sharer/sharer.php?u=${shareLink}/tutors/` +
													urlId
												}
											>
												<FontAwesome5
													style={{
														fontSize: 16,
														color: text,
													}}
													name="facebook"
												></FontAwesome5>
											</A>
										</View>
									</View>
								</View>
							</View>
						</View>

						{/* Right Section  */}

						<View
							style={{
								flex: 1,
								display: "flex",
								flexDirection: "column",
								alignContent: "flex-start",
								justifyContent: "flex-start",
								padding: 30,
							}}
						>
							<View
								style={{
									display: "flex",
									flexDirection: isLg ? "row" : "column",
									marginBottom: 20,
									gap: 30,
									borderBottomColor: border,
									borderBottomWidth: 1,
									paddingBottom: 15,
									flexDirection: isMd ? "row" : "column",
									alignItems: isMd ? "center" : "flex-start",
								}}
							>
								<TouchableOpacity
									onPress={() => {
										setView("personal");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "personal"
														? primary
														: textVariant,
												fontFamily:
													view === "personal"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Personal details
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setView("tutor");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "tutor"
														? primary
														: textVariant,
												fontFamily:
													view === "tutor"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Tutor profile
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setView("education");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "education"
														? primary
														: textVariant,
												fontFamily:
													view === "education"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Education
									</Text>
								</TouchableOpacity>
							</View>

							{/* Personal */}
							{view === "personal" && (
								<>
									{/* Email  */}
									<View>
										<Text style={styles.subtitle}>
											Email
										</Text>
										<TextInput
											disabled={true}
											style={styles.input}
											value={user.email}
										></TextInput>
									</View>
									{/* Name  */}
									<View style={{ marginTop: 15 }}>
										<Text style={styles.subtitle}>
											Name
										</Text>
										<TextInput
											style={styles.input}
											placeholder={tutorName}
											value={tutorName}
											onChange={(e) => {
												setTutorName(e.target.value);
											}}
										></TextInput>
									</View>
									{/* Location - City province country */}
									<View style={{ marginTop: 15 }}>
										<Text style={styles.subtitle}>
											Location
										</Text>

										{/* Country  */}
										<Picker
											style={styles.picker}
											selectedValue={selectedCountry}
											onValueChange={(
												itemValue,
												itemIndex
											) => setSelectedCountry(itemValue)}
										>
											<Picker.Item
												label="Select Country"
												value="Select Country"
											/>
											<Picker.Item
												label="Canada"
												value="CA"
											></Picker.Item>

											<Picker.Item
												label="United States"
												value="US"
											></Picker.Item>
										</Picker>

										{/* Province  */}
										<Picker
											style={styles.picker}
											selectedValue={
												selectedProvince == ""
													? provinces[0].province
													: selectedProvince
											}
											onValueChange={(
												itemValue,
												itemIndex
											) => setSelectedProvince(itemValue)}
										>
											{provinces.map((item, i) => (
												<Picker.Item
													key={`provinces-picker-${i}`}
													label={
														provinces[i].province
													}
													value={
														provinces[i].province
													}
													enabled={
														provinces[i].enabled
													}
												/>
											))}
										</Picker>

										{/* City */}
										<View
											style={{
												marginTop: 10,
											}}
										>
											<TextInput
												style={styles.input}
												placeholder="City"
												value={city}
												onChange={(e) => {
													setCity(e.target.value);
												}}
											></TextInput>
										</View>
									</View>
								</>
							)}

							{/* Tutor */}
							{view === "tutor" && (
								<>
									{/*Language*/}
									<View style={{}}>
										<Text style={styles.subtitle}>
											Language
										</Text>
										<View style={styles.wordContainer}>
											{selectedLanguage.map(
												(doc, index) => {
													return (
														<WordCard
															key={`lang-word-card-${index}`}
															word={
																selectedLanguage[
																	index
																]
															}
															array="languages"
														></WordCard>
													);
												}
											)}
										</View>
										<Picker
											style={styles.picker}
											selectedValue={
												selectedLanguage.length == 0
													? languages[0].lang
													: selectedLanguage[
															selectedLanguage.length -
																1
													  ]
											}
											onValueChange={(
												itemValue,
												itemIndex
											) => {
												if (
													selectedLanguage.includes(
														itemValue
													)
												) {
													return null;
												} else {
													setSelectedLanguage([
														...selectedLanguage,
														itemValue,
													]);
												}
											}}
										>
											{languages.map((item, i) => (
												<Picker.Item
													key={`lang-picker-${i}`}
													label={languages[i].lang}
													value={languages[i].lang}
													enabled={
														languages[i].enabled
													}
												/>
											))}
										</Picker>
									</View>
									{/*Subject*/}
									<View style={{ marginTop: 15 }}>
										<Text style={styles.subtitle}>
											Subject
										</Text>
										<View style={styles.wordContainer}>
											{selectedSubject.map(
												(doc, index) => {
													return (
														<WordCard
															key={`subject-word-card-${index}`}
															word={
																selectedSubject[
																	index
																]
															}
															array={"subject"}
														></WordCard>
													);
												}
											)}
										</View>
										<Picker
											style={styles.picker}
											selectedValue={
												selectedSubject.length == 0
													? subjects[0].subject
													: selectedSubject[
															selectedSubject.length -
																1
													  ]
											}
											onValueChange={(
												itemValue,
												itemIndex
											) => {
												if (
													selectedSubject.includes(
														itemValue
													)
												) {
													return null;
												} else {
													setSelectedSubject([
														...selectedSubject,
														itemValue,
													]);
												}
											}}
										>
											{subjects.map((item, i) => (
												<Picker.Item
													label={subjects[i].subject}
													value={subjects[i].subject}
													enabled={
														subjects[i].enabled
													}
												/>
											))}
										</Picker>
									</View>
									{/*Skills*/}
									<View style={{ marginTop: 15 }}>
										<Text style={styles.subtitle}>
											Skills
										</Text>
										<View style={styles.wordContainer}>
											{selectedSkill.map((doc, index) => {
												return (
													<WordCard
														key={`skill-word-card-${index}`}
														word={
															selectedSkill[index]
														}
														array="skill"
													></WordCard>
												);
											})}
										</View>
										<Picker
											style={styles.picker}
											selectedValue={
												selectedSkill.length == 0
													? skills[0].skill
													: selectedSkill[
															selectedSkill.length -
																1
													  ]
											}
											onValueChange={(
												itemValue,
												itemIndex
											) => {
												if (
													selectedSkill.includes(
														itemValue
													)
												) {
													return null;
												} else {
													setSelectedSkill([
														...selectedSkill,
														itemValue,
													]);
												}
											}}
										>
											{skills.map((item, i) => (
												<Picker.Item
													key={`skills-picker-${i}`}
													label={skills[i].skill}
													value={skills[i].skill}
													enabled={skills[i].enabled}
												/>
											))}
										</Picker>
									</View>
									<View style={{ marginTop: 15 }}>
										<Text style={styles.subtitle}>Bio</Text>
										<TextInput
											placeholderTextColor="#999999"
											style={styles.descriptionInput}
											placeholder={description}
											multiline={true}
											value={description}
											maxLength={1000}
											onChange={(e) => {
												setDescription(e.target.value);
											}}
											// maxLength={250}
										></TextInput>
										<Text
											style={[
												styles.details,
												{ padding: 5 },
											]}
										>
											{description.length}/1000
										</Text>
									</View>
								</>
							)}

							{/* Education */}
							{view === "education" && (
								<>
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											flexWrap: "wrap",
										}}
									>
										{degreeArray.map((doc, index) => {
											return (
												<DegreeCard
													key={`degree-card-${index}`}
													school={
														degreeArray[index]
															.school
													}
													year={
														degreeArray[index].year
													}
													type={
														degreeArray[index].type
													}
													degree={
														degreeArray[index].name
													}
												></DegreeCard>
											);
										})}
									</View>
									<View style={{ width: "100%" }}>
										<Text style={styles.subtitle}>
											Degree info
										</Text>
										{/* University  */}
										{selectedUniversity != "Other" ? (
											<Picker
												style={styles.picker}
												selectedValue={
													selectedUniversity == ""
														? universities[0].school
														: selectedUniversity
												}
												onValueChange={(
													itemValue,
													itemIndex
												) =>
													setSelectedUniversity(
														itemValue
													)
												}
											>
												{universities.map((item, i) => (
													<Picker.Item
														key={`uni-picker-${i}`}
														label={
															universities[i]
																.school
														}
														value={
															universities[i]
																.school
														}
														enabled={
															universities[i]
																.enabled
														}
													/>
												))}
											</Picker>
										) : (
											<>
												<TextInput
													placeholderTextColor={
														darkMode
															? "#fff"
															: "#000"
													}
													style={[
														styles.descriptionInput,
														{ height: 50 },
													]}
													placeholder={
														"Enter University Name"
													}
													multiline={false}
													value={customUniversity}
													onChange={(e) => {
														setCustomUniversity(
															e.target.value
														);
													}}
												></TextInput>
											</>
										)}
										{/* Field of study  */}

										<Picker
											style={styles.picker}
											selectedValue={
												selectedDegree == ""
													? degrees[0].name
													: selectedDegree
											}
											onValueChange={(
												itemValue,
												itemIndex
											) => setSelectedDegree(itemValue)}
										>
											{degrees.map((item, i) => (
												<Picker.Item
													key={`degree-picker-${i}`}
													label={degrees[i].name}
													value={degrees[i].name}
													enabled={degrees[i].enabled}
												/>
											))}
										</Picker>

										{/* Type */}
										<Picker
											style={styles.picker}
											selectedValue={selectedDegreeType}
											onValueChange={(
												itemValue,
												itemIndex
											) =>
												setSelectedDegreeType(itemValue)
											}
										>
											<Picker.Item
												key={`degree-type-picker-0`}
												label="Select a degree type"
												value=""
											/>

											<Picker.Item
												key={`degree-type-picker-1`}
												label="Associates Degree"
												value="Associates Degree"
											/>

											<Picker.Item
												key={`degree-type-picker-3`}
												label="Bachelors Degree"
												value="Bachelors Degree"
											/>

											<Picker.Item
												key={`degree-type-picker-4`}
												label="Masters Degree"
												value="Masters Degree"
											/>

											<Picker.Item
												key={`degree-type-picker-5`}
												label="Doctorate Degree"
												value="Doctorate Degree"
											/>

											<Picker.Item
												key={`degree-type-picker-6`}
												label="Professional Degree"
												value="Professional Degree"
											/>
										</Picker>

										{/* Year */}
										<Picker
											style={styles.picker}
											selectedValue={
												selectedCompletionYear == ""
													? years[0].year
													: selectedCompletionYear
											}
											onValueChange={(
												itemValue,
												itemIndex
											) =>
												setSelectedCompletionYear(
													itemValue
												)
											}
										>
											{years.map((item, i) => (
												<Picker.Item
													key={`year-picker-${i}`}
													label={years[i].year}
													value={years[i].year}
													enabled={years[i].enabled}
												/>
											))}
										</Picker>

										{selectedUniversity === "Other" ? (
											/* Add degree */
											<TouchableOpacity
												onPress={() => {
													if (
														degreeInfo.customUniversity ===
															"" ||
														degreeInfo.year ===
															"" ||
														degreeInfo.school ===
															"" ||
														degreeInfo.type === ""
													) {
														customAlert.error(
															"[Edit Profile Error] You must fill in all fields!"
														);
														return;
													}

													setDegreeArray([
														...degreeArray,
														degreeInfo,
													]);
													resetDegreeValues();
												}}
											>
												<Text
													style={
														styles.addDegreeButton
													}
												>
													{" "}
													Add Degree{" "}
												</Text>
											</TouchableOpacity>
										) : (
											/* Add degree */
											<TouchableOpacity
												onPress={() => {
													if (
														degreeInfo.name ===
															"" ||
														degreeInfo.year ===
															"" ||
														degreeInfo.school ===
															"" ||
														degreeInfo.type === ""
													) {
														customAlert.error(
															"[Edit Profile Error] You must fill in all fields!"
														);
														return;
													}

													setDegreeArray([
														...degreeArray,
														degreeInfo,
													]);
													resetDegreeValues();
												}}
											>
												<Text
													style={
														styles.addDegreeButton
													}
												>
													{" "}
													Add Degree{" "}
												</Text>
											</TouchableOpacity>
										)}
									</View>
								</>
							)}
						</View>
					</View>

					{/* Footer */}
					{isSm ? (
						<View style={{ marginTop: 100, width: "100%" }}>
							<Footer></Footer>{" "}
						</View>
					) : null}
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};
