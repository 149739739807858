// Imports
import { FontAwesome } from "@expo/vector-icons";
import {
	collection,
	doc,
	onSnapshot,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import FadeIn from "react-native-fade-in-image";
import { useMediaQuery } from "react-responsive";
import { useFirebaseContext } from "../../contexts/firebaseContext";
import { useUserContext } from "../../contexts/userContext";
import { useColorsContext, useStyles } from "../../utils/styles";

// Tutor component
export const ChatListCard = ({ chat, openChat, setOpenChat, navigation }) => {
	// Firebase
	const { db } = useFirebaseContext();
	const { user, isTutor, unreadMessages, setUnreadMessages } =
		useUserContext();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Media queries
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// States
	const [name, setName] = useState(null);
	const [image, setImage] = useState(
		"https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e"
	);

	const [loadedUser, setLoadedUser] = useState(false);
	const [lastOnline, setLastOnline] = useState(null);
	const [hoursSinceOnline, setHoursSinceOnline] = useState(null);
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

	useEffect(() => {
		// Filter unread messages by chat id
		if (unreadMessages) {
			const unreadMessagesFiltered = unreadMessages.filter(
				(message) => message.chat === chat.id
			);
			setUnreadMessagesCount(unreadMessagesFiltered.length);
		}
	}, [unreadMessages]);

	// Stylesheet
	const styles = StyleSheet.create({
		userImg: {
			width: 60,
			height: 60,
			marginRight: 5,
			borderRadius: defaultStyles.buttonRadius,
			border: `1px solid ${border}`,
		},
		textSection: {
			flex: 1,
			flexDirection: "column",
			justifyContent: "center",
			// padding: 15,
			paddingLeft: 0,
			marginLeft: 10,
			// width: 350,
			height: "auto",
			// borderBottomColor: border,
			// borderBottomWidth: 1,
		},
		userInfoText: {
			flexDirection: isLg ? "row" : "column",
			justifyContent: isLg ? "space-between" : "flex-start",
			marginBottom: 5,
		},
		userName: {
			color: text,
			fontSize: 22,
			fontFamily: defaultStyles.titleFont,
			marginTop: unreadMessagesCount > 0 ? -20 : 0,
		},
		online: {
			marginLeft: 15,
			fontSize: 12,
			color: "#00AA54",
			fontFamily: defaultStyles.boldFont,
			marginTop: unreadMessagesCount > 0 ? -20 : 0,
		},
		messageText: {
			fontSize: 16,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginTop: unreadMessagesCount > 0 ? -18 : 0,
		},
		postTime: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
	});

	// Get tutor status
	useEffect(() => {
		if (isTutor === null) {
			return;
		}
		if (
			!chat ||
			!chat.tutor ||
			!chat.tutor.uid ||
			!chat.user ||
			!chat.user.uid
		)
			return;
		if (loadedUser) return;
		setLoadedUser(true);
		// Get tutor
		var q;

		// Student so search for tutor
		if (!isTutor) {
			q = query(
				collection(db, "users"),
				where("uid", "==", chat.tutor.uid),
				where("isTutor", "==", true)
			);
			setName(chat.tutor.name);
			if (chat.tutor.image) {
				setImage(chat.tutor.image);
			}
		}
		// Tutor so search for student
		else {
			q = query(
				collection(db, "users"),
				where("uid", "==", chat.user.uid)
			);
			setName(chat.user.name);
			if (chat.user.image) {
				setImage(chat.user.image);
			}
		}

		onSnapshot(q, (snapshot) => {
			snapshot.forEach((userDoc) => {
				// Check if tutor found
				if (userDoc.exists()) {
					setLastOnline(userDoc.data().lastOnline);

					// User is tutor
					if (isTutor) {
						// Check if student name and picture is the same as in chat
						if (
							userDoc.data().name !== chat.user.name ||
							userDoc.data().picture !== chat.user.image
						) {
							// Update chat
							updateDoc(doc(db, "chats", chat.id), {
								user: {
									name: userDoc.data().name,
									image: userDoc.data().picture,
									uid: userDoc.data().uid,
									id: userDoc.data().id,
								},
							});
						}
					}
					// User is student
					else {
						// Check if tutor name and picture is the same as in chat
						if (
							userDoc.data().name !== chat.tutor.name ||
							userDoc.data().picture !== chat.tutor.image
						) {
							// Update chat
							updateDoc(doc(db, "chats", chat.id), {
								tutor: {
									name: userDoc.data().name,
									image: userDoc.data().picture,
									uid: userDoc.data().uid,
									id: userDoc.data().id,
								},
							});
						}
					}
				}
			});
		});
	}),
		[isTutor];

	// Get hours since last online for tutor
	useEffect(() => {
		if (lastOnline !== null && lastOnline !== undefined) {
			// Get hours since last online
			let today = new Date();
			let lastOnlineTs = new Date(lastOnline.toDate());
			var hours = Math.floor((today - lastOnlineTs) / (1000 * 60 * 60));
			setHoursSinceOnline(hours);
		}
	}, [lastOnline]);

	return (
		<TouchableOpacity
			onPress={
				() => setOpenChat({ chat: chat, hoursSinceOnline })
				// navigation.navigate('Chat', {chat: chat, hoursSinceOnline})
			}
		>
			<View
				style={{
					flexDirection: "row",
					flex: 1,
					backgroundColor:
						openChat && openChat.chat.id === chat.id
							? backgroundVariant
							: "",
					padding: 15,
					borderRadius: defaultStyles.radius,
				}}
			>
				<View
					style={
						{
							// paddingTop: 15,
							// paddingBottom: 15,
						}
					}
				>
					<FadeIn
						placeholderStyle={{
							backgroundColor: backgroundVariant,
						}}
					>
						<Image style={styles.userImg} source={{ uri: image }} />
					</FadeIn>
				</View>
				<View style={styles.textSection}>
					<View style={styles.userInfoText}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Text style={styles.userName}>
								{name ? (
									<>
										{name.split(" ").length > 1
											? name.split(" ")[0] +
											  " " +
											  name.split(" ")[1][0] +
											  "."
											: name}
									</>
								) : (
									""
								)}
							</Text>

							{hoursSinceOnline !== null &&
							hoursSinceOnline <= 1 ? (
								<Text style={styles.online}>
									<FontAwesome
										name="circle"
										size={12}
										color={"#00AA54"}
									/>{" "}
									Online
								</Text>
							) : null}
						</View>

						<View
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
						>
							<Text style={styles.postTime}>
								{chat.lastMessage &&
								chat.lastMessage.createdAt ? (
									<>
										{
											// If last message was sent today
											new Date(
												chat.lastMessage.createdAt.toDate()
											).toLocaleDateString() ===
											new Date().toLocaleDateString()
												? new Date(
														chat.lastMessage.createdAt.toDate()
												  ).toLocaleTimeString([], {
														hour: "2-digit",
														minute: "2-digit",
												  })
												: // If last was another day
												  new Date(
														chat.lastMessage.createdAt.toDate()
												  ).toLocaleDateString([], {
														month: "short",
														day: "numeric",
														year: "numeric",
												  })
										}
									</>
								) : (
									"-"
								)}
							</Text>

							{/* Check this out */}
							{unreadMessagesCount > 0 && (
								<View
									style={{
										backgroundColor: primaryVariant,
										borderRadius: 100,
										alignItems: "center",
										height: 20,
										width: 20,
										justifyContent: "center",
										marginTop: 5,
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 12,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										{unreadMessagesCount}
									</Text>
								</View>
							)}
						</View>
					</View>

					<Text numberOfLines={2} style={styles.messageText}>
						{chat.lastMessage && chat.lastMessage.text
							? chat.lastMessage.text
							: "No messages yet"}
					</Text>
				</View>
			</View>
		</TouchableOpacity>
	);
};
