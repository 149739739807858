//Package imports
import { Feather } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import {
	getAuth,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
	Alert,
	Modal,
	Platform,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useUserContext } from "../contexts/userContext";
import Hoverable from "../utils/hover/Hoverable";
import { useColorsContext, useStyles } from "../utils/styles";

const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

export const LoginModal = ({ loginModal, setLoginModal }) => {
	const customAlert = useAlert();

	const [error, setError] = useState(null);

	// States and variables
	const { user } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const auth = getAuth();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			padding: Platform.OS === "web" ? 30 : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "40%"
				: isLg
				? "50%"
				: isMd
				? "60%"
				: isSm
				? "70%"
				: "90%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: " space-between",
		},
		closeButton: {
			width: 30,
		},
		modalTitle: {
			fontSize: 32,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		marginTop: { marginVertical: 20 },
		infoContainer: {
			flex: 1,
			display: "flex",
			flexDirection: !isMd ? "column" : "row",
			justifyContent: "space-between",
		},
		mainContainer: {
			flex: 1,
			padding: 30,
			paddingTop: 0,
		},

		button: {
			backgroundColor: primary,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.buttonRadius,
			textAlign: "center",
			fontFamily: defaultStyles.boldFont,
			paddingTop: 0,
			paddingBottom: 0,
			height: 45,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		buttonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 225,
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		input: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
		},
		link: {
			color: primaryVariant,
		},
	});

	// Sign in function
	const signIn = async () => {
		if (email && password) {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {
					// Signed in
					const user = userCredential.user;
					setLoginModal(false);
					// ...
				})
				.catch(async (error) => {
					const errorCode = error.code;
					const errorMessage = error.message;
					if (errorCode == "auth/wrong-password") {
						// Platform.OS === 'web' ? customAlert.error("[Login Error] Wrong password") :
						//     Alert.alert('Error logging in!',  "Wrong password", [
						//         { text: 'OK', onPress: () => console.log('OK Pressed') },
						//     ]);
						setError("Wrong password");
					} else if (errorCode == "auth/invalid-email") {
						// Platform.OS === 'web' ? customAlert.error("[Login Error] Invalid email") :
						//     Alert.alert('Error logging in!',  "Invalid email", [
						//         { text: 'OK', onPress: () => console.log('OK Pressed') },
						//     ]);
						setError("Invalid email");
					} else if (errorCode == "auth/too-many-requests") {
						// Platform.OS === 'web' ? customAlert.error("[Login Error] Too many requests") :
						//     Alert.alert('Error logging in!',  "Too many requests", [
						//         { text: 'OK', onPress: () => console.log('OK Pressed') },
						//     ]);
						setError("Too many requests");
					} else if (errorCode == "auth/user-not-found") {
						// Platform.OS === 'web' ? customAlert.error("[Login Error] No account with this email exists") :
						//     Alert.alert('Error logging in!',  "User not found", [
						//         { text: 'OK', onPress: () => console.log('OK Pressed') },
						//     ]);
						setError("No account with this email exists");
					} else {
						// Platform.OS === 'web' ? customAlert.error("[Login Error] Error logging in!") :
						//     Alert.alert('Error logging in!',  errorMessage, [
						//         { text: 'OK', onPress: () => console.log('OK Pressed') },
						//     ]);
						setError("Error logging in!");
					}
				});
		} else {
			// Platform.OS === 'web' ? customAlert.error("[Login Error] Please enter an email and password") :
			//     Alert.alert('Error logging in!',  "Please enter an email and password", [
			//         { text: 'OK', onPress: () => console.log('OK Pressed') },
			//     ]);
			setError("Please enter an email and password");
		}
	};

	return (
		<View>
			<Modal
				key={3}
				animationType="fade"
				transparent={true}
				visible={loginModal}
				onRequestClose={() => {
					setLoginModal(!loginModal);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							<TouchableOpacity
								style={styles.closeButton}
								onPress={() => {
									setLoginModal(!loginModal);
								}}
							>
								<CloseOutlined
									style={{ fontSize: 22, color: text }}
								></CloseOutlined>
							</TouchableOpacity>

							<View
								style={{
									flex: 1,
									paddingTop: 15,
									alignItems: "center",
								}}
							>
								<Text style={styles.modalTitle}>Login</Text>
							</View>

							<View style={styles.closeButton}></View>
						</View>
						<View style={styles.mainContainer}>
							<TextInput
								value={email}
								onChangeText={(t) => {
									setEmail(t);
								}}
								placeholderTextColor="#999999"
								style={styles.input}
								textContentType="emailAddress"
								placeholder="Email"
							></TextInput>
							<View style={{ alignItems: "flex-end" }}>
								<View
									style={[
										styles.input,
										{
											width: "100%",
											display: "flex",
											flexDirection: "row-reverse",
										},
									]}
								>
									<TouchableOpacity
										onPress={() => {
											setShowPassword(!showPassword);
										}}
										style={{
											marginRight: 5,
											maxWidth: 30,
											flex: 1,
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{showPassword ? (
											<Feather
												name="eye-off"
												size={16}
												color={textVariant}
											/>
										) : (
											<Feather
												name="eye"
												size={16}
												color={textVariant}
											/>
										)}
									</TouchableOpacity>

									<TextInput
										style={{
											fontFamily:
												defaultStyles.regularFont,
											color: text,
											fontSize: 16,
											flex: 1,
										}}
										value={password}
										onChangeText={(t) => {
											setPassword(t);
										}}
										placeholderTextColor="#999999"
										secureTextEntry={
											showPassword ? false : true
										}
										textContentType="password"
										placeholder="Password"
									></TextInput>
								</View>
								<TouchableOpacity
									style={{
										marginTop: 15,
									}}
									onPress={() => {
										if (!email) {
											setError("Please enter an email");
											return;
										}
										sendPasswordResetEmail(auth, email)
											.then(() => {
												setLoginModal(false);
												// Password reset email sent!
												// ..
												Platform.OS === "web"
													? customAlert.success(
															"[Password Reset] Check your inbox for a link to reset your password."
													  )
													: Alert.alert(
															"Password reset email sent!",
															"Check your inbox",
															[
																{
																	text: "OK",
																	onPress:
																		() =>
																			console.log(
																				"OK Pressed"
																			),
																},
															]
													  );
											})
											.catch((error) => {
												setLoginModal(false);
												const errorCode = error.code;
												const errorMessage =
													error.message;
												// ..
												Platform.OS === "web"
													? customAlert.error(
															"[Login Error] Error sending password reset email try again!"
													  )
													: Alert.alert(
															"Error sending password reset email!",
															errorMessage,
															[
																{
																	text: "OK",
																	onPress:
																		() =>
																			console.log(
																				"OK Pressed"
																			),
																},
															]
													  );
											});
									}}
								>
									<Text style={styles.link}>
										Forgot Password?
									</Text>
								</TouchableOpacity>
							</View>

							<Hoverable>
								{(isHovered) => (
									<TouchableOpacity
										onPress={() => {
											signIn();
										}}
										style={[
											styles.button,
											{
												backgroundColor: isHovered
													? primaryVariant
													: primary,
											},
										]}
									>
										<Text
											style={{
												color: "#fff",
												fontFamily:
													defaultStyles.boldFont,
												fontSize: 16,
											}}
										>
											Login
										</Text>
									</TouchableOpacity>
								)}
							</Hoverable>
						</View>

						{error && (
							<Text
								style={{
									color: "#FF0000",
									fontFamily: defaultStyles.boldFont,
									fontSize: 14,
									textAlign: "center",
									marginBottom: 30,
								}}
							>
								{error}
							</Text>
						)}
					</View>
				</View>
			</Modal>
		</View>
	);
};
