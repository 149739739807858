//Package imports
import { CloseOutlined } from "@mui/icons-material";
import * as ImagePicker from "expo-image-picker";
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadBytesResumable,
} from "firebase/storage";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
	Alert,
	Modal,
	Platform,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";

// // File imports
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";
const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

export const VerifyModal = ({ verifyModal, setVerifyModal }) => {
	// State
	const [image, setImage] = useState(null);
	const [description, setDescription] = useState("");
	const [id, setId] = useState(null);
	const [transcript, setTranscript] = useState(null);
	const [degree, setDegree] = useState(null);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	// Init alert
	const customAlert = useAlert();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	const { user } = useUserContext();

	// handle submit
	const handleSubmit = () => {
		if (id === null || transcript === null || degree === null) {
			// customAlert.error('Please upload the required documents');
			setError("Please upload the required documents");
		} else {
			// Add auth to header
			// 'Authorization': 'Bearer ' + user.accessToken
			const headers = {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user.accessToken,
			};

			fetch(`${hostname}/api/verify-tutor`, {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					photoURL: id,
					transcriptURL: transcript,
					degreeURL: degree,
					message: description,
					email: user.email,
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.json();
				})
				.then((data) => {
					if (localStorage) {
						localStorage.setItem("verificationRequest", "true");
					}
					setSuccess(true);
				})
				.catch((error) => {
					setError("Something went wrong, please try again later");
					// customAlert.error('Something went wrong, please try again later');
				});
		}
	};

	// Make ID string for image name
	const makeid = (length) => {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	};

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			padding: Platform.OS === "web" ? 30 : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},

		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "40%"
				: isLg
				? "50%"
				: isMd
				? "60%"
				: isSm
				? "70%"
				: "90%",
			height: isMd ? "70%" : "95%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: " space-between",
			marginBottom: 5,
		},
		closeButton: {
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		marginTop: { marginVertical: 20 },
		infoContainer: {
			flex: 1,
			display: "flex",
			flexDirection: !isMd ? "column" : "row",
			justifyContent: "space-between",
		},
		mainContainer: {
			flex: 1,
			padding: 30,
		},

		button: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			borderRadius: defaultStyles.buttonRadius,
			padding: 15,
			alignItems: "center",
		},
		buttonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 225,
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
	});

	return (
		<View>
			<Modal
				key={3}
				animationType="fade"
				transparent={true}
				visible={verifyModal}
				onRequestClose={() => {
					setVerifyModal(!verifyModal);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							<View style={{ flexDirection: "row" }}>
								{/* Close filters button */}
								<TouchableOpacity
									style={styles.closeButton}
									onPress={() => {
										setVerifyModal(!verifyModal);
									}}
								>
									<CloseOutlined
										style={{ fontSize: 22, color: text }}
									></CloseOutlined>
								</TouchableOpacity>

								{/* Title */}
								<Text style={styles.modalTitle}>
									Verify your account
								</Text>
							</View>
							{!isMd || success ? null : (
								<TouchableOpacity
									style={{
										backgroundColor: primary,
										padding: 10,
										borderRadius:
											defaultStyles.buttonRadius,
									}}
									onPress={() => {
										handleSubmit();
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 14,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										Submit Request
									</Text>
								</TouchableOpacity>
							)}
						</View>
						<View style={styles.mainContainer}>
							<Text style={styles.title}>
								Please upload the required documents to verify
								your account. All your information is kept
								private and secure.
							</Text>
							<Text
								style={{
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									color: text,
									marginVertical: 10,
								}}
							>
								Please allow our team to review your request.
							</Text>
							{/* Photo id  */}
							<TouchableOpacity
								onPress={async () => {
									// No permissions request is necessary for launching the image library
									let result =
										await ImagePicker.launchImageLibraryAsync(
											{
												mediaTypes:
													ImagePicker.MediaTypeOptions
														.Images,
												allowsEditing: true,
												aspect: [4, 3],
												quality: 1,
											}
										);

									if (!result.cancelled) {
										// Set local image
										setId(result.uri);
										// Upload image to firebase storage
										const storage = getStorage();
										let imageFile = await fetch(result.uri);
										let name =
											imageFile._bodyBlob &&
											imageFile._bodyBlob._data
												? imageFile._bodyBlob._data.name
												: makeid(12);
										let imageBlob = await imageFile.blob();
										const storageRef = ref(
											storage,
											`${name}`
										);
										let task = uploadBytesResumable(
											storageRef,
											imageBlob
										);
										task.on(
											"state_changed",
											(snapshot) => {},
											(error) => {
												Platform.OS === "web"
													? customAlert.error(error)
													: Alert.alert(
															"Error uploading image!",
															error,
															[
																{
																	text: "OK",
																	onPress:
																		() =>
																			console.log(
																				"OK Pressed"
																			),
																},
															]
													  );
											},
											() => {
												getDownloadURL(
													task.snapshot.ref
												).then((downloadURL) => {
													setId(downloadURL);
												});
											}
										);
									}
								}}
								style={[
									styles.button,
									{
										backgroundColor:
											id != null
												? green
												: backgroundVariant,
									},
								]}
							>
								{/* <FontAwesome name='openid' size={24} color={textVariant} /> */}
								<Text style={[styles.buttonText]}>
									Photo ID
								</Text>
							</TouchableOpacity>
							<Text
								style={{
									fontFamily: defaultStyles.regularFont,
									fontSize: 12,
								}}
							>
								Any valid government issued photo ID.
							</Text>

							{/* Transcript  */}
							<View style={{ marginVertical: 15 }}>
								<TouchableOpacity
									onPress={async () => {
										// No permissions request is necessary for launching the image library
										let result =
											await ImagePicker.launchImageLibraryAsync(
												{
													mediaTypes:
														ImagePicker
															.MediaTypeOptions
															.Images,
													allowsEditing: true,
													aspect: [4, 3],
													quality: 1,
												}
											);

										if (!result.cancelled) {
											// Set local image
											setTranscript(result.uri);
											// Upload image to firebase storage
											const storage = getStorage();
											let imageFile = await fetch(
												result.uri
											);
											let name =
												imageFile._bodyBlob &&
												imageFile._bodyBlob._data
													? imageFile._bodyBlob._data
															.name
													: makeid(12);
											let imageBlob =
												await imageFile.blob();
											const storageRef = ref(
												storage,
												`${name}`
											);
											let task = uploadBytesResumable(
												storageRef,
												imageBlob
											);
											task.on(
												"state_changed",
												(snapshot) => {},
												(error) => {
													Platform.OS === "web"
														? customAlert.error(
																error
														  )
														: Alert.alert(
																"Error uploading image!",
																error,
																[
																	{
																		text: "OK",
																		onPress:
																			() =>
																				console.log(
																					"OK Pressed"
																				),
																	},
																]
														  );
												},
												() => {
													getDownloadURL(
														task.snapshot.ref
													).then((downloadURL) => {
														setTranscript(
															downloadURL
														);
													});
												}
											);
										}
									}}
									style={[
										styles.button,
										{
											backgroundColor:
												transcript != null
													? green
													: backgroundVariant,
										},
									]}
								>
									{/* <FontAwesome name='openid' size={24} color={textVariant} /> */}

									<Text style={[styles.buttonText]}>
										Official Transcript
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										fontFamily: defaultStyles.regularFont,
										fontSize: 12,
									}}
								>
									Official transcript issued from your
									university.
								</Text>
							</View>

							{/* Transcript  */}
							<View style={{ marginVertical: 15 }}>
								<TouchableOpacity
									onPress={async () => {
										// No permissions request is necessary for launching the image library
										let result =
											await ImagePicker.launchImageLibraryAsync(
												{
													mediaTypes:
														ImagePicker
															.MediaTypeOptions
															.Images,
													allowsEditing: true,
													aspect: [4, 3],
													quality: 1,
												}
											);

										if (!result.cancelled) {
											// Set local image
											setDegree(result.uri);
											// Upload image to firebase storage
											const storage = getStorage();
											let imageFile = await fetch(
												result.uri
											);
											let name =
												imageFile._bodyBlob &&
												imageFile._bodyBlob._data
													? imageFile._bodyBlob._data
															.name
													: makeid(12);
											let imageBlob =
												await imageFile.blob();
											const storageRef = ref(
												storage,
												`${name}`
											);
											let task = uploadBytesResumable(
												storageRef,
												imageBlob
											);
											task.on(
												"state_changed",
												(snapshot) => {},
												(error) => {
													Platform.OS === "web"
														? customAlert.error(
																error
														  )
														: Alert.alert(
																"Error uploading image!",
																error,
																[
																	{
																		text: "OK",
																		onPress:
																			() =>
																				console.log(
																					"OK Pressed"
																				),
																	},
																]
														  );
												},
												() => {
													getDownloadURL(
														task.snapshot.ref
													).then((downloadURL) => {
														setDegree(downloadURL);
													});
												}
											);
										}
									}}
									style={[
										styles.button,
										{
											backgroundColor:
												degree != null
													? green
													: backgroundVariant,
										},
									]}
								>
									{/* <FontAwesome name='openid' size={24} color={textVariant} /> */}

									<Text style={[styles.buttonText]}>
										Degree
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										fontFamily: defaultStyles.regularFont,
										fontSize: 12,
									}}
								>
									A photo of your degree / diploma.
								</Text>
							</View>

							<TextInput
								placeholderTextColor="#999999"
								style={styles.descriptionInput}
								placeholder="Any additional information..."
								multiline={true}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								maxLength={300}
							></TextInput>
							<Text style={[styles.details, { padding: 5 }]}>
								{description.length} / 300
							</Text>
						</View>

						{error && (
							<Text
								style={{
									color: "#FF0000",
									fontFamily: defaultStyles.boldFont,
									fontSize: 14,
									textAlign: "center",
									marginBottom: 30,
								}}
							>
								{error}
							</Text>
						)}

						{success && (
							<Text
								style={{
									color: "green",
									fontFamily: defaultStyles.boldFont,
									fontSize: 14,
									textAlign: "center",
									marginBottom: 30,
								}}
							>
								Your verification request has been sent to our
								team. You will be notified when your request has
								been reviewed. Please allow 7-10 business days
								for your request to be processed. Thank you!
							</Text>
						)}

						{isMd || success ? null : (
							<TouchableOpacity
								style={{
									backgroundColor: primary,
									padding: 10,
									borderRadius: defaultStyles.buttonRadius,
									alignContent: "center",
									justifyContent: "center",
									alignItems: "center",
								}}
								onPress={() => {
									handleSubmit();
								}}
							>
								<Text
									style={{
										color: "#fff",
										fontSize: 14,
										fontFamily: defaultStyles.boldFont,
									}}
								>
									Submit Request
								</Text>
							</TouchableOpacity>
						)}
					</View>
				</View>
			</Modal>
		</View>
	);
};
