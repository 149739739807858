import { AntDesign } from "@expo/vector-icons";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { Link } from "@react-navigation/native";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../../../components/AuthHeader";
import { Header } from "../../../components/Header";
import { useColorsContext, useStyles } from "../../../utils/styles";

import { LoadingPage } from "../../../components/LoadingPage";

import { ContactModal } from "../../../components/ContactUsModal";
import Footer from "../../../components/Footer";
import { LoginModal } from "../../../components/LoginModal";
import { Onboarding } from "../../../components/Onboarding";
import { PaymentMethodModal } from "../../../components/PaymentMethodModal";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";


import { useAlert } from "react-alert";


const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

export const ProfileNew = ({ navigation }) => {
	// Appearance
	const {
		toggleDarkMode,
		darkMode,
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Media queries
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// States and variables
	const {
		showStripeOnboarding,
		getPaymentMethods,
		paymentMethods,
		customerId,
		isTutor,
		user,
		name,
		profile,
		isOnboarded,
		URL,
	} = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const auth = getAuth();
	const { db } = useFirebaseContext();
	const [image, setImage] = useState(profile);
	const [contactModal, setContactModal] = useState(false);
	const [paymentMethodModal, setPaymentMethodModal] = useState(false);
	const [showOnboarding, setShowOnboarding] = useState(false);
	const [showImageCropModal, setShowImageCropModal] = useState(false);
	const [imageData, setImageData] = useState(null);
	const [termsAndPrivacyModal, setTermsAndPrivacyModal] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const customAlert = useAlert();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);
	}, [user]);

	// Stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			justifyContent: "center",
			alignItems: isSm ? "flex-start" : "center",
			borderBottomWidth: 1,
			borderBottomColor: border,
			paddingBottom: 15,
		},
		imageButton: {
			backgroundColor: backgroundVariant,
			borderRadius: 100,
			width: 90,
			height: 90,
			alignSelf: isSm ? "flex-start" : "center",
		},
		name: {
			marginTop: 5,
			fontSize: 36,
			color: text,
			fontFamily: defaultStyles.titleFont,
		},
		email: {
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
		},
		title: {
			marginTop: 30,
			fontSize: 22,
			color: text,
			fontFamily: defaultStyles.boldFont,
		},
		listItem: {
			marginTop: 15,
		},
		icon: {
			width: 30,
			height: 30,
			borderRadius: defaultStyles.buttonRadius,
			// backgroundColor: '#000',
			marginRight: 15,
			justifyContent: "center",
			alignItems: "center",
		},
		listText: {
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
		},
		menuText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 15,
		},
	});

	const LinkCardComponent = ({
		title,
		description,
		icon,
		onPress,
		isPaymentCard,
	}) => {
		return (
			<TouchableOpacity
				style={{
					backgroundColor: darkMode ? backgroundVariant : background,
					width: !isMd ? "100%" : 400,
					borderRadius: 20,
					elevation: 5, // Shadow on Android
					shadowColor: "#333333", // Adjust the shadow color to a lighter shade
					shadowOffset: { width: 0, height: 2 }, // Shadow on iOS
					shadowOpacity: 0.2, // Adjust the opacity for a softer shadow
					shadowRadius: 10, // Adjust the radius for a softer shadow
					padding: 10,
					marginBottom: 20,
				}}
				onPress={onPress}
			>
				{/* Icon with text below */}
				<View
					style={{
						padding: 20,
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "flex-start",
					}}
				>
					<View style={{}}>
						<AntDesign name={icon} size={32} color={textVariant} />
					</View>

					<View style={{ marginTop: 20 }}>
						<Text
							style={{
								color: text,
								fontSize: 19,
								fontFamily: defaultStyles.boldFont,
								marginBottom: 5,
							}}
						>
							{title}
						</Text>
						{isPaymentCard && paymentMethods.length != 0 ? (
							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									maxWidth: "100%",
								}}
							>
								{paymentMethods[0].card.brand.toUpperCase()}{" "}
								ending in **** {paymentMethods[0].card.last4}
							</Text>
						) : (
							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									maxWidth: "100%",
								}}
							>
								{description}
							</Text>
						)}
					</View>
				</View>
			</TouchableOpacity>
		);
	};

	const TermsAndPrivacyModal = () => {
		return (
			<Modal
				animationType="fade"
				transparent={true}
				onRequestClose={() => {
					setTermsAndPrivacyModal(!termsAndPrivacyModal);
				}}
			>
				<View
					style={{
						backgroundColor: "rgba(0,0,0,0.5)",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<View
						style={{
							backgroundColor: background,
							width: 350,
							height: 100,
							borderRadius: 20,
							elevation: 5,
							shadowColor: darkMode ? "#3c3c3c" : "#d3d3d3",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.45,
							shadowRadius: 3.84,
							padding: 20,
						}}
					>
						<View
							style={{
								display: "flex",
								flexDirection: "row-reverse",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							{/* Close icon top right  */}
							<TouchableOpacity
								onPress={() => setTermsAndPrivacyModal(false)}
							>
								<AntDesign
									name="close"
									size={18}
									color={text}
								/>
							</TouchableOpacity>

							{/* Link to terms and privacy */}
							<TouchableOpacity
								onPress={() => {
									window.location.href = "/tos";
								}}
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<AntDesign
									name="link"
									size={16}
									color={textVariant}
								/>
								<Text
									style={{
										color: text,
										fontSize: 18,
										fontFamily: defaultStyles.regularFont,
										marginLeft: 10,
									}}
								>
									Terms of Service
								</Text>
							</TouchableOpacity>
						</View>

						<TouchableOpacity
							onPress={() => {
								window.location.href = "/privacy";
							}}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginTop: 10,
							}}
						>
							<AntDesign
								name="link"
								size={16}
								color={textVariant}
							/>
							<Text
								style={{
									color: text,
									fontSize: 18,
									fontFamily: defaultStyles.regularFont,
									maxWidth: "95%",
									marginLeft: 10,
								}}
							>
								Privacy Policy
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
		);
	};

	if (loading) {
		return <LoadingPage></LoadingPage>;
	}

	if (!user) {
		return (
			<SafeAreaView style={{ flex: 1, backgroundColor: background }}>
				<ScrollView
					style={{ flex: 1, backgroundColor: background }}
					showVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				>
					{user ? (
						<Header
							isFromSettings={true}
							navigation={navigation}
						></Header>
					) : (
						<AuthHeader navigation={navigation}></AuthHeader>
					)}

					<View
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							paddingVertical: 60,
							paddingHorizontal: !isSm ? 15 : 45,
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 28,
								fontFamily: defaultStyles.titleFont,
							}}
						>
							Please sign in to view your profile.
						</Text>
					</View>
				</ScrollView>
			</SafeAreaView>
		);
	}

	return (
		<SafeAreaView style={{ flex: 1, backgroundColor: background }}>
			{termsAndPrivacyModal && <TermsAndPrivacyModal />}

			<ContactModal
				setContactModal={setContactModal}
				contactModal={contactModal}
			/>

			<PaymentMethodModal
				setModalVisible={setPaymentMethodModal}
				modalVisible={paymentMethodModal}
			/>

			<LoginModal
				setLoginModal={setShowLoginModal}
				loginModal={showLoginModal}
			/>

			{showOnboarding && isTutor && (
				<View
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: 100,
					}}
				>
					<Onboarding
						user={user}
						name={name}
						visible={showOnboarding}
						setVisible={setShowOnboarding}
					></Onboarding>
				</View>
			)}

			<ScrollView
				style={{ flex: 1, backgroundColor: background }}
				showVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			>
				{user ? (
					<Header
						isFromSettings={true}
						navigation={navigation}
					></Header>
				) : (
					<AuthHeader navigation={navigation}></AuthHeader>
				)}

				<View
					style={{
						paddingVertical: 60,
						paddingHorizontal: !isSm ? 15 : 45,
					}}
				>
					<Text
						style={{
							color: text,
							fontSize: 36,
							fontFamily: defaultStyles.titleFont,
						}}
					>
						Settings
					</Text>
					<View
						style={{
							display: "flex",
							flexDirection: isSm ? "row" : "column",
						}}
					>
						<Text
							style={{
								color: text,
								fontSize: 18,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{name ? name + " " : "No Account, Please Sign in."}
						</Text>
						{user && (
							<Text
								style={{
									color: text,
									fontSize: 18,
									fontFamily: defaultStyles.regularFont,
								}}
							>
								{user.email}.{" "}
								{isTutor && isOnboarded && (
									<Link
										style={{
											fontFamily: defaultStyles.boldFont,
											textDecorationLine: "underline",
											textDecorationColor: text,
											textDecorationStyle: "solid",
											textDecorationThickness: 2,
											textUnderlineOffset: 4,
										}}
										to={`/tutors/${URL}`}
									>
										Go to profile
									</Link>
								)}
							</Text>
						)}
					</View>
					{/* Show this if there is a user logged in */}
					{user && (
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "wrap",
								marginTop: 30,
								maxWidth: "100%",
								gap: 15,
							}}
						>
							{/* Account Settings */}
							{user && (
								<LinkCardComponent
									title="Account settings"
									description="Edit your account settings."
									icon="user"
									onPress={() => {
										navigation.navigate("Account");
									}}
								/>
							)}

							{isTutor && isOnboarded && (
								<LinkCardComponent
									title="InstaBook settings"
									description="Edit your InstaBook settings."
									icon="videocamera"
									onPress={() => {
										navigation.navigate("InstaBook");
									}}
								/>
							)}

							{isTutor && isOnboarded && (
								<LinkCardComponent
									title="Session settings"
									description="Edit your session settings."
									icon="calendar"
									onPress={() => {
										navigation.navigate("SessionSettings");
									}}
								/>
							)}

							{/* Notifications */}
							{user && (
								<LinkCardComponent
									title="Notifications"
									description="Manage your notification preferences."
									icon="bells"
									onPress={() => {
										navigation.navigate("Notifications");
									}}
								/>
							)}

							{/* Tutor profile */}
							{isTutor && isOnboarded && (
								<LinkCardComponent
									title="Payments"
									description="View your earnings via your virtual wallet and manage your payment methods."
									icon="wallet"
									onPress={() => {
										navigation.navigate("PaymentProfile");
									}}
								/>
							)}

							{/* Complete Profile */}
							{isTutor && !isOnboarded && (
								<LinkCardComponent
									title="Complete profile"
									description="Complete your profile to start tutoring."
									icon="user"
									onPress={() =>
										setShowOnboarding(!showOnboarding)
									}
								/>
							)}

							{/* Payment Method */}
							{/* {!isTutor && user && (
                            <LinkCardComponent
                              title={paymentMethods.length == 0 ? 'Add Payment Method': 'Update Payment Method'}
                              isPaymentCard={true}
                              description='Manage your payment method.'
                              icon='creditcard'
                              onPress={() => setPaymentMethodModal(!paymentMethodModal)}
                            />
                          )}
         */}
							{/* Performance */}
							{isTutor && isOnboarded && (
								<LinkCardComponent
									title="Performance"
									description="View your performance as a tutor."
									icon="linechart"
									onPress={() => {
										navigation.navigate("Performance");
									}}
								/>
							)}

							{/* Support */}
							<LinkCardComponent
								title="Support"
								description="Contact support for help with your account."
								icon="customerservice"
								onPress={() => setContactModal(!contactModal)}
							/>

							{/* Terms of Service & Privacy Policy */}
							<LinkCardComponent
								title="Privacy"
								description="View our terms of service and privacy policy."
								icon="lock"
								onPress={() =>
									setTermsAndPrivacyModal(
										!termsAndPrivacyModal
									)
								}
							/>
						</View>
					)}

					{/* Show this if there is no user logged in */}
					{!user && (
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "wrap",
								marginTop: 50,
								maxWidth: "100%",
								gap: 10,
							}}
						>
							<LinkCardComponent
								title="Login"
								description="Login to your account."
								icon="user"
								onPress={() => {
									setShowLoginModal(true);
								}}
							/>

							{/* Toggle Dark Mode */}
							<LinkCardComponent
								title={darkMode ? "Light Mode" : "Dark Mode"}
								description={
									"Toggle between dark mode and light mode."
								}
								icon="user"
								onPress={() => toggleDarkMode()}
							/>
						</View>
					)}
				</View>

				{isSm && <Footer />}
			</ScrollView>
		</SafeAreaView>
	);
};
