// Package imports
import { MaterialIcons } from "@expo/vector-icons";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FlatList, Platform, StyleSheet, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import { useFirebaseContext } from "../../../contexts/firebaseContext";

// File imports
import { Header as AuthHeader } from "../../../components/AuthHeader";
import { Header } from "../../../components/Header";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

import { Chat } from "../../../components/Chat/Chat";
import { ChatListCard } from "../../../components/Chat/ChatListCard";
import Footer from "../../../components/Footer";

export const Chats = ({ navigation, route, params }) => {
	// Firebase
	const { db } = useFirebaseContext();
	const auth = getAuth();
	const { chats, user } = useUserContext();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Styles
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		title: {
			color: text,
			fontSize: 36,
			fontFamily: defaultStyles.titleFont,
			marginBottom: 15,
		},
		indicator: {
			marginTop: 15,
			color: text,
			fontSize: 22,
			fontFamily: defaultStyles.boldFont,
			textAlign: "center",
		},
		indicatorMessage: {
			color: textVariant,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
		},
	});

	const [openChat, setOpenChat] = useState(false);

	useEffect(() => {
		if (route.params?.chat) {
			// Find chat by route params openChat
			chats.forEach((chat) => {
				if (chat.id === route.params?.chat) {
					setOpenChat({
						chat: chat,
						hoursSinceOnline: route.params?.lo,
					});
				}
			});
		}
	}, [route.params?.chat]);

	useEffect(() => {
		console.log("Chats: ", openChat);
	}, [openChat]);

	return (
		<SafeAreaView style={styles.container}>
			{isMd || !openChat ? (
				<>
					{user ? (
						<Header navigation={navigation}></Header>
					) : (
						<AuthHeader navigation={navigation}></AuthHeader>
					)}
				</>
			) : null}
			<View style={styles.maxWidth}>
				{/* Main section  */}
				<View
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "row",
						padding: !isSm ? 15 : 45,
					}}
				>
					{!isMd && openChat ? null : (
						<View
							style={{
								flex: 1,
								borderRight: isMd
									? "1px solid " + border
									: null,
								paddingRight: isMd ? 30 : null,
							}}
						>
							<Text style={styles.title}>Chats</Text>

							<FlatList
								data={chats}
								keyExtractor={(item) => item.id}
								renderItem={({ item, index }) => (
									<ChatListCard
										key={`chat-${index}`}
										chat={item}
										openChat={openChat}
										navigation={navigation}
										setOpenChat={setOpenChat}
									/>
								)}
							/>
						</View>
					)}

					<View
						style={{
							display: isMd || openChat ? "flex" : "none",
							flex: 1,
							paddingLeft: isMd ? 30 : null,
							justifyContent: !openChat ? "center" : "",
							alignItems: !openChat ? "center" : "",
						}}
					>
						{openChat ? (
							<Chat
								openChat={openChat}
								setOpenChat={setOpenChat}
								navigation={navigation}
							/>
						) : (
							<>
								{isMd ? (
									<>
										{/* <FontAwesome name="comment" size={100} color={primaryVariant} /> */}
										<MaterialIcons
											name="chat"
											size={100}
											color={primaryVariant}
										/>
										{/* <MaterialCommunityIcons name="chat-processing" size={100} color={primaryVariant} /> */}

										<Text style={styles.indicator}>
											Your Chats
										</Text>
										{user ? (
											<Text
												style={styles.indicatorMessage}
											>
												{chats.length > 0
													? "Select a chat to view messages"
													: "You have no chats yet"}
											</Text>
										) : (
											<Text
												style={styles.indicatorMessage}
											>
												Sign in to view your chats
											</Text>
										)}
									</>
								) : null}
							</>
						)}
					</View>
				</View>
			</View>
			{isSm ? <Footer></Footer> : null}
		</SafeAreaView>
	);
};
