// Imports
import { AntDesign, Feather } from "@expo/vector-icons";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Link } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import { SafeAreaView } from "react-native-safe-area-context";
import {
	addDoc,
	collection,
	doc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Bubble, GiftedChat } from "react-native-gifted-chat";
import {
	Menu,
	MenuOption,
	MenuOptions,
	MenuTrigger,
} from "react-native-popup-menu";

import { useMediaQuery } from "react-responsive";
import { Input } from "../../components/Chat/Input";
import { useColorsContext, useStyles } from "../../utils/styles";
import { ReportModal } from "../ReportModal";

// Firebase
import { useFirebaseContext } from "../../contexts/firebaseContext";
import { useUserContext } from "../../contexts/userContext";
const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

// Chat Screen
export const Chat = ({ openChat, setOpenChat, navigation }) => {
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	let chat = openChat.chat;
	let tutor = openChat.chat.tutor;
	let hoursSinceOnline = openChat.hoursSinceOnline;

	const { isTutor, user, name, profile, unreadMessages } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const { db } = useFirebaseContext();
	const auth = getAuth();
	const [messages, setMessages] = useState(null);
	const [image, setImage] = useState(
		"https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e"
	);
	const [reportModal, setReportModal] = useState(false);
	const [tutorURL, setTutorURL] = useState(null);

	// Styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// const updateMessagesToRead = async () => {
	//     const q = query(collection(db, "messages"), where("chat", "==", chat.id), where("read", "==", false), where("user.uid", "!=", user.uid));
	//     const querySnapshot = await getDocs(q);

	//     querySnapshot.forEach((msg) => {

	//         if (msg.exists()) {

	//             // Update message to read
	//              updateDoc(doc(db, "messages", msg.id), {
	//                 read: true,
	//             });

	//         } else {
	//             console.log(`All messages are read!`);
	//         }
	//     });
	// }

	// Update messages to read on load
	// useEffect(() => {
	//     if (user && chat && chat.id) {
	//         updateMessagesToRead();
	//     }

	// }, [chat, unreadMessages])

	// Bubble Chat Component
	const BubbleChat = (props) => {
		return (
			<Bubble
				{...props}
				textStyle={{
					left: {
						color: text,
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
					},
					right: {
						color: "#fff",
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
					},
				}}
				wrapperStyle={{
					left: {
						backgroundColor: darkMode ? backgroundVariant : "#eee",
						maxWidth: "80%",
					},
					right: {
						backgroundColor: primaryVariant,
						maxWidth: "80%",
					},
				}}
			/>
		);
	};

	// Get messages on load
	useEffect(() => {
		if (user && chat && chat.id) {
			if (isTutor) {
				if (chat.user.image) {
					setImage(chat.user.image);
				} else {
					setImage(
						"https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e"
					);
				}
			} else {
				if (tutor.image) {
					setImage(tutor.image);
				} else {
					setImage(
						"https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e"
					);
				}
			}
			getTutorURL();

			setMessages(null);

			// Load messages
			getMessages();
		}
	}, [user, chat]);

	const getTutorURL = async () => {
		// Get TUTOR doc from users collection
		const q = query(
			collection(db, "users"),
			where("uid", "==", chat.tutor.uid)
		);
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setTutorURL(doc.data().URL);
		});
	};

	// Get chat messages
	const getMessages = async () => {
		// const q = query(collection(db, "chats", chat.id, "messages"), where("user._id", "==", user.uid));
		const q = query(
			collection(db, "messages"),
			orderBy("createdAt", "desc"),
			where("chat", "==", chat.id)
		);

		const unsubscribe = onSnapshot(q, (snapshot) =>
			setMessages(
				snapshot.docs.map((doc) => ({
					_id: doc.data()._id,
					createdAt: doc.data().createdAt
						? doc.data().createdAt.toDate()
						: null,
					text: doc.data().text,
					image: doc.data().image ? doc.data().image : null,
					user: doc.data().user,
				}))
			)
		);
		return unsubscribe;
	};

	const onSend = (message, isImage, isFile) => {
		// setMessages(previousMessages => GiftedChat.append(previousMessages, messages))
		addDoc(collection(db, "messages"), {
			chat: chat.id,
			text: isImage ? "" : message,
			image: isImage ? message : "",
			createdAt: new Date(),
			read: false,
			user: {
				_id: user.uid,
				uid: user.uid,
				name: name,
				avatar: profile
					? profile
					: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
			},
		});

		// TODO: HIT EMAIL API
		fetch(`${hostname}/api/email/message`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user.accessToken,
			},
			body: JSON.stringify({
				other: isTutor
					? chat.user.name.split(" ")[0]
					: tutor.name.split(" ")[0],
				name: isTutor
					? tutor.name.split(" ")[0]
					: chat.user.name.split(" ")[0],
				userId: isTutor ? chat.user.uid : tutor.uid,
				message: isImage ? "a media file" : message,
			}),
		});

		// Update last message
		updateDoc(doc(db, "chats", chat.id), {
			lastUpdated: serverTimestamp(),
			lastMessage: {
				text: isImage ? "Image" : message,
				createdAt: serverTimestamp(),
				user: {
					_id: user.uid,
					uid: user.uid,
					name: name,
					avatar: profile
						? profile
						: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
				},
			},
		});
	};

	const onSendImage = (image) => {
		onSend(image, true, false);
	};

	const styles = StyleSheet.create({
		mainSection: {
			flex: 1,
		},
		menuText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
		},
		title: {
			fontSize: 22,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		tutorInfo: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.boldFont,
		},
	});

	if (!user) {
		return (
			<SafeAreaView style={styles.container}>
				<View style={styles.maxWidth}>
					<View
						style={[
							styles.mainSection,
							{
								alignItems: "center",
								justifyContent: "center",
							},
						]}
					>
						<Text style={styles.title}>404 Tutor not found</Text>
					</View>
				</View>
			</SafeAreaView>
		);
	}

	// Return chat screen
	return (
		<SafeAreaView style={styles.container}>
		<View style={styles.mainSection}>
			{/* Header */}
			<View
				style={{
					marginTop: 15,
					height: 60,
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<TouchableOpacity
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
						onPress={() => {
							setOpenChat(null);
						}}
					>
						<NavigateBeforeIcon
							style={{
								marginRight: 30,
								marginLeft: -12,
								fontSize: 32,
								color: text,
							}}
						/>
					</TouchableOpacity>

					<Image
						source={{ uri: image }}
						style={{
							width: 60,
							height: 60,
							borderRadius: defaultStyles.buttonRadius,
							marginRight: 15,
						}}
					/>

					<View>
						{isTutor || !tutorURL ? (
							<Text style={styles.title}>
								{chat.user.name.split(" ").length > 1
									? `${chat.user.name.split(" ")[0]} ${
											chat.user.name.split(" ")[1][0]
									  }.`
									: `${chat.user.name}`}
							</Text>
						) : (
							<Link to={`/tutors/${tutorURL}`}>
								<Text style={styles.title}>
									{tutor.name.split(" ").length > 1
										? `${tutor.name.split(" ")[0]} ${
												tutor.name.split(" ")[1][0]
										  }.`
										: `${tutor.name}`}
								</Text>
							</Link>
						)}

						<Text style={styles.tutorInfo}>
							{hoursSinceOnline !== null &&
							hoursSinceOnline !== undefined ? (
								<>
									{hoursSinceOnline > 24 ? (
										<Text>
											Last online{" "}
											{Math.floor(hoursSinceOnline / 24)}{" "}
											days ago
										</Text>
									) : (
										<Text>
											{hoursSinceOnline <= 1 ? (
												<Text
													style={{ color: "#00AA54" }}
												>
													Online now
												</Text>
											) : (
												<>
													Last online{" "}
													{hoursSinceOnline} hours ago
												</>
											)}
										</Text>
									)}
								</>
							) : (
								""
							)}
						</Text>
					</View>
				</View>

				{/* Report icon in the top right */}
				{user && (
					<View
						style={{
							marginLeft: 15,
							display: "flex",
						}}
					>
						<Menu>
							<MenuTrigger
								style={{
									display: "flex",
									flexDirection: "row-reverse",
								}}
							>
								<View
									style={{
										zIndex: 999,
										padding: 15,
									}}
								>
									<Feather
										name="more-vertical"
										size={24}
										color={textVariant}
									/>
								</View>
							</MenuTrigger>

							<MenuOptions
								optionsContainerStyle={{
									backgroundColor: backgroundVariant,
									borderRadius: defaultStyles.radius,
									position: "absolute",
									paddingVertical: 10,
									paddingHorizontal: 10,
									position: "absolute",
									top: 50,
									right: -20,
									zIndex: 999,
									shadowColor: "rgba(0, 0, 0, 0.1)",
									shadowOpacity: 0.8,
									shadowOffset: { width: 2, height: 2 },
									shadowRadius: 4,
								}}
								customStyles={{
									optionText: {
										fontSize: 16,
										fontFamily: defaultStyles.regularFont,
									},
								}}
							>
								<MenuOption
									onSelect={() => {
										setReportModal(true);
									}}
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										gap: 10,
									}}
								>
									<View
										style={{
											display: "flex",
											flexDirection: "row-reverse",
											alignItems: "center",
											justifyContent: "center",
											padding: 5,
										}}
									>
										<Text style={styles.menuText}>
											{isTutor
												? "Report Student"
												: "Report Tutor"}
										</Text>

										<Feather
											style={{ marginRight: 10 }}
											name="alert-circle"
											size={20}
											color={text}
										/>
									</View>
								</MenuOption>

								{!isTutor && (
									<MenuOption
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											gap: 10,
											marginTop: 5,
										}}
									>
										<Link
											to={`/tutors/${tutorURL}`}
											style={{
												display: "flex",
												flexDirection: "row-reverse",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<View
												style={{
													margin: 5,
													display: "flex",
													flexDirection:
														"row-reverse",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<Text style={styles.menuText}>
													View Profile
												</Text>
												<AntDesign
													style={{ marginRight: 10 }}
													name="user"
													size={20}
													color={text}
												/>
											</View>
										</Link>
									</MenuOption>
								)}
							</MenuOptions>
						</Menu>
					</View>
				)}
			</View>

			{messages !== null ? (
				<>
					<GiftedChat
						messages={messages}
						showAvatarForEveryMessage={true}
						onSend={(messages) => onSend(messages)}
						lightboxProps={{
							backgroundColor: background,
							activeProps: {
								resizeMode: "fill",
								style: {
									flex: 1,
									width: "100%",
									height: "100%",
									margin: 0,
								},
							},
						}}
						user={{
							_id: user.uid,
							name: name,
							avatar: profile
								? profile
								: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
						}}
						renderBubble={(props) => {
							return <BubbleChat {...props} />;
						}}
						multiline={true}
						renderInputToolbar={() => (
							<Input
								onSendImage={onSendImage}
								onSend={(messages) => onSend(messages)}
							/>
						)}
					/>
				</>
			) : null}

			{/* REPORT MODAL  */}
			<ReportModal
				modalVisible={reportModal}
				setModalVisible={setReportModal}
				tutor={tutor}
				chat={chat}
				navigation={navigation}
				isChatScreen={true}
				studentName={chat.user.name}
				studentID={chat.user.uid}
				studentEmail={chat.user.email}
			/>
		</View>
		</SafeAreaView>
	);
};
