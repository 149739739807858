import { FontAwesome } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import Slider from "@react-native-community/slider";
import { Picker } from "@react-native-picker/picker";
import { useEffect, useState } from "react";
import {
	Modal,
	ScrollView,
	StyleSheet,
	Switch,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

import { languages } from "../config/languages";
import { provinces } from "../config/locations";
import { subjects as subjectData } from "../config/subjects";

// const skills = [
//     { value: 'all', label: 'All' },
//     { value: 'Programming', label: 'Programming' },
//     { value: 'Javascript', label: 'Javascript' },
//     { value: 'Calculus', label: 'Calculus' },
//     { value: 'Algebra', label: 'Algebra' }
// ];

// const subjects = [
//     { id: 1, subject: 'All', icon: 'calculator', value: 'all' },
//     { id: 1, subject: 'Mathematics', icon: 'calculator', value: 'Mathematics' },
//     { id: 2, subject: 'Law', icon: 'gavel', value: 'Law' },
//     { id: 3, subject: 'Business', icon: 'briefcase', value: 'Business' },
//     { id: 4, subject: 'Geography', icon: 'map', value: 'Geography' },
//     { id: 6, subject: 'Media Studies', icon: 'camera', value: 'Media Studies' },
//     { id: 7, subject: 'Chemistry', icon: 'flask', value: 'Chemistry' },
//     { id: 8, subject: 'Biology', icon: 'leaf', value: 'Biology' },
//     { id: 12, subject: 'Linguistics', icon: 'book', value: 'Linguistics' },
//     {
//     id: 13,
//     subject: 'Physiotherapy',
//     icon: 'heartbeat',
//     value: 'Physiotherapy',
//     },
//     {
//     id: 15,
//     subject: 'Computer Science',
//     icon: 'laptop',
//     value: 'Computer Science',
//     },
//     {
//     id: 16,
//     subject: 'Civil Engineering',
//     icon: 'building',
//     value: 'Civil Engineering',
//     },
//     { id: 18, subject: 'Philosophy', icon: 'apple', value: 'Philosophy' },
//     { id: 19, subject: 'Astronomy', icon: 'space-shuttle', value: 'Astronomy' },
//     {
//     id: 20,
//     subject: 'Health Sciences',
//     icon: 'medkit',
//     value: 'Health Sciences',
//     },
// ];

// const majorCities = [
//     { value: 'all', label: 'All' },
//     { value: 'Toronto', label: 'Toronto' },
//     { value: 'Montréal', label: 'Montréal' },
//     { value: 'Vancouver', label: 'Vancouver' },
//     { value: 'Calgary', label: 'Calgary' },
//     { value: 'Edmonton', label: 'Edmonton' },
//     { value: 'Ottawa', label: 'Ottawa' },
//     { value: 'Winnipeg', label: 'Winnipeg' },
//     { value: 'Hamilton', label: 'Hamilton' },
//     { value: 'Kitchener', label: 'Kitchener' },
// ];

// const majorLanguages = [
//     { value: 'all', label: 'All' },
//     { value: 'English', label: 'English' },
//     { value: 'French', label: 'French' },
//     { value: 'Arabic', label: 'Arabic' },
// ];

const price = [
	{ value: "all", label: "All" },
	{ value: "10", label: "Less than $10/h" },
	{ value: "15", label: "Less than $15/h" },
	{ value: "25", label: "Less than $25/h" },
	{ value: "50", label: "Less than $50/h" },
	{ value: "75", label: "Less than $75/h" },
];

export const FiltersModal = ({ modalVisible, setModalVisible, setFilters }) => {
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "50%"
				: isLg
				? "60%"
				: isMd
				? "70%"
				: isSm
				? "80%"
				: "90%",
			height: isXl
				? "70%"
				: isLg
				? "70%"
				: isMd
				? "70%"
				: isSm
				? "80%"
				: "90%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			// justifyContent: 'space-between',
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			// position: 'absolute',
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		circleButton: {
			width: 40,
			height: 40,
			borderRadius: defaultStyles.buttonRadius,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			marginRight: 15,
			marginLeft: "auto",
		},
		applyButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 40,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
		},

		marginTop: {
			marginTop: 15,
		},
		title: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	// States
	const [selectedSubject, setSelectedSubject] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [selectedRating, setSelectedRating] = useState(0);
	const [selectedLanguage, setSelectedLanguage] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState("all");
	const [selectedPrice, setSelectedPrice] = useState(0);
	const [sliderPosition, setSliderPosition] = useState(0);
	const [verified, setVerified] = useState(false);
	const [maxRating, setMaxRating] = useState(0);
	const [minRating, setMinRating] = useState(0);
	const [maxPrice, setMaxPrice] = useState(0);
	const [minPrice, setMinPrice] = useState(0);

	// States for picker data
	const [subjects, setSubjects] = useState([]);
	const [skills, setSkills] = useState([]);
	const [majorCities, setMajorCities] = useState([]);
	const [majorProvinces, setMajorProvinces] = useState([]);
	const [majorLanguages, setMajorLanguages] = useState([]);

	// Functions
	const saveFilters = () => {
		setFilters([
			{ name: "subject", value: selectedSubject },
			{ name: "skills", value: selectedSkills },
			{ name: "rating", value: selectedRating },
			{ name: "language", value: selectedLanguage },
			{ name: "location", value: selectedLocation },
			{ name: "price", value: selectedPrice },
			{ name: "verified", value: verified },
			{
				name: "minRating",
				value: minRating,
			},
			{
				name: "maxRating",
				value: maxRating,
			},
			{
				name: "minPrice",
				value: minPrice,
			},
			{
				name: "maxPrice",
				value: maxPrice,
			},
		]);
		setModalVisible(!modalVisible);
	};

	const resetFilters = () => {
		setSelectedSubject([]);
		setSelectedSkills([]);
		setSelectedRating(0);
		setSelectedLanguage([]);
		setSelectedLocation("all");
		setSliderPosition(0.01);
		setSelectedPrice("all");
		setVerified(false);
		setMaxRating(0);
		setMinRating(0);
		setMaxPrice(0);
		setMinPrice(0);
	};

	useEffect(() => {
		if (languages) {
			var majorLanguages = [
				{
					value: "all",
					label: "All",
				},
			];
			languages.forEach((language) => {
				majorLanguages.push({
					value: language,
					label: language,
				});
			});
			setMajorLanguages(majorLanguages);
		}

		if (provinces) {
			var majorProvinces = [
				{
					value: "all",
					label: "All",
				},
			];
			provinces.forEach((province) => {
				majorProvinces.push({
					value: province,
					label: province,
				});
			});
			setMajorProvinces(majorProvinces);
		}

		if (subjectData) {
			var subjectsArr = [];
			let subjectKeys = Object.keys(subjectData);
			subjectKeys.forEach((subject) => {
				subjectsArr.push({
					subject: subject,
					value: subject,
				});
			});

			// Sort alphabetically
			subjectsArr.sort((a, b) => {
				if (a.subject < b.subject) {
					return -1;
				}

				if (a.subject > b.subject) {
					return 1;
				}

				return 0;
			});

			subjectsArr.unshift({
				subject: "All",
				value: "all",
			});

			setSubjects(subjectsArr);

			// Set skills
			var skillsArr = [];
			subjectKeys.forEach((subject) => {
				let skills = subjectData[subject];
				skills.forEach((skill) => {
					skillsArr.push({
						label: skill,
						value: skill,
					});
				});
			});

			// Sort alphabetically
			skillsArr.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}

				if (a.label > b.label) {
					return 1;
				}

				return 0;
			});

			skillsArr.unshift({
				label: "All",
				value: "all",
				enabled: false,
			});

			setSkills(skillsArr);
		}
	}, []);

	// If subjet is selected, set skills to only those skills
	useEffect(() => {
		if (selectedSubject.length > 0) {
			if (selectedSubject.includes("all")) {
				setSelectedSubject([]);
				var skillsArr = [];
				let subjectKeys = Object.keys(subjectData);
				subjectKeys.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			} else {
				var skillsArr = [];
				selectedSubject.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			}
		}
	}, [selectedSubject]);

	// If skills selected check if all is selected
	useEffect(() => {
		if (selectedSkills.length > 0) {
			if (selectedSkills.includes("all")) {
				setSelectedSkills([]);
			}
		}
	}, [selectedSkills]);

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(!modalVisible);
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={styles.modalHeader}>
						{/* Close filters button */}
						<TouchableOpacity
							style={styles.closeButton}
							onPress={() => {
								setModalVisible(!modalVisible);
							}}
						>
							<CloseOutlined
								style={{ fontSize: 22, color: text }}
							></CloseOutlined>
						</TouchableOpacity>

						{/* Title */}
						<Text style={styles.modalTitle}>Advanced Search</Text>

						{/* Clear filters */}
						<TouchableOpacity
							onPress={resetFilters}
							style={[styles.circleButton]}
						>
							<FontAwesome name="undo" size={14} color={text} />
						</TouchableOpacity>

						{/* Apply filters button */}
						<TouchableOpacity
							style={styles.applyButton}
							onPress={saveFilters}
						>
							<Text style={styles.buttonText}>Search</Text>
						</TouchableOpacity>
					</View>

					<ScrollView showsVerticalScrollIndicator={false}>
						{/* Subject  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>Subject</Text>
							<Text style={styles.pickerInfoText}>
								Filter by subject.{" "}
							</Text>

							{/* Render selected subjects */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
								}}
							>
								{selectedSubject.length > 0
									? selectedSubject.map((item, i) => (
											<TouchableOpacity
												key={`filters-subject-${i}`}
												onPress={(e) => {
													// Ignore parent touchable opacity
													e.stopPropagation();

													let newSelectedSubject = [
														...selectedSubject,
													];
													newSelectedSubject.splice(
														i,
														1
													);
													setSelectedSubject(
														newSelectedSubject
													);
												}}
											>
												<View
													style={{
														marginRight: 5,
														marginTop: 5,
														backgroundColor:
															backgroundVariant,
														border: `1px solid ${border}`,
														borderRadius:
															defaultStyles.buttonRadius,
														padding: 8,
														position: "relative",
													}}
												>
													<Text
														style={{
															color: textVariant,
															fontFamily:
																defaultStyles.regularFont,
														}}
													>
														{selectedSubject[i]}
													</Text>

													{/* Close icon */}
													<View
														style={{
															position:
																"absolute",
															top: -5,
															right: -5,
															backgroundColor:
																backgroundVariant,
															borderRadius: "50%",
															padding: 2,
														}}
													>
														<FontAwesome
															name="close"
															size={8}
															color={textVariant}
														/>
													</View>
												</View>
											</TouchableOpacity>
									  ))
									: null}
							</View>
							<Picker
								style={styles.picker}
								selectedValue={
									selectedSubject.length > 0
										? selectedSubject[
												selectedSubject.length - 1
										  ]
										: "all"
								}
								onValueChange={(itemValue, itemIndex) =>
									setSelectedSubject((prevState) => [
										...prevState,
										itemValue,
									])
								}
							>
								{subjects.map((item, i) => (
									<Picker.Item
										key={i}
										label={subjects[i].subject}
										value={subjects[i].value}
									/>
								))}
							</Picker>
						</View>

						{/* Price  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}> Price</Text>
							<Text style={styles.pickerInfoText}>
								Filter by hourly price.
							</Text>
							<TouchableOpacity
								onPress={(e) => {
									e.stopPropagation();
								}}
								style={{ marginTop: 10 }}
							>
								<Text style={{ color: textVariant }}>
									Min{" "}
									{minPrice > 0 ? (
										<Text>${minPrice} </Text>
									) : null}
								</Text>
								<Slider
									style={{ width: "100%", height: 40 }}
									step={10}
									value={minPrice}
									minimumValue={0}
									maximumValue={100}
									onValueChange={(val) => setMinPrice(val)}
									minimumTrackTintColor={primary}
									maximumTrackTintColor={border}
									thumbTintColor={primary}
								/>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={(e) => {
									e.stopPropagation();
								}}
								style={{ marginTop: 10 }}
							>
								<Text style={{ color: textVariant }}>
									Max{" "}
									{maxPrice > 0 ? (
										<Text>${maxPrice} </Text>
									) : null}
								</Text>
								<Slider
									style={{ width: "100%", height: 40 }}
									step={10}
									value={maxPrice}
									minimumValue={minPrice}
									maximumValue={100}
									onValueChange={(val) => setMaxPrice(val)}
									minimumTrackTintColor={primary}
									maximumTrackTintColor={border}
									thumbTintColor={primary}
								/>
							</TouchableOpacity>
						</View>

						{/* Skills  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>Skills</Text>
							<Text style={styles.pickerInfoText}>
								Filter by skills.{" "}
							</Text>

							{/* Render selected skill if any  */}
							{/* <View
                style={{ display: 'flex', flexDirection: 'row', maxWidth: 350 }}
              >
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                >
                  {selectedSkills.length > 0
                    ? selectedSkills.map((item, i) => (
                        <View
                          key={i}
                          style={{
                            marginRight: 5,
                            marginTop: 5,
                            backgroundColor: backgroundVariant,
                            border: `1px solid ${border}`,
                            borderRadius: defaultStyles.radius,
                            padding: 8,
                          }}
                        >
                          <Text
                            style={{
                              color: textVariant,
                              fontFamily: defaultStyles.regularFont,
                            }}
                          >
                            {selectedSkills[i]}
                          </Text>
                        </View>
                      ))
                    : null}
                </ScrollView>
              </View> */}

							<View
								style={{
									display: "flex",
									flexWrap: "wrap",
									flexDirection: "row",
								}}
							>
								{selectedSkills.length > 0
									? selectedSkills.map((item, i) => (
											<TouchableOpacity
												onPress={(e) => {
													// Ignore parent touchable opacity
													e.stopPropagation();
													let newSkills =
														selectedSkills.filter(
															(skill) =>
																skill !== item
														);
													setSelectedSkills(
														newSkills
													);
												}}
											>
												<View
													key={i}
													style={{
														marginRight: 5,
														marginTop: 5,
														backgroundColor:
															backgroundVariant,
														border: `1px solid ${border}`,
														borderRadius:
															defaultStyles.buttonRadius,
														padding: 8,
														position: "relative",
													}}
												>
													<Text
														style={{
															color: textVariant,
															fontFamily:
																defaultStyles.regularFont,
														}}
													>
														{selectedSkills[i]}
													</Text>
													{/* Close icon */}
													<View
														style={{
															position:
																"absolute",
															top: -5,
															right: -5,
															backgroundColor:
																backgroundVariant,
															borderRadius: "50%",
															padding: 2,
														}}
													>
														<FontAwesome
															name="close"
															size={8}
															color={textVariant}
														/>
													</View>
												</View>
											</TouchableOpacity>
									  ))
									: null}
							</View>
							<Picker
								style={styles.picker}
								selectedValue={
									selectedSkills.length > 0
										? selectedSkills[
												selectedSkills.length - 1
										  ]
										: "all"
								}
								onValueChange={(itemValue, itemIndex) =>
									setSelectedSkills((prevState) => [
										...prevState,
										itemValue,
									])
								}
							>
								{skills.map((item, i) => (
									<Picker.Item
										key={i}
										label={skills[i].label}
										value={skills[i].value}
									/>
								))}
							</Picker>
						</View>

						{/* Filter by verified true/false */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>Verified</Text>
							<Text style={styles.pickerInfoText}>
								Verified tutors are those who's credentials and
								information have been verified by Tutred.
							</Text>
							{/* Toggle switch */}
							<TouchableOpacity
								onPress={(e) => {
									e.stopPropagation();
								}}
							>
								<Switch
									style={{
										marginTop: 10,
									}}
									trackColor={{
										false: "#767577",
										true: primaryVariant,
									}}
									thumbColor={
										verified
											? primaryVariant
											: primaryVariant
									}
									ios_backgroundColor="#3e3e3e"
									onValueChange={(e) => {
										setVerified(!verified);
									}}
									value={verified}
								/>
							</TouchableOpacity>
						</View>
						{/* Rating  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>
								Rating{" "}
								{/* Show as many stars as selected if > 0  */}
								{[...Array(selectedRating)].map((e, i) => {
									return (
										<FontAwesome
											key={i}
											style={{ marginRight: 5 }}
											name="star"
											size={18}
											color={yellow}
										/>
									);
								})}
							</Text>
							<Text style={styles.pickerInfoText}>
								Filter by rating.{" "}
							</Text>

							<TouchableOpacity
								onPress={(e) => {
									e.stopPropagation();
								}}
								style={{ marginTop: 10 }}
							>
								<Text style={{ color: textVariant }}>
									Min
									{minRating > 0 ? (
										<Text>
											<FontAwesome
												name="star"
												size={16}
												color={yellow}
											/>
											{minRating}
										</Text>
									) : null}
								</Text>
								<Slider
									style={{ width: "100%", height: 40 }}
									step={1}
									value={minRating}
									minimumValue={0}
									maximumValue={5}
									onValueChange={(val) => setMinRating(val)}
									minimumTrackTintColor={primary}
									maximumTrackTintColor={border}
									thumbTintColor={primary}
								/>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={(e) => {
									e.stopPropagation();
								}}
								style={{ marginTop: 10 }}
							>
								<Text style={{ color: textVariant }}>
									Max
									{maxRating > 0 ? (
										<Text>
											<FontAwesome
												name="star"
												size={16}
												color={yellow}
											/>
											{maxRating}
										</Text>
									) : null}
								</Text>
								<Slider
									style={{ width: "100%", height: 40 }}
									step={1}
									value={maxRating}
									minimumValue={minRating}
									maximumValue={5}
									onValueChange={(val) => setMaxRating(val)}
									minimumTrackTintColor={primary}
									maximumTrackTintColor={border}
									thumbTintColor={primary}
								/>
							</TouchableOpacity>
						</View>

						{/* Location  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>Location</Text>
							<Text style={styles.pickerInfoText}>
								Filter by province.{" "}
							</Text>

							<Picker
								style={styles.picker}
								selectedValue={selectedLocation}
								onValueChange={(itemValue, itemIndex) =>
									setSelectedLocation(itemValue)
								}
							>
								{majorProvinces.map((item, i) => (
									<Picker.Item
										key={i}
										label={majorProvinces[i].label}
										value={majorProvinces[i].value}
									/>
								))}
							</Picker>
						</View>

						{/* Language  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>Language</Text>
							<Text style={styles.pickerInfoText}>
								Filter by language.{" "}
							</Text>
							{/* Render selected skill if any  */}
							<View
								style={{
									display: "flex",
									flexWrap: "wrap",
									flexDirection: "row",
								}}
							>
								{selectedLanguage.length > 0
									? selectedLanguage.map((item, i) => (
											<TouchableOpacity
												onPress={(e) => {
													// Ignore parent touchable opacity
													e.stopPropagation();
													let newLang =
														selectedLanguage.filter(
															(newLang) =>
																newLang !== item
														);
													setSelectedLanguage(
														newLang
													);
												}}
											>
												<View
													key={i}
													style={{
														marginRight: 5,
														marginTop: 5,
														backgroundColor:
															backgroundVariant,
														border: `1px solid ${border}`,
														borderRadius:
															defaultStyles.radius,
														padding: 8,
														position: "relative",
													}}
												>
													<Text
														style={{
															color: textVariant,
															fontFamily:
																defaultStyles.regularFont,
														}}
													>
														{selectedLanguage[i]}
													</Text>
													{/* Close icon */}
													<View
														style={{
															position:
																"absolute",
															top: -5,
															right: -5,
															backgroundColor:
																backgroundVariant,
															borderRadius: "50%",
															padding: 2,
														}}
													>
														<FontAwesome
															name="close"
															size={8}
															color={textVariant}
														/>
													</View>
												</View>
											</TouchableOpacity>
									  ))
									: null}
							</View>
							<TouchableOpacity
								onPress={(e) => {
									e.stopPropagation();
								}}
							>
								<Picker
									style={styles.picker}
									selectedValue={
										selectedLanguage.length > 0
											? selectedLanguage[
													selectedLanguage.length - 1
											  ]
											: "all"
									}
									onValueChange={(itemValue, itemIndex) =>
										setSelectedLanguage((prevState) => [
											...prevState,
											itemValue,
										])
									}
								>
									{majorLanguages.map((item, i) => (
										<Picker.Item
											key={i}
											label={majorLanguages[i].label}
											value={majorLanguages[i].value}
										/>
									))}
								</Picker>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</View>
		</Modal>
	);
};
