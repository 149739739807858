import React from "react";
import { Container} from "react-bootstrap";
import Button from '@mui/material/Button';
import { useStyles, useColorsContext } from "../../utils/styles";
import { TypeAnimation } from "react-type-animation";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigation } from '@react-navigation/native';
import StudentBackground from "../../assets/Student-Section.jpg";
import 'react-multi-carousel/lib/styles.css';

function ForStudents() {
	const defaultStyles = useStyles();
	const { primary } = useColorsContext();
	const navigation = useNavigation();
	return (
		<Container fluid className="subject-section">
			<Container className="home-subject">
				<Card sx={{ position: 'relative', height: '20vw', borderRadius: 5 }}>
                        <img src={StudentBackground} alt="Card image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        <CardContent sx={{ position: 'absolute', top: 0, left: 0, textAlign: 'left', color: 'white', padding: '1.04167vw' }}>
                            <h5 className="card-title">For Students</h5>
						<h1 className="tutors-heading">
						Find tutors that <TypeAnimation preRenderedFirstString={true} className="highlight-animated"
							sequence={[
								500,
								'speak french',
								1000,
								'teach calculus',
								1000,
								'are in Alberta',
								1000,
								'are verified',
								500,
							]}
							speed={50}
							repeat={Infinity}
						/>
					</h1>
                        </CardContent>
                        <CardContent sx={{ position: 'absolute', bottom: 0, left: 0, textAlign: 'left', color: 'white', padding: '1.04167vw' }}>
							<Button style={{ marginBottom: '2.604vw' }} 
							variant="contained" 
							sx={{ borderRadius: defaultStyles.buttonRadius, 
								fontFamily: defaultStyles.boldFont, 
								textTransform: 'none', 
								marginTop: '0.78125vw', 
								bgcolor: primary }} 
							disableElevation
							onClick={() => navigation.navigate("Tutors", {screen:"Tutors"})}>Start Browsing</Button>
                        </CardContent>
                    </Card>
			</Container>
		</Container>
		
	);
}

export default ForStudents;
