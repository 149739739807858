// Imports
import { Feather, FontAwesome5 } from '@expo/vector-icons';
import { Link } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import {
  doc,
  getFirestore,
  setDoc
} from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import {
  Alert,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import { Header } from '../../components/AuthHeader';
import { useUserContext } from '../../contexts/userContext';
import Hoverable from '../../utils/hover/Hoverable';
import { useColorsContext, useStyles } from '../../utils/styles';

import Footer from '../../components/Footer';

const dev = false;
const hostname = dev
  ? 'https://tutredstage-266226951372.herokuapp.com'
  : 'https://tutred-10235aff3fe9.herokuapp.com';

// Components
export const Register = ({ navigation, route, params }) => {
  // Appearance
  const {
    darkMode,
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
  } = useColorsContext();
  const defaultStyles = useStyles();

  // Media queries
  const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMd = useMediaQuery({ query: '(min-width: 768px)' });
  const isSm = useMediaQuery({ query: '(min-width: 640px)' });

  // Alert
  const customAlert = useAlert();

  // States and variables
  const { user } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
  const auth = getAuth();
  const db = getFirestore();
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [accountType, setAccountType] = useState('student');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [birthday, setBirthday] = useState('');
  const [terms, setTerms] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [lastName, setLastName] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (user) {
      navigation.navigate('Home');
    }
  }, [user]);

  const makeid = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      const storage = getStorage();
      let imageFile = await fetch(result.uri);
      let name =
        imageFile._bodyBlob && imageFile._bodyBlob._data
          ? imageFile._bodyBlob._data.name
          : makeid(64);
      let imageBlob = await imageFile.blob();
      const storageRef = ref(storage, `signups/${name}`);
      let task = uploadBytesResumable(storageRef, imageBlob);
      task.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
          // Alert.alert('Error uploading image!',  error, [
          //     { text: 'OK', onPress: () => console.log('OK Pressed') },
          // ]);
        },
        () => {
          getDownloadURL(task.snapshot.ref).then((downloadURL) => {
            setImage(downloadURL);
          });
        }
      );
    }
  };

  const checkPassword = (str) => {
    var re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    return re.test(str);
  };

  const signup = () => {
    if (!submit && email && name && lastName && password && confirmPassword) {
      setSubmit(true);
      // Validate password
      if (password != confirmPassword) {
        Platform.OS === 'web'
          ? customAlert.error('[Sign up error] Passwords do not match')
          : Alert.alert('Error signing up!', 'Passwords do not match', [
              { text: 'OK', onPress: () => console.log('OK Pressed') },
            ]);
        setSubmit(false);
        return;
      }
      if (!checkPassword(password)) {
        Platform.OS === 'web'
          ? customAlert.error(
              '[Sign up error] Password must be 6-16 characters and contain at least one number and one special character'
            )
          : Alert.alert(
              'Error signing up!',
              'Password must be 6-16 characters and contain at least one number and one special character',
              [{ text: 'OK', onPress: () => console.log('OK Pressed') }]
            );
        setSubmit(false);
        return;
      }

      var birthdayDate;
      if (accountType == 'tutor') {
        // Validate birthday
        birthdayDate = new Date(birthday);
        let today = new Date();
        // Check if birthday 13 years or more
        if (today.getFullYear() - birthdayDate.getFullYear() < 13) {
            Platform.OS === 'web' ? customAlert.error("[Sign up error] You must be 13 years or older to use this app") :
            Alert.alert('Error signing up!',  "You must be 13 years or older to use this app", [
                { text: 'OK', onPress: () => console.log('OK Pressed') },
            ]);
            setSubmit(false);
            return;
        }
      }


      // Validate terms
      if (!terms) {
        Platform.OS === 'web'
          ? customAlert.error('[Sign up error] Please accept the terms and conditions')
          : Alert.alert(
              'Error signing up!',
              'Please accept the terms and conditions',
              [{ text: 'OK', onPress: () => console.log('OK Pressed') }]
            );
        setSubmit(false);
        return;
      }


      // Create user
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          if (accountType == 'student') {
            // Create stripe customer
            fetch(`${hostname}/api/create-customer`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: email,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.success) {
                  let userData = {
                    created: new Date(),
                    uid: user.uid,
                    id: user.uid,
                    email: email,
                    name: name + " " + lastName,
                    picture: image,
                    birthday: '',
                    lastOnline: new Date(),
                    isTutor: false,
                    customerId: data.customerId,
                  };

                  setDoc(doc(db, 'users', user.uid), userData);

                  // TODO: Hit email API
                  fetch(`${hostname}/api/email/welcome`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + user.accessToken,
                    },
                    body: JSON.stringify({
                      email: email,
                      name: name,
                      isTutor: false,
                    }),
                  });

                  setSubmit(false);
                  // Success alert
                  Platform.OS === 'web'
                    ? customAlert.success('[Sign up completed] Thank you for signing up!')
                    : Alert.alert('Success!', 'Account created successfully!', [
                        {
                          text: 'OK',
                          onPress: () => console.log('OK Pressed'),
                        },
                      ]);
                } else {
                  setSubmit(false);
                  Platform.OS === 'web'
                    ? customAlert.error(
                        '[Sign up error] There was an issue initializing your account. Please try again later or contact support.'
                      )
                    : Alert.alert(
                        'Error signing up!',
                        'There was an issue initializing your account. Please try again later or contact support.',
                        [
                          {
                            text: 'OK',
                            onPress: () => console.log('OK Pressed'),
                          },
                        ]
                      );
                }
              })
              .catch((error) => {
                console.log(error);
                setSubmit(false);
                Platform.OS === 'web'
                  ? customAlert.error(
                      '[Sign up error] There was an issue initializing your account. Please try again later or contact support.'
                    )
                  : Alert.alert(
                      'Error signing up!',
                      'There was an issue initializing your account. Please try again later or contact support.',
                      [{ text: 'OK', onPress: () => console.log('OK Pressed') }]
                    );
              });
          } else {
            let userData = {
              created: new Date(),
              uid: user.uid,
              id: user.uid,
              email: email,
              name: name + " " + lastName,
              picture: null,
              birthday: birthdayDate,
              lastOnline: new Date(),
              isTutor: true,
              isOnboarded: false,
              stripe: {
                confirmed: false,
              },
            };

            setDoc(doc(db, 'users', user.uid), userData);

            // TODO: Hit email API
            fetch(`${hostname}/api/email/welcome`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.accessToken,
              },
              body: JSON.stringify({
                email: email,
                name: name + " " + lastName,
                isTutor: true,
              }),
            });

            // Success alert
            Platform.OS === 'web'
              ? customAlert.success('[Sign up completed] Thank you for signing up!')
              : Alert.alert('Success!', 'Account created successfully!', [
                  { text: 'OK', onPress: () => console.log('OK Pressed') },
                ]);
          }
        })
        .catch((error) => {
          setSubmit(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode == 'auth/email-already-in-use') {
            Platform.OS === 'web'
              ? customAlert.error('[Sign up error] Email already in use')
              : Alert.alert('Error signing up!', 'Email already in use', [
                  { text: 'OK', onPress: () => console.log('OK Pressed') },
                ]);
          } else {
            console.log(error);
            Platform.OS === 'web'
              ? customAlert.error('[Sign up error] Error signing up, please try again later')
              : Alert.alert('Error signing up!', errorMessage, [
                  { text: 'OK', onPress: () => console.log('OK Pressed') },
                ]);
          }
        });
    } else {
      setSubmit(false);
      Platform.OS === 'web'
        ? customAlert.error('[Sign up error] Please input all fields')
        : Alert.alert('Error signing up!', 'Please input all fields', [
            { text: 'OK', onPress: () => console.log('OK Pressed') },
          ]);
    }
  };

  // Styles
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      backgroundColor: background,
      display: 'flex',
      flexDirection: Platform.OS === 'web' ? 'row' : null,
      width: '100%',
      // maxWidth: Platform.OS === "web" ? 1350 : null,
      margin: Platform.OS === 'web' ? 'auto' : null,
    },
    title: {
      marginBottom: 30,
      fontSize: 36,
      color: text,
      fontWeight: 'bold',
      fontFamily: 'Satoshi_900',
    },
    input: {
      backgroundColor: backgroundVariant,
      padding: 15,
      fontSize: 16,
      marginTop: 15,
      borderRadius: defaultStyles.radius,
      border: '1px solid ' + border,
      fontFamily: defaultStyles.regularFont,
      color: text,
    },
    imageButton: {
      marginTop: 15,
      marginRight: 15,
      backgroundColor: backgroundVariant,
      border: '1px solid ' + border,
      borderRadius: defaultStyles.radius,
      width: 55,
      height: 55,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      backgroundColor: primary,
      padding: 15,
      fontSize: 16,
      marginTop: 15,
      borderRadius: defaultStyles.buttonRadius,
      textAlign: 'center',
      fontFamily: defaultStyles.boldFont,
    },
    link: {
      marginTop: 15,
      fontSize: 14,
      color: primaryVariant,
      fontFamily: defaultStyles.boldFont,
    },
  });

  // Return register screen
  return (
    <SafeAreaView style={styles.container}>
      <Header navigation={navigation}></Header>

      <View style={styles.maxWidth}>
        <ScrollView style={{paddingTop:90, flex:1}} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}  contentContainerStyle={{flexGrow: 1, justifyContent: 'space-between'}}>
        <View
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 950,
            margin: 'auto',
            padding: 15,
            paddingBottom:60
          }}
        > 
          <Text style={styles.title}>Register</Text>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {/* <TouchableOpacity onPress={pickImage} style={styles.imageButton}>
                {image ? (
                  <Image
                    style={{
                      width: 55,
                      height: 55,
                      border: '1px solid ' + border,
                      borderRadius: defaultStyles.radius,
                      opacity: 1,
                    }}
                    source={{ uri: image }}
                  />
                ) : (
                  <Image
                    style={{
                      width: 55,
                      height: 55,
                      border: '1px solid ' + border,
                      borderRadius: defaultStyles.radius,
                    }}
                    source={{
                      uri: 'https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e',
                    }}
                  />
                )}
              </TouchableOpacity> */}
              <TextInput
                value={name}
                onChangeText={(t) => {
                  setName(t);
                }}
                placeholderTextColor='#999999'
                style={[styles.input, { marginRight: 8, flex: 1 }]}
                placeholder='First Name'
              />
              <TextInput
                value={lastName}
                onChangeText={(t) => {
                  setLastName(t);
                }}
                placeholderTextColor='#999999'
                style={[styles.input, { marginLeft: 8, flex: 1 }]}
                placeholder='Last Name'
              />
            </View>

          <TextInput
            value={email}
            onChangeText={(t) => {
              setEmail(t);
            }}
            placeholderTextColor='#999999'
            style={styles.input}
            textContentType='emailAddress'
            placeholder='Email'
          ></TextInput>

          <View style={[styles.input, {width:"100%", display:"flex", flexDirection:"row-reverse"}]} >
              <TouchableOpacity onPress={() => {
                  setShowPassword(!showPassword);
              }} style={{marginRight:5, maxWidth:30, flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                  {
                  showPassword ? <Feather name="eye-off" size={16} color={textVariant} /> : <Feather name="eye" size={16} color={textVariant} />
                  }
              </TouchableOpacity>

              <TextInput style={{
                  fontFamily: defaultStyles.regularFont,
                  color: text,
                  fontSize: 16,
                  flex:1
              }} value={password} onChangeText={(t) => {setPassword(t)}} placeholderTextColor="#999999" secureTextEntry={
                  showPassword ? false : true
              } textContentType="password" placeholder='Password'></TextInput>
          </View>

          <View style={[styles.input, {width:"100%", display:"flex", flexDirection:"row-reverse"}]} >
              <TouchableOpacity onPress={() => {
                  setShowConfirmPassword(!showConfirmPassword);
              }} style={{marginRight:5, maxWidth:30, flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                  {
                  showConfirmPassword ? <Feather name="eye-off" size={16} color={textVariant} /> : <Feather name="eye" size={16} color={textVariant} />
                  }
              </TouchableOpacity>

              <TextInput style={{
                  fontFamily: defaultStyles.regularFont,
                  color: text,
                  fontSize: 16,
                  flex:1
              }} value={confirmPassword} onChangeText={(t) => {setConfirmPassword(t)}} placeholderTextColor="#999999" secureTextEntry={
                  showConfirmPassword ? false : true
              } textContentType="password" placeholder='Confirm Password'></TextInput>
          </View>



          {/* Birthday */}
          {Platform.OS == 'web' && accountType === "tutor" ? (
              <View style={{marginTop:15}}>
                  <Text
                      style={{
                          color: text,
                          fontSize:14,
                          fontFamily: defaultStyles.regularFont,
                      }}
                  >
                      Birthday
                  </Text>
                  <input type="date"
                      style={{
                          colorScheme: darkMode ? 'dark' : 'light',
                          marginTop: 5,
                          borderRadius: defaultStyles.radius,
                          fontFamily: defaultStyles.regularFont,
                          padding: 15,
                          color: text,
                          backgroundColor: backgroundVariant,
                          border: '1px solid ' + border
                      }}
                      value={birthday}
                      onChange={(e) => {
                          setBirthday(e.target.value);
                      }}
                  />
              </View>
          ) : null }

          {/* Account type (student or tutor) */}
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                color: text,
                fontSize: 14,
                fontFamily: defaultStyles.regularFont,
              }}
            >
              Account type:
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TouchableOpacity
                onPress={() => {
                  setAccountType('student');
                }}
                style={[
                  styles.imageButton,
                  {
                    marginTop: 5,
                    backgroundColor:
                      accountType === 'student' ? primary : backgroundVariant,
                  },
                ]}
              >
                {/* Student icon */}
                <FontAwesome5
                  style={{ marginTop: 5, marginBottom: 2.5 }}
                  name='user-graduate'
                  size={19}
                  color={accountType === 'student' ? '#fff' : textVariant}
                />
                <Text
                  style={{
                    color: accountType === 'student' ? '#fff' : textVariant,
                    fontSize: 10,
                    fontFamily: defaultStyles.boldFont,
                  }}
                >
                  Student
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  setAccountType('tutor');
                }}
                style={[
                  styles.imageButton,
                  {
                    marginTop: 5,
                    backgroundColor:
                      accountType === 'tutor' ? primary : backgroundVariant,
                  },
                ]}
              >
                {/* Tutor icon */}
                <FontAwesome5
                  style={{ marginTop: 5, marginBottom: 2.5 }}
                  name='chalkboard-teacher'
                  size={19}
                  color={accountType === 'tutor' ? '#fff' : textVariant}
                />
                <Text
                  style={{
                    color: accountType === 'tutor' ? '#fff' : textVariant,
                    fontSize: 10,
                    fontFamily: defaultStyles.boldFont,
                  }}
                >
                  Tutor
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          {/* Checkbox confirm terms of service */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setTerms(!terms);
              }}
              style={{
                width: 20,
                height: 20,
                backgroundColor: terms ? primary : backgroundVariant,
                border: '1px solid' + border,
                borderRadius: defaultStyles.radius,
                marginRight: 10,
              }}
            ></TouchableOpacity>
            <Text
              style={{
                color: text,
                fontSize: 12,
                fontFamily: defaultStyles.regularFont,
              }}
            >
              I agree to the
              <TouchableOpacity
                onPress={() => {
                  window.open("/tos", "tutred-terms", "width=600,height=600");
                }}
              >
                <Text
                  style={{
                    color: primaryVariant,
                    fontFamily: defaultStyles.boldFont,
                  }}
                >
                  Terms of Service
                </Text>
              </TouchableOpacity>
              and confirm I am at least 13 years old.
            </Text>
          </View>
              
          <Hoverable>
           { isHovered => (  <TouchableOpacity
              disabled={submit}
              onPress={signup}
              style={[
                styles.button,
                {
                  opacity: submit ? 0.5 : 1,
                  backgroundColor: isHovered ? primaryVariant : primary,
                },
              ]}
            >
              <Text
                style={{
                  textAlign: 'center',
                  fontFamily: defaultStyles.boldFont,
                  fontSize: 16,
                  color: '#fff',
                }}
              >
                Register
              </Text>
             </TouchableOpacity>
           )  
           }
          </Hoverable>   
        
          <Link
            style={{ margin:'auto', marginTop: 60 }}
            to={{
              screen: 'Login'
            }}
          >
            <Text
              style={[
                {
                  textAlign: 'center',
                  color: textVariant,
                  fontFamily: defaultStyles.lightFont,
                  fontSize: 14,
                },
              ]}
            >
              Already have an account? <Text style={styles.link}>Login</Text>
            </Text>
          </Link>
        </View>
       {/* Footer  */}
       <Footer></Footer>
        </ScrollView>
      </View>

    </SafeAreaView>
  );
};
