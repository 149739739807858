//Package imports
import { Feather } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
	Alert,
	Modal,
	Platform,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useUserContext } from "../contexts/userContext";
import Hoverable from "../utils/hover/Hoverable";
import { useColorsContext, useStyles } from "../utils/styles";
const dev = false;
const hostname = dev
	? "https://tutredstage-266226951372.herokuapp.com"
	: "https://tutred-10235aff3fe9.herokuapp.com";

export const SignUpModal = ({ loginModal, setLoginModal }) => {
	const customAlert = useAlert();

	const [error, setError] = useState(null);

	// States and variables
	const { user } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
	const auth = getAuth();
	const db = getFirestore();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [accountType, setAccountType] = useState("student");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [birthday, setBirthday] = useState("");
	const [terms, setTerms] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [lastName, setLastName] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const {
		darkMode,
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			padding: Platform.OS === "web" ? 30 : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},

		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "40%"
				: isLg
				? "50%"
				: isMd
				? "60%"
				: isSm
				? "70%"
				: "90%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: " space-between",
		},
		closeButton: {
			width: 30,
		},
		modalTitle: {
			fontSize: 32,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		marginTop: { marginVertical: 20 },
		infoContainer: {
			flex: 1,
			display: "flex",
			flexDirection: !isMd ? "column" : "row",
			justifyContent: "space-between",
		},
		mainContainer: {
			flex: 1,
			padding: 30,
			paddingTop: 0,
		},

		button: {
			backgroundColor: primary,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.buttonRadius,
			textAlign: "center",
			fontFamily: defaultStyles.boldFont,
			paddingTop: 0,
			paddingBottom: 0,
			height: 45,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		buttonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 225,
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		input: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
		},
		imageButton: {
			marginTop: 15,
			marginRight: 15,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			borderRadius: defaultStyles.radius,
			width: 55,
			height: 55,
			alignSelf: "center",
			justifyContent: "center",
			alignItems: "center",
		},
	});

	const checkPassword = (str) => {
		var re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
		return re.test(str);
	};

	const signup = () => {
		if (
			!submit &&
			email &&
			name &&
			lastName &&
			password &&
			confirmPassword
		) {
			setSubmit(true);
			// Validate password
			if (password != confirmPassword) {
				setError("Passwords do not match");
				setSubmit(false);
				return;
			}
			if (!checkPassword(password)) {
				setError(
					"Password must be 6-16 characters and contain at least one number and one special character"
				);
				setSubmit(false);
				return;
			}

			var birthdayDate;
			if (accountType == "tutor") {
				// Validate birthday
				birthdayDate = new Date(birthday);
				let today = new Date();
				// Check if birthday 13 years or more
				if (today.getFullYear() - birthdayDate.getFullYear() < 13) {
					setError("You must be 13 years or older to use this app");
					setSubmit(false);
					return;
				}
			}

			// Validate terms
			if (!terms) {
				setError("Please accept the terms and conditions");
				setSubmit(false);
				return;
			}

			// Create user
			createUserWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {
					// Signed in
					const user = userCredential.user;
					if (accountType == "student") {
						// Create stripe customer
						fetch(`${hostname}/api/create-customer`, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								email: email,
							}),
						})
							.then((response) => response.json())
							.then((data) => {
								if (data.success) {
									let urlId = Math.floor(
										100000 + Math.random() * 900000
									);
									let userData = {
										created: new Date(),
										uid: user.uid,
										id: user.uid,
										email: email,
										name: name + " " + lastName,
										picture: null,
										birthday: "",
										lastOnline: new Date(),
										isTutor: false,
										customerId: data.customerId,
										URL: urlId,
									};

									setDoc(
										doc(db, "users", user.uid),
										userData
									);

									// TODO: Hit email API
									fetch(`${hostname}/api/email/welcome`, {
										method: "POST",
										headers: {
											"Content-Type": "application/json",
											Authorization:
												"Bearer " + user.accessToken,
										},
										body: JSON.stringify({
											email: email,
											name: name,
											isTutor: false,
										}),
									});

									setSubmit(false);
									// Success alert
									setLoginModal(false);
									Platform.OS === "web"
										? customAlert.success(
												"[Sign up completed] Thank you for signing up!"
										  )
										: Alert.alert(
												"Success!",
												"Account created successfully!",
												[
													{
														text: "OK",
														onPress: () =>
															console.log(
																"OK Pressed"
															),
													},
												]
										  );
								} else {
									setSubmit(false);
									setLoginModal(false);
									Platform.OS === "web"
										? customAlert.error(
												"[Sign up error] There was an issue initializing your account. Please try again later or contact support."
										  )
										: Alert.alert(
												"Error signing up!",
												"There was an issue initializing your account. Please try again later or contact support.",
												[
													{
														text: "OK",
														onPress: () =>
															console.log(
																"OK Pressed"
															),
													},
												]
										  );
								}
							})
							.catch((error) => {
								console.log(error);
								setSubmit(false);
								setLoginModal(false);
								Platform.OS === "web"
									? customAlert.error(
											"[Sign up error] There was an issue initializing your account. Please try again later or contact support."
									  )
									: Alert.alert(
											"Error signing up!",
											"There was an issue initializing your account. Please try again later or contact support.",
											[
												{
													text: "OK",
													onPress: () =>
														console.log(
															"OK Pressed"
														),
												},
											]
									  );
							});
					} else {
						let userData = {
							created: new Date(),
							uid: user.uid,
							id: user.uid,
							email: email,
							name: name + " " + lastName,
							picture: null,
							birthday: birthdayDate,
							lastOnline: new Date(),
							isTutor: true,
							isOnboarded: false,
							stripe: {
								confirmed: false,
							},
						};

						setDoc(doc(db, "users", user.uid), userData);

						// TODO: Hit email API
						fetch(`${hostname}/api/email/welcome`, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + user.accessToken,
							},
							body: JSON.stringify({
								email: email,
								name: name + " " + lastName,
								isTutor: true,
							}),
						});

						// Success alert
						setLoginModal(false);
						Platform.OS === "web"
							? customAlert.success(
									"[Sign up completed] Thank you for signing up!"
							  )
							: Alert.alert(
									"Success!",
									"Account created successfully!",
									[
										{
											text: "OK",
											onPress: () =>
												console.log("OK Pressed"),
										},
									]
							  );
					}
				})
				.catch((error) => {
					setSubmit(false);
					const errorCode = error.code;
					const errorMessage = error.message;
					if (errorCode == "auth/email-already-in-use") {
						setError("Email already in use");
					} else {
						setError(errorMessage);
					}
				});
		} else {
			setSubmit(false);
			setError("Please input all fields");
		}
	};

	return (
		<View>
			<Modal
				key={3}
				animationType="fade"
				transparent={true}
				visible={loginModal}
				onRequestClose={() => {
					setLoginModal(!loginModal);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							<TouchableOpacity
								style={styles.closeButton}
								onPress={() => {
									setLoginModal(!loginModal);
								}}
							>
								<CloseOutlined
									style={{ fontSize: 22, color: text }}
								></CloseOutlined>
							</TouchableOpacity>

							<View
								style={{
									flex: 1,
									paddingTop: 15,
									alignItems: "center",
								}}
							>
								<Text style={styles.modalTitle}>Sign Up</Text>
							</View>

							<View style={styles.closeButton}></View>
						</View>
						<View style={styles.mainContainer}>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<TextInput
									value={name}
									onChangeText={(t) => {
										setName(t);
									}}
									placeholderTextColor="#999999"
									style={[
										styles.input,
										{ marginRight: 8, flex: 1 },
									]}
									placeholder="First Name"
								/>
								<TextInput
									value={lastName}
									onChangeText={(t) => {
										setLastName(t);
									}}
									placeholderTextColor="#999999"
									style={[
										styles.input,
										{ marginLeft: 8, flex: 1 },
									]}
									placeholder="Last Name"
								/>
							</View>

							<TextInput
								value={email}
								onChangeText={(t) => {
									setEmail(t);
								}}
								placeholderTextColor="#999999"
								style={styles.input}
								textContentType="emailAddress"
								placeholder="Email"
							></TextInput>

							<View
								style={[
									styles.input,
									{
										width: "100%",
										display: "flex",
										flexDirection: "row-reverse",
									},
								]}
							>
								<TouchableOpacity
									onPress={() => {
										setShowPassword(!showPassword);
									}}
									style={{
										marginRight: 5,
										maxWidth: 30,
										flex: 1,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{showPassword ? (
										<Feather
											name="eye-off"
											size={16}
											color={textVariant}
										/>
									) : (
										<Feather
											name="eye"
											size={16}
											color={textVariant}
										/>
									)}
								</TouchableOpacity>

								<TextInput
									style={{
										fontFamily: defaultStyles.regularFont,
										color: text,
										fontSize: 16,
										flex: 1,
									}}
									value={password}
									onChangeText={(t) => {
										setPassword(t);
									}}
									placeholderTextColor="#999999"
									secureTextEntry={
										showPassword ? false : true
									}
									textContentType="password"
									placeholder="Password"
								></TextInput>
							</View>

							<View
								style={[
									styles.input,
									{
										width: "100%",
										display: "flex",
										flexDirection: "row-reverse",
									},
								]}
							>
								<TouchableOpacity
									onPress={() => {
										setShowConfirmPassword(
											!showConfirmPassword
										);
									}}
									style={{
										marginRight: 5,
										maxWidth: 30,
										flex: 1,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{showConfirmPassword ? (
										<Feather
											name="eye-off"
											size={16}
											color={textVariant}
										/>
									) : (
										<Feather
											name="eye"
											size={16}
											color={textVariant}
										/>
									)}
								</TouchableOpacity>

								<TextInput
									style={{
										fontFamily: defaultStyles.regularFont,
										color: text,
										fontSize: 16,
										flex: 1,
									}}
									value={confirmPassword}
									onChangeText={(t) => {
										setConfirmPassword(t);
									}}
									placeholderTextColor="#999999"
									secureTextEntry={
										showConfirmPassword ? false : true
									}
									textContentType="password"
									placeholder="Confirm Password"
								></TextInput>
							</View>

							{/* Birthday */}
							{Platform.OS == "web" && accountType === "tutor" ? (
								<View style={{ marginTop: 15 }}>
									<Text
										style={{
											color: text,
											fontSize: 14,
											fontFamily:
												defaultStyles.regularFont,
										}}
									>
										Birthday
									</Text>
									<input
										type="date"
										style={{
											colorScheme: darkMode
												? "dark"
												: "light",
											marginTop: 5,
											borderRadius: defaultStyles.radius,
											fontFamily:
												defaultStyles.regularFont,
											padding: 15,
											color: text,
											backgroundColor: backgroundVariant,
											border: "1px solid " + border,
										}}
										value={birthday}
										onChange={(e) => {
											setBirthday(e.target.value);
										}}
									/>
								</View>
							) : null}

							{/* Checkbox confirm terms of service */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									marginTop: 30,
								}}
							>
								<TouchableOpacity
									onPress={() => {
										setTerms(!terms);
									}}
									style={{
										width: 20,
										height: 20,
										backgroundColor: terms
											? primary
											: backgroundVariant,
										border: "1px solid" + border,
										borderRadius: defaultStyles.radius,
										marginRight: 10,
									}}
								></TouchableOpacity>
								<Text
									style={{
										color: text,
										fontSize: 12,
										fontFamily: defaultStyles.regularFont,
									}}
								>
									I agree to the
									<TouchableOpacity
										onPress={() => {
											// window.open("https://robertoinfante.notion.site/Terms-of-service-4f061b576e8b42eaafa045d1664c52ac", "tutred-terms", "width=600,height=600");
										}}
									>
										<Text
											style={{
												color: primaryVariant,
												fontFamily:
													defaultStyles.boldFont,
											}}
										>
											{" "}
											Terms of Service{" "}
										</Text>
									</TouchableOpacity>
									and confirm I am at least 13 years old.
								</Text>
							</View>

							<Hoverable>
								{(isHovered) => (
									<TouchableOpacity
										disabled={submit}
										onPress={signup}
										style={[
											styles.button,
											{
												opacity: submit ? 0.5 : 1,
												backgroundColor: isHovered
													? primaryVariant
													: primary,
											},
										]}
									>
										<Text
											style={{
												textAlign: "center",
												fontFamily:
													defaultStyles.boldFont,
												fontSize: 16,
												color: "#fff",
											}}
										>
											Register
										</Text>
									</TouchableOpacity>
								)}
							</Hoverable>
						</View>

						{error && (
							<Text
								style={{
									color: "red",
									fontFamily: defaultStyles.boldFont,
									fontSize: 14,
									textAlign: "center",
									marginBottom: 30,
								}}
							>
								{error}
							</Text>
						)}
					</View>
				</View>
			</Modal>
		</View>
	);
};
