import React, { useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Menu, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

const times = [
	"12:00 AM",
	"12:30 AM",
	"1:00 AM",
	"1:30 AM",
	"2:00 AM",
	"2:30 AM",
	"3:00 AM",
	"3:30 AM",
	"4:00 AM",
	"4:30 AM",
	"5:00 AM",
	"5:30 AM",
	"6:00 AM",
	"6:30 AM",
	"7:00 AM",
	"7:30 AM",
	"8:00 AM",
	"8:30 AM",
	"9:00 AM",
	"9:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
	"11:30 AM",
	"12:00 PM",
	"12:30 PM",
	"1:00 PM",
	"1:30 PM",
	"2:00 PM",
	"2:30 PM",
	"3:00 PM",
	"3:30 PM",
	"4:00 PM",
	"4:30 PM",
	"5:00 PM",
	"5:30 PM",
	"6:00 PM",
	"6:30 PM",
	"7:00 PM",
	"7:30 PM",
	"8:00 PM",
	"8:30 PM",
	"9:00 PM",
	"9:30 PM",
	"10:00 PM",
	"10:30 PM",
	"11:00 PM",
	"11:30 PM",
];

// Tutor Review component
export const CustomTimeInput = ({ time, setTime }) => {
	const [fullTime, setFullTime] = React.useState(time);

	// Colors and styles
	const {
		darkMode,
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		input: {
			zIndex: 999,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingTop: 10,
			paddingBottom: 10,
			paddingLeft: 20,
			paddingRight: 20,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	useEffect(() => {
		if (fullTime) {
			saveTime();
		}
	}, [fullTime]);

	const saveTime = () => {
		setTime(fullTime);
	};

	return (
		<Menu>
			<MenuTrigger style={styles.input}>
				<Text
					style={{
						color: text,
						fontSize: 16,
						fontFamily: defaultStyles.boldFont,
					}}
				>
					{fullTime ? fullTime : "Select a time"}
				</Text>
			</MenuTrigger>

			<MenuOptions
				optionsContainerStyle={{
					backgroundColor: backgroundVariant,
					border: "1px solid" + border,
					borderRadius: defaultStyles.radius,
					padding: 15,
					marginLeft: 0,
					marginTop: 60,
					width: 250,
					shadowColor: "rgba(0, 0, 0, 0.1)",
					shadowOpacity: 0.8,
					shadowOffset: { width: 2, height: 2 },
					shadowRadius: 4,
				}}
				customStyles={{
					optionText: {
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
					},
				}}
			>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<select
						style={{
							flex: 1,
							colorScheme: darkMode ? "dark" : "light",
							borderRadius: defaultStyles.radius,
							fontFamily: defaultStyles.boldFont,
							paddingTop: 10,
							paddingBottom: 10,
							paddingLeft: 15,
							paddingRight: 15,
							color: text,
							backgroundColor: backgroundVariant,
							border: "1px solid " + border,
							marginRight: 5,
							fontSize: 19,
						}}
						onChange={(e) => {
							setFullTime(e.target.value);
						}}
						value={fullTime}
					>
						<option value="" selected disabled>
							Select a time
						</option>
						{times.map((timeO, index) => {
							return <option value={timeO}>{timeO}</option>;
						})}
					</select>
				</View>
			</MenuOptions>
		</Menu>
	);
};
