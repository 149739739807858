import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Text, View } from "react-native";
import { useColorsContext, useStyles } from "../utils/styles";

// Subjects component
export const SubjectFilterIcon = ({ subject, active }) => {
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	return (
		<View
			style={{
				marginRight: 30,
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<FontAwesome
				color={active ? primaryVariant : textVariant}
				size={16}
				name={subject.icon}
			></FontAwesome>
			<Text
				style={{
					marginTop: 5,
					fontSize: 14,
					color: active ? primaryVariant : textVariant,
					fontFamily: defaultStyles.boldFont,
				}}
			>
				{subject.subject}
			</Text>
		</View>
	);
};
