import { useEffect, useState } from "react";
import {
	ScrollView,
	StyleSheet,
	Text,
	View,
	Modal,
	Pressable,
	TouchableOpacity,
	Alert,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import Slider from "@react-native-community/slider";
import { FontAwesome } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";
import { CloseOutlined } from "@mui/icons-material";

import { cities, provinces } from "../config/locations";
import { subjects as subjectData } from "../config/subjects";
import { languages } from "../config/languages";

export const FiltersModal = ({ modalVisible, setModalVisible, setFilters }) => {
	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	// Appearance
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "50%"
				: isLg
				? "60%"
				: isMd
				? "70%"
				: isSm
				? "80%"
				: "90%",
			height: isXl
				? "70%"
				: isLg
				? "70%"
				: isMd
				? "70%"
				: isSm
				? "80%"
				: "90%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			// justifyContent: 'space-between',
			alignItems: "center",
			marginBottom: 5,
		},
		closeButton: {
			// position: 'absolute',
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		circleButton: {
			width: 40,
			height: 40,
			borderRadius: defaultStyles.buttonRadius,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			marginRight: 15,
			marginLeft: "auto",
		},
		applyButton: {
			borderRadius: defaultStyles.buttonRadius,
			height: 40,
			paddingHorizontal: 15,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			backgroundColor: primary,
		},

		marginTop: {
			marginTop: 15,
		},
		title: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
	});

	// States
	const [selectedSubject, setSelectedSubject] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [selectedRating, setSelectedRating] = useState(0);
	const [selectedLanguage, setSelectedLanguage] = useState("all");
	const [selectedLocation, setSelectedLocation] = useState("all");
	const [selectedPrice, setSelectedPrice] = useState("all");
	const [sliderPosition, setSliderPosition] = useState(0);
	const [verified, setVerified] = useState(false);

	// States for picker data
	const [subjects, setSubjects] = useState([]);
	const [skills, setSkills] = useState([]);
	const [majorCities, setMajorCities] = useState([]);
	const [majorProvinces, setMajorProvinces] = useState([]);
	const [majorLanguages, setMajorLanguages] = useState([]);

	// Functions
	const saveFilters = () => {
		setFilters([
			{ name: "subject", value: selectedSubject },
			{ name: "skills", value: selectedSkills },
			{ name: "rating", value: selectedRating },
			{ name: "language", value: selectedLanguage },
			{ name: "location", value: selectedLocation },
			{ name: "price", value: selectedPrice },
			{ name: "verified", value: verified },
		]);
		setModalVisible(!modalVisible);
	};

	const resetFilters = () => {
		setSelectedSubject([]);
		setSelectedSkills([]);
		setSelectedRating(0);
		setSelectedLanguage("all");
		setSelectedLocation("all");
		setSliderPosition(0.01);
		setSelectedPrice("all");
		setVerified(false);
	};

	useEffect(() => {
		if (languages) {
			var majorLanguages = [
				{
					value: "all",
					label: "All",
				},
			];
			languages.forEach((language) => {
				majorLanguages.push({
					value: language,
					label: language,
				});
			});
			setMajorLanguages(majorLanguages);
		}

		if (provinces) {
			var majorProvinces = [
				{
					value: "all",
					label: "All",
				},
			];
			provinces.forEach((province) => {
				majorProvinces.push({
					value: province,
					label: province,
				});
			});
			setMajorProvinces(majorProvinces);
		}

		if (subjectData) {
			var subjectsArr = [];
			let subjectKeys = Object.keys(subjectData);
			subjectKeys.forEach((subject) => {
				subjectsArr.push({
					subject: subject,
					value: subject,
				});
			});

			// Sort alphabetically
			subjectsArr.sort((a, b) => {
				if (a.subject < b.subject) {
					return -1;
				}

				if (a.subject > b.subject) {
					return 1;
				}

				return 0;
			});

			subjectsArr.unshift({
				subject: "All",
				value: "all",
			});

			setSubjects(subjectsArr);

			// Set skills
			var skillsArr = [];
			subjectKeys.forEach((subject) => {
				let skills = subjectData[subject];
				skills.forEach((skill) => {
					skillsArr.push({
						label: skill,
						value: skill,
					});
				});
			});

			// Sort alphabetically
			skillsArr.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}

				if (a.label > b.label) {
					return 1;
				}

				return 0;
			});

			skillsArr.unshift({
				label: "All",
				value: "all",
				enabled: false,
			});

			setSkills(skillsArr);
		}
	}, []);

	// If subjet is selected, set skills to only those skills
	useEffect(() => {
		if (selectedSubject.length > 0) {
			if (selectedSubject.includes("all")) {
				setSelectedSubject([]);
				var skillsArr = [];
				let subjectKeys = Object.keys(subjectData);
				subjectKeys.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			} else {
				var skillsArr = [];
				selectedSubject.forEach((subject) => {
					let skills = subjectData[subject];
					skills.forEach((skill) => {
						skillsArr.push({
							label: skill,
							value: skill,
						});
					});
				});

				// Sort alphabetically
				skillsArr.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}

					if (a.label > b.label) {
						return 1;
					}

					return 0;
				});

				skillsArr.unshift({
					label: "All",
					value: "all",
					enabled: false,
				});
				setSkills(skillsArr);
			}
		}
	}, [selectedSubject]);

	// If skills selected check if all is selected
	useEffect(() => {
		if (selectedSkills.length > 0) {
			if (selectedSkills.includes("all")) {
				setSelectedSkills([]);
			}
		}
	}, [selectedSkills]);

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(!modalVisible);
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={styles.modalHeader}>
						{/* Close filters button */}
						<TouchableOpacity
							style={styles.closeButton}
							onPress={() => {
								setModalVisible(!modalVisible);
							}}
						>
							<CloseOutlined
								style={{ fontSize: 22, color: text }}
							></CloseOutlined>
						</TouchableOpacity>

						{/* Title */}
						<Text style={styles.modalTitle}>Advanced Search</Text>

						{/* Clear filters */}
						<TouchableOpacity
							onPress={resetFilters}
							style={[styles.circleButton]}
						>
							<FontAwesome name="undo" size={14} color={text} />
						</TouchableOpacity>

						{/* Apply filters button */}
						<TouchableOpacity
							style={styles.applyButton}
							onPress={saveFilters}
						>
							<Text style={styles.buttonText}>Search</Text>
						</TouchableOpacity>
					</View>

					<ScrollView showsVerticalScrollIndicator={false}>
						{/* Subject  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>Subject</Text>
							<Text style={styles.pickerInfoText}>
								Filter by subject.{" "}
							</Text>

							{/* Render selected subjects */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									maxWidth: 350,
								}}
							>
								<ScrollView
									horizontal={true}
									showsHorizontalScrollIndicator={false}
								>
									{selectedSubject.length > 0
										? selectedSubject.map((item, i) => (
												<View
													key={i}
													style={{
														marginRight: 5,
														marginTop: 5,
														backgroundColor:
															backgroundVariant,
														border: `1px solid ${border}`,
														borderRadius:
															defaultStyles.buttonRadius,
														padding: 8,
													}}
												>
													<Text
														style={{
															color: textVariant,
															fontFamily:
																defaultStyles.regularFont,
														}}
													>
														{selectedSubject[i]}
													</Text>
												</View>
										  ))
										: null}
								</ScrollView>
							</View>
							<Picker
								style={styles.picker}
								selectedValue={
									selectedSubject.length > 0
										? selectedSubject[
												selectedSubject.length - 1
										  ]
										: "all"
								}
								onValueChange={(itemValue, itemIndex) =>
									setSelectedSubject((prevState) => [
										...prevState,
										itemValue,
									])
								}
							>
								{subjects.map((item, i) => (
									<Picker.Item
										key={i}
										label={subjects[i].subject}
										value={subjects[i].value}
									/>
								))}
							</Picker>
						</View>

						{/* Skills  */}
						<View style={styles.marginTop}>
							<Text style={styles.title}>Skills</Text>
							<Text style={styles.pickerInfoText}>
								Filter by skills.{" "}
							</Text>

							{/* Render selected skill if any  */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									maxWidth: 350,
								}}
							>
								<ScrollView
									horizontal={true}
									showsHorizontalScrollIndicator={false}
								>
									{selectedSkills.length > 0
										? selectedSkills.map((item, i) => (
												<View
													key={i}
													style={{
														marginRight: 5,
														marginTop: 5,
														backgroundColor:
															backgroundVariant,
														border: `1px solid ${border}`,
														borderRadius:
															defaultStyles.buttonRadius,
														padding: 8,
													}}
												>
													<Text
														style={{
															color: textVariant,
															fontFamily:
																defaultStyles.regularFont,
														}}
													>
														{selectedSkills[i]}
													</Text>
												</View>
										  ))
										: null}
								</ScrollView>
							</View>
							<Picker
								style={styles.picker}
								selectedValue={
									selectedSkills.length > 0
										? selectedSkills[
												selectedSkills.length - 1
										  ]
										: "all"
								}
								onValueChange={(itemValue, itemIndex) =>
									setSelectedSkills((prevState) => [
										...prevState,
										itemValue,
									])
								}
							>
								{skills.map((item, i) => (
									<Picker.Item
										key={i}
										label={skills[i].label}
										value={skills[i].value}
									/>
								))}
							</Picker>
						</View>
					</ScrollView>
				</View>
			</View>
		</Modal>
	);
};
