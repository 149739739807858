// Imports
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { Link } from "@react-navigation/native";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import FadeIn from "react-native-fade-in-image";
import { useMediaQuery } from "react-responsive";
import { useFirebaseContext } from "../contexts/firebaseContext";
import { useColorsContext, useStyles } from "../utils/styles";

// Tutor component
export const TutorListCard = ({
	navigation,
	tutor,
	favourite,
	maxRating,
	minRating,
}) => {
	const { db } = useFirebaseContext();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	// Media queries
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// States
	const [reviews, setReviews] = useState([]);
	const [rating, setRating] = useState(0);

	// Get all reviews for tutor
	useEffect(() => {
		setReviews([]);
		setRating(0);
		// Get reviews for tutor
		const reviewsRef = collection(db, "reviews");
		const q = query(reviewsRef, where("tutor", "==", tutor.id));
		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			const reviews = [];
			querySnapshot.forEach((doc) => {
				reviews.push(doc.data());
			});
			setReviews(reviews);
		});
		return unsubscribe;
	}, []);

	// On reviews change
	useEffect(() => {
		// Get average rating of review
		let total = 0;
		let count = 0;
		reviews.forEach((review) => {
			total += review.rating;
			count++;
		});
		if (count > 0) {
			setRating((total / count).toFixed(1));
		}
	}, [reviews]);

	// Stylesheet
	const styles = StyleSheet.create({
		tutor: {
			flex: 1,
			display: "flex",
			marginLeft: 10,
			marginRight: 10,
			marginTop: 20,
			marginBottom: 20,
			flexDirection: "column",

			// borderColor: border,
			// borderWidth:1
		},
		tutorName: {
			fontSize: 19,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		online: {
			fontSize: 12,
			color: "#00AA54",
			marginRight: 15,
			marginl: 5,
			fontFamily: defaultStyles.boldFont,
		},
		verified: {
			fontSize: 12,
			color: green,
			marginRight: 15,
			marginl: 5,
			fontFamily: defaultStyles.boldFont,
		},
		tutorInfo: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		tutorRating: {
			color: yellow,
		},
		tutorDescription: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		tutorRate: {
			marginTop: 5,
			fontSize: 14,
			color: text,
			fontFamily: defaultStyles.titleFont,
		},
	});

	let today = new Date();
	var hoursSinceOnline = null;
	if (!tutor.lastOnline) {
		hoursSinceOnline = null;
	} else {
		let lastOnline = new Date(tutor.lastOnline.toDate());
		var hoursSinceOnline = Math.floor(
			(today - lastOnline) / (1000 * 60 * 60)
		);
	}

	if (maxRating && rating >= maxRating) {
		return null;
	}

	if (minRating && rating <= minRating) {
		return null;
	}

	// Skills / subject / language component
	const WordCard = ({ word }) => {
		return (
			<View
				style={{
					marginRight: 5,
					marginTop: 5,
					backgroundColor: backgroundVariant,
					borderRadius: 100,
					paddingVertical: 2,
					paddingHorizontal: 10,
					border: `.5px solid ${border}`,
					display: "flex",
					flexDirection: "row",
					alignContent: "center",
					alignItems: "center",
					justifyContent: "space-evenly",
					// maxWidth: 150,
					height: 30,
				}}
			>
				<Text
					style={{
						fontSize: 12,
						color: textVariant,
						fontFamily: defaultStyles.regularFont,
						// padding: 5,
					}}
				>
					{word}
				</Text>
			</View>
		);
	};

	return (
		// Navigate in new tab
		<Link
			to={`/tutors/${tutor.URL}`}
			// target='_blank'
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				padding: 15,
				borderBottom: "1.5px solid" + border,
			}}
		>
			<View
				style={{
					display: "flex",
				}}
			>
				{/* Image with text on the right side */}
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "flex-start",
						width: "100%",
					}}
				>
					<View style={{ width: 80, position: "relative" }}>
						<FadeIn>
							<Image
								style={{
									width: 80,
									height: 80,
									borderRadius: 100,
								}}
								source={{
									uri: tutor.picture
										? tutor.picture
										: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
								}}
							/>
						</FadeIn>

						{favourite && (
							<FontAwesome
								style={{
									position: "absolute",
									top: 0,
									left: -15,
								}}
								name="heart"
								size={16}
								color={primary}
							/>
						)}

						{tutor.superTutor && (
							<FontAwesome5
								style={{
									position: "absolute",
									bottom: 5,
									right: 0,
								}}
								name="award"
								size={22}
								color={primary}
							/>
						)}
					</View>
					<View style={{ display: "flex", flex: 1 }}>
						<View
							style={{
								flex: 1,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginLeft: 15,
								flexWrap: "wrap",
							}}
						>
							<Text
								style={{
									fontSize: 24,
									fontWeight: 500,
									color: text,
									marginRight: 5,
									fontFamily: defaultStyles.boldFont,
								}}
							>
								{tutor.name.split(" ").length > 1
									? `${tutor.name.split(" ")[0]} ${
											tutor.name.split(" ")[1][0]
									  }.`
									: tutor.name}
							</Text>

							{hoursSinceOnline !== null &&
							hoursSinceOnline <= 1 ? (
								<Text style={styles.online}>
									<FontAwesome
										name="circle"
										size={12}
										color={"#00AA54"}
									/>{" "}
									Online
								</Text>
							) : null}

							{tutor.isVerified ? (
								<Text style={styles.verified}>
									<FontAwesome5
										name="check-circle"
										size={12}
										color={green}
									/>{" "}
									Credentials verified by Tutred
								</Text>
							) : null}

							{reviews.length > 0 && (
								<View
									style={{
										display: "flex",
										flexDirection: "row",
									}}
								>
									<FontAwesome
										name="star"
										size={14}
										color="#FFBF00"
									></FontAwesome>
									<Text
										style={{
											fontSize: 12,
											fontWeight: 500,
											color: textVariant,
											marginLeft: 5,
											fontFamily:
												defaultStyles.regularFont,
										}}
									>
										{rating}
									</Text>

									<Text
										style={{
											fontSize: 12,
											color: textVariant,
											marginLeft: 5,
											fontFamily:
												defaultStyles.regularFont,
										}}
									>
										({reviews.length})
									</Text>
								</View>
							)}
						</View>

						<Text
							style={{
								fontSize: 14,
								color: textVariant,
								marginLeft: 15,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{Object.keys(tutor.languages).map(
								(language, index) => {
									if (
										index ==
										Object.keys(tutor.languages).length - 1
									) {
										language = language;
									} else {
										language = language + ", ";
									}
									return <>{language}</>;
								}
							)}
						</Text>

						<Text
							style={{
								fontSize: 14,
								color: textVariant,
								marginLeft: 15,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{tutor.location.province}
						</Text>

						{/* Price  */}
						<Text
							style={{
								fontSize: 14,
								color: textVariant,
								marginLeft: 15,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{tutor.rate}/hr
						</Text>
					</View>
				</View>

				<Text
					style={{
						fontSize: 14,
						color: textVariant,
						marginTop: 15,
						fontFamily: defaultStyles.regularFont,
					}}
					numberOfLines={3}
				>
					{tutor.description}
				</Text>

				{/* skills component here */}
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "flex-start",
						// justifyContent: 'center',
						flexWrap: "wrap",
						width: "100%",
						marginTop: 10,
					}}
				>
					{Object.keys(tutor.subjects).map((subject, index) => {
						return <WordCard word={subject} />;
					})}

					{Object.keys(tutor.skills).map((skill, index) => {
						return (
							<WordCard
								word={Object.values(tutor.skills)[index]}
							/>
						);
					})}
				</View>
			</View>
		</Link>
	);
};
